import { ReactElement, useState } from 'react';
import './KaliberChipMultiSelect.scss';
import { Path } from 'react-hook-form';
import { ReactComponent as CheckCircle } from '../../assets/icons/CheckCircle.svg';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface KaliberChipMultiSelectProps {
  select?: string[];
  list: string[];
  onChange?: (val: any) => void;
  label: Path<any>;
}

interface SelectChipProps {
  label: string;
  isSelected: boolean;
  onToggle: (label: string) => void;
}

const SelectChip = ({ label, isSelected, onToggle }: SelectChipProps) => (
  <div
    className={`chip-multi-select-container__chip ${
      isSelected ? 'chip-multi-select-container__chip--selected' : ''
    }`}
    onClick={() => onToggle(label)}>
    <div className="chip-multi-select-container__check-circle-icon">
      {isSelected ? (
        <CheckCircle />
      ) : (
        <span className="chip-multi-select-container__unchecked-circle-icon" />
      )}
    </div>
    {label}
  </div>
);

export default function KaliberChipMultiSelect({
  select = [],
  list,
  onChange,
  label
}: KaliberChipMultiSelectProps): ReactElement {
  const [selectedItems, setSelectedItems] = useState<string[]>(select);

  const handleToggleItem = (item: string) => {
    const isItemSelected = selectedItems.includes(item);
    const newSelectedItems = isItemSelected
      ? selectedItems.filter(i => i !== item)
      : [...selectedItems, item];

    setSelectedItems(newSelectedItems);
    onChange?.(newSelectedItems);
  };

  return (
    <div className="multi-select-container">
      <div className="multi-select-text-container">
        <div className="multi-select-label">{label}</div>
      </div>
      <div className="chip-multi-select-container">
        {list.map(item => (
          <SelectChip
            key={item}
            label={item}
            isSelected={selectedItems.includes(item)}
            onToggle={handleToggleItem}
          />
        ))}
      </div>
    </div>
  );
}
