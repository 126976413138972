import { ReactElement } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './KaliberButton.scss';

interface KaliberLinkButtonProps {
  buttonClass: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  dataTestId?: string;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (val: any) => void;
  to: LinkProps['to'];
  disabled?: boolean;
}
export default function KaliberLinkButton({
  onClick,
  children,
  buttonClass,
  to,
  ...options
}: KaliberLinkButtonProps): ReactElement {
  return (
    <Link onClick={onClick} className={buttonClass} to={to} {...options}>
      <span className="btn__text">{children}</span>
    </Link>
  );
}
