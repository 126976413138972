import store from '../store';
import { callStratusApiV2, RequestMethod, AuthorizationError } from '../../api';
import { KLSurgeon, KLFacility } from '../types';
import camelcaseKeys from 'camelcase-keys';

export async function getSurgeons(accessToken: string): Promise<string> {
  let message = 'ok';
  let data: KLSurgeon[] = [];
  try {
    const res = await callStratusApiV2<KLSurgeon[]>('surgeons', {
      accessToken,
      method: RequestMethod.Get
    });
    data = res;
  } catch (error) {
    if (error instanceof AuthorizationError) {
      message =
        "Seems you don't have authorization to see this information, please contact your administrator.";
    } else if (error instanceof Error) {
      message = JSON.parse(error.message).message;
    }
  }

  store.dispatch({
    type: 'SET_SURGEONS',
    payload: camelcaseKeys(data, { deep: true })
  });
  return message;
}

export async function getFacilitiesBySurgeon(
  accessToken: string,
  surgeon_id: number
): Promise<KLFacility[]> {
  const res = await callStratusApiV2<KLFacility[]>(
    `surgeons/${surgeon_id}/facilities`,
    {
      accessToken,
      method: RequestMethod.Get
    }
  );

  return camelcaseKeys(res);
}
