import React, { ReactElement } from 'react';
import './ReportPrimalPictures.scss';

interface ReportPrimalPicturesProps {
  surgeryRegion: string;
  surgeryPerformedProcedures?: string[];
}

const procedureRankings = {
  knee: {
    1: ['29888'],
    2: ['29882', '29883'],
    3: ['29880', '29881'],
    4: ['27428'],
    5: ['29999'],
    6: ['29877'],
    7: ['29879'],
    8: ['29875', '29876'],
    9: ['29866', '29867'],
    10: ['29870', '29871'],
    11: ['29874'],
    12: ['29873'],
    13: ['27427']
  },
  shoulder: {
    1: ['29827', '23410', '23412'],
    2: ['29807'],
    3: ['23455'],
    4: ['29828'],
    5: ['29822', '29823'],
    6: ['29826'],
    7: ['29824'],
    8: ['29825'],
    9: ['29819'],
    10: ['29805']
  }
};

const procedureEmbedLinks: {
  [region: string]: { [procedure: string]: string };
} = {
  knee: {
    '29870':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=8AFF8C6&timeCode=undefined',
    '29871':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=8AFF8C6&timeCode=undefined',
    '29880':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=1917F76&timeCode=undefined',
    '29881':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=1917F76&timeCode=undefined',
    '29882':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=1917F76&timeCode=undefined',
    '29883':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=1917F76&timeCode=undefined',
    '29877':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=C85516C&timeCode=undefined',
    '29888':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=AC176A8&timeCode=undefined',
    '29873':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=8AFF8C6&timeCode=undefined',
    '29866':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=6F663F8&timeCode=undefined',
    '29867':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=6F663F8&timeCode=undefined',
    '27428':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=60D4D71&timeCode=undefined',
    '29874':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=8AFF8C6&timeCode=undefined',
    '29875':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=FAEC4C8&timeCode=undefined',
    '29876':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=FAEC4C8&timeCode=undefined',
    '29879':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=6F663F8&timeCode=undefined',
    '27427':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=7085E3A&timeCode=undefined',
    '29999':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=CA7593F&timeCode=undefined'
  },
  shoulder: {
    '29805':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=A1AFE9B&timeCode=undefined',
    '29827':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=A3EBB14&timeCode=undefined',
    '23410':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=A3EBB14&timeCode=undefined',
    '23412':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=A3EBB14&timeCode=undefined',
    '29826':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=E400D47&timeCode=undefined',
    '29824':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=1224F82&timeCode=undefined',
    '23455':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=EEA86CA&timeCode=undefined',
    '29822':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=CC50011&timeCode=undefined',
    '29823':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=CC50011&timeCode=undefined',
    '29828':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=CC50011&timeCode=undefined',
    '29807':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=EEA86CA&timeCode=undefined',
    '29825':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=D4C7202&timeCode=undefined',
    '29819':
      'https://www.anatomy.tv/anatomytv/gotoview.aspx?embeddedcode=A1AFE9B&timeCode=undefined'
  }
};

// Utility function to get iframe link for the highest-ranked procedure
function getHighestRankedProcedureEmbedLink(
  region: string,
  procedures: string[]
): string | undefined {
  const rankings = procedureRankings[region as keyof typeof procedureRankings];

  if (!rankings) {
    return undefined;
  }

  const flatRankings: { [key: string]: number } = {};
  Object.entries(rankings).forEach(([rank, codes]) => {
    codes.forEach(code => {
      flatRankings[code] = parseInt(rank, 10);
    });
  });

  const rankedProcedures = procedures
    .map(procedure => ({
      procedure,
      rank: flatRankings[procedure]
    }))
    .filter(({ rank }) => rank !== undefined)
    .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));

  const highestRankedProcedure = rankedProcedures.find(({ procedure }) =>
    Object.prototype.hasOwnProperty.call(
      procedureEmbedLinks[region as keyof typeof procedureEmbedLinks],
      procedure
    )
  );

  return highestRankedProcedure
    ? procedureEmbedLinks[region as keyof typeof procedureEmbedLinks][
        highestRankedProcedure.procedure
      ]
    : undefined;
}

export default function ReportPrimalPictures({
  surgeryRegion,
  surgeryPerformedProcedures
}: ReportPrimalPicturesProps): ReactElement | null {
  if (
    !surgeryRegion ||
    (surgeryRegion.toLowerCase() !== 'knee' &&
      surgeryRegion.toLowerCase() !== 'shoulder') ||
    !surgeryPerformedProcedures ||
    surgeryPerformedProcedures.length === 0 ||
    surgeryPerformedProcedures.some(procedure => procedure.trim() === '')
  ) {
    return null;
  }

  const primalLink = getHighestRankedProcedureEmbedLink(
    surgeryRegion,
    surgeryPerformedProcedures
  );

  if (!primalLink || primalLink.trim() === '') {
    return null;
  }

  return (
    <div className="primal-picture-container">
      <iframe
        className="primal-picture-container__iframe"
        src={primalLink}></iframe>
      <div className="primal-picture-container__text">
        This is a 3D rendering of a {surgeryRegion.toLowerCase()}. You can
        interact with it by clicking and dragging to rotate the image or select
        anatomical structures.
      </div>
    </div>
  );
}
