/* eslint-disable @typescript-eslint/no-explicit-any */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Path } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as ExpandMoreIcon } from '../../assets/icons/CaretDown.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/cross-small.svg';
import { isDefinedAndNotNull, isNotEmpty } from '../../utils/validations';
import './KaliberSelect.scss';

interface KaliberSelectProps {
  select?: string;
  list: string[];
  onChange?: (val: any) => void;
  id?: '';
  includeOther: boolean;
  errors?: any;
  disabled?: boolean;
  label: Path<any>;
  placeholder?: string;
  dataTestId?: string;
  className?: string;
  clear?: boolean;
  height?: 'tall' | 'short';
  fullWidth?: boolean;
  errorMessage?: string;
  editable?: boolean;
  resetAfterSelection?: boolean;
  hasError?: boolean;
}

export default function KaliberSelect({
  select,
  list,
  label,
  onChange,
  includeOther,
  disabled,
  placeholder,
  dataTestId,
  className,
  clear = false,
  height = 'short',
  fullWidth = false,
  errorMessage,
  editable = true,
  resetAfterSelection,
  hasError
}: KaliberSelectProps): ReactElement {
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>();
  const [customInput, setCustomInput] = useState('');
  const [localList, setLocalList] = useState<string[]>([]);
  const searchInput = useRef<HTMLInputElement>(null);
  const [showIcon, setShowIcon] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsListOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isDefinedAndNotNull(select)) {
      setSelectedItem(select);
    } else {
      setLocalList(list);
    }
  }, [select, list]);

  function handleOnChange(items: string | undefined) {
    if (onChange) {
      onChange(items);
    }
  }

  const handleChange = (value: any) => {
    setCustomInput(value.target.value);
  };

  const handleClear = () => {
    setSelectedItem(undefined);
    handleOnChange(undefined);
  };

  const handleBlur = () => {
    if (isNotEmpty(customInput) && !(customInput.trim().length === 0)) {
      handleSelection(customInput);
      setLocalList([...localList, customInput]);
      setCustomInput('');
      setShowIcon(false);
    }
  };

  const handleSelection = (item: any) => {
    if (!resetAfterSelection) {
      setSelectedItem(item);
    }
    handleOnChange(item);
    toggleList();
  };

  const toggleList = () => {
    setIsListOpen(!isListOpen);
    setShowIcon(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      if (isNotEmpty(customInput) && !(customInput.trim().length === 0)) {
        handleSelection(customInput);
        setLocalList([...localList, customInput]);
        setCustomInput('');
      }
    }
  };

  const handleFocus = () => {
    if (searchInput.current) {
      searchInput.current.focus();
      setShowIcon(true);
    }
  };
  return editable ? (
    <div
      ref={wrapperRef}
      className={`ks-container ${disabled && 'ks-disabled'} ${
        className ? className : ''
      } ${fullWidth ? '' : 'ks-reg-width'}`}>
      <div className="ks-text-container">
        <label className="ks-label">{label}</label>
      </div>
      {isListOpen && <div className={`overlay`} onClick={toggleList}></div>}
      <div className={`ks-wrapper`}>
        <div
          className={`ks-input-container ${
            hasError && 'ks-input-error-border'
          } ${isListOpen ? 'ks-focused' : ''} ${
            height === 'tall' ? 'ks-tall' : 'ks-short'
          }`}
          onClick={toggleList}
          data-testid={dataTestId ? dataTestId : 'kaliber-select'}
          aria-disabled={disabled}>
          <div className={`ks-input-text`}>
            {isDefinedAndNotNull(selectedItem) ? (
              <div className="ks-input-selected-item">{selectedItem}</div>
            ) : (
              <div className="ks-placeholder">{placeholder}</div>
            )}

            <div className={`ks-icon`}>
              {clear && isDefinedAndNotNull(selectedItem) ? (
                <div className="ks-clear" onClick={handleClear}>
                  <ClearIcon />
                </div>
              ) : (
                <ExpandMoreIcon />
              )}
            </div>
          </div>
        </div>

        {isListOpen && (
          <div
            role="list"
            className="ks-list"
            onClick={e => e.stopPropagation()}>
            <div className={`ks-scroll-list`}>
              {list.map(item => (
                <button
                  id="fs-mask"
                  type="button"
                  data-testid={
                    dataTestId ? `${dataTestId}-option` : 'ks-option'
                  }
                  className={`${
                    selectedItem == item && 'ks-item-selected'
                  } ks-list-item`}
                  key={uuidv4()}
                  onClick={() => {
                    handleSelection(item);
                  }}>
                  {item}
                </button>
              ))}
              {includeOther && (
                <button
                  type="button"
                  id="fs-mask"
                  className="ks-list-item add-option"
                  onClick={handleFocus}>
                  {showIcon ? (
                    <span className={`icon`}>
                      <AddCircleOutlineIcon />
                    </span>
                  ) : (
                    'Other'
                  )}
                  <input
                    id="fs-mask"
                    className={`add-option-input`}
                    value={customInput}
                    onBlur={handleBlur}
                    onFocus={() => setIsListOpen(true)}
                    onChange={handleChange}
                    ref={searchInput}
                    onKeyPress={handleKeyPress}
                  />
                </button>
              )}
            </div>
          </div>
        )}
        {hasError && (
          <div className="ks-input-error">
            {errorMessage
              ? errorMessage
              : `${label.replace('*', '')} is required`}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={`ks-container`}>
      <div className="ks-text-container">
        <label className="ks-label">{label}</label>
      </div>
      <div className={`ks-wrapper`}>
        <div className={`ks-value-container`}>
          <div className={`ks-input-text`}>
            {isDefinedAndNotNull(selectedItem) ? selectedItem : ''}
          </div>
        </div>
      </div>
    </div>
  );
}
