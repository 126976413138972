import React, { ReactElement } from 'react';
import './OptionalValueDisplay.scss';
import { isDefinedAndNotNull, isNotEmpty } from '../../utils/validations';

interface OptionalValueDisplayProps {
  value: string | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}
export default function OptionalValueDisplay({
  value,
  children
}: OptionalValueDisplayProps): ReactElement {
  return isDefinedAndNotNull(value) && isNotEmpty(value) ? (
    <>{children}</>
  ) : (
    <span className="optional-value--no-entry">No Entry</span>
  );
}
