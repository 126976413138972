import camelcaseKeys from 'camelcase-keys';
import { callStratusApiV2, RequestMethod } from '../../api';
import store from '../store';
import { KLCptCode } from '../types';

export async function getCptCodes(accessToken: string): Promise<void> {
  const res = await callStratusApiV2<KLCptCode[]>('cpt_codes', {
    accessToken,
    method: RequestMethod.Get
  });

  store.dispatch({
    type: 'SET_CPT_CODES',
    payload: camelcaseKeys(res)
  });
}
