import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PatientReportPatient from './PatientReportPatientView';
import PatientReportSurgeon from './PatientReportSurgeonView';

import {
  clearSurgery,
  getReviewDataById,
  getSurgery,
  getSurgeryDataById
} from '../../redux/actions/surgeriesActions';
import { KLReview, KLState, KLUser, Surgery } from '../../redux/types';
import { isNotEmpty } from '../../utils/validations';

type SurgeryUrlParms = {
  id: string;
};

export default function PatientReport(): ReactElement {
  const surgeryId = parseInt(useParams<SurgeryUrlParms>().id, 10);
  const [user, surgery, review]: [KLUser, Surgery, KLReview] = useSelector(
    (state: KLState) => [
      state.user,
      state.surgeries.surgery,
      state.surgeries.review
    ]
  );

  const [loading, setLoading] = useState(true);

  const [reloadSurgery, setReloadSurgery] = useState(false);
  // Get initial surgery
  useEffect(() => {
    if (user.accessToken) {
      setLoading(true);
      Promise.resolve(getSurgery(user.accessToken, surgeryId)).then(() => {
        setLoading(false);
      });
    }
    return () => {
      clearSurgery();
    };
  }, [user.accessToken]);

  // Refresh surgery later
  useEffect(() => {
    if (user.accessToken && reloadSurgery) {
      setLoading(true);
      Promise.resolve(getSurgery(user.accessToken, surgeryId)).then(() => {
        setLoading(false);
      });
    }
  }, [reloadSurgery]);

  useEffect(() => {
    setReloadSurgery(false);
  }, [surgery]);

  useEffect(() => {
    if (user.accessToken && surgeryId !== null) {
      Promise.all([
        getSurgeryDataById(user.accessToken, surgeryId),
        getReviewDataById(user.accessToken, surgeryId)
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [user.accessToken, surgeryId]);

  return (
    <div className="page-container">
      {loading && !(isNotEmpty(surgery) && isNotEmpty(review.surgeryInfo)) ? (
        <div className="patient-report patient-report__container">
          <div className="patient-report__skeleton patient-report__skeleton--details" />
        </div>
      ) : user.role === 'Patient' ? (
        <PatientReportPatient review={review} />
      ) : (
        <PatientReportSurgeon review={review} />
      )}
    </div>
  );
}
