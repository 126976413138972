import { ReactElement } from 'react';
import { Controller, Control } from 'react-hook-form';

import KLCptProcedure from '../../../../../components/cptProcedure/KLCptProcedure';
import SegmentedControl from '../../../../../components/segmentedControl/SegmentedControl';
import '../../../AddSurgeryPage.scss';

import { CptSurgeryProcedure } from '../../../../../redux/types';
import { capitalizeFirstLetter } from '../../../../../utils/textFormat';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface SurgeryProcedureDetailComponentProps {
  anatomy: string;
  cptSurgeryProcedures: CptSurgeryProcedure[];
  control: Control<any, any>;
  onRemove: (cptSurgeryProcedure: CptSurgeryProcedure) => void;
  onSegmentChange: (anatomy: string, side: string) => void;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function SurgeryProcedureDetailComponent({
  anatomy,
  cptSurgeryProcedures,
  control,
  onRemove,
  onSegmentChange
}: SurgeryProcedureDetailComponentProps): ReactElement {
  const isKneeOrShoulder = anatomy === 'knee' || anatomy === 'shoulder';

  return (
    <>
      <div className="asp-patient-region-label">
        {capitalizeFirstLetter(anatomy)}
      </div>

      {cptSurgeryProcedures.map(cptSurgeryProcedure => (
        <KLCptProcedure
          key={cptSurgeryProcedure.cptCode.code}
          cptCode={cptSurgeryProcedure.cptCode.code}
          description={cptSurgeryProcedure.cptCode.description}
          commonName={cptSurgeryProcedure.cptCode.commonName}
          onRemove={() => onRemove(cptSurgeryProcedure)}
        />
      ))}

      {isKneeOrShoulder && (
        <div className="asp-surgery-side-container">
          <div className="asp-surgery-side-label">Surgery Side</div>
          <Controller
            name="anatomySide"
            control={control}
            defaultValue={{
              knee: 'left',
              shoulder: 'left'
            }}
            render={({ field }) => (
              <SegmentedControl
                name="anatomySide"
                segments={['left', 'right', 'both']}
                selected={cptSurgeryProcedures[0].side}
                onSegmentChange={side => {
                  onSegmentChange(anatomy, side);
                  field.onChange({
                    ...field.value,
                    [anatomy]: side
                  });
                }}
              />
            )}
          />
        </div>
      )}
    </>
  );
}
