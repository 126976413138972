import React, { ChangeEvent, ReactElement, useState } from 'react';
import './KLCheckbox.scss';

interface KLCheckboxProps {
  label: string;
  checked?: boolean;
  onChange: (value: string, checked: boolean) => void;
  disabled?: boolean;
}

export default function KLCheckbox({
  label,
  checked,
  onChange,
  disabled
}: KLCheckboxProps): ReactElement {
  const [isChecked, setIsChecked] = useState(checked || false);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const checked = event.target.checked;
    setIsChecked(checked);
    onChange(label, checked);
  };

  return (
    <label className="checkbox-label">
      <input
        className="checkbox-input"
        disabled={disabled}
        type="checkbox"
        value={label}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      {label}
    </label>
  );
}
