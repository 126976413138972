import { ReactElement } from 'react';
import { Controller, Control, UseFormRegister } from 'react-hook-form';
import './CapsularReleaseComponent.scss';
import {
  KaliberTextArea,
  KaliberChipMultiSelect
} from '../../../../../../../../components';

const CAPSULE_LOCATIONS = ['Anterior', 'Posterior', 'Inferior', 'Superior'];

export interface CapsularReleaseDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  register: UseFormRegister<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  cptProcedureIndex: number;
}

export default function CapsularReleaseDetailCard({
  control,
  register,
  cptProcedureIndex
}: CapsularReleaseDetailCardProps): ReactElement {
  return (
    <div className="capsular-release">
      <input
        type="hidden"
        {...register(`cptProcedures.${cptProcedureIndex}.procedureDetail.id`, {
          setValueAs: v => (v === '' ? null : parseInt(v, 10))
        })}
      />

      <input
        type="hidden"
        {...register(
          `cptProcedures.${cptProcedureIndex}.procedureDetail.type`,
          {
            value: 'ProcedureDetails::CapsularReleaseDetail'
          }
        )}
      />

      <div className="multi-select-container">
        <Controller
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.capsuleLocation`}
          control={control}
          render={({ field }) => (
            <KaliberChipMultiSelect
              label="Capsule Location (optional)"
              select={field.value}
              onChange={capsuleLocation => {
                field.onChange(capsuleLocation);
              }}
              list={CAPSULE_LOCATIONS}
            />
          )}
        />
      </div>

      <div className="additional-details">
        <KaliberTextArea
          label="Additional Details (optional)"
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
          register={register}
          errors={{}}
          rows={3}
          placeholder="Additional notes about size, repair details, tissue quality, anchor count etc"
        />
      </div>
    </div>
  );
}
