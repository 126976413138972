import { ReactElement, useEffect } from 'react';
import {
  Controller,
  Control,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';
import './OsteoarticularTransferComponent.scss';
import {
  KaliberTextArea,
  KaliberChipMultiSelect
} from '../../../../../../../../components';
import { CptSurgeryProcedure } from '../../../../../../../../redux/types';

const REGIONS_OF_CARTILAGE_LESSION = [
  'Medial Femoral Condyle',
  'Lateral Femoral Condyle',
  'Patella',
  'Trochlea'
];

export interface OsteoarticularTransferDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  cptProcedureIndex: number;
  cptProcedures: CptSurgeryProcedure[];
  osteoarticularTransferIndices: number[];
}

export default function OsteoarticularTransferDetailCard({
  control,
  register,
  setValue,
  cptProcedureIndex,
  cptProcedures,
  osteoarticularTransferIndices
}: OsteoarticularTransferDetailCardProps): ReactElement {
  useEffect(() => {
    // Do not update if there is only 1 Osteoarticular Transfer index
    if (osteoarticularTransferIndices.length < 2) {
      return;
    }

    // This syncs the rest of the Osteoarticular Transfer components with the current source of truth
    if (cptProcedures != undefined) {
      // Take the value from the current source of truth
      const procedureDetail = cptProcedures[cptProcedureIndex].procedureDetail;
      if (procedureDetail && 'regionOfCartilageLesion' in procedureDetail) {
        const regionOfCartilageLesion = procedureDetail.regionOfCartilageLesion;
        const additionalDetails = procedureDetail.additionalDetails;

        // Iterate through the osteoarticularTransferIndices and update the rest of the values
        osteoarticularTransferIndices
          .filter(idx => idx !== cptProcedureIndex)
          .map(idx => {
            setValue(
              `cptProcedures.${idx}.procedureDetail.regionOfCartilageLesion`,
              regionOfCartilageLesion
            );
            setValue(
              `cptProcedures.${idx}.procedureDetail.additionalDetails`,
              additionalDetails
            );
          });
      }
    }
  }, []);

  const handleAdditionalDetailsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newAdditionalDetails = event.target.value;

    osteoarticularTransferIndices.forEach(idx => {
      setValue(
        `cptProcedures.${idx}.procedureDetail.additionalDetails`,
        newAdditionalDetails
      );
    });
  };

  const inputComponents = osteoarticularTransferIndices.map(
    osteoarticularTransferIndex => (
      <>
        <input
          type="hidden"
          {...register(
            `cptProcedures.${osteoarticularTransferIndex}.procedureDetail.id`,
            {
              setValueAs: v => (v === '' ? null : parseInt(v, 10))
            }
          )}
        />
        <input
          type="hidden"
          {...register(
            `cptProcedures.${osteoarticularTransferIndex}.procedureDetail.type`,
            {
              value: 'ProcedureDetails::OsteoarticularTransferDetail'
            }
          )}
        />
      </>
    )
  );

  return (
    <div className="osteoarticular-transfer">
      {inputComponents}
      <div className="multi-select-container">
        <Controller
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfCartilageLesion`}
          control={control}
          render={({ field }) => (
            <KaliberChipMultiSelect
              label="Region of Cartilage Lesion (optional)"
              select={field.value}
              onChange={regionOfCartilageLesion => {
                field.onChange(regionOfCartilageLesion);
                if (osteoarticularTransferIndices.length > 0) {
                  osteoarticularTransferIndices.map(idx => {
                    setValue(
                      `cptProcedures.${idx}.procedureDetail.regionOfCartilageLesion`,
                      regionOfCartilageLesion
                    );
                  });
                }
              }}
              list={REGIONS_OF_CARTILAGE_LESSION}
            />
          )}
        />
      </div>

      <div className="additional-details">
        <KaliberTextArea
          label="Additional Details (optional)"
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
          register={register}
          errors={{}}
          rows={3}
          placeholder="Additional notes about size, repair details, tissue quality, anchor count etc"
          onChange={handleAdditionalDetailsChange}
        />
      </div>
    </div>
  );
}
