import { ReactElement, useState, BaseSyntheticEvent, useEffect } from 'react';

import KLSearch from '../../../../components/search/KLSearch';
import KLSelect from '../../../../components/select/KLSelect';
import { Filter } from '../../StaffTable';
import './StaffTableFilters.scss';

export interface StaffTableFiltersProps {
  roles: string[];
  facilities: string[];
  onFiltersChange: (filters: Filter[]) => void;
}

export default function StaffTableFilters({
  roles,
  facilities,
  onFiltersChange
}: StaffTableFiltersProps): ReactElement {
  const [filters, setFilters] = useState<Filter[]>([
    { column: 'name', value: '' },
    { column: 'role', value: '' }
  ]);
  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  const updateFilters = (newFilter: Filter) => {
    setFilters(
      filters.map(filter =>
        filter.column === newFilter.column ? newFilter : filter
      )
    );
  };

  const [searchInput, setSearchInput] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [facilityFilter, setFacilityFilter] = useState('');

  const handleFilterChange = (e: BaseSyntheticEvent) => {
    const value = e.target.value || '';
    // setFilter('name', value);
    setSearchInput(value);
    updateFilters({ column: 'name', value });
  };

  const handleRoleChange = (input: string) => {
    const value = input || '';
    updateFilters({ column: 'role', value });
    setRoleFilter(value);
  };

  const handleFacilityChange = (input: string) => {
    const value = input || '';
    updateFilters({ column: 'facility', value });
    setFacilityFilter(value);
  };

  return (
    <div id="container">
      <div className="left-container">
        <div className="filter">
          <KLSearch
            value={searchInput}
            onChange={handleFilterChange}
            placeholder="Search staff"
          />
        </div>
        <div className="filter">
          <KLSelect
            editable={true}
            label={'Roles'}
            menuItems={roles}
            selectStyle={{ width: 245, height: 54 }}
            value={roleFilter}
            placeholder={'Select a role'}
            onChange={handleRoleChange}
          />
        </div>
        <div className="filter">
          <KLSelect
            editable={true}
            label={'Facilities'}
            menuItems={facilities}
            selectStyle={{ width: 245, height: 54 }}
            value={facilityFilter}
            placeholder={'Select a facility'}
            onChange={handleFacilityChange}
          />
        </div>
      </div>
    </div>
  );
}
