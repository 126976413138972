import { ReactElement, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import {
  KaliberTextButton,
  SelectChip
} from '../../../../../../../../components';
import './ThumbnailComponent.scss';

import {
  AnnotatedData,
  StagePrediction
} from '../../../../../../../../redux/types';
import { isNotEmpty } from '../../../../../../../../utils/validations';

function padToLimit(
  predictions: string[],
  fillerValues: string[],
  options: { limit: number }
): string[] {
  if (predictions.length === 0 || predictions.length >= options.limit) {
    return predictions;
  }

  return predictions.concat(
    _.sampleSize(
      _.filter(
        fillerValues,
        quickNote => quickNote !== '' && !predictions.includes(quickNote)
      ),
      options.limit - predictions.length
    )
  );
}

type StagePredictionSelectionComponentProps = {
  stagePredictions: StagePrediction[];
  quickNotesForRegion: string[];
  onShowMoreClicked: () => void;
  onStagePredictionSelected?: (selectedPrediction: string) => void;
  onClickDeletePredictions?: () => void;
};

function StagePredictionSelectionComponent({
  stagePredictions,
  quickNotesForRegion,
  onShowMoreClicked,
  onStagePredictionSelected,
  onClickDeletePredictions
}: StagePredictionSelectionComponentProps): ReactElement {
  const hasStagePredictions = stagePredictions.length > 0;

  if (hasStagePredictions) {
    const predictionsWithPadding = useMemo(() => {
      return padToLimit(
        stagePredictions.map(stagePrediction => stagePrediction.text),
        quickNotesForRegion,
        { limit: 3 }
      );
    }, [stagePredictions, quickNotesForRegion]);

    return (
      <div className="quick-note-selection">
        <span className="quick-note-selection__title">Predictions</span>
        <div className="quick-note-selection__options">
          {predictionsWithPadding.map(stagePrediction => (
            <div
              key={Math.random()}
              className="quick-note-selection__tag-container">
              <SelectChip
                onClick={() => {
                  if (onStagePredictionSelected) {
                    onStagePredictionSelected(stagePrediction);
                  }
                }}>
                {stagePrediction}
              </SelectChip>
            </div>
          ))}
        </div>
        {!onClickDeletePredictions && (
          <KaliberTextButton onClick={onShowMoreClicked}>
            Show More
          </KaliberTextButton>
        )}
        {onClickDeletePredictions && (
          <KaliberTextButton onClick={onClickDeletePredictions}>
            Remove Predictions
          </KaliberTextButton>
        )}
      </div>
    );
  } else {
    return <></>;
  }
}

type QuickNoteSelectedComponentProps = {
  quickNote: string;
  detailedNote: string;
  onQuickNoteChange?: (quickNote: string) => void;
};

function QuickNoteSelectedComponent({
  quickNote,
  detailedNote,
  onQuickNoteChange
}: QuickNoteSelectedComponentProps): ReactElement {
  return (
    <>
      {quickNote && (
        <div className="quick-note-selection">
          <span className="quick-note-selection__title">Quick Note</span>
          <span className="quick-note-selection__tag-container">
            <SelectChip
              selected
              onClick={() => {
                if (onQuickNoteChange) {
                  onQuickNoteChange('');
                }
              }}>
              {quickNote}
            </SelectChip>
          </span>
        </div>
      )}
      {detailedNote && (
        <div className="quick-note-selection">
          <span className="quick-note-selection__title">Additional notes</span>
          <span className="quick-note-selection__detailed-content">
            {detailedNote}
          </span>
        </div>
      )}
    </>
  );
}

type ThumbnailComponentProps = {
  quickNote: string;
  detailedNote: string;
  phaseIcon: string;
  imageUrl: string;
  included: boolean;
  annotation: AnnotatedData;
  quickNotesForRegion: string[];
  onClick: () => void;
  onQuickNoteChange?: (newQuickNote: string) => void;
  onClickDeletePredictions?: () => void;
};

export default function ThumbnailComponent({
  quickNote,
  detailedNote,
  phaseIcon,
  imageUrl,
  included,
  annotation,
  quickNotesForRegion,
  onClick,
  onQuickNoteChange,
  onClickDeletePredictions
}: ThumbnailComponentProps): ReactElement {
  const { stagePredictionSelections } = useFlags();

  const hasSelectedNotes = useMemo(() => {
    return isNotEmpty(quickNote) || isNotEmpty(detailedNote);
  }, [quickNote, detailedNote]);

  const quickNotesWithStagePredictionSelection = () => {
    if (hasSelectedNotes) {
      return (
        <QuickNoteSelectedComponent
          quickNote={quickNote}
          detailedNote={detailedNote}
          onQuickNoteChange={onQuickNoteChange}
        />
      );
    } else {
      return (
        <StagePredictionSelectionComponent
          stagePredictions={annotation.mediaLabels.stagePredictions ?? []}
          quickNotesForRegion={quickNotesForRegion}
          onStagePredictionSelected={onQuickNoteChange}
          onShowMoreClicked={onClick}
          onClickDeletePredictions={onClickDeletePredictions}
        />
      );
    }
  };

  return (
    <div className="thumbnail">
      <button onClick={onClick} className="thumbnail__edit_button">
        <div className="phase-icon-bar">
          {phaseIcon && (
            <img className="phase-icon-bar__icon" src={phaseIcon} />
          )}
        </div>
        <img className="review-image" src={imageUrl} />
        {!included && (
          <div className="review-image__excluded__overlay">
            <div className="review-image__excluded__line" />
          </div>
        )}
      </button>
      {stagePredictionSelections ? (
        quickNotesWithStagePredictionSelection()
      ) : (
        <QuickNoteSelectedComponent
          quickNote={quickNote}
          detailedNote={detailedNote}
          onQuickNoteChange={onQuickNoteChange}
        />
      )}
    </div>
  );
}
