import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import XCircle from '../../assets/icons/XCircle-white.svg';
import menuSVG from '../../assets/icons/menu.svg';
import signOutSVG from '../../assets/icons/signout.svg';
import kaliberRekapLogo from '../../assets/logos/kaliber-rekap-logo-white-2022-05.png';
import { getAllPostOpInstructions } from '../../redux/actions/postOpInstructionsActions';
import { getSurgeriesForLoggedInUser } from '../../redux/actions/surgeriesActions';
import { logoutUser } from '../../redux/actions/userActions';
import {
  KLPostOpInstructions,
  KLState,
  KLSurgeries,
  KLUser
} from '../../redux/types';
import KaliberTermsOfUse from '../termsOfUse/KaliberTermsOfUse';
import './KLNavBar.scss';
import KLSideDrawer from './KLSideDrawer';
import { DEFAULT_POST_OP_INSTRUCTION_URL } from '../../utils/surgeryData';

export interface MenuItem {
  name: string;
  path: string;
  icon: JSX.Element;
}

const SURGERIES_ROUTE = '/surgeries';
const REVIEW_ROUTE = '/review';
const STAFF_ROUTE = '/staff';
const FACILITIES_ROUTE = '/facilities';
const HELP_ROUTE = '/help';
const SETTINGS_ROUTE = '/user-settings';

const linkBuilder = (userRole: string, pathname: string): ReactElement => {
  if (
    userRole === 'Surgeon' ||
    userRole === 'Surgical Clinical Team' ||
    userRole === 'Surgical Office Staff' ||
    userRole === 'Kaliber Admin'
  ) {
    return (
      <>
        <Link className="item-link" to={SURGERIES_ROUTE}>
          <div
            className={
              'nav-item' +
              (pathname.includes(SURGERIES_ROUTE) ||
              pathname.includes(REVIEW_ROUTE)
                ? ' highlighted'
                : '')
            }>
            <div className="item">Surgeries</div>
          </div>
        </Link>
        <Link className="item-link" to={STAFF_ROUTE}>
          <div
            className={
              'nav-item' + (STAFF_ROUTE === pathname ? ' highlighted' : '')
            }>
            <div className="item">Staff</div>
          </div>
        </Link>
        <Link className="item-link" to={FACILITIES_ROUTE}>
          <div
            className={
              'nav-item' + (FACILITIES_ROUTE === pathname ? ' highlighted' : '')
            }>
            <div className="item">Facilities</div>
          </div>
        </Link>
        {userRole !== 'Kaliber Admin' && (
          <Link className="item-link" to={SETTINGS_ROUTE}>
            <div
              className={
                'nav-item' + (SETTINGS_ROUTE === pathname ? ' highlighted' : '')
              }>
              <div className="item">Settings</div>
            </div>
          </Link>
        )}
        <Link data-testid="help-link" className="item-link" to={HELP_ROUTE}>
          <div
            className={
              'nav-item' + (HELP_ROUTE === pathname ? ' highlighted' : '')
            }>
            <div className="item">Help</div>
          </div>
        </Link>
      </>
    );
  } else {
    return (
      <>
        {userRole !== 'Kaliber Qa' && (
          <Link className="item-link" to={SETTINGS_ROUTE}>
            <div
              className={
                'nav-item' + (SETTINGS_ROUTE === pathname ? ' highlighted' : '')
              }>
              <div className="item">Settings</div>
            </div>
          </Link>
        )}
      </>
    );
  }
};

export default function KLNavBar(): ReactElement {
  const history = useHistory();
  const pathname = useLocation().pathname;
  const [user, surgeries, postOpInstructions]: [
    KLUser,
    KLSurgeries,
    KLPostOpInstructions
  ] = useSelector((state: KLState) => [
    state.user,
    state.surgeries,
    state.postOpInstructions
  ]);
  const [showPostOp, setShowPostOp] = useState(false);
  const [postOpLink, setPostOpLink] = useState('');

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

  const toggleSideDrawer = () => {
    setIsSideDrawerOpen(!isSideDrawerOpen);
  };

  useEffect(() => {
    if (user.accessToken) {
      if (user.role === 'Patient') {
        getSurgeriesForLoggedInUser(user.accessToken);
        getAllPostOpInstructions(user.accessToken);
      }
    }
  }, [user]);

  useEffect(() => {
    if (surgeries.surgeries && surgeries.surgeries.length > 0) {
      if (
        surgeries.surgeries[0].surgeryStatus === 'Sent to Patient' &&
        user.role === 'Patient'
      ) {
        if (surgeries.surgeries[0].postOpInstructionsId) {
          setShowPostOp(true);
          const postOp = postOpInstructions.all.find(
            element =>
              element.postOpInstructionsId ===
              surgeries.surgeries[0].postOpInstructionsId
          );
          if (postOp) {
            setPostOpLink(
              postOp.customPdfSelected
                ? postOp?.customUrl
                : postOp?.defaultPostOpInstructionsUrl
            );
          }
        } else {
          setPostOpLink(DEFAULT_POST_OP_INSTRUCTION_URL);
        }
      }
    }
  }, [surgeries.surgeries, postOpInstructions.all]);

  history.listen(() => {
    setIsSideDrawerOpen(false);
  });

  const listBuilder = (userRole: string): ReactElement => {
    if (
      userRole === 'Surgeon' ||
      userRole === 'Surgical Clinical Team' ||
      userRole === 'Surgical Office Staff' ||
      userRole === 'Kaliber Admin'
    ) {
      return (
        <div className="mobile-nav-list">
          <Link
            className={`mobile-nav-list-item${
              pathname === SURGERIES_ROUTE ? ' active' : ''
            }`}
            to={SURGERIES_ROUTE}>
            Surgeries
          </Link>
          <Link
            className={`mobile-nav-list-item${
              pathname === STAFF_ROUTE ? ' active' : ''
            }`}
            to={STAFF_ROUTE}>
            Staff
          </Link>
          <Link
            className={`mobile-nav-list-item${
              pathname === FACILITIES_ROUTE ? ' active' : ''
            }`}
            to={FACILITIES_ROUTE}>
            Facilities
          </Link>
          {userRole !== 'Kaliber Admin' && (
            <Link className="mobile-nav-list-item" to={SETTINGS_ROUTE}>
              <span className="">Settings</span>
            </Link>
          )}
          <Link
            className={`mobile-nav-list-item${
              pathname === HELP_ROUTE ? ' active' : ''
            }`}
            to={HELP_ROUTE}>
            Help
          </Link>
          <div className="mobile-nav-divider" />
          <Link
            className="mobile-nav-list-item"
            to=""
            onClick={() => logoutUser()}
            style={{ display: 'flex', alignItems: 'center' }}>
            <span className="logout-icon">
              <img src={signOutSVG} alt="logout" />
            </span>
            Logout
          </Link>
        </div>
      );
    } else {
      return (
        <div className="mobile-nav-list">
          {userRole !== 'Kaliber QA' && (
            <Link className="mobile-nav-list-item" to={SETTINGS_ROUTE}>
              <span className="">Settings</span>
            </Link>
          )}
          {showPostOp && (
            <Link
              className="mobile-nav-list-item"
              to=""
              onClick={() => window.open(postOpLink, '_blank')}>
              Post-op care
            </Link>
          )}
          <div className="mobile-nav-divider" />
          <Link
            className="mobile-nav-list-item"
            to=""
            onClick={() => logoutUser()}>
            <span className="logout-icon">
              <img src={signOutSVG} alt="logout" />
            </span>
            Logout
          </Link>
        </div>
      );
    }
  };
  return (
    <div>
      <KaliberTermsOfUse />
      <div className="kl-navbar-container">
        <div className="kl-navbar">
          {/* mobilemenu icon */}
          <div className="mobile-menu-icon-container">
            {isSideDrawerOpen ? (
              <img
                src={XCircle}
                alt="close menu icon"
                onClick={toggleSideDrawer}
              />
            ) : (
              <img
                src={menuSVG}
                alt="open menu icon"
                onClick={toggleSideDrawer}
              />
            )}
          </div>
          <KLSideDrawer
            isOpen={isSideDrawerOpen}
            toggleSideDrawer={toggleSideDrawer}>
            {listBuilder(user.role)}
          </KLSideDrawer>

          {/* mobile & desktop logo */}
          <div className="logo-container">
            <Link className="kl-logo-link" to="/">
              <img
                className="kl-logo"
                src={kaliberRekapLogo}
                alt="kaliber labs logo"
              />
            </Link>
          </div>

          {user.accessToken ? (
            <>
              {/* desktop links section */}
              <div className="nav-icon-container">
                {linkBuilder(user.role, pathname)}

                {showPostOp && (
                  <a
                    className="item-link"
                    href={postOpLink}
                    target="_blank"
                    rel="noreferrer">
                    <div className="nav-item">
                      <div className="item">Post-op care</div>
                    </div>
                  </a>
                )}
              </div>

              <div className="profile-section">
                <div className="user-container">
                  <Menu
                    menuButton={
                      <div>
                        <Button className="profile-button">
                          <div className="profile-text" id="fs-mask">
                            {user.email}
                          </div>
                        </Button>
                      </div>
                    }
                    arrowClassName="arrow-style"
                    transition
                    viewScroll="close"
                    arrow
                    align="end">
                    <MenuItem onClick={() => logoutUser()}>
                      <div className="logout-icon">
                        <img className="" src={signOutSVG} alt="logout" />
                      </div>
                      Logout
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
