import { ReactElement, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ReviewImageComponent from './components/reviewImage/ReviewImageComponent';
import ReviewFooter from './components/reviewFooter/ReviewFooter';
import {
  KaliberCard,
  KaliberButton,
  KLSnackbar,
  KaliberModal
} from '../../../../components';
import EvaluationIcon from '../../../../assets/icons/evaluation-icon.svg';
import PostTreatmentIcon from '../../../../assets/icons/post-treatment-icon.svg';
import TreatmentIcon from '../../../../assets/icons/treatment-icon.svg';
import WarningRed from '../../../../assets/icons/warning-red.svg';
import './ImageReviewComponent.scss';

import {
  KLReview,
  KLQuickNote,
  AnnotatedData,
  SurgeryInfo,
  KLUser,
  KLState,
  StagePrediction
} from '../../../../redux/types';
import {
  sortAnnotatedData,
  mergeAnnotations
} from '../../../../utils/annotations';
import {
  sortPredictions,
  evaluationPredictions,
  postTreatmetPredictions
} from '../../../../utils/stagePrediction';
import { updateReviewDataById } from '../../../../redux/actions/surgeriesActions';
import { updateNotes } from '../../../../utils/annotations';

function PhaseLegend(): ReactElement {
  return (
    <div className="image-phase-legend">
      <div className="image-phase-legend__item">
        <img src={EvaluationIcon} alt="" />
        <span className="image-phase-legend__value">Evaluation</span>
      </div>
      <div className="image-phase-legend__item">
        <img src={TreatmentIcon} alt="" />
        <span className="image-phase-legend__value">Treatment</span>
      </div>
      <div className="image-phase-legend__item">
        <img src={PostTreatmentIcon} alt="" />
        <span className="image-phase-legend__value">Post-Treatment</span>
      </div>
    </div>
  );
}

type QuickNoteBulkSelectProps = {
  surgeryInfo: SurgeryInfo;
  annotations: AnnotatedData[];
  onAnnotationsChange: (annotations: AnnotatedData[]) => void;
};

function QuickNoteBulkSelect({
  surgeryInfo,
  annotations,
  onAnnotationsChange
}: QuickNoteBulkSelectProps): ReactElement {
  const inferencedOptionsCount = useMemo(() => {
    return annotations.reduce((acc, annotation) => {
      if (
        annotation.mediaLabels.stagePredictions &&
        annotation.mediaLabels.stagePredictions.length > 0
      ) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
  }, [annotations, surgeryInfo]);

  const setTopOptions = () => {
    const updatedAnnotations = annotations
      .filter(annotation => {
        return (
          annotation.mediaLabels.stagePredictions &&
          annotation.mediaLabels.stagePredictions.length > 0
        );
      })
      .map(annotation => {
        const stagePredictions = annotation.mediaLabels.stagePredictions ?? [];

        return updateNotes(
          annotation,
          stagePredictions.at(0)?.text ?? '',
          annotation.mediaLabels.surgeryNotes.split('\n').at(1) ?? ''
        );
      });

    onAnnotationsChange(updatedAnnotations);
  };

  if (inferencedOptionsCount > 0) {
    return (
      <div className="quick-note-bulk-select__container">
        <p className="quick-note-bulk-select__message">
          We have identified potential quick notes for {inferencedOptionsCount}{' '}
          of your surgery images. Click the button below to automatically select
          the top suggested note for all {inferencedOptionsCount} of these
          images
        </p>
        <KaliberButton
          buttonClass="btn--primary quick-note-bulk-select__button"
          type="button"
          onClick={setTopOptions}>
          Select top option for {inferencedOptionsCount} images
        </KaliberButton>
      </div>
    );
  } else {
    return <></>;
  }
}

interface ImageReviewComponentProps {
  quickNotes: KLQuickNote[];
  handleSendReport: () => void;
  isInternalUser: boolean;
  review: KLReview;
  submittingReview: boolean;
}

export default function ImageReviewComponent({
  quickNotes,
  handleSendReport,
  isInternalUser,
  review,
  submittingReview
}: ImageReviewComponentProps): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const { stagePredictionSelections } = useFlags();

  const filterStagePredictions = (annotation: AnnotatedData) => {
    let stagePredictions: StagePrediction[] = [];
    switch (annotation.mediaLabels.surgeryStage) {
      case 'evaluation': {
        stagePredictions = sortPredictions(
          evaluationPredictions(
            annotation.mediaLabels.stagePredictions ?? [],
            review.surgeryInfo.region
          )
        );
        break;
      }
      case 'treatment': {
        stagePredictions = sortPredictions(
          annotation.mediaLabels.stagePredictions ?? []
        );
        break;
      }
      case 'post-treatment': {
        stagePredictions = sortPredictions(
          postTreatmetPredictions(annotation.mediaLabels.stagePredictions ?? [])
        );
        break;
      }
    }

    return {
      ...annotation,
      mediaLabels: {
        ...annotation.mediaLabels,
        stagePredictions: stagePredictions
      }
    };
  };

  const imageAnnotations = review.annotatedData
    .filter(annotation => annotation.mediaType == 'image')
    .sort(sortAnnotatedData)
    .map(filterStagePredictions);
  const [annotations, setAnnotations] =
    useState<AnnotatedData[]>(imageAnnotations);
  const [includedImagesCount, setIncludedImagesCount] = useState(
    imageAnnotations.length
  );
  const [saving, setSaving] = useState(false);
  const [showError, setShowError] = useState(false);

  const performedCptProcedures = useMemo(() => {
    if (review.surgeryInfo.performedProcedures) {
      return review.surgeryInfo.performedProcedures.cptProcedures;
    } else {
      return [];
    }
  }, [review.surgeryInfo]);

  const quickNotesOptionList = useMemo(() => {
    const evaluationKneeResponses = [''];
    const treatmentKneeResponses = [''];
    const postTreatmentKneeResponses = [''];
    const evaluationShoulderResponses = [''];
    const treatmentShoulderResponses = [''];
    const postTreatmentShoulderResponses = [''];

    quickNotes.forEach(element => {
      const quickNoteText = element.customNoteSelected
        ? element.customNote
        : element.note;
      switch (element.region) {
        case 'knee':
          if (element.stage.toLowerCase() === 'evaluation') {
            evaluationKneeResponses.push(quickNoteText);
          }
          if (element.stage.toLowerCase() === 'treatment') {
            treatmentKneeResponses.push(quickNoteText);
          }
          if (element.stage.toLowerCase() === 'post-treatment') {
            postTreatmentKneeResponses.push(quickNoteText);
          }
          break;
        case 'shoulder':
          if (element.stage.toLowerCase() === 'evaluation') {
            evaluationShoulderResponses.push(quickNoteText);
          }
          if (element.stage.toLowerCase() === 'treatment') {
            treatmentShoulderResponses.push(quickNoteText);
          }
          if (element.stage.toLowerCase() === 'post-treatment') {
            postTreatmentShoulderResponses.push(quickNoteText);
          }
          break;
      }
    });

    return {
      shoulder: {
        evaluationResponses: evaluationShoulderResponses,
        treatmentResponses: treatmentShoulderResponses,
        postTreatmentResponses: postTreatmentShoulderResponses
      },
      knee: {
        evaluationResponses: evaluationKneeResponses,
        treatmentResponses: treatmentKneeResponses,
        postTreatmentResponses: postTreatmentKneeResponses
      }
    };
  }, [quickNotes]);

  const saveUpdatedAnnotation = async (
    annotation: AnnotatedData | AnnotatedData[]
  ) => {
    if (user.accessToken) {
      setSaving(true);

      try {
        await updateReviewDataById(
          user.accessToken,
          review.surgeryInfo.surgeryId,
          annotation
        );

        const annotationsWithUpdate = mergeAnnotations(annotations, annotation);

        setAnnotations(annotationsWithUpdate);
        computeIncludedImages(annotationsWithUpdate);
      } catch (e) {
        setShowError(true);
      }

      setSaving(false);
    }
  };

  const computeIncludedImages = (annotations: AnnotatedData[]) => {
    const newIncludedImagesCount = annotations.reduce((total, annotation) => {
      if (annotation.include) {
        return total + 1;
      } else {
        return total;
      }
    }, 0);

    setIncludedImagesCount(newIncludedImagesCount);
  };

  return (
    <div className="image-review-page">
      <KaliberCard
        title="Review images to send to patient"
        titleAlignment="left">
        <div className="image-review-container">
          <PhaseLegend />
          {stagePredictionSelections && (
            <QuickNoteBulkSelect
              surgeryInfo={review.surgeryInfo}
              annotations={annotations}
              onAnnotationsChange={saveUpdatedAnnotation}
            />
          )}
          <div className="image-grid">
            {annotations.map(annotation => (
              <ReviewImageComponent
                key={annotation.filename}
                quickNotes={quickNotesOptionList}
                performedCptProcedures={performedCptProcedures}
                annotation={annotation}
                onAnnotationChanged={saveUpdatedAnnotation}
              />
            ))}
          </div>
        </div>
      </KaliberCard>
      <ReviewFooter
        isInternalUser={isInternalUser}
        surgeryStatus={review.surgeryInfo.surgeryStatus}
        includedImagesCount={includedImagesCount}
        totalImagesCount={imageAnnotations.length}
        onSendReport={handleSendReport}
        submittingReview={submittingReview}
      />
      <KaliberModal
        visible={showError}
        title="Error updating image annotations"
        titleAlignment="left"
        titleIcon={<img src={WarningRed} />}
        titleIconAlignment="left"
        onClose={() => setShowError(false)}
        closeIcon>
        <div className="image-grid-error">
          <p className="image-grid-error__text">
            There was an error updating the image annotations. Please contact
            support@kaliberlabs.com for further assistance.
          </p>
          <KaliberButton
            type="button"
            buttonClass="btn--cancel image-grid-error__button"
            onClick={() => setShowError(false)}>
            Close
          </KaliberButton>
        </div>
      </KaliberModal>
      {saving && (
        <div className="saving-snackbar">
          <KLSnackbar text="Saving changes.." loading />
        </div>
      )}
    </div>
  );
}
