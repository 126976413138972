import { ReactElement, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { KaliberSelect } from '../../../../../../../../components';

import { ReactComponent as XCircleIcon } from '../../../../../../../../assets/icons/XCircle.svg';
import './PostOpInstructionsMultiSelectComponent.scss';

import {
  KLState,
  KLPostOpInstructions
} from '../../../../../../../../redux/types';

type PostOpInstructionsMultiSelectComponent = {
  surgeryRegions: string[];
  selectedPostOpInstructionIds: number[];
  onChangePostOp: (postOpInstructions: number[]) => void;
};

export default function PostOpInstructionsMultiSelectComponent({
  surgeryRegions,
  selectedPostOpInstructionIds,
  onChangePostOp
}: PostOpInstructionsMultiSelectComponent): ReactElement {
  const [postOpInstructions]: [KLPostOpInstructions] = useSelector(
    (state: KLState) => [state.postOpInstructions]
  );

  const combinedList = useMemo(
    () =>
      [...postOpInstructions.pdfList, ...postOpInstructions.defaults].filter(
        postOpInstruction => surgeryRegions.includes(postOpInstruction.region)
      ),
    [postOpInstructions.pdfList, postOpInstructions.defaults, surgeryRegions]
  );

  const selectedPostOpInstructionNames = useMemo(
    () =>
      selectedPostOpInstructionIds
        .map(
          selectedPostOpInstructionId =>
            combinedList.find(
              element =>
                element.postOpInstructionsId === selectedPostOpInstructionId
            )?.procedure ?? ''
        )
        .filter(procedure => procedure !== ''),
    [
      selectedPostOpInstructionIds,
      postOpInstructions.pdfList,
      postOpInstructions.defaults
    ]
  );

  const [postOpInstructionsOptions, setPostOpInstructionsOptions] = useState<
    string[]
  >([]);

  useEffect(() => {
    setPostOpInstructionsOptions(
      combinedList
        .map(instruction => instruction.procedure)
        .filter(
          procedure => !selectedPostOpInstructionNames.includes(procedure)
        )
        .sort()
    );
  }, [postOpInstructions.pdfList, postOpInstructions.defaults]);

  const handleAddPostOp = (postop: string) => {
    const newPostOpId = combinedList.find(
      element => element.procedure === postop
    )?.postOpInstructionsId;

    if (newPostOpId) {
      setPostOpInstructionsOptions(
        postOpInstructionsOptions.filter(
          postOpInstructionOption => postOpInstructionOption !== postop
        )
      );
      onChangePostOp([...selectedPostOpInstructionIds, newPostOpId].flat());
    }
  };

  const handleRemovePostOp = (postOpInstructionName: string) => {
    const postOpIdToRemove = combinedList.find(
      element => element.procedure === postOpInstructionName
    )?.postOpInstructionsId;

    if (postOpIdToRemove) {
      const newSelectedPostOpInstructionIds = [...selectedPostOpInstructionIds];
      const index = newSelectedPostOpInstructionIds.indexOf(postOpIdToRemove);
      newSelectedPostOpInstructionIds.splice(index, 1);
      setPostOpInstructionsOptions(
        [...postOpInstructionsOptions, postOpInstructionName].sort()
      );
      onChangePostOp(newSelectedPostOpInstructionIds);
    }
  };

  return (
    <div className="post-op-instructions-multi-select">
      <KaliberSelect
        className="post-op-instructions"
        label="Post-Op Instructions"
        onChange={handleAddPostOp}
        list={postOpInstructionsOptions}
        includeOther={false}
        resetAfterSelection
      />
      {selectedPostOpInstructionNames.length > 0 && (
        <ul className="selected-instructions-list">
          {selectedPostOpInstructionNames.map(selectedPostOpInstructionName => (
            <li
              key={selectedPostOpInstructionName}
              className="selected-instructions-list__item">
              <span className="selected-instructions-list__item-text">
                {selectedPostOpInstructionName}
              </span>
              <button
                type="button"
                className="selected-instructions-list__item-button--clear"
                onClick={() =>
                  handleRemovePostOp(selectedPostOpInstructionName)
                }>
                <XCircleIcon />
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
