import { ReactElement } from 'react';

import KaliberCard from '../../../../components/card/KaliberCard';
import { NewSurgeryData } from '../../../../redux/types';
import '../../AddSurgeryPage.scss';
import { OptionalValueDisplay } from '../../../../components';

interface PatientInformationDisplayComponentProps {
  newSurgeryData: NewSurgeryData;
  isMinor: boolean;
}

export default function PatientInformationDisplayComponent({
  newSurgeryData,
  isMinor
}: PatientInformationDisplayComponentProps): ReactElement {
  return (
    <KaliberCard title="Patient Information" titleAlignment="left">
      <div className="ascp-info-section-container">
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Patient Name</div>
            <div className="ascp-info-value">
              {newSurgeryData.firstName + ' ' + newSurgeryData.lastName}
            </div>
          </div>
        </div>
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Date of Birth</div>
            <div className="ascp-info-value">{newSurgeryData.dob}</div>
          </div>
        </div>
        <div>
          <div className="ascp-row-container">
            <div className="ascp-info-card">
              <div className="ascp-info-label">Address</div>
              <div className="ascp-info-value">
                {newSurgeryData.address} {newSurgeryData.city},{' '}
                {newSurgeryData.state} {newSurgeryData.zipcode}
              </div>
            </div>
          </div>
          {!isMinor && (
            <div className="ascp-row-container">
              <div className="ascp-info-card">
                <div className="ascp-info-label">Mobile Phone</div>
                <OptionalValueDisplay value={newSurgeryData.phone}>
                  <div className="ascp-info-value">{newSurgeryData.phone}</div>
                </OptionalValueDisplay>
              </div>
              <div className="ascp-info-card">
                <div className="ascp-info-label">Email</div>
                <OptionalValueDisplay value={newSurgeryData.email}>
                  <div className="ascp-info-value">{newSurgeryData.email}</div>
                </OptionalValueDisplay>
              </div>
            </div>
          )}
        </div>
      </div>
    </KaliberCard>
  );
}
