/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import moment, { Moment } from 'moment-timezone';

import { STATE_OPTIONS } from '../constants';

const OLDEST_VALID_DATE = '1850-01-01';

export function isEmpty(value: any): boolean | undefined {
  try {
    switch (typeof value) {
      case 'string':
        return value.length === 0;
      case 'object':
        // Arrays are objects in Javascript. Check if the value is an array
        if (Array.isArray(value)) {
          return value.length === 0;
        }
        // For other objects
        return Object.keys(value).length === 0;
    }
  } catch (error) {
    //Swollow errors
  }
}

export function isNotEmpty(value: any): boolean | undefined {
  try {
    switch (typeof value) {
      case 'string':
        return value.length !== 0;
      case 'object':
        return Object.keys(value).length !== 0;
    }
  } catch (error) {
    console.error(error);
  }
}

export function isNullOrUndefined(value: any): boolean {
  if (value == null || typeof value === 'undefined') {
    return true;
  } else {
    return false;
  }
}

export function isDefinedAndNotNull(value: any): boolean {
  if (value !== null && typeof value !== 'undefined') {
    return true;
  } else {
    return false;
  }
}

export function isValidBirthDate(date: Date | string | Moment): boolean {
  if (isMoment(date)) {
    return date.isValid() && date.isAfter(OLDEST_VALID_DATE);
  } else {
    const parsedDate = moment(date, 'MM/DD/YYYY', true);
    return parsedDate.isValid() && parsedDate.isAfter(OLDEST_VALID_DATE);
  }
}

function isMoment(date: Date | string | Moment): date is Moment {
  return (date as Moment) !== undefined;
}

export function isMinor(birthdate: Moment, patientState: string): boolean {
  const currDate = moment().tz('Pacific/Kiritimati');
  const age = moment(currDate.format('MM/DD/YYYY')).diff(
    birthdate.format('MM/DD/YYYY'),
    'years'
  );

  return age < STATE_OPTIONS[patientState as keyof typeof STATE_OPTIONS];
}
