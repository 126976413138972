import { ReactElement } from 'react';

import KaliberCard from '../../../../../../components/card/KaliberCard';
import './PatientInfoComponent.scss';

import { isEmpty } from '../../../../../../utils/validations';

interface PatientCardProps {
  firstName: string;
  middleName: string;
  preferredName: string;
  lastName: string;
  sex: string;
  dob: string;
  race: string;
}

export default function PatientInfoComponent({
  firstName,
  middleName,
  preferredName,
  lastName,
  sex,
  dob,
  race
}: PatientCardProps): ReactElement {
  const name = `${isEmpty(preferredName) ? '' : preferredName} ${
    isEmpty(preferredName) ? firstName : '(' + firstName + ')'
  } ${!isEmpty(middleName) ? middleName : ''} ${lastName}`;
  return (
    <KaliberCard title="Patient Information" titleAlignment="left">
      <div className="review-patient-info-container">
        <div>
          <div className="item__label" id="fs-mask">
            Patient Name
          </div>
          <div className="item__value" id="fs-mask">
            {name}
          </div>
        </div>
        <div className="item__row">
          <div className="item">
            <div className="item__label" id="fs-mask">
              Race
            </div>
            <div className="item__value" id="fs-mask">
              {race ? (
                race
              ) : (
                <span className="item__value--no-entry">No Entry</span>
              )}
            </div>
          </div>
          <div className="item">
            <div className="item__label" id="fs-mask">
              Sex
            </div>
            <div className="item__value" id="fs-mask">
              {sex}
            </div>
          </div>
          <div className="item">
            <div className="item__label" id="fs-mask">
              Date of Birth
            </div>
            <div className="item__value" id="fs-mask">
              {dob}
            </div>
          </div>
        </div>
      </div>
    </KaliberCard>
  );
}
