import React, { ReactElement } from 'react';
import { Container } from '@mui/material';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import './TermsOfUsePage.scss';

export default function SurgeonTermsOfUsePage(): ReactElement {
  setFullstoryPage('Terms Of Use Page - Surgeon');
  return (
    <Container maxWidth="xl">
      <div className="page-container container-terms">
        <h3>Kaliber Labs - Surgeon Review Terms of Use</h3>
        <p>
          <b>KALIBER LABS - SURGEON REVIEW TERMS OF USE</b>
        </p>
        <p>
          <b>Options for Approval of Rekap Report by Surgeon User</b>
          <br />
          As a condition of using the Rekap Portal, Surgeon User agrees that
          Rekap Report (“Report”) shall be approved by Surgeon User for release
          to patients within three days from submission of the Report to Surgeon
          User for review. Surgeon User has two options for approving the Report
          for release to patients. Surgeon User may approve the Report for
          release to patients by: (1) review and approval of the Report within
          three days, or (2) automatic approval of the Report after the
          three-day review period elapses. If Surgeon User exercises the second
          option of automatic approval of the Report, then the Report will be
          automatically approved by the Surgeon User and released to the Surgeon
          User’s patient at the end of the three-day review period. Surgeon User
          agrees that the Report may be approved for release to Surgeon User’s
          patients under both options, and that Surgeon User is responsible for
          the content of the Report released to Surgeon User’s patients under
          both options for approval.
        </p>
        <p>Last revised [05/01/2023].</p>
      </div>
    </Container>
  );
}
