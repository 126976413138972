/* eslint-disable @typescript-eslint/no-explicit-any */
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import {
  callStratusApiV2,
  RequestMethod,
  callStratusApiV2Unauthenticated,
  BadRequestError
} from '../../api';
import store from '../store';
import { KLPatient, Patient } from '../types';
import { formatDate, getDifferenceInDays } from '../../utils/dateFormat';
import { INVALID_PHONE_MESSAGE } from '../../utils/textFormat';

export async function getPatient(
  accessToken: string,
  id: number
): Promise<void> {
  const response = await callStratusApiV2<KLPatient>(`patients/${id}`, {
    accessToken,
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });

  store.dispatch({
    type: 'SET_CURRENT_PATIENT',
    payload: camelcaseKeys(response, { deep: true })
  });
}

export async function isSignUpLinkExpired(key: string): Promise<boolean> {
  const response = await callStratusApiV2Unauthenticated<{
    date: string;
  }>(`patients/signup_link/date?key=${key}`, {
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });

  const signupLinkDate = response.date;
  const currentDateTime = formatDate(new Date().toString());

  const differenceInDays = getDifferenceInDays(signupLinkDate, currentDateTime);
  const expirationThresholdInDays = 14;

  return differenceInDays > expirationThresholdInDays;
}

export async function getPatientEmailByKey(key: string): Promise<string> {
  const response = await callStratusApiV2Unauthenticated<{ email: string }>(
    `patients/search_by_key?key=${key}`,
    {
      method: RequestMethod.Get,
      headers: { 'Content-Type': 'application/json' }
    }
  );
  return response.email;
}

export async function resendSignupNotifications(key: string): Promise<boolean> {
  const responseData = await callStratusApiV2Unauthenticated<{
    message: string;
  }>(`patients/signup_link/new?expired_key=${key}`, {
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' }
  });

  if (responseData.message === 'Signup link sent successfully') {
    return true;
  } else {
    return false;
  }
}

export async function createNewStytchUserAccount(
  email: string,
  password: string,
  key: string
): Promise<number> {
  const signupPatientResponse = await callStratusApiV2Unauthenticated<{
    id: number;
  }>(`users/patient/signup?key=${key}`, {
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password
    })
  });
  return Promise.resolve(signupPatientResponse.id);
}

// link patient to logged in user using crypto key
export async function linkPatient(
  accessToken: string,
  key: string
): Promise<void> {
  await callStratusApiV2(`users/patient/link?key=${key}`, {
    accessToken,
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' }
  });
}

export async function updatePatient(
  accessToken: string,
  patientId: number,
  patientData: Patient
): Promise<void> {
  try {
    await callStratusApiV2<void>(`patients/${patientId}`, {
      accessToken,
      method: RequestMethod.Put,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        { patient: snakecaseKeys(patientData as any) },
        (_k, v) => (v === undefined ? null : v)
      )
    });
  } catch (error) {
    const message =
      error instanceof BadRequestError
        ? INVALID_PHONE_MESSAGE
        : 'An unexpected error occurred';
    throw new Error(message);
  }
}

export async function getPatientForUser(accessToken: string): Promise<void> {
  const res = await callStratusApiV2<KLPatient>('users/patient', {
    accessToken,
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });

  store.dispatch({
    type: 'SET_CURRENT_PATIENT',
    payload: camelcaseKeys(res)
  });
}
