import { Container } from '@mui/material';
import React, { ReactElement } from 'react';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import './PrivacyPolicyPage.scss';

export default function PrivacyPolicyPage(): ReactElement {
  setFullstoryPage('Privacy Policy Page');
  return (
    <Container maxWidth="xl">
      <div className="page-container">
        <div className="pp-container">
          <h3>Kaliber Labs Privacy Policy</h3>
          <br />
          <b>EFFECTIVE DATE : November 2, 2021</b>
          <br />
          <p>
            Kaliber Labs, Inc. (“we”, “us”, or “our”) respect your privacy and
            are committed to protecting it through our compliance with this
            policy.
          </p>
          <p>
            This Privacy Policy (“Policy”) describes the types of information we
            may collect from you or that you may provide when you visit the
            Kaliber.ai website or the rekap.kaliber.ai website (collectively,
            the “Websites”), and Rekap software; (“the Service).
          </p>
          <b>This Policy applies to information we collect:</b>
          <ol type="a">
            <li>On these Websites;</li>
            <li>
              In email, text, and other electronic messages between you and
              these Websites;
            </li>
            <li>
              Through mobile and desktop applications you download from these
              Websites, which provide dedicated non-browser-based interaction
              between you and these Websites;
            </li>
            <li>
              Through information that we collect from the software; and/or (e)
              Through information that we collect from any devices that you
              connect to a computer or mobile device running the software.
            </li>
          </ol>
          <b>It does not apply to information collected by:</b>
          <ol type="a">
            <li>
              Us offline or through any other means, including on any other
              website operated by Company or any third party (including our
              affiliates and subsidiaries); or
            </li>
            <li>
              Any third party (including our affiliates and subsidiaries),
              including through any application or content (including
              advertising) that may link to or be accessible from [or on] the
              Website
            </li>
          </ol>
          <p>
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Websites or our Service. By accessing or using our
            Websites or our Service, you agree to this Privacy Policy. This
            Policy may change from time to time (see Changes to Our Privacy
            Policy). Your continued use of Websites or our Service after we make
            changes is deemed to be acceptance of those changes, so please check
            the policy periodically for updates.
          </p>
          <p>
            Your information is stored in the United States. By using or
            downloading this Service, you agree that your personal information,
            including any information about your health that you provide
            directly to us or that we collect through your use of the Service,
            may be transferred to and stored in the United States.
          </p>
          <div>
            <b>The Information We Collect About You</b>
            <p>
              We collect information directly from you, from devices and
              third-party services you connect to, and automatically through
              your use of our Services.
            </p>
          </div>
          <p>
            When you register to use the Service, we collect personal
            information including name, email address, telephone number,
            password, gender, ethnic background, birthdate, emergency contact
            name, and telephone number from patient users. We collect
            information including surgeon and staff names, telephone numbers,
            addresses, email addresses, and professional biographical
            information from healthcare provider users.
          </p>
          <p>
            In addition to the collection described above, we may use automatic
            data collection technologies to collect certain information about
            your equipment, browsing actions, and patterns, including basic
            information from your equipment or mobile device, including device
            model, OS version, device language, IP address, and browser make and
            version used to access our Websites or our Service
          </p>
          <p>
            When you contact us directly, such as when you contact support, we
            will receive the content of your message or any attachments you may
            send us, as well as any additional information you choose to
            provide.
          </p>
          <div>
            <b>How We Use Your Information</b>
            <br />
            <p>We process your information for the following purposes:</p>
            <ol type="a">
              <li>
                To provide our Websites and Service to you, to communicate with
                you about your use of our Service, to respond to your inquiries,
                and for other customer service purposes;
              </li>
              <li>
                To tailor the content and information that we may send or
                display to you, to offer personalized help and instructions, for
                identification and security purposes, and to otherwise
                personalize your experiences while using the Websites and
                Service;
              </li>
              <li>
                To research and develop new products and features, to the extent
                permitted by applicable law, and where required, with your
                consent;
              </li>
              <li>
                For marketing, promotional and informational purposes, to the
                extent permitted by applicable law, and where required, with
                your consent
              </li>
              <li>
                To better understand how users access and use our Websites and
                Service, both on an aggregated and individualized basis, to
                improve our Websites and Service, and respond to user desires
                and preferences, and for other analytical purposes;
              </li>
              <li>To administer surveys and questionnaires;</li>
              <li>
                To comply with legal obligations, as part of our general
                business operations, and for other business administration
                purposes;
              </li>
              <li>
                To investigate, prevent or take action regarding illegal
                activities, suspected fraud, situations involving potential
                threats to the safety of any person, or violations of our{' '}
                <b>
                  <a href="/terms-of-use">Terms of Use</a>
                </b>{' '}
                or this Privacy Policy; and
              </li>
              <li>For any other purpose with your consent.</li>
            </ol>
          </div>
          <div>
            <b>How We Share Your Information.</b>
            <br />
            <p>
              We may share your information, including personal information, as
              follows:
            </p>
            <ol type="a">
              <li>
                Third Party Partners. With your consent, we may share your
                information from the Websites and Service with other third party
                partners, including your personal information and data collected
                from your devices.
              </li>
              <li>
                our Healthcare Providers or Family. With your consent, we
                information, including information collected from your your
                connected devices, with your healthcare providers and/or family
                family members that you designate to receive your information
              </li>
              <li>
                Affiliates. We may disclose the information we collect from
                affiliates or subsidiaries. If we do so, their use and
                disclosure disclosure of your personal information will be
                subject to this Policy.
              </li>
              <li>
                Service Providers. We provide selected information we collect
                from you to third party vendors, service providers, contractors,
                or agents who perform service functions needed by us to run the
                business, such as providers of hosting, email communication,
                customer support services, analytics, marketing, and
                advertising, based on our instructions and in compliance with
                this Policy and any other appropriate confidentiality and
                security measures.
              </li>
              <li>
                Clinical Trial Studies. With your consent, physicians and staff
                of clinical trial programs may use the Service as a means of
                collecting data for trial studies. If the Service is used as
                part of a clinical trial study, we will use and share
                information about the clinical trial collected through the
                Service in accordance with our agreement with the clinical trial
                program and any privacy notices provided to you as part of the
                clinical trial program
              </li>
              <li>
                Aggregated and De-identified Information. We may share //
                eslint-disable-next-line prettier/prettier aggregated or
                de-identified information with third parties for marketing,
                advertising, research or similar purposes. Aggregated or
                de-identified information cannot reasonably be used to identify
                an individual.
              </li>
              <li>
                In Response to Legal Process. We may disclose the information we
                collect from you to comply with the law, a judicial proceeding,
                court order, or other legal process, such as in response to a
                court order or a subpoena, or to respond to any government or
                regulatory request.
              </li>
              <li>
                To Protect Us and Others. We may disclose the information we
                collect from you where we believe it is necessary to
                investigate, prevent, or take action regarding illegal
                activities, suspected fraud, situations involving potential
                threats to the safety of any person, violations of our{' '}
                <b>
                  <a href="/terms-of-use">Terms of Use</a>
                </b>{' '}
                or this Policy, or as evidence in litigation in which Kaliber is
                involved.
              </li>
              <li>
                hird Party Analytics. We use automated devices and applications,
                such as Google Analytics, to evaluate usage of our Service. We
                also may use other analytic means to evaluate our Service. We
                use these tools to help us to improve our Service, performance,
                and user experiences.
              </li>
              <li>
                Business Transfers. If we are acquired by or merge with another
                company, if substantially all of our assets are transferred to
                another company, or as part of a bankruptcy proceeding or
                reorganization, we will give affected users notice before
                transferring any personal information to a new entity.
              </li>
            </ol>
          </div>
          <b>Cookies</b>
          <p>
            Cookies are small text files stored on your device and used by web
            browsers to deliver personalized content and remember logins and
            account settings. We use cookies for authentication purposes. We use
            Google analytics. The cookie policy for Google analytics can be
            found{' '}
            <b>
              <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                here
              </a>
            </b>
            .
          </p>
          <b>Third Party Links</b>
          <p>
            Our Service may contain links to third-party websites. Any access to
            and use of such linked websites is not governed by this Privacy
            Policy, but instead is governed by the privacy policies of those
            third-party websites. We are not responsible for the information
            practices of such third-party websites. We do not control third
            parties&apos; tracking technologies or how they may be used. If you
            have any questions about an advertisement or other targeted content,
            you should contact the responsible provider directly.
          </p>
          <b>Security of Your Personal Information</b>
          <p>
            We have implemented reasonable security precautions to protect the
            information we collect from loss, misuse, and unauthorized access,
            disclosure, alteration, or destruction. Please be aware that despite
            our best effort, no data security protocol can guarantee security.
            You should take steps to protect against unauthorized access to your
            password, phone, and computer, by, among other things, signing off
            after using a shared computer, choosing a robust password that no
            one else knows or can easily guess, and keeping your login and
            password private. We are not responsible for any lost, stolen, or
            compromised passwords or for any activity on your account via
            unauthorized password activity
          </p>
          <b>
            Access to, Storage of, and Deletion of Your Personal Information
          </b>
          <p>
            You may access and modify personal information that you have
            submitted by logging into your account and updating your profile
            information. Please note that copies of information that you have
            updated, modified, or deleted may remain viewable in cached and
            archived pages of the Websites and Service for a period of time.
            Your personal data is stored and accessible on your device as well
            as in the cloud.
          </p>
          <p>
            We store information associated with your account until your account
            is deleted. You can delete your account at any time by contacting us
            at privacy@kaliberlabs.com. Please note that it may take some time
            to delete your account information, and we may preserve it for legal
            reasons or to prevent harm, including as described in the “How
            Information is Shared” section of this Policy.
          </p>
          <p>
            You can set your browser to refuse all or some browser cookies, or
            to alert you when cookies are being sent. To learn how you can
            manage your Flash cookie settings, visit the Flash player settings
            page on Adobe&apos;s website. If you disable or refuse cookies,
            please note that some parts of this site may then be inaccessible or
            not function properly.
          </p>
          <b>Children Under the Age of 16</b>
          <p>
            Our Websites are not intended for children under 16 years of age. No
            one under age 16 may provide any information to or on the Websites.
            We do not knowingly collect personal information from children under
            16. If you are under 16, do not use or provide any information on
            these Websites or provide any information about yourself to us,
            including your name, address, telephone number, email address, or
            any screen name or user name you may use. If we learn we have
            collected or received personal information from a child under 16
            without verification of parental consent, we will delete that
            information. If you believe we might have any information from or
            about a child under 16, please contact us at{' '}
            <b>
              <a href="mailto:privacy@kaliberlabs.com">
                privacy@kaliberlabs.com
              </a>
            </b>
            . Users under the age of 16 must have parental or guardian consent
            to use the Rekap Service. Kaliber does not knowingly collect
            personal identifiable information from users under 16, unless a
            minor under 16 years of age has parental or guardian consent to use
            the Service and we have parental or guardian consent to collect
            personal information of the minor. If a child under 16 has provided
            us with information without parental or guardian consent, the parent
            or guardian may contact us at{' '}
            <b>
              <a href="mailto:privacy@kaliberlabs.com">
                privacy@kaliberlabs.com
              </a>
            </b>
            . We will remove the information and unsubscribe the child from any
            of our electronic marketing lists. Where required by applicable law,
            we may ask children for consent from their parents or guardian
            before allowing the use of the Service or providing a product or
            service to them. California residents under 16 years of age may have
            additional rights regarding the collection and sale of their
            personal information. Please see Your California Privacy Rights for
            more information.
          </p>
          <b>Your California Privacy Rights</b>
          <p>
            If you are a California resident, California law may provide you
            with additional rights regarding our use of your personal
            information. To learn more about your California privacy rights,
            visit{' '}
            <b>
              <a href="https://oag.ca.gov/privacy/ccpa">
                https://oag.ca.gov/privacy/ccpa
              </a>
            </b>
            .
          </p>
          <p>
            You can find information regarding the sources from which we collect
            personal information above in the section titled, “The Information
            We Collect about You,” business and commercial purposes for which we
            collect your personal information in the section titled, “How We Use
            Your Information,” and categories of recipients with whom personal
            information is shared in the section above titled, “How We Share
            Your Information.”
          </p>
          <p>
            We do not sell any personal information collected from your use of
            the Kaliber Service.
          </p>
          <p>
            We do not use cookies on our website that collect and share
            information collected from your browser for behavioral targeting.
          </p>
          <p>
            For any questions about our Privacy Policy or practices, please
            contact us at{' '}
            <b>
              <a href="mailto:privacy@kaliberlabs.com">
                privacy@kaliberlabs.com
              </a>
            </b>
            .
          </p>
          <b>GDPR - Rights for EEA Users</b>
          <p>
            This Privacy Policy (the <b>“Policy”</b>) applies to the processing
            of Personal Data, subject to all applicable privacy and data
            protection laws of the European Union and the European Economic Area
            (collectively, <b>“Europe”</b>), by Kaliber. It describes how we
            collect, use, and disclose such Personal Data, your rights and
            choices with respect to your Personal Data, and how you can contact
            us if you have any questions or concerns.
          </p>
          <ol>
            <li>
              Personal Data We Collect: “Personal Data” means any information
              relating to an identified or identifiable individual. We may
              collect Personal Data about you from various sources, including
              those described below:
              <ul>
                <li>
                  Communications. Wƒhen you contact us via a contact form,
                  email, or other means, you provide us with Personal Data, such
                  as your name and contact details, and the content, date, and
                  time of our communications.
                </li>
                <li>
                  <>Careers.</> If you apply for a job with us, you may provide
                  us with your resume, name, contact details, and any other
                  relevant information. If you become an employee, we collect
                  additional information, such as your family information,
                  beneficiary selections, banking information, and other
                  relevant information for employment, payroll, and benefit
                  purposes, and we may collect certain sensitive Personal Data
                  such as trade union membership data or biometric data for
                  identity verification.
                </li>
                <li>
                  <>Support Information.</> When you request technical support
                  services, we will process your Personal Data such as your name
                  and the contact details you use to contact us, as well as
                  information on the reasons for your support request, and any
                  additional information you may provide in that context.
                </li>
                <li>
                  <>Business opportunities.</> Information we collect in order
                  to communicate with current and prospective business partners
                  and vendors.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Where applicable, we may indicate whether and why you must provide
            us with your Personal Data as well as the consequences of failing to
            do so. For example, it may be necessary for you to disclose certain
            Personal Data in order for us to provide the Services to you.
          </p>
          <p>
            Our Services are not intended for use by children under the age of
            16. We may collect personal information of children under the age of
            16 with parental or guardian consent.
          </p>
          <p>Information Collected from Other Sources:</p>
          <ul>
            <li>
              Third Parties. We may obtain Personal Data about you from third
              parties such as current and prospective vendors and business
              partners and other entities. This information may include names,
              email addresses, phone numbers, business addresses and other
              similar information.
            </li>
          </ul>
          <p>Information We Collect by Automated Means:</p>
          <ul>
            <li>
              <>Cookies.</> We may collect Personal Data via cookies and similar
              technologies.
            </li>
          </ul>
          <b>How We Use Personal Data</b>
          <p>We use the Personal Data we collect for the following purposes:</p>
          <ul>
            <li>
              <>Providing services,</> including to operate, maintain, support,
              and provide our Services.
            </li>
            <li>
              <>Communicating with you,</> including to contact you for
              administrative purposes (e.g., to provide services and information
              that you request or to respond to comments and questions), or to
              send you marketing communications, including updates on promotions
              and events relating to products and services offered by us.
            </li>
            <li>
              <>Personalization,</> including to customize our Services to you
              and provide you with the most relevant marketing and advertising
              materials.
            </li>
            <li>
              <>Analytics and product development,</> including to analyze usage
              trends and preferences in order to improve our Services, and to
              develop new products, services, and features.
            </li>
            <li>
              <>Customer and vendor relationship management,</> including to
              track emails, phone calls, and other actions you have taken as our
              customer or vendor.
            </li>
            <li>
              <>Aggregation.</> We may aggregate or anonymize Personal Data and
              use the resulting information for statistical analysis or other
              purposes.
            </li>
            <li>
              <>Administrative and legal,</> such as to address administrative
              issues or to defend our legal rights and to comply with our legal
              obligations and internal policies as permitted by law.
            </li>
          </ul>
          <b>Legal Basis for the Processing of Personal Data</b>
          <p>
            We rely on various legal bases to process your Personal Data,
            including:
          </p>
          <ul>
            <li>
              <>Consent.</> You may have consented to the use of your Personal
              Data, for example to send you electronic marketing communications
              or for the use of certain cookies.
            </li>
            <li>
              <>Contract.</> We need your Personal Data to provide you with our
              Services and to respond to your inquiries.
            </li>
            <li>
              <>Legal.</> We may have a legal obligation to process your
              Personal Data, for example to comply with tax and accounting
              obligations, and we may process your Personal Data when necessary
              to establish, exercise, or defend legal claims. We may also
              process your Personal Data when necessary to protect your or
              another individual’s vital interests.
            </li>
            <li>
              <>Legitimate interest.</> We or a third party have a legitimate
              interest in using your Personal Data, for example to prevent
              fraud. We only rely on this legal basis when such legitimate
              interests are not overridden by your interests or your fundamental
              rights and freedoms.
            </li>
          </ul>
          <b>How We Disclose Personal Data</b>
          <p>
            We may disclose Personal Data about you in the following
            circumstances:
          </p>
          <ul>
            <li>
              <>Group Entities.</> We may disclose Personal Data about you to
              our affiliates and subsidiaries.
            </li>
            <li>
              <>Service providers.</> We work with third parties to provide
              services such as hosting, maintenance, and support. These third
              parties may have access to or process your Personal Data as part
              of providing those services to us.
            </li>
            <li>
              <>Legal.</> We may disclose your Personal Data if it is necessary
              (i) for compliance with our legal obligations or (ii) to
              establish, exercise, or defend legal claims.
            </li>
            <li>
              <>Merger.</> Information about our users, including Personal Data,
              may be disclosed and otherwise transferred to an acquirer,
              successor, or assignee as part of any merger, acquisition, debt
              financing, sale of assets, or similar transaction, as well as in
              the event of an insolvency, bankruptcy, or receivership in which
              information is transferred to one or more third parties as one of
              our business assets.
            </li>
            <li>
              <>Aggregated information.</> We may use and disclose aggregated or
              otherwise anonymized information for any purpose, unless we are
              prohibited from doing so under applicable law.
            </li>
          </ul>
          <b>Your Rights and Choices</b>
          <p>
            As provided under applicable law and subject to any limitations in
            such law, you have the following rights:
          </p>
          <ul>
            <li>
              <>Access and portability.</> You may ask us to provide you with a
              copy of the Personal Data we maintain about you, including a
              machine-readable copy of the Personal Data that you have directly
              provided to us, and request certain information about its
              processing.
            </li>
            <li>
              <>Rectification.</> You may ask us to update and correct
              inaccuracies in your Personal Data.
            </li>
            <li>
              <>Deletion.</> You may ask to have your Personal Data anonymized
              or deleted, as appropriate.
            </li>
            <li>
              <>Restriction and objection.</> You may ask us to restrict the
              processing of your Personal Data or object to such processing.
            </li>
            <li>
              <>Consent withdrawal.</> You may withdraw any consent you
              previously provided to us regarding the processing of your
              Personal Data at any time and free of charge. We will apply your
              preferences going forward. This will not affect the lawfulness of
              the processing before you withdraw your consent.
            </li>
            <li>
              <>Complaint.</> You may lodge a complaint with a supervisory
              authority, including in your country of residence, place of work,
              or where you believe an incident took place.
            </li>
          </ul>
          <p>
            You may exercise these rights by contacting us using the contact
            details at the end of this Policy. Note that applicable laws contain
            certain exceptions and limitations to each of these rights.
          </p>
          <b>International Data Transfers</b>
          <p>
            We may transfer your Personal Data outside of the country in which
            it was collected and where the level of protection of Personal Data
            may be different than in your country. Personal Data may be
            transferred to the United States. If we do so, we will comply with
            applicable data protection laws, in particular by relying on an EU
            Commission adequacy decision, on contractual protections for the
            transfer of your Personal Data, or on another approved derogation
            for a specific situation, such as your explicit consent. For more
            information about how we transfer Personal Data internationally, or
            to obtain a copy of the safeguards we use for such transfers, please
            contact us as specified below
          </p>
          <b>Data Security and Data Retention</b>
          <p>
            We use physical, managerial, and technical safeguards that are
            designed to improve the integrity and security of Personal Data that
            we collect, maintain, and otherwise process. We take measures to
            delete or anonymize your Personal Data when it is no longer
            necessary for the purposes for which we process it, unless we are
            required by law to keep it for a longer period. When determining the
            retention period, we take into account various criteria, such as the
            type of products or services provided to you, the nature and length
            of our relationship with you, mandatory retention periods, and
            applicable statutes of limitations.
          </p>
          <b>Third-Party Services</b>
          <p>
            Our Services may contain features or links to websites and services
            provided by third parties. Any information you provide via these
            websites or services is provided directly to these third-party
            operators and is subject to their privacy policies, even if accessed
            through our Services. We encourage you to learn about these third
            parties’ policies before providing them with your Personal Data.
          </p>
          <b>Contact Us</b>
          <p>
            If you have any questions about our Privacy Policy, please contact
            us at{' '}
            <b>
              <a href="mailto:privacy@kaliberlabs.com">
                privacy@kaliberlabs.com
              </a>
            </b>
            .
          </p>
          <address>
            Kaliber Labs, Inc.
            <br />
            188 King Street <br />
            San Francisco, CA 94107
          </address>
          <p>
            If you are an EEA customer and are unable to reach Kaliber at the
            contact information provided above, you have the right to contact
            your local Data Protection Authority
          </p>
          <b>Changes to this Policy</b>
          <p>
            This Policy is current as of the Effective Date set forth above. We
            may change this Policy from time to time. We will post any changes
            to this Policy on our Websites or Service. If we make any changes to
            this Policy that materially affect our practices with regard to the
            personal information we have previously collected from you, we will
            endeavor to provide you with notice in advance of such change.
          </p>
        </div>
      </div>
    </Container>
  );
}
