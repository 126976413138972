import { AnyAction } from 'redux';
import { KLPatientRoles } from '../types';

const initialState = {
  newPatient: {
    dob: '',
    email: '',
    emergencyContact: { name: '', email: '', phone: '' },
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    preferredCommunication: '',
    preferredName: '',
    pronouns: '',
    race: '',
    sex: ''
  },
  currentPatient: {
    dob: '',
    email: '',
    emergencyContact: { name: '', email: '', phone: '' },
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    preferredCommunication: '',
    preferredName: '',
    pronouns: '',
    race: '',
    sex: ''
  }
};

export default function PatientReducer(
  state = initialState,
  action: AnyAction
): KLPatientRoles {
  switch (action.type) {
    case 'SET_NEW_PATIENT': {
      return {
        ...state,
        newPatient: { ...state.newPatient, ...action.payload }
      };
    }
    case 'SET_CURRENT_PATIENT': {
      return {
        ...state,
        currentPatient: { ...state.currentPatient, ...action.payload }
      };
    }
    default:
      return state;
  }
}
