import { AnnotatedData } from '../redux/types';

export function sortAnnotatedData(
  first: AnnotatedData,
  second: AnnotatedData
): number {
  if (first.filename < second.filename) {
    return -1;
  }

  if (first.filename > second.filename) {
    return 1;
  }

  return 0;
}

export function updateNotes(
  annotatedData: AnnotatedData,
  quickNote: string,
  detailedNote: string
): AnnotatedData {
  return {
    ...annotatedData,
    mediaLabels: {
      ...annotatedData.mediaLabels,
      surgeryNotes:
        detailedNote === '' ? quickNote : `${quickNote}\n${detailedNote}`
    }
  };
}

export function mergeAnnotations(
  to: AnnotatedData[],
  from: AnnotatedData | AnnotatedData[]
): AnnotatedData[] {
  if (Array.isArray(from)) {
    return to.map(currentAnnotation => {
      const updatedAnnotationIdx = from.findIndex(updatedAnnotation => {
        return updatedAnnotation.filename === currentAnnotation.filename;
      });

      if (updatedAnnotationIdx !== -1) {
        return from[updatedAnnotationIdx];
      } else {
        return currentAnnotation;
      }
    });
  } else {
    return to.map(currentAnnotation => {
      if (from.filename === currentAnnotation.filename) {
        return from;
      } else {
        return currentAnnotation;
      }
    });
  }
}
