import React, { ReactElement, useEffect, useState } from 'react';
import './ReportSurgeryVideo.scss';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { KLReview, KLState, KLUser } from '../../../../redux/types';

export default function ReportSurgeryImages(): ReactElement {
  const [videoSrc, setVideoSrc] = useState('');
  const [review, user]: [KLReview, KLUser] = useSelector((state: KLState) => [
    state.surgeries.review,
    state.user
  ]);
  useEffect(() => {
    const video = review.annotatedData.filter(
      item => item.mediaType === 'video_report'
    );
    setVideoSrc(video[0]?.mediaUrl ? video[0]?.mediaUrl : '');
  }, [review]);
  return videoSrc !== '' ? (
    <div>
      <div className="videos-title">
        {user.role !== 'Patient' && <h3>Video from this surgery</h3>}
        {user.role === 'Patient' && <h3>Video from your surgery</h3>}
      </div>
      <div className="player-wrapper">
        {videoSrc !== '' && (
          <ReactPlayer
            id="fs-exclude"
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
            url={videoSrc}
          />
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
}
