import { ReactElement } from 'react';
import {
  Controller,
  Control,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';

import {
  KaliberTextArea,
  SegmentedControl
} from '../../../../../../../../components';
import './AclReconstructionRepairComponent.scss';

import { isDefinedAndNotNull } from '../../../../../../../../utils/validations';

const graftTypes = ['N/A (Repair)', 'Allograft', 'Autograft'];
const autograftTypes = ['N/A', 'Hamstring', 'Patellar', 'Quad'];

type TendonUsedComponentProps = {
  cptProcedureIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
};

function TendonUsedComponent({
  cptProcedureIndex,
  control
}: TendonUsedComponentProps): ReactElement {
  return (
    <Controller
      name={`cptProcedures.${cptProcedureIndex}.procedureDetail.tendonUsed`}
      control={control}
      defaultValue="N/A"
      render={({ field }) => (
        <SegmentedControl
          label="Tendon Used"
          name="tendonUsed"
          className="acl-segment"
          segments={autograftTypes}
          selected={field.value}
          onSegmentChange={autograftType => {
            field.onChange(autograftType);
          }}
        />
      )}
    />
  );
}

export interface AclReconstructionRepairDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  cptProcedureIndex: number;
  graftType: string;
}

export default function AclReconstructionRepairDetailCard({
  control,
  register,
  setValue,
  cptProcedureIndex,
  graftType
}: AclReconstructionRepairDetailCardProps): ReactElement {
  const updateTendonUsedValues = (
    previousSelection: string,
    newSelection: string
  ) => {
    if (previousSelection === 'N/A (Repair)') {
      setValue(
        `cptProcedures.${cptProcedureIndex}.procedureDetail.tendonUsed`,
        'N/A'
      );
    } else if (newSelection === 'N/A (Repair)') {
      setValue(
        `cptProcedures.${cptProcedureIndex}.procedureDetail.tendonUsed`,
        null
      );
    }
  };

  return (
    <div className="acl-reconstruction-repair">
      <input
        type="hidden"
        {...register(`cptProcedures.${cptProcedureIndex}.procedureDetail.id`, {
          setValueAs: v => (v === '' ? null : parseInt(v, 10))
        })}
      />
      <input
        type="hidden"
        {...register(
          `cptProcedures.${cptProcedureIndex}.procedureDetail.type`,
          {
            value: 'ProcedureDetails::AclReconstructionDetail'
          }
        )}
      />
      <Controller
        name={`cptProcedures.${cptProcedureIndex}.procedureDetail.graftType`}
        control={control}
        defaultValue="N/A (Repair)"
        render={({ field }) => {
          return (
            <SegmentedControl
              label="Graft Used"
              name="graftUsed"
              className="acl-segment"
              segments={graftTypes}
              selected={field.value}
              onSegmentChange={graft => {
                updateTendonUsedValues(field.value, graft);
                field.onChange(graft);
              }}
            />
          );
        }}
      />
      {isDefinedAndNotNull(graftType) && graftType !== 'N/A (Repair)' && (
        <TendonUsedComponent
          cptProcedureIndex={cptProcedureIndex}
          control={control}
        />
      )}
      {isDefinedAndNotNull(graftType) && graftType !== 'Autograft' && (
        <div className="additional-details">
          <KaliberTextArea
            label="Additional Details (optional)"
            name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
            register={register}
            errors={{}}
            rows={3}
            placeholder="Additional notes about size, repair details, tissue quality, anchor count etc"
            fullwidth
          />
        </div>
      )}
    </div>
  );
}
