import { ReactElement } from 'react';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import KaliberCard from '../../../../../components/card/KaliberCard';
import KaliberPhoneField from '../../../../../components/phoneField/KaliberPhoneField';
import KaliberTextField from '../../../../../components/textField/KaliberTextField';
import '../../../AddSurgeryPage.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface EmergencyContactFormComponentProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<any>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function EmergencyContactFormComponent({
  control,
  register,
  errors
}: EmergencyContactFormComponentProps): ReactElement {
  return (
    <KaliberCard title="Emergency Contact (optional)" titleAlignment="left">
      <div className="asp-form-inputs">
        <div className="asp-patient-details">
          <div className="asp-patient-details-subcontainer">
            <KaliberTextField
              label="Name (optional)"
              placeholder=""
              name="emergencyContactName"
              register={register}
              errors={errors}
              registerObject={{ required: false }}
            />
            <div className="asp-top-margin-field">
              <KaliberPhoneField
                label="Phone (optional)"
                name="emergencyContactPhone"
                placeholder={false}
                errors={errors}
                control={control}
              />
            </div>
            <KaliberTextField
              label="Email (optional)"
              placeholder=""
              name="emergencyContactEmail"
              register={register}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </KaliberCard>
  );
}
