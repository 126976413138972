export function formatDate(dateTimeString: string): string {
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const hour = String(dateTime.getHours()).padStart(2, '0');
  const minute = String(dateTime.getMinutes()).padStart(2, '0');
  const second = String(dateTime.getSeconds()).padStart(2, '0');
  const millisecond = String(dateTime.getMilliseconds()).padStart(5, '0');

  return `${year}-${month}-${day} ${hour}:${minute}:${second}.${millisecond}`;
}

export function getDifferenceInDays(
  dateString1: string,
  dateString2: string
): number {
  const isoString1 = dateString1.replace(' ', 'T').split('.')[0] + 'Z';
  const isoString2 = dateString2.replace(' ', 'T').split('.')[0] + 'Z';

  const date1 = new Date(isoString1);
  const date2 = new Date(isoString2);

  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

  return diffInDays;
}
