import React, { ReactElement } from 'react';
import './KLCustomTabs.scss';

interface Props {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

export default function Tab({ children }: Props): ReactElement {
  return <div>{children}</div>;
}
