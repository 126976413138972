import { KLUser } from '../redux/types';

export function extractUid(user_sub: string): string {
  const user_subs = user_sub.split('|');
  return user_subs[user_subs.length - 1];
}

export function isInternalUser(user: KLUser): boolean {
  return user.role === 'Kaliber Qa' || user.role === 'Kaliber Admin';
}

export function isSurgeon(user: KLUser): boolean {
  return user.role === 'Surgeon';
}

export function isHCP(user: KLUser): boolean {
  return (
    user.role === 'Surgeon' ||
    user.role === 'Surgical Clinical Team' ||
    user.role === 'Surgical Office Staff'
  );
}
