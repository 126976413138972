import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { KLReview, KLState } from '../../../../redux/types';
import './ReportSurgeonBio.scss';
import { sentenceCase } from '../../../../utils/textFormat';

export default function ReportSurgeonBio(): ReactElement {
  const [review]: [KLReview] = useSelector((state: KLState) => [
    state.surgeries.review
  ]);
  const bio = review.surgeonBio?.bio ? review.surgeonBio?.bio.split('\\n') : [];
  const memberships = review.surgeonBio?.memberships
    ? review.surgeonBio?.memberships.split('\\n')
    : [];

  const certifications = review.surgeonBio?.certifications
    ? review.surgeonBio?.certifications
    : '';

  const headshotUri = review.surgeonBio?.headshotUri
    ? review.surgeonBio?.headshotUri
    : '';

  const bioQuote = review.surgeonBio?.bioQuote
    ? review.surgeonBio?.bioQuote
    : '';

  return (
    <div className="bio-container">
      <div className="bio-left-col">
        <div className="bio-title">
          <h4>{review.surgeryInfo.surgeonName}</h4>
        </div>

        {bio.map((item, key) => {
          return (
            <h5 id="fs-mask" key={key}>
              {item}
              <br />
            </h5>
          );
        })}
        <br />
        <h5 id="fs-mask">{certifications}</h5>
        <br />
        <h5 id="fs-mask">Memberships</h5>
        {memberships.map((item, key) => {
          return (
            <h6 id="fs-mask" key={key}>
              {item}
              <br />
            </h6>
          );
        })}
      </div>
      <div className="bio-right-col">
        <div className="bio-title-m">
          <h4 className="surgeon-name" id="fs-mask">
            {review.surgeryInfo.surgeonName}
          </h4>
        </div>

        <img src={headshotUri} className="surgeon-photo" id="fs-exclude" />

        <div className="bio-contact-quote">
          <h6 className="surgeon-quote" id="fs-mask">
            &quot;{bioQuote}&quot;
          </h6>

          <h6 className="surgeon-contact" id="fs-mask">
            {sentenceCase(review.surgeryInfo.facilityName)}
            <br />
            <span className="surgeon-contact-label" id="fs-mask">
              {' '}
              Office |{' '}
            </span>{' '}
            {review.surgeryInfo.facilityPhone}
            <br />
            <span className="surgeon-contact-label" id="fs-mask">
              After hours |{' '}
            </span>
            {review.surgeryInfo.facilityAfterHoursPhone}
            <br />
            <span className="surgeon-contact-label" id="fs-mask">
              Email |{' '}
            </span>
            {review.surgeryInfo.facilityEmail}
            <br />
            <span className="surgeon-contact-label" id="fs-mask">
              Web |{' '}
            </span>
            {review.surgeryInfo.facilityWebsite}
          </h6>
        </div>
      </div>
    </div>
  );
}
