import React, { ReactElement } from 'react';
import './KaliberButton.scss';
interface KaliberButtonProps {
  buttonClass: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  dataTestId?: string;
  disabled?: boolean;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (val: any) => void;
  type: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
}
export default function KaliberButton({
  onClick,
  children,
  disabled = false,
  dataTestId,
  buttonClass,
  loading,
  ...options
}: KaliberButtonProps): ReactElement {
  return (
    <button
      onClick={onClick}
      className={`${buttonClass} ${loading && 'btn--loading'}`}
      disabled={disabled}
      data-testid={dataTestId}
      {...options}>
      <span className="btn__text">{children}</span>
    </button>
  );
}
