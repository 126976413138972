import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Back } from '../../assets/icons/ArrowLeft.svg';
import './PageHeader.scss';

interface PageHeaderProps {
  loading?: boolean;
  title: string;
  pageToRedirect?: string;
  onBack?: () => void;
}

export default function PageHeader({
  loading,
  pageToRedirect,
  onBack,
  title
}: PageHeaderProps): ReactElement {
  const history = useHistory();

  function handleBack() {
    if (pageToRedirect) {
      history.push(pageToRedirect);
    } else if (onBack) {
      onBack();
    }
  }

  return (
    <>
      {loading ? (
        <>
          <div className="surgery-page__skeleton surgery-page__skeleton--header" />
        </>
      ) : (
        <div className={`k-page-header`}>
          <div className="kph-backbutton" onClick={handleBack}>
            <Back />
          </div>
          <div className="kph-title">{title}</div>
        </div>
      )}
    </>
  );
}
