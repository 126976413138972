import { Box, Modal } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthorizationError } from '../../api';
import KaliberButton from '../../components/button/KaliberButton';
import KLNavBar from '../../components/navbar/KLNavBar';
import KLTextField from '../../components/textField/KLTextField';
import { DEFAULT_ERROR_MESSAGE } from '../../constants';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import {
  getExternalReport,
  updateSharedReportVisited
} from '../../redux/actions/reportActions';
import {
  clearReviewData,
  clearSurgeries
} from '../../redux/actions/surgeriesActions';
import { setLoading } from '../../redux/actions/uiActions';
import { KLState, KLUi } from '../../redux/types';
import { useQuery } from '../../utils/react';
import './ExternalPatientReport.scss';
import ExternalPatientReportComplete from './ExternalPatientReportComplete';

export default function ExternalPatientReport(): ReactElement {
  setFullstoryPage('External Patient Report Page');

  const ui: KLUi = useSelector((state: KLState) => state.ui);

  useEffect(() => {
    return () => {
      clearReviewData();
      clearSurgeries();
    };
  }, []);

  useEffect(() => {
    if (ui.loading) {
      setLoading(false);
    }
  }, [ui]);

  const [cryptoKey, setCryptoKey] = useState<string>('');
  const [sharedEmail, setSharedEmail] = useState('');
  const [open, setOpen] = useState(true);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const query = useQuery();
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const url = location.pathname;
    setCryptoKey(url.split('/')[2]);
  }, [query]);

  const handleSubmit = async () => {
    try {
      setShowErrorMessage(false);
      setIsValidatingEmail(true);

      const isSuccess = await getExternalReport(
        cryptoKey,
        sharedEmail.trim().toLowerCase()
      );

      if (isSuccess) {
        updateSharedReportVisited(cryptoKey);
      }

      setIsValidatingEmail(false);
    } catch (error) {
      setIsValidatingEmail(false);
      setShowErrorMessage(true);
      if (error instanceof AuthorizationError) {
        setErrorMessage(
          'The email address you entered is not found. Please try again.'
        );
      } else {
        setErrorMessage(DEFAULT_ERROR_MESSAGE);
      }
      return;
    }
    handleClose();
    setLoading(true);
  };

  return (
    <div>
      <KLNavBar />
      {open ?? <div className="overlay"></div>}
      <Modal open={open} disableBackdropClick onClose={handleClose}>
        <Box className="external-report-modal">
          <div className="external-report-modal-title">
            Please enter email to view report
          </div>
          {showErrorMessage && (
            <div className="error-message-container">{errorMessage}</div>
          )}
          <KLTextField
            editable={true}
            isRequired
            type="email"
            placeholder="Email"
            name="Email"
            value={sharedEmail}
            onChange={val => {
              setSharedEmail(val.target.value);
            }}
          />
          <div className="modal-button-container">
            {isValidatingEmail ? (
              <div className="spinner-container">
                <CircularProgress />
              </div>
            ) : (
              <KaliberButton
                buttonClass="btn--primary"
                type="submit"
                onClick={handleSubmit}>
                Submit
              </KaliberButton>
            )}
          </div>
        </Box>
      </Modal>
      <ExternalPatientReportComplete />
    </div>
  );
}
