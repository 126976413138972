import { ReactElement, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import InternalReviewImageComponent from './components/reviewImage/InternalReviewImageComponent';
import ReviewFooter from './components/reviewFooter/ReviewFooter';
import {
  KaliberCard,
  KaliberButton,
  KLSnackbar,
  KaliberModal
} from '../../../../components';
import EvaluationIcon from '../../../../assets/icons/evaluation-icon.svg';
import PostTreatmentIcon from '../../../../assets/icons/post-treatment-icon.svg';
import TreatmentIcon from '../../../../assets/icons/treatment-icon.svg';
import WarningRed from '../../../../assets/icons/warning-red.svg';
import './ImageReviewComponent.scss';

import {
  KLReview,
  KLQuickNote,
  QuickNotesList,
  AnnotatedData,
  KLUser,
  KLState,
  StagePrediction
} from '../../../../redux/types';
import {
  sortAnnotatedData,
  mergeAnnotations
} from '../../../../utils/annotations';
import {
  sortPredictions,
  evaluationPredictions,
  postTreatmetPredictions
} from '../../../../utils/stagePrediction';
import { updateReviewDataById } from '../../../../redux/actions/surgeriesActions';

function PhaseLegend(): ReactElement {
  return (
    <div className="image-phase-legend">
      <div className="image-phase-legend__item">
        <img src={EvaluationIcon} alt="" />
        <span className="image-phase-legend__value">Evaluation</span>
      </div>
      <div className="image-phase-legend__item">
        <img src={TreatmentIcon} alt="" />
        <span className="image-phase-legend__value">Treatment</span>
      </div>
      <div className="image-phase-legend__item">
        <img src={PostTreatmentIcon} alt="" />
        <span className="image-phase-legend__value">Post-Treatment</span>
      </div>
    </div>
  );
}

interface ImageReviewComponentProps {
  quickNotes: KLQuickNote[];
  handleSendReport: () => void;
  review: KLReview;
  submittingReview: boolean;
}

export default function InternalImageReviewComponent({
  quickNotes,
  handleSendReport,
  review,
  submittingReview
}: ImageReviewComponentProps): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);

  const performedCptProcedures = useMemo(() => {
    if (review.surgeryInfo.performedProcedures) {
      return review.surgeryInfo.performedProcedures.cptProcedures;
    } else {
      return [];
    }
  }, [review.surgeryInfo]);

  const [quickNotesOptionList, setQuickNotesOptionList] =
    useState<QuickNotesList>({
      shoulder: {
        evaluationResponses: [''],
        treatmentResponses: [''],
        postTreatmentResponses: ['']
      },
      knee: {
        evaluationResponses: [''],
        treatmentResponses: [''],
        postTreatmentResponses: ['']
      }
    });

  const filterStagePredictions = (annotation: AnnotatedData) => {
    let stagePredictions: StagePrediction[] = [];
    switch (annotation.mediaLabels.surgeryStage) {
      case 'evaluation': {
        stagePredictions = sortPredictions(
          evaluationPredictions(
            annotation.mediaLabels.stagePredictions ?? [],
            review.surgeryInfo.region
          )
        );
        break;
      }
      case 'treatment': {
        stagePredictions = sortPredictions(
          annotation.mediaLabels.stagePredictions ?? []
        );
        break;
      }
      case 'post-treatment': {
        stagePredictions = sortPredictions(
          postTreatmetPredictions(annotation.mediaLabels.stagePredictions ?? [])
        );
        break;
      }
    }

    return {
      ...annotation,
      mediaLabels: {
        ...annotation.mediaLabels,
        stagePredictions: stagePredictions
      }
    };
  };

  const imageAnnotations = review.annotatedData
    .filter(annotation => annotation.mediaType == 'image')
    .sort(sortAnnotatedData)
    .map(filterStagePredictions);
  const [annotations, setAnnotations] =
    useState<AnnotatedData[]>(imageAnnotations);
  const [includedImagesCount, setIncludedImagesCount] = useState(
    imageAnnotations.length
  );
  const [saving, setSaving] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const evaluationKneeResponses: string[] = [];
    const treatmentKneeResponses: string[] = [];
    const postTreatmentKneeResponses: string[] = [];
    const evaluationShoulderResponses: string[] = [];
    const treatmentShoulderResponses: string[] = [];
    const postTreatmentShoulderResponses: string[] = [];
    quickNotes.forEach(element => {
      const quickNoteText = element.customNoteSelected
        ? element.customNote
        : element.note;
      switch (element.region) {
        case 'knee':
          if (element.stage.toLowerCase() === 'evaluation') {
            evaluationKneeResponses.push(quickNoteText);
          }
          if (element.stage.toLowerCase() === 'treatment') {
            treatmentKneeResponses.push(quickNoteText);
          }
          if (element.stage.toLowerCase() === 'post-treatment') {
            postTreatmentKneeResponses.push(quickNoteText);
          }
          break;
        case 'shoulder':
          if (element.stage.toLowerCase() === 'evaluation') {
            evaluationShoulderResponses.push(quickNoteText);
          }
          if (element.stage.toLowerCase() === 'treatment') {
            treatmentShoulderResponses.push(quickNoteText);
          }
          if (element.stage.toLowerCase() === 'post-treatment') {
            postTreatmentShoulderResponses.push(quickNoteText);
          }
          break;
      }
    });

    setQuickNotesOptionList({
      shoulder: {
        evaluationResponses: evaluationShoulderResponses,
        treatmentResponses: treatmentShoulderResponses,
        postTreatmentResponses: postTreatmentShoulderResponses
      },
      knee: {
        evaluationResponses: evaluationKneeResponses,
        treatmentResponses: treatmentKneeResponses,
        postTreatmentResponses: postTreatmentKneeResponses
      }
    });
  }, [quickNotes]);

  const saveUpdatedAnnotation = async (
    annotation: AnnotatedData | AnnotatedData[]
  ) => {
    if (user.accessToken) {
      setSaving(true);

      try {
        await updateReviewDataById(
          user.accessToken,
          review.surgeryInfo.surgeryId,
          annotation
        );

        const annotationsWithUpdate = mergeAnnotations(annotations, annotation);

        setAnnotations(annotationsWithUpdate.map(filterStagePredictions));
        computeIncludedImages(annotationsWithUpdate);
      } catch (e) {
        setShowError(true);
      }

      setSaving(false);
    }
  };

  const computeIncludedImages = (annotations: AnnotatedData[]) => {
    const newIncludedImagesCount = annotations.reduce((total, annotation) => {
      if (annotation.include) {
        return total + 1;
      } else {
        return total;
      }
    }, 0);

    setIncludedImagesCount(newIncludedImagesCount);
  };

  return (
    <div className="image-review-page">
      <KaliberCard
        title="Review images to send to patient"
        titleAlignment="left">
        <div className="image-review-container">
          <PhaseLegend />
          <div className="image-grid">
            {annotations.map(annotation => (
              <InternalReviewImageComponent
                key={annotation.filename}
                quickNotes={quickNotesOptionList}
                performedCptProcedures={performedCptProcedures}
                annotation={annotation}
                onAnnotationChanged={saveUpdatedAnnotation}
              />
            ))}
          </div>
        </div>
      </KaliberCard>
      <ReviewFooter
        isInternalUser={true}
        surgeryStatus={review.surgeryInfo.surgeryStatus}
        includedImagesCount={includedImagesCount}
        totalImagesCount={imageAnnotations.length}
        onSendReport={handleSendReport}
        submittingReview={submittingReview}
      />
      <KaliberModal
        visible={showError}
        title="Error updating image annotations"
        titleAlignment="left"
        titleIcon={<img src={WarningRed} />}
        titleIconAlignment="left"
        onClose={() => setShowError(false)}
        closeIcon>
        <div className="image-grid-error">
          <p className="image-grid-error__text">
            There was an error updating the image annotations. Please contact
            support@kaliberlabs.com for further assistance.
          </p>
          <KaliberButton
            type="button"
            buttonClass="btn--cancel image-grid-error__button"
            onClick={() => setShowError(false)}>
            Close
          </KaliberButton>
        </div>
      </KaliberModal>
      {saving && (
        <div className="saving-snackbar">
          <KLSnackbar text="Saving changes.." loading />
        </div>
      )}
    </div>
  );
}
