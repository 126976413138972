import { makeStyles, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { ReactElement } from 'react';
import MaskedInput from 'react-text-mask';
import './KLNpiField.scss';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'theme.palette.text.secondary',
    marginLeft: 1
  },
  title: {
    color: '#44546a',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '1rem',
    letterSpacing: '0.25px',
    fontSize: '14px',
    margin: '0 0 0.25rem 0'
  },
  optional: {
    fontSize: 9,
    marginLeft: 10,
    fontWeight: 500
  },
  field: {
    height: 42,
    width: 286
  },
  noBorder: {
    border: 'none'
  },
  fixPadding: {
    padding: '10px 0'
  },
  disabledColor: {
    color: theme.palette.text.primary
  }
}));

interface KLNpiFieldProps {
  value: string;
  onChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoFocus?: boolean;
  name?: string;
  type?: 'email' | 'password' | 'text';
  helperText?: string;
  autoComplete?: string;
  label?: string;
  id?: string;
  isRequired?: boolean;
  error?: boolean;
  fieldSize?: 'small' | 'medium' | 'large';
  className?: string;
  optionalText?: string;
  editable: boolean;
  dataTestId?: string;
  onBlur?: () => void;
  disabled?: boolean;
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      // Temporarily disabling warning:
      // Fix involves working with 3rd party library.
      // Jira Card: https://kaliberlabs.atlassian.net/browse/ENG-629
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
}

export default function KLNpiField({
  onChange,
  label,
  isRequired,
  className,
  optionalText,
  editable,
  dataTestId,
  onBlur,
  disabled = false,
  ...options
}: KLNpiFieldProps): ReactElement {
  const classes = useStyles();
  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <div className={className}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="caption">
          {label}
        </Typography>

        <Typography className={classes.optional} variant="caption">
          {optionalText}
        </Typography>
      </div>
      {editable ? (
        <TextField
          fullWidth
          id="fs-mask"
          variant="outlined"
          required={isRequired}
          onChange={onChange}
          onBlur={handleBlur}
          disabled={disabled}
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: TextMaskCustom as any
          }}
          inputProps={{
            'data-testid': dataTestId ? dataTestId : 'npifield-textfield'
          }}
          {...options}
        />
      ) : (
        <div>
          <TextField
            disabled={true}
            fullWidth
            id="fs-mask"
            variant="outlined"
            required={isRequired}
            onChange={onChange}
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: TextMaskCustom as any,
              classes: {
                notchedOutline: classes.noBorder,
                input: classes.fixPadding,
                disabled: classes.disabledColor
              }
            }}
            inputProps={{
              'data-testid': dataTestId ? dataTestId : 'npifield-textfield'
            }}
            {...options}
          />
        </div>
      )}
    </div>
  );
}
