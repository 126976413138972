import { ReactElement } from 'react';
import './SurgeryCardSkeleton.scss';

export default function SurgeryCardSkeleton(): ReactElement {
  return (
    <div
      className={`kaliber-surgery-card-skeleton`}
      data-testid="surgeries-skeleton"></div>
  );
}
