import { Divider } from '@material-ui/core';
import { Container } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React, { ReactElement, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReportBanner from '../../assets/rekap-report-header.jpg';
import { KLReview, KLState } from '../../redux/types';
import { isEmpty } from '../../utils/validations';
import './ExternalPatientReportComplete.scss';
import ReportHowTo from './patientReportComplete/reportHowTo/ReportHowTo';
import ReportOverview from './patientReportComplete/reportOverview/ReportOverview';
import ReportSurgeonBio from './patientReportComplete/reportSurgeonBio/ReportSurgeonBio';
import ReportSurgeryImages from './patientReportComplete/reportSurgeryImages/ReportSurgeryImages';
import PatientReportStepper from './patientReportStepper/PatientReportStepper';
import ReportSurgeryVideos from './patientReportComplete/reportSurgeryVideo/ReportSurgeryVideo';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function ExternalPatientReportComplete(): ReactElement {
  const { showReportSurgeryVideo } = useFlags();

  const overview = useRef<HTMLDivElement>(null);
  const howto = useRef<HTMLDivElement>(null);
  const images = useRef<HTMLDivElement>(null);
  const videos = useRef<HTMLDivElement>(null);
  const reportSurgeonBio = useRef<HTMLDivElement>(null);

  const [review]: [KLReview] = useSelector((state: KLState) => [
    state.surgeries.review
  ]);

  const bannerImage = useMemo(
    () => review.surgeonBio.bannerUrl ?? ReportBanner,
    [review]
  );

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }, []);

  function scrollTo(index: number): void {
    switch (index) {
      case 0:
        overview.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
        break;
      case 1:
        howto.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        break;
      case 2:
        images.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        break;
      case 3:
        videos.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        break;
      case 4:
        reportSurgeonBio.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
        break;
    }
  }

  return (
    <div className="external-container">
      <Container maxWidth="xl">
        <div className="report-container">
          <div className="side-stepper">
            <PatientReportStepper
              scrollTo={scrollTo}
              sharedReport={true}
              className={'external-top-margin'}
            />
          </div>
          {isEmpty(review.surgeryInfo) ? (
            <Skeleton variant="rectangular" height={2000} animation="wave" />
          ) : (
            <div className="report">
              <img className="overview-image" src={bannerImage} />
              <div className="report-info-container">
                <div ref={overview} id="report-overview" data-testid="overview">
                  <ReportOverview surgery={review.surgeryInfo} />
                </div>
                <Divider className="divider" />
                <div ref={howto} id="report-howto" data-testid="how-to">
                  <ReportHowTo />
                </div>
                <div
                  ref={images}
                  className="images-cont"
                  id="report-images"
                  data-testid="images">
                  <Divider className="divider" />
                  <ReportSurgeryImages />
                </div>
                <Divider className="divider" />
                {showReportSurgeryVideo && (
                  <>
                    <div
                      ref={videos}
                      className="videos-cont"
                      id="report-videos"
                      data-testid="videos">
                      <ReportSurgeryVideos />
                    </div>
                    <Divider className="divider" />
                  </>
                )}
                <div
                  ref={reportSurgeonBio}
                  className="surgeon-cont"
                  id="report-surgeonbio"
                  data-testid="surgeon-bio">
                  <ReportSurgeonBio />
                </div>
              </div>
              <div
                className="body1 report-disclaimer"
                data-testid="report-disclaimer">
                Legal Disclaimer: This Report has been approved for release by{' '}
                {review.surgeryInfo.surgeonName}. This report and any
                accompanying images or video are not intended for any medical
                use including diagnosis or interpretation of a surgical or
                medical outcome. No representations are made with regard to the
                labeling shown in the image(s).
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
