import { ReactElement } from 'react';
import CheckCircle from '../../../../assets/icons/check-circle.svg';
import { KaliberButton } from '../../../../components';
import './DeleteModal.scss';
interface PostDeleteModalProps {
  visible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (val: any) => void;
}
export default function PostDeleteModal({
  visible,
  onClick
}: PostDeleteModalProps): ReactElement {
  return (
    <div className={`modal-container ${visible ? 'show-modal' : ''}`}>
      <div className="modal-container__container">
        <div className="post-delete-modal">
          <div className="post-delete-modal__header-container">
            <div className="post-delete-modal__icon">
              <img
                src={CheckCircle}
                alt="Check Circle"
                className="post-delete-modal__icon"
              />
            </div>
            <div className="post-delete-modal__title">
              Surgery has been successfully deleted.
            </div>
          </div>

          <div className="post-delete-modal__buttons">
            <KaliberButton
              type="button"
              buttonClass="post-delete-modal__buttons--cancel"
              onClick={onClick}>
              Ok
            </KaliberButton>
          </div>
        </div>
      </div>
    </div>
  );
}
