import { sentenceCase } from 'change-case';
import moment from 'moment';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  KaliberButton,
  KaliberDivider,
  KaliberLinkButton,
  KaliberModal,
  KaliberTooltip
} from '../../../../components';
import {
  setUploadData,
  toggleNavbarShow,
  toggleNavbarStatus
} from '../../../../redux/actions/surgeriesActions';
import { KLSurgery, KLUser } from '../../../../redux/types';
import { capitalizeFirstLetter } from '../../../../utils/textFormat';
import { isNullOrUndefined } from '../../../../utils/validations';
import './SurgeryCard.scss';
import { GroupedProcedures, KaliberActionMenu, StatusChip } from './components';
import { triggerCustomGAEvent } from '../../../../utils/googleAnalytics';
import { isHCP, isSurgeon } from '../../../../utils/user';

interface KaliberSurgeryCardProps {
  surgery: KLSurgery;
  showDelete: boolean;
  handleDelete: (surgeryId: number) => void;
  isReportViewed: boolean | null;
  user: KLUser;
}

export default function SurgeryCard({
  surgery,
  showDelete,
  handleDelete,
  isReportViewed,
  user
}: KaliberSurgeryCardProps): ReactElement {
  const [isPendingReviewModalOpen, setPendingReviewModalOpen] = useState(false);

  function profileLink() {
    const linkText = `${capitalizeFirstLetter(surgery.patientFirstName)} ${
      surgery.patientMiddleName
        ? capitalizeFirstLetter(surgery.patientMiddleName)
        : ''
    } ${capitalizeFirstLetter(surgery.patientLastName)}`;

    return (
      <Link
        onClick={() => {
          toggleNavbarShow(false);
          toggleNavbarStatus('profile');
        }}
        to={{
          pathname: `/surgeries/${surgery.surgeryId}`,
          search: '?tab=profile'
        }}>
        {linkText}
      </Link>
    );
  }

  function surgeryAction() {
    const surgeonEmail = surgery.surgeonEmail ?? '';
    const surgeryDate = surgery.surgeryDate;
    const tableDate = moment(surgeryDate).toDate();
    const todayDate = new Date(new Date().setHours(0, 0, 0, 0));

    const returnStatus = (tableDate: Date, todayDate: Date): boolean => {
      if (tableDate > todayDate) {
        return false;
      } else {
        return true;
      }
    };

    const openRawImagePDF = () => {
      if (surgery.rawPdfUrl) {
        window.open(surgery.rawPdfUrl, '_blank');

        const userUid = user.uid;
        triggerCustomGAEvent(
          'raw_image_pdf_click',
          `role: patient(uid: ${userUid})`,
          `surgeryId: ${surgery.surgeryId}`
        );
      }
    };

    if (surgery.surgeryStatus === 'Processing') {
      return (
        <>
          {isHCP(user) ? (
            <KaliberButton
              type="button"
              buttonClass="btn--primary"
              disabled={!surgery.rawPdfUrl}
              onClick={openRawImagePDF}>
              View Raw Images
            </KaliberButton>
          ) : (
            <KaliberButton type="button" buttonClass="btn--primary" disabled>
              Continue Report
            </KaliberButton>
          )}
        </>
      );
    }
    if (surgery.surgeryStatus === 'Surgeon Review Processing') {
      return (
        <KaliberButton type="button" buttonClass="btn--primary" disabled>
          View report
        </KaliberButton>
      );
    } else if (surgery.surgeryStatus === 'Ready for Review') {
      if (isSurgeon(user)) {
        return (
          <KaliberLinkButton
            buttonClass="btn--primary"
            to={{
              pathname: `/surgeries/${surgery.surgeryId}/review`
            }}
            disabled>
            Continue report
          </KaliberLinkButton>
        );
      } else {
        return (
          <div className="pending-review-container">
            <KaliberButton
              type="button"
              buttonClass="btn--primary disabled-review-button"
              disabled={false}
              onClick={() => setPendingReviewModalOpen(true)}>
              Pending Review
            </KaliberButton>
            <div className="pending-review-modal">
              <KaliberModal
                title="Pending Surgeon Review"
                titleAlignment="left"
                visible={isPendingReviewModalOpen}
                closeIcon={true}
                onClose={() => setPendingReviewModalOpen(false)}>
                <div className="modal-text">
                  The patient report is pending surgeon review. Please check
                  back later.
                </div>
              </KaliberModal>
            </div>
          </div>
        );
      }
    } else if (
      surgery.surgeryStatus === 'Sent to Patient' ||
      (surgery.surgeryStatus !== 'Not Started' &&
        surgeonEmail.includes('ryan+'))
    ) {
      return (
        <KaliberLinkButton
          onClick={() => {
            setUploadData({
              patientName: `${surgery.patientFirstName} ${surgery.patientLastName}`,
              dob: surgery.patientDob,
              surgeonName: surgery.surgeonName,
              surgeryDescription: `${sentenceCase(
                surgery.side ?? ''
              )} ${sentenceCase(surgery.region ?? '')} -
                    ${surgery.surgeryType}
                  }`,
              surgeryDate: surgery.surgeryDate
            });
            toggleNavbarStatus('report');
            toggleNavbarShow(true);
          }}
          buttonClass="btn--primary"
          to={{
            pathname: `/surgeries/${surgery.surgeryId}`,
            search: '?tab=surgery_report'
          }}>
          View report
        </KaliberLinkButton>
      );
    } else if (surgery.surgeryStatus === 'Not Started') {
      if (isNullOrUndefined(surgery.surgeryDate)) {
        return (
          <KaliberTooltip
            title="Please complete the surgery information"
            arrow
            placement="bottom">
            <Link
              onClick={() => {
                toggleNavbarShow(false);
                toggleNavbarStatus('profile');
              }}
              className="action-button"
              to={{
                pathname: '/edit-surgery',
                search: `surgeryId=${surgery.surgeryId}`
              }}>
              <KaliberButton type="button" buttonClass="btn--primary" disabled>
                Edit surgery
              </KaliberButton>
            </Link>
          </KaliberTooltip>
        );
      }
      const dateCheck = returnStatus(tableDate, todayDate);

      if (dateCheck) {
        return (
          <KaliberLinkButton
            buttonClass="btn--primary"
            onClick={() => {
              setUploadData({
                patientName: `${surgery.patientFirstName} ${surgery.patientLastName}`,
                dob: surgery.patientDob,
                surgeonName: surgery.surgeonName,
                surgeryDescription: `${sentenceCase(
                  surgery.side ?? ''
                )} ${sentenceCase(surgery.region ?? '')} -
                    ${surgery.surgeryType}
                  }`,
                surgeryDate: surgery.surgeryDate
              });
              toggleNavbarStatus('report');
              toggleNavbarShow(true);
            }}
            to={{
              pathname: `/surgeries/${surgery.surgeryId}/upload`
            }}>
            Upload files
          </KaliberLinkButton>
        );
      } else {
        return (
          <KaliberTooltip
            title="The surgery date hasn't passed yet"
            className="action-button"
            arrow
            placement="bottom">
            <KaliberButton type="button" buttonClass="btn--primary" disabled>
              Upload files
            </KaliberButton>
          </KaliberTooltip>
        );
      }
    } else if (surgery.surgeryStatus === 'Internal Review') {
      return (
        <KaliberLinkButton
          buttonClass="btn--primary"
          to={{
            pathname: `/surgeries/${surgery.surgeryId}/review`
          }}>
          Review outputs{' '}
        </KaliberLinkButton>
      );
    } else {
      return null;
    }
  }

  function reportReadMsg() {
    if (isReportViewed == true) {
      return (
        <div className="report-read-status__viewed">
          report viewed by patient
        </div>
      );
    } else if (isReportViewed == false) {
      return (
        <div className="report-read-status__not-viewed">
          report not viewed by patient
        </div>
      );
    } else {
      return <></>;
    }
  }

  return surgery !== null ? (
    <div className="surgery-card">
      <div className="surgery-card__header">
        <div className="surgery-card__name">
          <span className="surgery-card__name--link">{profileLink()}</span>
        </div>
        <StatusChip status={surgery.surgeryStatus} />
      </div>
      <div className="procedures-report-wrapper">
        <GroupedProcedures {...surgery} />
        <div className="report-read-msg-container">{reportReadMsg()}</div>
      </div>
      <KaliberDivider />
      <div className="surgery-card__info">
        <div className="surgery-card__info-field">
          <div className="surgery-card__info-field--title">DOB</div>
          <div className="surgery-card__info-field--details">
            {surgery.patientDob}
          </div>
        </div>
        <div className="surgery-card__info-field">
          <div className="surgery-card__info-field--title">Surgery Date</div>
          <div className="surgery-card__info-field--details">
            {surgery.surgeryDate}
          </div>
        </div>
        <div className="surgery-card__info-field">
          <div className="surgery-card__info-field--title">Surgeon</div>
          <div className="surgery-card__info-field--details">
            {surgery.surgeonName}
          </div>
        </div>
        <div className="surgery-card__info-field">
          <div className="surgery-card__info-field--title">
            Surgery Facility
          </div>
          <div className="surgery-card__info-field--details">
            {surgery.facilityName}
          </div>
        </div>
        <div className="surgery-card__button">
          {surgeryAction()}
          <KaliberActionMenu
            surgery={surgery}
            handleDelete={handleDelete}
            showDelete={showDelete}
          />
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
