import { ReactElement, useState } from 'react';
import CaretDown from '../../assets/icons/CaretDown.svg';
import CaretUp from '../../assets/icons/CaretUp.svg';
import './KaliberQuickSwitch.scss';

interface KLTabsProps {
  titles: string[];
  active: string;
  onChange: (value: string) => void;
  stepper?: boolean;
  loading: boolean;
}

export default function KaliberQuickSwitch({
  titles,
  active,
  onChange,
  stepper = false,
  loading
}: KLTabsProps): ReactElement {
  function onClickHandler(newTitle: string) {
    onChange(newTitle);
  }
  const [expanded, setExpanded] = useState(false);

  const activeIndex = titles.indexOf(active);

  return (
    <>
      {loading ? (
        <div className="quick-switch__container">
          <div className="surgery-page__skeleton surgery-page__skeleton--switch" />
        </div>
      ) : (
        <div className="quick-switch__container">
          <div className="quick-switch__select">
            <div className="qs-select__container">
              <button
                className="qs-select__active"
                onClick={() => setExpanded(!expanded)}>
                <div id="fs-mask" className="qs-select__title">
                  {active}
                </div>
                <div className="qs-select__expand-icon">
                  {expanded ? <img src={CaretUp} /> : <img src={CaretDown} />}
                </div>
              </button>
              <div
                className={`qs-option-list ${
                  expanded
                    ? 'qs-option-list--expanded'
                    : 'qs-option-list--collapsed'
                }`}>
                {titles.map((value: string, i: number) => (
                  <button
                    key={i}
                    className={`qs-option-list__button ${
                      value === active && 'qs-option-list__button--active'
                    }`}
                    onClick={() => onClickHandler(value)}>
                    {value}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="quick-switch__tabs">
            <div className="qs-tabs__container">
              {titles.map((value: string, i: number) => (
                <button
                  key={i}
                  onClick={() => onClickHandler(value)}
                  disabled={stepper ? activeIndex < i : false}
                  className={`qs-tabs__button ${
                    value === active && 'qs-tabs__active'
                  }`}>
                  <div className="qs-tabs__text">{value}</div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
