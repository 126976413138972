// GenericDetailCard.tsx
import { ReactElement } from 'react';
import { Control, UseFormRegister } from 'react-hook-form';
import { KaliberTextArea } from '../../../../../../../../components';
import './GenericAdditionalDetailComponent.scss';

export interface GenericDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  register: UseFormRegister<any>;
  cptProcedureIndex: number;
  detailTypeValue: string;
  placeholder: string;
}

export default function GenericDetailCard({
  register,
  cptProcedureIndex,
  detailTypeValue,
  placeholder = 'Additional notes about size, repair details, tissue quality, anchor count etc'
}: GenericDetailCardProps): ReactElement {
  return (
    <div className="generic-additional-detail">
      <input
        type="hidden"
        {...register(`cptProcedures.${cptProcedureIndex}.procedureDetail.id`, {
          setValueAs: v => (v === '' ? null : parseInt(v, 10))
        })}
      />

      <input
        type="hidden"
        {...register(
          `cptProcedures.${cptProcedureIndex}.procedureDetail.type`,
          { value: detailTypeValue }
        )}
      />

      <div className="additional-details">
        <KaliberTextArea
          label="Additional Details (optional)"
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
          register={register}
          errors={{}}
          rows={3}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
