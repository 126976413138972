import { ReactElement } from 'react';

import RadioButton from '../radioButton/RadioButton';
import { ReactComponent as CheckCircle } from '../../assets/icons/CheckCircle.svg';
import './SegmentedControl.scss';

import { capitalizeFirstLetter } from '../../utils/textFormat';

interface SegmentedControlProps {
  label?: string;
  name: string;
  segments: string[];
  selected?: string;
  disabled?: boolean;
  className?: string;
  onSegmentChange?: (value: string) => void;
}

const SegmentedControl = ({
  label,
  name,
  segments,
  selected,
  disabled,
  className,
  onSegmentChange
}: SegmentedControlProps): ReactElement => {
  const selectedSegment = selected ? segments.indexOf(selected) : -1;

  const handleSegmentClick = (index: number) => {
    if (!disabled) {
      if (onSegmentChange) {
        onSegmentChange(segments[index]);
      }
    }
  };

  return (
    <div className={className}>
      {label ? <div className="segmented-control__label">{label}</div> : null}
      <>
        <div className="segmented-control">
          {segments.map((segment, index) => {
            return (
              <div
                key={index}
                className={`segmented-control__segment ${
                  selectedSegment === index
                    ? 'segmented-control__segment--selected'
                    : ''
                }`}
                onClick={() => handleSegmentClick(index)}>
                {selectedSegment === index && !disabled ? (
                  <CheckCircle />
                ) : null}
                {capitalizeFirstLetter(segment)}
              </div>
            );
          })}
        </div>
        <div className="radio-buttons">
          {segments.map((segment, index) => {
            return (
              <RadioButton
                key={segment}
                label={capitalizeFirstLetter(segment)}
                value={segment}
                checked={index === selectedSegment}
                nameGroup={name}
                onClick={() => handleSegmentClick(index)}
              />
            );
          })}
        </div>
      </>
    </div>
  );
};

export default SegmentedControl;
