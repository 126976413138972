import React, { ReactElement } from 'react';
import CloseIcon from '../../assets/icons/cross-large.svg';
import './KaliberModal.scss';

interface KaliberModalProps {
  title?: string;
  titleIcon?: ReactElement;
  titleIconAlignment?: 'left' | 'center';
  visible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  titleAlignment?: 'left' | 'center' | 'right';
  size?: 'default' | 'medium';
  onClose?: () => void;
  closeIcon?: boolean;
}

export default function KaliberModal({
  children,
  visible,
  title,
  titleIcon,
  titleIconAlignment = 'center',
  titleAlignment,
  size = 'default',
  onClose,
  closeIcon
}: KaliberModalProps): ReactElement {
  return (
    <div className={`kaliber-modal-container ${visible ? 'show-modal' : ''}`}>
      <div className={`kaliber-modal-content-container ${size}`}>
        <div
          className={`kaliber-modal-title-icon-container kaliber-modal-${titleIconAlignment}-icon kaliber-modal-title-icon-container-${titleAlignment}`}>
          {titleIcon ? (
            <div className="kaliber-modal-header-icon">{titleIcon}</div>
          ) : null}
          <div
            className={`kaliber-modal-header-text kaliber-modal-header-text-${titleAlignment}`}>
            {title}
          </div>
        </div>
        {closeIcon ? (
          <img
            className="kaliber-modal-close-icon"
            src={CloseIcon}
            onClick={onClose}
          />
        ) : null}
        {children}
      </div>
    </div>
  );
}
