import { ReactElement } from 'react';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import '../../AddSurgeryPage.scss';
import {
  KaliberCard,
  KaliberTextField,
  KaliberPhoneField
} from '../../../../components';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface GuardianContactFormComponentProps {
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<any>;
  control: Control<any, any>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function GuardianContactFormComponent({
  register,
  errors,
  control
}: GuardianContactFormComponentProps): ReactElement {
  return (
    <KaliberCard title="Parent/Guardian Contact" titleAlignment="left">
      <div className="asp-form-inputs">
        <div className="asp-patient-details">
          <div className="asp-patient-details-subcontainer">
            <KaliberTextField
              label="Parent/Guardian Name"
              placeholder=""
              name="emergencyContactName"
              register={register}
              errors={errors}
              errorMessage="Parent/Guardian name is required"
              registerObject={{ required: true }}
            />
            <div className="asp-top-margin-field">
              <KaliberPhoneField
                label="Mobile Phone (recommended)"
                name="emergencyContactPhone"
                placeholder={false}
                errors={errors}
                control={control}
              />
            </div>
            <KaliberTextField
              label="Email (recommended)"
              placeholder=""
              name="emergencyContactEmail"
              register={register}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </KaliberCard>
  );
}
