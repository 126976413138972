import { ReactElement } from 'react';

import KaliberCard from '../../../../components/card/KaliberCard';
import KLCptProcedure from '../../../../components/cptProcedure/KLCptProcedure';
import { NewSurgeryData } from '../../../../redux/types';
import { groupCptSurgeryProceduresByAnatomy } from '../../../../utils/surgeryProcedures';
import { capitalizeFirstLetter } from '../../../../utils/textFormat';
import '../../AddSurgeryPage.scss';

export interface SurgeryDetailsDisplayComponentProps {
  newSurgeryData: NewSurgeryData;
}

export default function SurgeryDetailsDisplayComponent({
  newSurgeryData
}: SurgeryDetailsDisplayComponentProps): ReactElement {
  return (
    <KaliberCard title="Surgery Details" titleAlignment="left">
      <div className="ascp-info-section-container">
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Surgeon</div>
            <div className="ascp-info-value">{newSurgeryData.surgeonName}</div>
          </div>
          <div className="ascp-info-card">
            <div className="ascp-info-label">Surgery Center</div>
            <div className="ascp-info-value">{newSurgeryData.facilityName}</div>
          </div>
          <div className="ascp-info-card">
            <div className="ascp-info-label">Date of Surgery</div>
            <div className="ascp-info-value">{newSurgeryData.surgeryDate}</div>
          </div>
        </div>
        <div className="ascp-procedures-label">CPT Codes</div>
        <div className="ascp-procedures-container">
          {Object.entries(
            groupCptSurgeryProceduresByAnatomy(
              newSurgeryData.plannedProcedures.cptProcedures
            )
          ).map(([anatomy, cptSurgeryProcedures], index) => (
            <div key={index}>
              {index > 0 ? (
                <div className="ascp-patient-region-line-divider" />
              ) : null}
              <div className="ascp-patient-region-label">
                {capitalizeFirstLetter(cptSurgeryProcedures[0].side || '') +
                  ' ' +
                  capitalizeFirstLetter(anatomy)}
              </div>
              {cptSurgeryProcedures.map(cptSurgeryProcedure => (
                <KLCptProcedure
                  key={cptSurgeryProcedure.cptCode.code}
                  cptCode={cptSurgeryProcedure.cptCode.code}
                  description={cptSurgeryProcedure.cptCode.description}
                  commonName={cptSurgeryProcedure.cptCode.commonName}
                />
              ))}
            </div>
          ))}

          {newSurgeryData.plannedProcedures.customProcedures.length != 0 ? (
            <div>
              {newSurgeryData.plannedProcedures.cptProcedures.length !== 0 ? (
                <div className="ascp-patient-region-line-divider" />
              ) : null}
              <div className="ascp-patient-region-label">Other</div>
              {newSurgeryData.plannedProcedures.customProcedures.map(
                customSurgeryProcedure => (
                  <div
                    className="ascp-patient-custom-procedure-value"
                    key={customSurgeryProcedure.customProcedure.procedure}>
                    {customSurgeryProcedure.customProcedure.procedure}
                  </div>
                )
              )}
            </div>
          ) : null}

          {newSurgeryData.plannedProcedures.cptProcedures.length === 0 &&
          newSurgeryData.plannedProcedures.customProcedures.length === 0 ? (
            <div className="ascp-patient-custom-procedure-empty-value">
              No Entry
            </div>
          ) : null}
        </div>
      </div>
    </KaliberCard>
  );
}
