/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import './KaliberTooltip.scss';

interface KaliberTooltipProps {
  title: string;
  arrow: boolean;
  children: any;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  visibleOnHover?: boolean;
  visibleOnClick?: boolean;
  onClick?: () => void;
  className?: string;
}

export default function KaliberTooltip({
  title,
  arrow,
  children,
  placement = 'top',
  visibleOnHover = true,
  visibleOnClick = false,
  className
}: KaliberTooltipProps): ReactElement {
  const [showTooltip, setShowTooltip] = useState(false);
  let timeout: NodeJS.Timeout;

  function handleHover(show: boolean) {
    if (visibleOnHover) {
      setShowTooltip(show);
    }
  }

  function handleClick() {
    if (visibleOnClick) {
      setShowTooltip(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      timeout = setTimeout(() => {
        setShowTooltip(false);
      }, 4000);
    }
  }

  return (
    <div
      className={`kaliber-tooltip-container`}
      onClick={() => handleClick()}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}>
      {showTooltip && (
        <div className={`tooltip-${placement} ${className ? className : ''}`}>
          {arrow && <div className="tooltip-arrow" />}
          <div className="tooltip-title">{title}</div>
        </div>
      )}
      {children}
    </div>
  );
}
