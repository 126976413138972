import React, { ReactElement } from 'react';
import './KaliberMessagePage.scss';
import KaliberButton from '../button/KaliberButton';

interface KaliberMessagePageProps {
  onClick?: (val: React.SyntheticEvent) => void;
  title: string;
  body?: string;
  buttonText?: string;
  hasButton?: boolean;
}
// * IMPORTANT: THIS COMPONENT IS TO BE USED AS THE ONLY COMPONENT ON THE PAGE ITS USED ON
export default function KaliberMessagePage({
  onClick,
  title,
  body,
  buttonText,
  hasButton
}: KaliberMessagePageProps): ReactElement {
  return (
    <div className="mp-card">
      <div className="mp-card__title">{title}</div>
      <div className="mp-card__body">{body}</div>
      {hasButton && (
        <div className="mp-card__btn">
          <KaliberButton
            buttonClass="btn--primary"
            onClick={onClick}
            type="button">
            {buttonText}
          </KaliberButton>
        </div>
      )}
    </div>
  );
}
