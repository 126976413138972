import { computeChecksumMd5 } from '../utils/common';
import { callStratusApiV2, RequestMethod, callS3Upload } from './index';

interface Blob {
  id: number;
  key: string;
  filename: string;
  content_type: string;
  service_name: string;
  byte_size: number;
  checksum: string;
  signed_id: string;
  attachable_sgid: string;
  direct_upload: DirectUploadParams;
}

interface DirectUploadParams {
  url: string;
  headers: {
    'Content-Type': string;
    'Content-MD5': string;
    'Content-Disposition': string;
  };
}

export class DirectUpload {
  accessToken: string;
  surgeryId: number;
  file: File;
  abortController?: AbortController;

  constructor(accessToken: string, surgeryId: number, file: File) {
    this.accessToken = accessToken;
    this.surgeryId = surgeryId;
    this.file = file;
  }

  async upload(): Promise<void> {
    try {
      const blob = await this.createBlob();
      await this.uploadToS3(blob.direct_upload);
      await this.createMediaRecord(blob);
    } catch (e) {
      throw new Error('Failed to upload to S3');
    }
  }

  private async createBlob(): Promise<Blob> {
    const hashVal = btoa(await computeChecksumMd5(this.file));
    return await callStratusApiV2<Blob>(
      `surgeries/${this.surgeryId}/direct_upload?source=raw`,
      {
        accessToken: this.accessToken,
        method: RequestMethod.Post,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          blob: {
            filename: this.file.name,
            content_type: this.file.type || 'application/octet-stream',
            byte_size: this.file.size,
            checksum: hashVal
          }
        }),
        signal: this.abortController?.signal
      }
    );
  }

  private async uploadToS3(uploadParams: DirectUploadParams): Promise<void> {
    await callS3Upload(uploadParams.url, {
      method: RequestMethod.Put,
      headers: uploadParams.headers,
      body: this.file,
      signal: this.abortController?.signal
    });

    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  private async createMediaRecord(blob: Blob): Promise<void> {
    await callStratusApiV2<{ message: string }>(
      `surgeries/${this.surgeryId}/medias`,
      {
        accessToken: this.accessToken,
        method: RequestMethod.Post,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          media: {
            media_source: 'raw',
            md5_hash: blob.checksum,
            asset: blob.signed_id,
            filename: this.file.name
          }
        }),
        signal: this.abortController?.signal
      }
    );
  }
}
