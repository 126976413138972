import { ReactElement, useEffect } from 'react';
import {
  Controller,
  Control,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';
import './RotatorCuffRepairComponent.scss';
import {
  KaliberTextArea,
  KaliberChipMultiSelect,
  SegmentedControl
} from '../../../../../../../../components';
import { CptSurgeryProcedure } from '../../../../../../../../redux/types';

const REGIONS_OF_TEAR = ['Supraspinatus', 'Infraspinatus', 'Subscapularis'];
const SIZES_OF_TEAR = ['N/A', 'S (<=1cm)', 'M (1-3cm)', 'L (>3cm)'];

type TearSizeComponentProps = {
  cptProcedureIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;

  rcrIndices: number[];
};

function TearSizeComponent({
  cptProcedureIndex,
  control,
  setValue,
  rcrIndices
}: TearSizeComponentProps): ReactElement {
  return (
    <Controller
      name={`cptProcedures.${cptProcedureIndex}.procedureDetail.tearSize`}
      control={control}
      defaultValue="N/A"
      render={({ field }) => (
        <SegmentedControl
          label="Tear Size"
          name="tearSize"
          segments={SIZES_OF_TEAR}
          selected={field.value}
          className="rcr-segment"
          onSegmentChange={tearSize => {
            field.onChange(tearSize);
            if (rcrIndices.length > 0) {
              rcrIndices.map((idx: number) => {
                setValue(
                  `cptProcedures.${idx}.procedureDetail.tearSize`,
                  tearSize
                );
              });
            }
          }}
        />
      )}
    />
  );
}

export interface RotatorCuffRepairDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;

  /* eslint-enable @typescript-eslint/no-explicit-any */
  cptProcedureIndex: number;
  cptProcedures: CptSurgeryProcedure[];
  rcrIndices: number[];
}

export default function RotatorCuffRepairDetailCard({
  control,
  register,
  setValue,
  cptProcedureIndex,
  cptProcedures,
  rcrIndices
}: RotatorCuffRepairDetailCardProps): ReactElement {
  useEffect(() => {
    // Do not update if there is only 1 rcr index
    if (rcrIndices.length < 2) {
      return;
    }

    // This syncs the rest of the RCR components with the current source of truth
    if (cptProcedures != undefined) {
      // Take the value from the current source of truth
      const procedureDetail = cptProcedures[cptProcedureIndex].procedureDetail;
      if (procedureDetail && 'tearRegion' in procedureDetail) {
        const tearRegion = procedureDetail.tearRegion;
        const tearSize = procedureDetail.tearSize;
        const additionalDetails = procedureDetail.additionalDetails;

        // Iterate through the rcrIndices and update the rest of the values
        rcrIndices
          .filter(idx => idx !== cptProcedureIndex)
          .map(idx => {
            setValue(
              `cptProcedures.${idx}.procedureDetail.tearRegion`,
              tearRegion
            );
            setValue(`cptProcedures.${idx}.procedureDetail.tearSize`, tearSize);
            setValue(
              `cptProcedures.${idx}.procedureDetail.additionalDetails`,
              additionalDetails
            );
          });
      }
    }
  }, []);

  const handleAdditionalDetailsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newAdditionalDetails = event.target.value;

    rcrIndices.forEach(idx => {
      setValue(
        `cptProcedures.${idx}.procedureDetail.additionalDetails`,
        newAdditionalDetails
      );
    });
  };

  const inputComponents = rcrIndices.map(rcrIndex => (
    <>
      <input
        type="hidden"
        {...register(`cptProcedures.${rcrIndex}.procedureDetail.id`, {
          setValueAs: v => (v === '' ? null : parseInt(v, 10))
        })}
      />
      <input
        type="hidden"
        {...register(`cptProcedures.${rcrIndex}.procedureDetail.type`, {
          value: 'ProcedureDetails::RotatorCuffRepairDetail'
        })}
      />
    </>
  ));

  return (
    <div className="rotator-cuff-repair">
      {inputComponents}
      <div className="multi-select-container">
        <Controller
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.tearRegion`}
          control={control}
          render={({ field }) => (
            <KaliberChipMultiSelect
              label="Region of Tear (optional)"
              select={field.value}
              onChange={tearRegion => {
                field.onChange(tearRegion);
                if (rcrIndices.length > 0) {
                  rcrIndices.map(idx => {
                    setValue(
                      `cptProcedures.${idx}.procedureDetail.tearRegion`,
                      tearRegion
                    );
                  });
                }
              }}
              list={REGIONS_OF_TEAR}
            />
          )}
        />
      </div>
      {
        <TearSizeComponent
          cptProcedureIndex={cptProcedureIndex}
          control={control}
          setValue={setValue}
          rcrIndices={rcrIndices}
        />
      }

      <div className="additional-details">
        <KaliberTextArea
          label="Additional Details (optional)"
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
          register={register}
          errors={{}}
          rows={3}
          placeholder="Additional notes about size, repair details, tissue quality, anchor count etc"
          onChange={handleAdditionalDetailsChange}
        />
      </div>
    </div>
  );
}
