/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import './KaliberSwitch.scss';

interface KaliberSwitchProps {
  checked?: boolean;
  onChange?: (val: boolean) => void;
  label: string;
  dataTestId?: string;
  className?: string;
  labelPosition?: 'left' | 'right';
  disabled?: boolean;
}

export default function KaliberSwitch({
  checked = false,
  onChange,
  label,
  dataTestId,
  className,
  labelPosition = 'right',
  disabled = false
}: KaliberSwitchProps): ReactElement {
  const [value, setValue] = useState(checked);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  function handleOnChange(val: boolean) {
    if (!disabled && onChange) {
      onChange(val);
    }
    if (!disabled) {
      setValue(val);
    }
  }

  return (
    <div
      className={`kswitch-container ${
        labelPosition === 'left' ? 'ksw-label-left' : 'ksw-label-right'
      } ${className ? className : ''}`}>
      <label className={`kswitch ${disabled ? 'kswitch-disabled' : ''}`}>
        <input
          className="kswitch-input"
          type="checkbox"
          onChange={e => handleOnChange(e.target.checked)}
          checked={value}
          data-testid={dataTestId ? dataTestId : 'ks-switch'}
          disabled={disabled}
        />
        <span className="kswitch-switch"></span>
      </label>
      <div>{label}</div>
    </div>
  );
}
