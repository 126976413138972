import React, { ReactElement } from 'react';
import './StyleGuide.scss';

export default function SGColors(): ReactElement {
  const colors = [
    {
      name: 'Kaliber Black',
      value: '#313D4E'
    },
    {
      name: 'Background',
      value: '#F9F9F9'
    },
    {
      name: 'Teal',
      value: '#1EB5B5'
    },
    {
      name: 'LightTeal',
      value: '#00A1A1'
    },
    {
      name: 'DarkTeal',
      value: '#008585'
    },
    {
      name: 'Grey400',
      value: '#44546A'
    },
    {
      name: 'Grey300',
      value: '#697688'
    },
    {
      name: 'Grey200',
      value: '#B4BBC3'
    },
    {
      name: 'Grey100',
      value: '#DADDE1'
    },
    {
      name: 'Grey000',
      value: '#EBECEE'
    },
    {
      name: 'White',
      value: '#FFFFFF'
    },
    {
      name: 'PrimaryBlue',
      value: '#4472C4'
    },
    {
      name: 'LightBlue',
      value: '#6291E5'
    },
    {
      name: 'DarkBlue',
      value: '#2B5A9E'
    },
    {
      name: 'Red',
      value: '#E52424'
    },
    {
      name: 'LightRed',
      value: '#EB5757'
    },
    {
      name: 'DarkRed',
      value: '#C81B1B'
    },
    {
      name: 'Yellow',
      value: '#FFDE79'
    },
    {
      name: 'LightYellow',
      value: '#FFECB0'
    },
    {
      name: 'DarkYellow',
      value: '#FFD34E'
    },
    {
      name: 'Brown',
      value: '#805D0B'
    },
    {
      name: 'Green',
      value: '#187841'
    }
  ];
  return (
    <div className="scss-colors">
      <div className="sg-subtitle">Rekap Color Guide</div>
      <div className="color-set">
        {colors.map(color => (
          <div
            key={color.name}
            className="color"
            onClick={() => {
              navigator.clipboard.writeText('$' + color.name);
            }}>
            <div
              className="color-swatch"
              style={{ backgroundColor: color.value }}></div>
            <span className="color-meta">{color.name}</span>
            <span className="color-meta">{color.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
