import React, { ReactElement } from 'react';
import './StatusChip.scss';

interface StatusChipProps {
  status: string;
}

export default function StatusChip({ status }: StatusChipProps): ReactElement {
  function cardColor() {
    switch (status.toLowerCase()) {
      case 'processing':
      case 'packaging patient report':
        return 'default';
      case 'not started':
        return 'red';
      case 'ready for review':
        return 'yellow';
      case 'internal review':
        return 'yellow';
      case 'sent to patient':
        return 'green';
    }
  }
  return (
    <div className={`status-chip status-chip--${cardColor()}`}>
      <span className="status-chip__text">{status}</span>
    </div>
  );
}
