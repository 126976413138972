import { ReactElement } from 'react';
import {
  Controller,
  Control,
  FieldErrorsImpl,
  UseFormSetValue
} from 'react-hook-form';
import moment from 'moment';

import {
  CptAutoCompleteSearch,
  KaliberSelect,
  KaliberCard,
  KaliberDateOfBirthPicker
} from '../../../../components';
import { SurgeryProcedureDetailsFormComponent } from './surgeryProcedureDetails';
import AddCustomProcedureComponent from '../../../../components/customProcedure/AddCustomProcedureComponent';
import '../../AddSurgeryPage.scss';

import { KLCptCode, CptSurgeryProcedure } from '../../../../redux/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface SurgeryDetailsComponentProps {
  control: Control<any, any>;
  errors: FieldErrorsImpl<any>;
  surgeonNames: string[];
  facilitiesNames: string[];
  cptCodes: KLCptCode[];
  selectedProcedures: CptSurgeryProcedure[];
  setSelectedProcedures: (surgeryProcedures: CptSurgeryProcedure[]) => void;
  customProcedures: string[];
  setCustomProcedures: (customProcedures: string[]) => void;
  setValue: UseFormSetValue<any>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function SurgeryDetailsComponent({
  control,
  errors,
  surgeonNames,
  facilitiesNames,
  cptCodes,
  selectedProcedures,
  setSelectedProcedures,
  customProcedures,
  setCustomProcedures,
  setValue
}: SurgeryDetailsComponentProps): ReactElement {
  function handleSelectedCptCode(selectedCptCode: KLCptCode) {
    const preExistingCptProcedure = selectedProcedures.find(
      selectedProcedure => selectedProcedure.region === selectedCptCode.anatomy
    );

    const defaultSide =
      selectedCptCode.anatomy === 'knee' ||
      selectedCptCode.anatomy === 'shoulder'
        ? 'left'
        : '';

    setSelectedProcedures([
      ...selectedProcedures,
      {
        side: preExistingCptProcedure?.side ?? defaultSide,
        region: selectedCptCode.anatomy,
        procedureType: 'planned',
        cptCode: selectedCptCode
      }
    ]);
  }

  function hasProcedures() {
    return selectedProcedures.length != 0 || customProcedures.length != 0;
  }

  return (
    <div className="sdf-container">
      <KaliberCard title="Surgery Details" titleAlignment="left">
        <div className="asp-form-inputs">
          <div className="asp-surgery-details">
            <Controller
              name="surgeonName"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <KaliberSelect
                  label="Surgeon"
                  placeholder=""
                  select={field.value}
                  errors={errors}
                  includeOther={false}
                  onChange={e => {
                    field.onChange(e);
                    setValue('facilityName', '');
                  }}
                  list={surgeonNames}
                  hasError={!!error}
                />
              )}
            />
            <Controller
              name="facilityName"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <KaliberSelect
                  label="Surgery Center"
                  placeholder=""
                  select={field.value}
                  errors={errors}
                  includeOther={false}
                  onChange={e => field.onChange(e)}
                  list={facilitiesNames}
                  hasError={!!error}
                />
              )}
            />
            <Controller
              name="surgeryDate"
              control={control}
              rules={{
                required: true,
                validate: date => {
                  const minDate = moment().subtract(125, 'years').toDate();
                  return !isNaN(date) && date > minDate;
                }
              }}
              render={({ field }) => (
                <KaliberDateOfBirthPicker
                  editable={true}
                  className="asp-datepicker-size"
                  label="Surgery Date"
                  name="surgeryDate"
                  errors={errors}
                  errorMessage="Surgery date is required"
                  value={field.value ?? null}
                  onChange={date => field.onChange(date)}
                  dataTestId="test-dob"
                />
              )}
            />
            <Controller
              name="cptCodeProcedures"
              control={control}
              render={({ field }) => (
                <CptAutoCompleteSearch
                  title="CPT Code"
                  suggestions={cptCodes}
                  minimumInputCharacters={3}
                  selected={selectedProcedures.map(
                    selectedProcedure => selectedProcedure.cptCode
                  )}
                  onSelected={e => {
                    handleSelectedCptCode(e);
                    field.onChange(selectedProcedures);
                  }}
                />
              )}
            />
            {hasProcedures() && (
              <SurgeryProcedureDetailsFormComponent
                selectedProcedures={selectedProcedures}
                setSelectedProcedures={setSelectedProcedures}
                control={control}
                customProcedures={customProcedures}
                setCustomProcedures={setCustomProcedures}
              />
            )}
          </div>
          <div className="asp-custom-procedure">
            <AddCustomProcedureComponent
              onCustomProcedureAdded={customProcedure =>
                setCustomProcedures([...customProcedures, customProcedure])
              }
            />
          </div>
        </div>
      </KaliberCard>
    </div>
  );
}
