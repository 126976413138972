import React, { ReactElement } from 'react';
import './KaliberTag.scss';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross-small.svg';

interface KaliberTagProps {
  color?: 'primary' | 'green' | 'yellow' | 'red' | 'black' | 'default';
  icon?: boolean;
  tagKey?: string | number;
  value?: string | number;
  onClick?: (val: string | number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  disabled?: boolean;
}

export default function KaliberTag({
  children,
  color = 'default',
  icon = false,
  tagKey,
  value,
  onClick,
  disabled = false
}: KaliberTagProps): ReactElement {
  function handleOnClick() {
    if (onClick && value && !disabled) {
      onClick(value);
    }
  }

  return (
    <div
      className={`kaliber-tag--${disabled ? 'disabled' : color}`}
      key={tagKey ? tagKey : ''}>
      <div className="kaliber-tag__content">{children}</div>
      {icon && (
        <div className="kt-icon" onClick={handleOnClick}>
          <CrossIcon />
        </div>
      )}
    </div>
  );
}
