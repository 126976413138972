import { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import SurgeryProcedureDetailFormComponent from './SurgeryProcedureDetailFormComponent';
import KLCustomTextInput from '../../../../../components/customTextInput/KLCustomTextInput';
import '../../../AddSurgeryPage.scss';

import { CptSurgeryProcedure } from '../../../../../redux/types';
import { groupCptSurgeryProceduresByAnatomy } from '../../../../../utils/surgeryProcedures';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface SurgeryProcedureDetailsComponentProps {
  selectedProcedures: CptSurgeryProcedure[];
  setSelectedProcedures: (selectedProcedures: CptSurgeryProcedure[]) => void;
  control: Control<any, any>;
  customProcedures: string[];
  setCustomProcedures: (customProcedures: string[]) => void;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function SurgeryProcedureDetailsComponent({
  selectedProcedures,
  setSelectedProcedures,
  control,
  customProcedures,
  setCustomProcedures
}: SurgeryProcedureDetailsComponentProps): ReactElement {
  function handleRemoveSelectedCptProcedure(
    removedProcedure: CptSurgeryProcedure
  ) {
    const updatedSelectedProcedures = selectedProcedures.filter(
      procedureToUpdate => procedureToUpdate !== removedProcedure
    );
    setSelectedProcedures(updatedSelectedProcedures);
  }

  function handleSelectedCptCodesBySide(anatomy: string, side: string) {
    const updatedSelectedProcedure = selectedProcedures.map(
      procedureToUpdate => {
        if (procedureToUpdate.region === anatomy) {
          procedureToUpdate.side = side;
        }

        return procedureToUpdate;
      }
    );
    setSelectedProcedures(updatedSelectedProcedure);
  }

  const handleCustomInputChange = (input: string, index: number) => {
    const newOtherProceduresInput = customProcedures;
    newOtherProceduresInput[index] = input;
    setCustomProcedures(newOtherProceduresInput);
  };

  const handleDeleteCustomInput = (indexToRemove: number) => {
    setCustomProcedures(
      customProcedures.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div className="asp-patient-region-procedure-details-container">
      {Object.entries(
        groupCptSurgeryProceduresByAnatomy(selectedProcedures)
      ).map(([anatomy, cptSurgeryProcedures], anatomyIndex) => (
        <>
          {anatomyIndex > 0 ? (
            <div className="asp-patient-region-line-divider" />
          ) : null}
          <SurgeryProcedureDetailFormComponent
            key={anatomyIndex}
            anatomy={anatomy}
            cptSurgeryProcedures={cptSurgeryProcedures}
            control={control}
            onRemove={handleRemoveSelectedCptProcedure}
            onSegmentChange={handleSelectedCptCodesBySide}
          />
        </>
      ))}

      {selectedProcedures.length > 0 && customProcedures.length > 0 ? (
        <div className="asp-patient-region-line-divider" />
      ) : null}
      {customProcedures.length > 0 && (
        <div className="asp-custom-procedures-container">
          <div className="asp-patient-region-label">Other</div>
          {customProcedures.map((customProcedureInput, index) => (
            <div className="custom-procedure" key={customProcedureInput}>
              <KLCustomTextInput
                value={customProcedureInput}
                onInputChange={e => handleCustomInputChange(e, index)}
                onRemove={() => handleDeleteCustomInput(index)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
