import { ReactElement, useState } from 'react';
import CaretDown from '../../assets/icons/CaretDown.svg';
import CaretUp from '../../assets/icons/CaretUp.svg';
import './KaliberExpandable.scss';

interface KaliberExpandableProps {
  title: string;
  children: ReactElement;
}

function AlwaysOpen({ title, children }: KaliberExpandableProps) {
  return (
    <div className="expandable-container__alwaysopen">
      <div className="expandable-container--header">
        <span id="fs-mask" className="expandable-container--header__text">
          {title}
        </span>
      </div>
      {children}
    </div>
  );
}

function Expandable({ title, children }: KaliberExpandableProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="expandable-container--outer">
      <div className="expandable-container--header">
        <button
          className="expandable-container--header__display_control"
          onClick={() => setExpanded(!expanded)}>
          <span id="fs-mask" className="expandable-container--header__text">
            {title}
          </span>
          {expanded ? <img src={CaretUp} /> : <img src={CaretDown} />}
        </button>
      </div>
      <div className="expandable-container__expandable">
        {expanded && children}
      </div>
    </div>
  );
}

export default function KaliberExpandable({
  title,
  children
}: KaliberExpandableProps): ReactElement {
  return (
    <div className="expandable-container">
      <Expandable title={title}>{children}</Expandable>
      <AlwaysOpen title={title}>{children}</AlwaysOpen>
    </div>
  );
}
