// import { snakeCase } from 'change-case';
import { capitalCase, snakeCase } from 'change-case';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { KaliberQuickSwitch, PageHeader } from '../../components';
import {
  clearSurgery,
  getReviewDataById,
  getSurgery,
  getSurgeryDataById
} from '../../redux/actions/surgeriesActions';
import { KLReview, KLState, KLUser, Surgery } from '../../redux/types';
import { isNotEmpty } from '../../utils/validations';
import PatientReportPatient from '../patientReport/PatientReportPatientView';
import PatientReportSurgeon from '../patientReport/PatientReportSurgeonView';
import './SurgeryPage.scss';
import { PatientProfilePage, SurgeryDocuments } from './components';

interface SurgeryURLParms {
  id: string;
}

export default function SurgeryPage(): ReactElement {
  const { id }: SurgeryURLParms = useParams();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const selectedTab = queryParams.get('tab') ?? 'surgery_report';
  const [user, surgery, review]: [KLUser, Surgery, KLReview] = useSelector(
    (state: KLState) => [
      state.user,
      state.surgeries.surgery,
      state.surgeries.review
    ]
  );
  const [loading, setLoading] = useState(false);
  const [reloadSurgery, setReloadSurgery] = useState(false);
  const [rawImagePDF, setRawImagePDF] = useState('');
  const [patientReportPDF, setPatientReportPDF] = useState('');

  // Get initial surgery
  useEffect(() => {
    if (user.accessToken) {
      setLoading(true);
      Promise.resolve(getSurgery(user.accessToken, parseInt(id, 10)))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          // Redirect user back to main page if there is a 403 error
          setLoading(false);
          history.push('/');
        });
    }
    return () => {
      clearSurgery();
    };
  }, [user.accessToken]);

  useEffect(() => {
    if (user.accessToken && id !== null) {
      Promise.all([
        getSurgeryDataById(user.accessToken, parseInt(id)),
        getReviewDataById(user.accessToken, parseInt(id))
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [user.accessToken, id]);

  // Refresh surgery later
  useEffect(() => {
    if (user.accessToken && reloadSurgery) {
      setLoading(true);
      Promise.resolve(getSurgery(user.accessToken, parseInt(id, 10))).then(
        () => {
          setLoading(false);
        }
      );
    }
  }, [reloadSurgery]);

  useEffect(() => {
    setReloadSurgery(false);
  }, [surgery]);

  // Removing this component until Surgery report is implemented  -janderson
  const setActiveTab = (activeTab: string) => {
    queryParams.set('tab', snakeCase(activeTab));
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  useEffect(() => {
    for (const annotation of review.annotatedData) {
      if (
        annotation.mediaType === 'pdf' &&
        annotation.filename ===
          `${review.surgeryInfo.surgeryDate}_${review.surgeryInfo.patientLastName}_${review.surgeryInfo.patientFirstName}_raw.pdf`
      ) {
        if (annotation.mediaUrl) {
          setRawImagePDF(annotation.mediaUrl);
        }
      }

      if (
        annotation.mediaType === 'pdf' &&
        annotation.filename ===
          `${review.surgeryInfo.surgeryDate}_${review.surgeryInfo.patientLastName}_${review.surgeryInfo.patientFirstName}_annotated.pdf`
      ) {
        if (annotation.mediaUrl) {
          setPatientReportPDF(annotation.mediaUrl);
        }
      }
    }
  }, [review]);

  return (
    <div className="surgery-page__container">
      <PageHeader
        loading={
          loading || !(isNotEmpty(surgery) && isNotEmpty(review.surgeryInfo))
        }
        title={`${review.surgeryInfo.patientFirstName} ${review.surgeryInfo.patientLastName}’s Procedure ${review.surgeryInfo.surgeryDate}`}
        onBack={() => history.goBack()}
      />
      <KaliberQuickSwitch
        loading={
          loading || !(isNotEmpty(surgery) && isNotEmpty(review.surgeryInfo))
        }
        titles={['Profile', 'Surgery Report', 'Documents']}
        active={capitalCase(selectedTab)}
        onChange={setActiveTab}
      />

      {selectedTab === 'profile' && isNotEmpty(surgery) && (
        <PatientProfilePage
          loading={
            loading || !(isNotEmpty(surgery) && isNotEmpty(review.surgeryInfo))
          }
          surgery={surgery}
          setReloadSurgery={setReloadSurgery}
        />
      )}
      {selectedTab === 'surgery_report' &&
        (user.role === 'Patient' ? (
          <PatientReportPatient
            review={review}
            loading={
              loading ||
              !(isNotEmpty(surgery) && isNotEmpty(review.surgeryInfo))
            }
          />
        ) : (
          <PatientReportSurgeon
            review={review}
            loading={
              loading ||
              !(isNotEmpty(surgery) && isNotEmpty(review.surgeryInfo))
            }
          />
        ))}
      {selectedTab === 'documents' && (
        <SurgeryDocuments
          loading={
            loading || !(isNotEmpty(surgery) && isNotEmpty(review.surgeryInfo))
          }
          review={review}
          rawImagePDF={rawImagePDF}
          patientReportPDF={patientReportPDF}
        />
      )}
    </div>
  );
}
