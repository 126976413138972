import React, { ReactElement, useState, useRef, useEffect } from 'react';
import './KLCustomTextInput.scss';
import { ReactComponent as XCircle } from '../../assets/icons/XCircle.svg';

interface KLCustomTextInputProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onInputChange: (value: string) => void;
  onRemove: () => void;
}

export default function KLCustomTextInput({
  label,
  placeholder,
  value,
  onInputChange,
  onRemove
}: KLCustomTextInputProps): ReactElement {
  const [inputValue, setInputValue] = useState(value || '');
  const [error, setError] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const resizeTextAreaHeight = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onInputChange(e.target.value);
    setInputValue(e.target.value);
    resizeTextAreaHeight();

    if (e.target.value === '') {
      setError(true);
    } else {
      setError(false);
    }
  };

  useEffect(() => {
    resizeTextAreaHeight();
  }, []);

  useEffect(() => {
    if (value?.length === 0) {
      setError(true);
    }

    resizeTextAreaHeight();
  }, [value]);

  return (
    <div className="kcti">
      {label && (
        <div className="kcti__text-container">
          <label className="kcti__label">{label}</label>
        </div>
      )}
      <div className="kcti__input-row">
        <div
          className={`kcti__input-container ${
            error && 'kcti__input-container--error'
          }`}>
          <textarea
            ref={textAreaRef}
            className="kcti__input-text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder}
            rows={1}
          />
        </div>
        <XCircle className="kcti__remove-icon" onClick={onRemove} />
      </div>
      {error && (
        <span className="kcti__error-text">
          Please enter custom procedure description or remove this entry.
        </span>
      )}
    </div>
  );
}
