/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import EnvelopeSimpleIcon from '../../../../assets/icons/EnvelopeSimple.svg';
import HouseIcon from '../../../../assets/icons/House.svg';
import PhoneCallIcon from '../../../../assets/icons/PhoneCall.svg';
import UserIcon from '../../../../assets/icons/user__gray--x-dark.svg';
import {
  EditModal,
  KaliberButton,
  KaliberCard,
  KaliberPhoneField,
  KaliberSelect,
  KaliberTextField,
  OptionalValueDisplay
} from '../../../../components';
import { STATE_OPTIONS } from '../../../../constants';
import { updatePatient } from '../../../../redux/actions/patientActions';
import {
  KLEmergencyContact,
  KLState,
  KLUser,
  Patient
} from '../../../../redux/types';
import '../emergencyContact/EmergencyContact.scss';
import { PatientCardProps } from '../patientProfile/PatientProfilePage';

interface DisplayProps {
  emergencyContact: KLEmergencyContact;
  modalOpen: () => void;
}

function Display({ emergencyContact, modalOpen }: DisplayProps) {
  return (
    <KaliberCard
      title="Parent/Guardian Contact"
      titleAlignment="left"
      editButton={true}
      onClickEdit={modalOpen}>
      <div className="emergency-contact">
        <div className="emergency-contact__info">
          <img src={UserIcon} className="emergency-contact__icon" />
          <div className="emergency-contact__text">{emergencyContact.name}</div>
        </div>
        <div className="emergency-contact__info">
          <img src={HouseIcon} className="patient-contact__icon" />
          <OptionalValueDisplay value={emergencyContact.address}>
            <div className="patient-contact__text">
              {emergencyContact.address}
              <br />
              {emergencyContact.city}, {emergencyContact.state}{' '}
              {emergencyContact.zipcode}
            </div>
          </OptionalValueDisplay>
        </div>
        <div className="emergency-contact__info">
          <img src={PhoneCallIcon} className="emergency-contact__icon" />
          <OptionalValueDisplay value={emergencyContact.phone}>
            <div className="emergency-contact__text">
              {emergencyContact.phone}
            </div>
          </OptionalValueDisplay>
        </div>
        <div className="emergency-contact__info">
          <img src={EnvelopeSimpleIcon} className="emergency-contact__icon" />
          <OptionalValueDisplay value={emergencyContact.email}>
            <div className="emergency-contact__text">
              {emergencyContact.email}
            </div>
          </OptionalValueDisplay>
        </div>
      </div>
    </KaliberCard>
  );
}

interface EditProps extends PatientCardProps {
  editModalVisible: boolean;
  modalClose: (dataChanged?: boolean) => void;
}

function Edit({ patient, editModalVisible, modalClose }: EditProps) {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty }
  } = useForm<any>({
    defaultValues: {
      address: patient.emergencyContact?.address,
      city: patient.emergencyContact?.city,
      state: patient.emergencyContact?.state,
      zipcode: patient.emergencyContact?.zipcode,
      phone: patient.emergencyContact?.phone,
      email: patient.emergencyContact?.email
    }
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    if (user.accessToken && patient.id) {
      updatePatient(user.accessToken, patient.id, {
        emergencyContact: { ...data }
      } as Patient).then(() => {
        setLoading(true);
        modalClose(true);
      });
    }
  };

  return (
    <EditModal
      visible={editModalVisible}
      title="Edit Emergency Contact"
      onClose={() => modalClose()}>
      <form
        className="edit-emergency-contact-form"
        onSubmit={handleSubmit(onSubmit)}>
        <KaliberTextField
          label="Address"
          name="address"
          placeholder=""
          register={register}
          errors={errors}
          errorMessage="Address is required."
        />
        <KaliberTextField
          label="City"
          name="city"
          placeholder=""
          register={register}
          errors={errors}
          errorMessage="City is required."
        />
        <Controller
          name="state"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <KaliberSelect
              label="State"
              placeholder=""
              select={field.value}
              errors={errors[field.name]}
              includeOther={false}
              onChange={e => field.onChange(e)}
              list={Object.keys(STATE_OPTIONS)}
              fullWidth
            />
          )}
        />
        <KaliberTextField
          label="Zipcode"
          name="zipcode"
          placeholder=""
          register={register}
          errors={errors}
          errorMessage="Zipcode is required."
        />
        <KaliberPhoneField
          label="Mobile Phone (recommended)"
          name="phone"
          placeholder={false}
          errors={errors}
          control={control}
          value={patient.phone}
          fullWidth
        />
        <KaliberTextField
          label="Email (recommended)"
          placeholder=""
          name="email"
          register={register}
          errors={errors}
        />
        <div className="edit-modal__buttons">
          <KaliberButton
            type="submit"
            disabled={!isDirty}
            buttonClass="btn--primary"
            loading={loading}>
            Save Changes
          </KaliberButton>
          <KaliberButton
            type="button"
            onClick={() => modalClose()}
            buttonClass="btn--cancel">
            Cancel
          </KaliberButton>
        </div>
      </form>
    </EditModal>
  );
}

export default function EmergencyContact({
  patient,
  setReloadSurgery
}: PatientCardProps): ReactElement {
  const [editModalVisible, setEditModalVisible] = useState(false);

  const handleModalOpen = () => {
    setEditModalVisible(true);
  };

  const handleModalClose = (dataChanged = false) => {
    setEditModalVisible(false);

    if (setReloadSurgery && dataChanged) {
      setReloadSurgery(true);
    }
  };

  if (!patient.emergencyContact) {
    return <></>;
  }

  return (
    <>
      <Display
        emergencyContact={patient.emergencyContact}
        modalOpen={handleModalOpen}
      />
      {editModalVisible && (
        <Edit
          patient={patient}
          editModalVisible={editModalVisible}
          modalClose={handleModalClose}
        />
      )}
    </>
  );
}
