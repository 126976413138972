import LogoutIcon from '@mui/icons-material/Logout';
import { Container, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import kaliberRekapLogo from '../../assets/logos/kaliber-rekap-logo-white-2022-05.png';
import { logoutUser } from '../../redux/actions/userActions';

import './KLNavBar.scss';

export default function KLEmptyNavBar(): ReactElement {
  return (
    <div className="kl-navbar-container">
      <Container maxWidth="xl">
        <Box
          sx={{
            flexGrow: 1,
            alignItems: 'center',
            height: '4.5rem',
            display: 'flex'
          }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid item>
              <Link to="/">
                <div className="logo-container">
                  <img
                    className="kl-logo"
                    src={kaliberRekapLogo}
                    alt="kaliber labs logo"
                  />
                </div>
              </Link>
            </Grid>
            <Grid item>
              <div className="logout-icon">
                <LogoutIcon
                  onClick={() => logoutUser()}
                  style={{ color: 'white' }}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
