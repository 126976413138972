import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Warning from '../../assets/icons/warning-red.svg';
import KaliberLogo from '../../assets/logos/kaliber-rekap-logo-TM-dark.png';
import KaliberButton from '../../components/button/KaliberButton';
import KaliberModal from '../../components/modal/KaliberModal';
import KaliberEmailField from '../../components/textField/KaliberEmailField';
import KaliberPasswordField from '../../components/textField/KaliberPasswordField';
import { loginUser } from '../../redux/actions/userActions';
import { KLState } from '../../redux/types';
import './CredentialForms.scss';

export interface PatientLoginFormData {
  email: string;
  password: string;
}

/**
 * Renders a login form for users to enter their email and password.
 * Handles user input, validates it, and authenticates the user.
 * If authentication is successful, redirects the user to the home page.
 *
 * @returns {ReactElement} The rendered login form.
 */
export default function Login(): ReactElement {
  const history = useHistory();
  const user = useSelector((state: KLState) => state.user);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState('');
  const [authError, setAuthError] = useState('');
  const [showAccountSuspendedModal, setShowAccountSuspendedModal] =
    useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.accessToken) {
      history.push('/');
    }
  }, [user.accessToken]);

  useEffect(() => {
    if (user.error) {
      setAuthError(user.error);
    }
  }, [user.error]);

  const accountSuspendedModal = (
    <div className="suspended-container">
      <KaliberModal
        visible={showAccountSuspendedModal}
        title="Cannot access your account"
        titleIcon={<img src={Warning} alt="" />}
        titleIconAlignment="left"
        titleAlignment="left"
        size="medium"
        closeIcon={true}
        onClose={() => setShowAccountSuspendedModal(false)}>
        <div className="suspended-container__modal">
          <div className="suspended-container__body-text">
            Access to your account has been suspended because the patient
            associated with this account has aged out of being legally
            considered a minor in your state. To reinstate access, please email
            us at
            <span className="email-text"> support@kaliber.ai</span>
          </div>
          <div className="suspended-container__button-container">
            <KaliberButton
              buttonClass="btn--secondary"
              type="button"
              onClick={() => setShowAccountSuspendedModal(false)}>
              Okay
            </KaliberButton>
          </div>
        </div>
      </KaliberModal>
    </div>
  );

  const onSubmit = async (signUpFormData: PatientLoginFormData) => {
    try {
      setLoading(true);
      await loginUser(signUpFormData.email, signUpFormData.password);
      setAuthError('');
      setLoading(false);
      history.push('/');
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === 'UnprocessableEntityError') {
          setAuthError(
            'The email or password you entered do not match our records. Please try again.'
          );
        } else if (
          e.name === 'AuthorizationError' &&
          e.message === "Patient's account has been suspended"
        ) {
          setShowAccountSuspendedModal(true);
        } else {
          setError(e.message);
          setErrorModal(true);
          setAuthError('');
        }
      }
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>();

  return (
    <div>
      <div>
        <div className="credential-container">
          <div
            className="credential-container__title"
            data-testid="welcome-message">
            Welcome to
            <img
              className="credential-container__logo"
              src={KaliberLogo}
              alt="kaliber-logo"
            />
          </div>
          <div className="credential-container__form">
            <div className="credential-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className="credential-form__header"
                  data-testid="credential-header">
                  Login
                </div>
                <div className="credential-form__sub-header"></div>
                {authError && (
                  <div className="credential-form__error">{authError}</div>
                )}
                <div className="credential-form__textbox">
                  <KaliberEmailField
                    register={register}
                    required
                    errors={errors}
                    tabIndex={0}
                    dataTestId="login-email"
                  />
                </div>
                <div className="credential-form__textbox">
                  <KaliberPasswordField
                    label="Password"
                    name="password"
                    register={register}
                    required
                    errors={errors}
                    errorMessage={'Please enter a password.'}
                    dataTestId="login-password"
                  />
                </div>

                <div className="credential-form__forgot-password">
                  <Link to={'/forgot-password'}>
                    <span>Forgot password?</span>
                  </Link>
                </div>
                <div className="credential-form__btn">
                  <KaliberButton
                    type="submit"
                    buttonClass="btn--primary btn--full-width"
                    loading={loading}
                    dataTestId="login-account">
                    Login
                  </KaliberButton>
                </div>
              </form>
            </div>
          </div>
          <div className="msg msg--agreement">
            By logging in, you agree to our{' '}
            <Link to={'/terms-of-use'}>
              <span className="msg--link">Terms of Use</span>
            </Link>{' '}
            and{' '}
            <Link to={'/privacy-policy'}>
              <span className="msg--link">Privacy Policy.</span>
            </Link>{' '}
          </div>
        </div>
      </div>
      <KaliberModal
        title="Login Error"
        titleAlignment="center"
        visible={errorModal}
        closeIcon={true}
        onClose={() => setErrorModal(false)}>
        <div className="modal-text">{error}</div>
      </KaliberModal>
      <div>{accountSuspendedModal}</div>
    </div>
  );
}
