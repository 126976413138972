import { ReactElement, useMemo } from 'react';

import PostOpInstructionsMultiSelectComponent from './components/postOpInstructionsMultiSelect/PostOpInstructionsMultiSelectComponent';
import { KaliberCard, EditProcedures } from '../../../../../../components';
import './SurgeryInfoComponent.scss';

import {
  CptSurgeryProcedure,
  CustomSurgeryProcedure,
  KLCptCode,
  SurgeryInfo
} from '../../../../../../redux/types';

function SurgeryDetails({
  surgeon,
  facility,
  date
}: {
  surgeon: string;
  facility: string;
  date: string;
}): ReactElement {
  return (
    <div className="surgery-details-row">
      <div className="item">
        <div className="item__label" id="fs-mask">
          Surgeon
        </div>
        <div className="item__value" id="fs-mask">
          {surgeon}
        </div>
      </div>
      <div className="item">
        <div className="item__label" id="fs-mask">
          Surgery Center
        </div>
        <div className="item__value" id="fs-mask">
          {facility}
        </div>
      </div>
      <div className="item">
        <div className="item__label" id="fs-mask">
          Date of Surgery
        </div>
        <div className="item__value" id="fs-mask">
          {date}
        </div>
      </div>
    </div>
  );
}

interface SurgeryInfoComponentProps {
  cptCodes: KLCptCode[];
  surgeryInfo: SurgeryInfo;
  selectedPostOpInstructionIds: number[];
  cptProcedures: CptSurgeryProcedure[];
  customProcedures: CustomSurgeryProcedure[];
  onChangeCptProcedures: (newCptProcedures: CptSurgeryProcedure[]) => void;
  onRemoveCptProcedure: (removedCptProcedure: CptSurgeryProcedure) => void;
  onChangeCustomProcedures: (value: CustomSurgeryProcedure[]) => void;
  onRemoveCustomProcedure: (
    removedCptProcedure: CustomSurgeryProcedure
  ) => void;
  onChangePostOp: (value: number[]) => void;
}

export default function SurgeryInfoComponent({
  cptCodes,
  surgeryInfo,
  selectedPostOpInstructionIds,
  cptProcedures,
  customProcedures,
  onChangeCptProcedures,
  onRemoveCptProcedure,
  onChangeCustomProcedures,
  onRemoveCustomProcedure,
  onChangePostOp
}: SurgeryInfoComponentProps): ReactElement {
  const surgeryRegions = useMemo(
    () => cptProcedures.map(cptProcedure => cptProcedure.region),
    [cptProcedures]
  );

  return (
    <KaliberCard titleAlignment="left" title="Surgery Details">
      <div className="review-surgery-details-container">
        <SurgeryDetails
          surgeon={surgeryInfo.surgeonName}
          facility={surgeryInfo.facilityName}
          date={surgeryInfo.surgeryDate}
        />

        <EditProcedures
          cptCodes={cptCodes}
          cptProcedures={cptProcedures}
          customProcedures={customProcedures}
          onChangeCptProcedures={onChangeCptProcedures}
          onRemoveCptProcedure={onRemoveCptProcedure}
          onChangeCustomProcedures={onChangeCustomProcedures}
          onRemoveCustomProcedure={onRemoveCustomProcedure}
          procedureType="performed"
        />

        <PostOpInstructionsMultiSelectComponent
          surgeryRegions={surgeryRegions}
          selectedPostOpInstructionIds={selectedPostOpInstructionIds}
          onChangePostOp={onChangePostOp}
        />
      </div>
    </KaliberCard>
  );
}
