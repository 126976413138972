import { ReactElement } from 'react';

import CheckCircleOutlineWhite from '../../assets/icons/check-circle-outline-white.svg';
import Circle from '../../assets/icons/Circle.svg';
import './SelectChip.scss';

type SelectChipProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  selected?: boolean;
  onClick?: () => void;
};

export default function SelectChip({
  children,
  selected = false,
  onClick
}: SelectChipProps): ReactElement {
  const icon = selected ? (
    <img src={CheckCircleOutlineWhite} className="select-chip__icon" />
  ) : (
    <img src={Circle} className="select-chip__icon" />
  );

  return (
    <div
      className={`select-chip select-chip--${
        selected ? 'checked' : 'unchecked'
      }`}
      onClick={onClick}>
      <div className="select-chip__content">
        {icon}
        {children}
      </div>
    </div>
  );
}
