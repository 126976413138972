import { ThemeOptions } from '@material-ui/core/styles/createTheme';
export const themeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#008585'
    },
    secondary: {
      main: '#44546a'
    },
    background: {
      default: '#E5E5E5',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#282828'
    },
    error: {
      main: '#EB5757'
    },
    warning: {
      main: '#F2C94C'
    },
    success: {
      main: '#219653'
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      'Source Sans Pro',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont'
    ].join(','),
    h1: {
      fontSize: 96,
      fontWeight: 400
    },
    h2: {
      fontSize: 60,
      fontWeight: 400
    },
    h3: {
      fontSize: 48,
      fontWeight: 400
    },
    h4: {
      fontSize: 34,
      fontWeight: 400
    },
    h5: {
      fontSize: 24,
      fontWeight: 400
    },
    h6: {
      fontSize: 20,
      fontWeight: 400
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 16,
      fontWeight: 700
    },
    overline: {
      fontSize: 10,
      fontWeight: 400
    },
    caption: {
      fontSize: 12,
      fontWeight: 400
    },
    button: {
      fontSize: 18,
      fontWeight: 500
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    },
    MuiIconButton: {
      root: {
        width: '20px'
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit'
        }
      },
      root: {
        fieldset: {
          display: 'none'
        }
      }
    },
    MuiInputBase: {
      root: {
        color: 'inherit'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '17.5px 14px'
      },
      adornedEnd: {
        paddingRight: 0
      }
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 1
      }
    }
  }
};
