/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { ReactElement, useState, useCallback, useEffect } from 'react';
import KLTextField from '../textField/KLTextField';
import moment, { Moment } from 'moment';
import './KLDateOfBirthPicker.scss';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-icon.svg';

export interface KaliberDateOfBirthPickerProps {
  value: Date | null;
  name: string;
  label?: string;
  className?: string;
  editable: boolean;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  dataTestId?: string;
  errors?: any;
  errorMessage?: string;
  size?: 'tall' | 'short';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (val: any) => void;
}
const ERROR_INVALID_DATE_MESSAGE = 'Please input a valid date';

export default function KaliberDateOfBirthPicker(
  props: KaliberDateOfBirthPickerProps
): ReactElement {
  const { onChange, label, className, name, errors, errorMessage } = props;
  const error = errors[name];
  const isError = Boolean(error);

  const [date, setDate] = useState<Moment | null>(
    props.value ? moment(props.value) : null
  );
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setDate(props.value ? moment(props.value) : null);
  }, [props.value]);

  const handleDateChange = useCallback(
    (newValue: Date | null) => {
      if (
        props.name !== 'dob' &&
        newValue?.getFullYear().toString().length === 2
      ) {
        const today = new Date();
        const yearFrontTwoDigits = today
          .getFullYear()
          .toString()
          .substring(0, 2);
        newValue.setFullYear(
          Number(`${yearFrontTwoDigits}${newValue.getFullYear()}`)
        );
      }
      if (newValue) {
        setDate(moment(newValue));
      } else {
        setDate(null);
      }
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange, props.name]
  );

  const hasRequiredError = () => {
    return error?.type === 'required';
  };

  const hasValidationError = () => {
    return error?.type === 'validate';
  };

  const Error = () => {
    if (hasRequiredError()) {
      return <div className="kl-dob-input-error">{errorMessage}</div>;
    } else if (hasValidationError()) {
      return (
        <div className="kl-dob-input-error">{ERROR_INVALID_DATE_MESSAGE}</div>
      );
    } else {
      return <div className="kl-dob-input-error">{error.message}</div>;
    }
  };

  return (
    <div className={`kl-datepicker ${className}`}>
      {dateComponent(
        props,
        'kl-dob-textfield',
        handleDateChange,
        date,
        setDate,
        focused,
        setFocused,
        label,
        isError
      )}
      {isError && <Error />}
    </div>
  );
}

function dateComponent(
  props: KaliberDateOfBirthPickerProps,
  textFieldClass: string,
  changeHandler: (newValue: Date | null) => void,
  date: Moment | null,
  setDate: (date: Moment | null) => void,
  focused: boolean,
  setFocused: (focused: boolean) => void,
  label: string | undefined,
  isError: boolean
): ReactElement {
  if (props.editable) {
    return editableComponent(
      props,
      textFieldClass,
      changeHandler,
      date,
      setDate,
      focused,
      setFocused,
      label,
      isError
    );
  } else {
    return uneditableComponent(props.value, props.dataTestId);
  }
}

function editableComponent(
  props: KaliberDateOfBirthPickerProps,
  textFieldClass: string,
  onChange: (newValue: Date | null) => void,
  date: Moment | null,
  setDate: (date: Moment | null) => void,
  focused: boolean,
  setFocused: (focused: boolean) => void,
  label: string | undefined,
  isError: boolean
): ReactElement {
  function range(day: Moment) {
    if (props.maxDate && props.minDate) {
      return !day.isBetween(moment(props.minDate), moment(props.maxDate));
    } else if (props.maxDate && props.minDate === undefined) {
      return !day.isSameOrBefore(props.maxDate);
    } else if (props.maxDate === undefined && props.minDate) {
      return !day.isSameOrAfter(props.minDate);
    } else {
      return false;
    }
  }
  return (
    <div>
      <div
        className={`${isError && 'kl-dob-error-border'} kl-dob-${
          props.size ? props.size : 'tall'
        }`}>
        <div className="kl-date-picker-editable-title">
          <div>{label}</div>
        </div>
        <SingleDatePicker
          id="date_input"
          date={date}
          focused={focused}
          placeholder="MM/DD/YYYY"
          onDateChange={date => {
            date ? onChange(date.toDate()) : onChange(null);
            setDate(date);
          }}
          keepFocusOnInput={true}
          orientation="horizontal"
          anchorDirection="left"
          numberOfMonths={1}
          onFocusChange={({ focused }) => setFocused(focused)}
          isOutsideRange={(day: Moment) => range(day)}
          showDefaultInputIcon
          inputIconPosition="after"
          customInputIcon={<CalendarIcon />}
          hideKeyboardShortcutsPanel
        />
      </div>
    </div>
  );
}

function uneditableComponent(
  value: Date | null,
  dataTestId?: string
): ReactElement {
  return (
    <KLTextField
      id="fs-mask"
      label="Date of Birth*"
      className="kl-date-picker-uneditable"
      editable={false}
      dataTestId={dataTestId ? dataTestId : 'input-date-test'}
      value={
        value !== null && isValidDate(value)
          ? moment(value).format('MM/DD/YYYY')
          : ''
      }
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.getTime());
}
