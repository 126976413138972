/* eslint-disable @typescript-eslint/no-explicit-any */
import * as FullStory from '@fullstory/browser';

export function setFullstoryPage(pageName: string): void {
  FullStory.setVars('page', {
    pageName: pageName
  });
}

export function setFullstoryEvent(
  eventName: string,
  eventProperties: any = {}
): void {
  FullStory.event(eventName, eventProperties);
}

export function setFullstoryClick(clickName: string): void {
  FullStory.event('click', {
    click: clickName
  });
}
