import { ReactElement, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReportBanner from '../../../assets/rekap-report-header.jpg';
import KLAlert from '../../../components/alert/KLAlert';
import {
  setFullstoryEvent,
  setFullstoryPage
} from '../../../redux/actions/fullstoryActions';
import { KLState, KLUser, KLReview } from '../../../redux/types';
import PatientReportStepper from '../patientReportStepper/PatientReportStepper';
import './PatientReportComplete.scss';
import ReportHowTo from './reportHowTo/ReportHowTo';
import ReportOverview from './reportOverview/ReportOverview';
import ReportSurgeonBio from './reportSurgeonBio/ReportSurgeonBio';
import ReportSurgeryImages from './reportSurgeryImages/ReportSurgeryImages';
import ReportSurgeryVideos from './reportSurgeryVideo/ReportSurgeryVideo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { trackVisits } from '../../../redux/actions/surgeriesActions';

interface PatientReportCompleteProps {
  review: KLReview;
  rawImagePDF?: string;
  patientReportPDF?: string;
  patientReportPatientViewHasLoaded?: boolean;
  setPatientReportPatientViewHasLoaded?: (value: boolean) => void;
  onResendReport?: () => void;
}
export default function PatientReportComplete({
  review,
  rawImagePDF,
  patientReportPDF,
  patientReportPatientViewHasLoaded,
  setPatientReportPatientViewHasLoaded,
  onResendReport
}: PatientReportCompleteProps): ReactElement {
  const { showReportSurgeryVideo } = useFlags();

  useEffect(() => {
    setFullstoryPage('Patient Report Complete Page');
  }, []);

  const bannerImage = useMemo(
    () => review.surgeonBio.bannerUrl ?? ReportBanner,
    [review]
  );

  const overview = useRef<HTMLDivElement>(null);
  const howto = useRef<HTMLDivElement>(null);
  const images = useRef<HTMLDivElement>(null);
  const videos = useRef<HTMLDivElement>(null);
  const reportSurgeonBio = useRef<HTMLDivElement>(null);
  const [surveyAlert, setSurveyAlert] = useState(false);
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
    document.addEventListener('scroll', surveyReportTracking);
  }, []);

  // Track report visit by a patient
  useEffect(() => {
    if (
      user.accessToken &&
      review.surgeryInfo.surgeryId &&
      user.role === 'Patient'
    ) {
      trackVisits(review.surgeryInfo.surgeryId, user.accessToken);
    }
  }, []);

  const surveyReportTracking = () => {
    const element = document.getElementById('report-videos');
    if (
      element &&
      element.getBoundingClientRect().bottom <= window.innerHeight
    ) {
      document.removeEventListener('scroll', surveyReportTracking);
      if (user.role === 'Patient' || user.role === 'Kaliber Admin') {
        setSurveyAlert(true);
        setFullstoryEvent('Viewed Survey Alert');
      }
    }
  };

  function scrollTo(index: number): void {
    switch (index) {
      case 0:
        overview.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
        break;
      case 1:
        howto.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        break;
      case 2:
        images.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        break;
      case 3:
        videos.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        break;
      case 4:
        reportSurgeonBio.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
        break;
    }
  }
  const surveyLabel =
    'On a scale of 0 to 10, how likely are you to recommend the Rekap report to a friend or colleague?';

  return (
    <div className="report-container">
      <div className="side-stepper">
        <PatientReportStepper
          scrollTo={scrollTo}
          rawImagePDF={rawImagePDF}
          patientReportPDF={patientReportPDF}
          patientReportPatientViewHasLoaded={patientReportPatientViewHasLoaded}
          setPatientReportPatientViewHasLoaded={
            setPatientReportPatientViewHasLoaded
          }
          onResendReport={onResendReport}
        />
      </div>
      <div className="report">
        {surveyAlert}
        <KLAlert visible={surveyAlert} label={surveyLabel}>
          <div className="report-survey" data-paperform-id="jkyss5zu"></div>
        </KLAlert>
        <img className="overview-image" src={bannerImage} />
        <div className="report-info-container">
          <div ref={overview} id="report-overview" data-testid="overview">
            <ReportOverview surgery={review.surgeryInfo} />
          </div>
          <div className="report__divider" />
          <div ref={howto} id="report-howto" data-testid="how-to">
            <ReportHowTo />
          </div>
          <div
            ref={images}
            className="images-cont"
            id="report-images"
            data-testid="images">
            <div className="divider" />
            <ReportSurgeryImages />
          </div>
          <div className="divider" />
          {showReportSurgeryVideo && (
            <>
              <div
                ref={videos}
                className="videos-cont"
                id="report-videos"
                data-testid="videos">
                <ReportSurgeryVideos />
              </div>
              <div className="divider" />
            </>
          )}
          <div
            ref={reportSurgeonBio}
            className="surgeon-cont"
            id="report-surgeonbio"
            data-testid="surgeon-bio">
            <ReportSurgeonBio />
          </div>
        </div>
        <div className="report-survey" data-paperform-id="jkyss5zu"></div>
        <div
          className="report-disclaimer center"
          data-testid="report-disclaimer">
          Legal Disclaimer: This Report has been approved for release by{' '}
          {review.surgeryInfo.surgeonName}. This report and any accompanying
          images or video are not intended for any medical use including
          diagnosis or interpretation of a surgical or medical outcome. No
          representations are made with regard to the labeling shown in the
          image(s).
        </div>
      </div>
    </div>
  );
}
