import { ReactElement, useEffect } from 'react';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import './Settings.scss';
import CustomNotes from './customNotes/CustomNotes';
import NotificationsSettings from './notificationsSettings/NotificationsSettings';
import PostOpInstructions from './postOpInstructions/PostOpInstructions';

export default function ProviderSettingsPage(): ReactElement {
  useEffect(() => {
    setFullstoryPage('Provider Settings Page');
  }, []);

  return (
    <div>
      <div className="page-container settings-container">
        <NotificationsSettings singleComponent={false} />
        <CustomNotes />
        <PostOpInstructions />
      </div>
    </div>
  );
}
