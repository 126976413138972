import { ReactElement } from 'react';
import Plus from '../../../../assets/icons/Plus.svg';
import './Fab.scss';

interface FabProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (val: any) => void;
}
export default function Fab({ onClick }: FabProps): ReactElement {
  return (
    <button className="fab" type="button" onClick={onClick}>
      <img src={Plus} />
    </button>
  );
}
