import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { KLState, KLUser } from '../../redux/types';
import OtherSettingsPage from './OtherUsersSettingsPage';
import PatientSettingsPage from './PatientSettingsPage';
import ProviderSettingsPage from './ProviderSettingsPage';

export default function SettingsPage(): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);

  return user.role === 'Surgeon' ? (
    <ProviderSettingsPage />
  ) : user.role === 'Patient' ? (
    <PatientSettingsPage />
  ) : (
    <OtherSettingsPage />
  );
}
