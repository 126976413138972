import { ReactElement, useState, useMemo, useEffect, ChangeEvent } from 'react';
import _ from 'lodash';

import Thumbnail from './components/thumbnail/ThumbnailComponent';
import {
  KaliberButton,
  ToggleGroup,
  ToggleButton,
  KaliberSelect,
  KaliberTextField,
  SegmentedControl,
  EditModal
} from '../../../../../../components';
import EvaluationIcon from '../../../../../../assets/icons/evaluation-icon.svg';
import TreatmentIcon from '../../../../../../assets/icons/treatment-icon.svg';
import PostTreatmentIcon from '../../../../../../assets/icons/post-treatment-icon.svg';
import './ReviewImageComponent.scss';

import {
  AnnotatedData,
  CptSurgeryProcedure,
  QuickNotesList
} from '../../../../../../redux/types';
import { CPTProcedureCategorizer } from '../../../../../../utils/surgeryProcedures';
import { updateNotes } from '../../../../../../utils/annotations';

function getQuickResponses(
  quickNotes: QuickNotesList,
  region: string,
  surgeryStage: string
): string[] {
  if (region === 'shoulder') {
    switch (surgeryStage) {
      case 'evaluation':
        return quickNotes.shoulder.evaluationResponses;
      case 'treatment':
        return quickNotes.shoulder.treatmentResponses;
      case 'post-treatment':
        return quickNotes.shoulder.postTreatmentResponses;
      default:
        return [];
    }
  } else if (region === 'knee') {
    switch (surgeryStage) {
      case 'evaluation':
        return quickNotes.knee.evaluationResponses;
      case 'treatment':
        return quickNotes.knee.treatmentResponses;
      case 'post-treatment':
        return quickNotes.knee.postTreatmentResponses;
      default:
        return [];
    }
  } else {
    return []; // Mixed or other non-knee/shoulder anatomies
  }
}

type ReviewImageComponentProps = {
  quickNotes: QuickNotesList;
  performedCptProcedures: CptSurgeryProcedure[];
  annotation: AnnotatedData;
  onAnnotationChanged: (annotation: AnnotatedData) => void;
};

export default function ReviewImageComponent({
  quickNotes,
  performedCptProcedures,
  annotation,
  onAnnotationChanged
}: ReviewImageComponentProps): ReactElement {
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [reviewAnnotation, setReviewAnnotation] = useState(annotation);
  const [quickNote, setQuickNote] = useState('');
  const [detailedNotes, setDetailedNotes] = useState('');
  const [saveChanges, setSaveChanges] = useState(false);

  const notChanged = _.isEqual(reviewAnnotation, annotation);

  const surgeryRegion = useMemo(() => {
    const categorizer = new CPTProcedureCategorizer(performedCptProcedures);

    return categorizer.getSurgeryRegion();
  }, [performedCptProcedures]);

  const quickResponseOptions = useMemo(() => {
    return getQuickResponses(
      quickNotes,
      surgeryRegion,
      reviewAnnotation.mediaLabels.surgeryStage
    );
  }, [quickNotes, surgeryRegion, reviewAnnotation.mediaLabels.surgeryStage]);

  const phaseIcon = useMemo(() => {
    switch (reviewAnnotation.mediaLabels.surgeryStage) {
      case 'evaluation':
        return EvaluationIcon;
      case 'treatment':
        return TreatmentIcon;
      case 'post-treatment':
        return PostTreatmentIcon;
      default:
        return '';
    }
  }, [reviewAnnotation.mediaLabels.surgeryStage]);

  useEffect(() => {
    if (annotation.mediaLabels) {
      const [savedQuickNote, savedDetailedNotes] =
        annotation.mediaLabels.surgeryNotes.split('\n');
      if (savedQuickNote) {
        setQuickNote(savedQuickNote);
      }

      if (savedDetailedNotes) {
        setDetailedNotes(savedDetailedNotes);
      }
    }

    setReviewAnnotation(annotation);
  }, [annotation]);

  const selectImageCategory = (surgeryStage: string) => {
    setReviewAnnotation({
      ...reviewAnnotation,
      mediaLabels: {
        ...reviewAnnotation.mediaLabels,
        surgeryStage: surgeryStage
      }
    });
  };

  const handleLabelChange = (newLabels: string[]) => {
    setReviewAnnotation({
      ...reviewAnnotation,
      mediaLabels: {
        ...reviewAnnotation.mediaLabels,
        labels: newLabels
      }
    });
  };

  const handleQuickNoteChange = (newQuickNote: string) => {
    setQuickNote(newQuickNote);
    setReviewAnnotation(
      updateNotes(reviewAnnotation, newQuickNote, detailedNotes)
    );
  };

  const handleDetailedNotesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newDetailedNote = e.target.value;
    setDetailedNotes(newDetailedNote);
    setReviewAnnotation(
      updateNotes(reviewAnnotation, quickNote, newDetailedNote)
    );
  };

  const saveReview = (annotationData: AnnotatedData) => {
    setSaveChanges(true);
    onAnnotationChanged(annotationData);
    setReviewModalVisible(false);
    setSaveChanges(false);
  };

  const cancelReview = () => {
    setReviewAnnotation(annotation);
    const [savedQuickNote, savedDetailedNotes] =
      annotation.mediaLabels.surgeryNotes.split('\n');
    setQuickNote(savedQuickNote);
    setDetailedNotes(savedDetailedNotes ?? '');

    setReviewModalVisible(false);
  };

  return (
    <>
      <Thumbnail
        quickNote={quickNote}
        detailedNote={detailedNotes}
        phaseIcon={phaseIcon}
        imageUrl={annotation.mediaUrl ?? ''}
        included={annotation.include}
        annotation={annotation}
        quickNotesForRegion={quickResponseOptions}
        onClick={() => setReviewModalVisible(true)}
        onQuickNoteChange={(newQuickNote: string) => {
          handleQuickNoteChange(newQuickNote);
          onAnnotationChanged(
            updateNotes(reviewAnnotation, newQuickNote, detailedNotes)
          );
        }}
      />
      <EditModal
        title={annotation.filename}
        visible={reviewModalVisible}
        onClose={cancelReview}>
        <div className="review-container">
          <div className="image-content">
            <img className="image-content__image" src={annotation.mediaUrl} />
            <label className="image-content__checkbox">
              <input
                className="image-content__checkbox__input"
                type="checkbox"
                checked={reviewAnnotation.include}
                onChange={() =>
                  setReviewAnnotation({
                    ...reviewAnnotation,
                    include: !reviewAnnotation.include
                  })
                }
              />
              Include image in report
            </label>
          </div>
          <div className="image-category">
            <span className="image-category__label">Image Category</span>
            <div className="image-category__selection">
              <SegmentedControl
                name="surgeryStage"
                segments={['evaluation', 'treatment', 'post-treatment']}
                selected={reviewAnnotation.mediaLabels.surgeryStage}
                onSegmentChange={selectImageCategory}
              />
            </div>
          </div>
          <div className="show-labels">
            <span className="show-labels__label">Show Labels</span>
            <div className="show-labels__anatomy-labels">
              <ToggleGroup
                id="fs-exclude"
                value={reviewAnnotation.mediaLabels.labels}
                onChange={handleLabelChange}>
                {reviewAnnotation.originalLabels?.labels?.map(label => (
                  <ToggleButton
                    className="label-button"
                    key={label}
                    value={label}>
                    {label}
                  </ToggleButton>
                ))}
              </ToggleGroup>
            </div>
          </div>
          <div className="notes">
            {surgeryRegion &&
              (surgeryRegion.toLowerCase() === 'shoulder' ||
                surgeryRegion.toLowerCase() === 'knee') && (
                <>
                  <span className="notes__label">Notes</span>
                  <KaliberSelect
                    label="Select quick note"
                    list={quickResponseOptions}
                    includeOther={false}
                    select={quickNote}
                    onChange={handleQuickNoteChange}
                  />
                </>
              )}
            <KaliberTextField
              id="additional-notes"
              label="Additional notes"
              name="additional-notes"
              value={detailedNotes}
              onChange={handleDetailedNotesChange}
            />
          </div>
          <div className="action-buttons">
            <KaliberButton
              type="button"
              buttonClass="btn--primary action-buttons__save"
              disabled={notChanged}
              loading={saveChanges}
              onClick={() => {
                saveReview(reviewAnnotation);
              }}>
              Save Changes
            </KaliberButton>
            <KaliberButton
              type="button"
              buttonClass="btn--cancel action-buttons__cancel"
              onClick={cancelReview}>
              Cancel
            </KaliberButton>
          </div>
        </div>
      </EditModal>
    </>
  );
}
