import React, { ReactElement, useEffect, useState } from 'react';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import './HelpPage.scss';

export default function HelpPage(): ReactElement {
  const [loading, setLoading] = useState(false);

  setFullstoryPage('Help Page');
  useEffect(() => {
    setLoading(true);
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
    setLoading(false);
  }, []);
  return (
    <div>
      {loading ? (
        <>
          <div className="help-form__skeleton help-form__skeleton--header" />
          <div className="help-form__skeleton help-form__skeleton--info" />
        </>
      ) : (
        <div className="help-form" id="fs-exclude" data-testid="help-div">
          <div data-paperform-id="gqt0a2dp" data-testid="help-form" />
        </div>
      )}
    </div>
  );
}
