import moment from 'moment';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  KaliberButton,
  KaliberCard,
  KaliberDateOfBirthPicker,
  KaliberSelect,
  KLCptProcedure,
  EditModal,
  EditProcedures
} from '../../../../components';
import {
  Surgery,
  CptSurgeryProcedure,
  CustomSurgeryProcedure,
  KLCptCode,
  KLState,
  KLUser,
  KLFacility,
  KLSurgeon
} from '../../../../redux/types';
import { capitalizeFirstLetter } from '../../../../utils/textFormat';
import './SurgeryDetails.scss';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCptCodes } from '../../../../redux/actions/cptCodesActions';
import { getFacilitiesBySurgeon } from '../../../../redux/actions/surgeonsActions';

import {
  createProcedures,
  updateProcedures,
  deleteProcedures
} from '../../../../redux/actions/proceduresActions';
import { isNullOrUndefined } from '../../../../utils/validations';
import { updateSurgery } from '../../../../redux/actions/surgeriesActions';
import { surgeryToKLSurgery } from '../../../../utils/surgeryData';
import { getSurgeons } from '../../../../redux/actions/surgeonsActions';

interface DisplayProps {
  surgery: Surgery;
  modalOpen: () => void;
  user: KLUser;
}

function Display({ surgery, user, modalOpen }: DisplayProps): ReactElement {
  const groupCPTByAnatomyAndSide = (cptCodesSurgery: CptSurgeryProcedure[]) => {
    return cptCodesSurgery.reduce((groupedCodes, cptCodeSurgery) => {
      const key = `${cptCodeSurgery.side}_${cptCodeSurgery.region}`;
      const item = groupedCodes.get(key) || [];
      item.push(cptCodeSurgery.cptCode);
      return groupedCodes.set(key, item);
    }, new Map());
  };

  const cptCodeGroupDivider = (idx: number): ReactElement => {
    return idx > 0 ? <div className="planned-procedures__divider" /> : <></>;
  };

  const cptCodeGroupHeader = (group: string): ReactElement => {
    const [side, anatomy] = group.split('_');
    const sideLabel =
      side && side !== 'null' ? `${capitalizeFirstLetter(side)} ` : '';

    return (
      <div className="planned-procedures__label">
        {`${sideLabel}${capitalizeFirstLetter(anatomy)}`}
      </div>
    );
  };

  const groupedCodes = groupCPTByAnatomyAndSide(
    surgery.plannedProcedures.cptProcedures ?? []
  );

  const extractOtherAnatomyProcedures = (
    groupedCptCodes: Map<string, KLCptCode[]>
  ): { key: string; value: KLCptCode[] }[] => {
    const otherAnatomyProcedures: { key: string; value: KLCptCode[] }[] = [];

    groupedCptCodes.forEach((procedures, key) => {
      const filteredProcedures = procedures.filter(
        proc => proc.anatomy === 'other'
      );

      if (filteredProcedures.length > 0) {
        otherAnatomyProcedures.push({ key, value: filteredProcedures });
      }
    });

    return otherAnatomyProcedures;
  };

  const otherProcedures = extractOtherAnatomyProcedures(groupedCodes);

  const extractNonOtherAnatomyProcedures = (
    groupedCptCodes: Map<string, KLCptCode[]>
  ): { key: string; value: KLCptCode[] }[] => {
    const nonOtherAnatomyProcedures: { key: string; value: KLCptCode[] }[] = [];

    groupedCptCodes.forEach((procedures, key) => {
      const filteredProcedures = procedures.filter(
        proc => proc.anatomy !== 'other'
      );

      if (filteredProcedures.length > 0) {
        nonOtherAnatomyProcedures.push({ key, value: filteredProcedures });
      }
    });

    return nonOtherAnatomyProcedures;
  };

  const nonOtherProcedures = extractNonOtherAnatomyProcedures(groupedCodes);

  return (
    <KaliberCard
      title="Surgery Details"
      titleAlignment="left"
      editButton={user.role === 'Surgeon' || user.role === 'Kaliber Admin'}
      onClickEdit={modalOpen}>
      <div className="surgery-details">
        <div className="surgery-details__info">
          <div className="surgery-details__info-container">
            <div className="surgery-details__label">Surgeon</div>
            <span className="surgery-details__text">
              {surgery.surgeon.surgeonName}
            </span>
          </div>
          <div className="surgery-details__info-container">
            <div className="surgery-details__label">Surgery Center</div>
            <span className="surgery-details__text">
              {surgery.facility.facilityName}
            </span>
          </div>
          <div className="surgery-details__info-container">
            <div className="surgery-details__label">Date of Surgery</div>
            <span className="surgery-details__text">
              {moment(surgery.surgeryDate).format('MM/DD/YYYY')}
            </span>
          </div>
        </div>
        <div>
          <div className="surgery-details__label">Planned Procedures</div>
          <div className="planned-procedures">
            {nonOtherProcedures.map(({ key, value }, idx) => (
              <div key={idx}>
                {cptCodeGroupDivider(idx)}
                {cptCodeGroupHeader(key)}
                {value.map((cptCode: KLCptCode) => (
                  <KLCptProcedure
                    key={cptCode.code}
                    cptCode={cptCode.code}
                    description={cptCode.description}
                    commonName={cptCode.commonName}
                  />
                ))}
              </div>
            ))}

            {surgery.plannedProcedures.customProcedures &&
              surgery.plannedProcedures.customProcedures?.length > 0 && (
                <>
                  {cptCodeGroupDivider(1)}
                  {cptCodeGroupHeader('Other')}
                  {otherProcedures.map(({ value }, idx) => (
                    <div key={idx}>
                      {cptCodeGroupDivider(idx)}
                      {value.map((cptCode: KLCptCode) => (
                        <KLCptProcedure
                          key={cptCode.code}
                          cptCode={cptCode.code}
                          description={cptCode.description}
                          commonName={cptCode.commonName}
                        />
                      ))}
                    </div>
                  ))}
                  <div className="planned-procedures__custom-text">
                    {surgery.plannedProcedures.customProcedures.map(
                      (customProcedure, idx) => (
                        <span key={idx}>
                          {customProcedure.customProcedure.procedure}
                        </span>
                      )
                    )}
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </KaliberCard>
  );
}

interface EditProps {
  surgery: Surgery;
  editModalVisible: boolean;
  surgeons: KLSurgeon[];
  facilities: KLFacility[];
  cptCodes: KLCptCode[];
  modalClose: (dataChanged?: boolean) => void;
  onSurgeonSelect: (surgeonName: string) => void;
}

function Edit({
  surgery,
  editModalVisible,
  surgeons,
  facilities,
  cptCodes,
  modalClose,
  onSurgeonSelect
}: EditProps): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const [loading, setLoading] = useState(false);
  const [cptSurgeryProcedures, setCptSurgeryProcedures] = useState<
    CptSurgeryProcedure[]
  >(surgery.plannedProcedures.cptProcedures);
  const [cptSurgeryProceduresToDelete, setCptSurgeryProceduresToDelete] =
    useState<CptSurgeryProcedure[]>([]);
  const [customSurgeryProcedures, setCustomSurgeryProcedures] = useState<
    CustomSurgeryProcedure[]
  >(surgery.plannedProcedures.customProcedures);
  const [customSurgeryProceduresToDelete, setCustomSurgeryProceduresToDelete] =
    useState<CustomSurgeryProcedure[]>([]);

  const [isError, setIsError] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>({
    defaultValues: {
      surgeryDate: moment(surgery.surgeryDate, 'YYYY-MM-DD'),
      surgeonName: surgery.surgeon.surgeonName,
      facilityName: surgery.facility.facilityName,
      plannedProcedures: surgery.plannedProcedures
    }
  });

  const surgeonSelected = watch('surgeonName');

  useEffect(() => {
    if (user.accessToken) {
      getCptCodes(user.accessToken);
    }
  }, [user.accessToken]);

  useEffect(() => {
    if (surgeonSelected) {
      onSurgeonSelect(surgeonSelected);
    }
  }, [surgeonSelected, onSurgeonSelect]);

  useEffect(() => {
    reset({
      surgeryDate: moment(surgery.surgeryDate, 'YYYY-MM-DD'),
      surgeonName: surgery.surgeon.surgeonName,
      facilityName: surgery.facility.facilityName,
      plannedProcedures: surgery.plannedProcedures
    });
  }, [surgery]);

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    setLoading(true);
    if (user.accessToken) {
      const surgeryDate = moment(data.surgeryDate).format('YYYY-MM-DD');
      const facilityId =
        facilities.filter(e => e.facilityName === data.facilityName)[0]
          .facilityId ?? 0;
      const surgeonId = surgeons.filter(
        e => e.surgeonName === data.surgeonName
      )[0].surgeonId;

      const updatedSurgeryData = surgeryToKLSurgery({
        ...surgery,
        surgeryDate: surgeryDate,
        surgeon: {
          ...surgery.surgeon,
          surgeonId: surgeonId
        },
        facility: {
          ...surgery.facility,
          facilityId: facilityId
        }
      });

      const [cptProceduresToCreate, cptProceduresToUpdate] =
        cptSurgeryProcedures.reduce<
          [CptSurgeryProcedure[], CptSurgeryProcedure[]]
        >(
          (acc, cptSurgeryProcedure) => {
            if (isNullOrUndefined(cptSurgeryProcedure.id)) {
              acc.at(0)?.push(cptSurgeryProcedure);
            } else {
              acc.at(1)?.push(cptSurgeryProcedure);
            }

            return acc;
          },
          [[], []]
        );

      const [customProceduresToCreate, customProceduresToUpdate] =
        customSurgeryProcedures.reduce<
          [CustomSurgeryProcedure[], CustomSurgeryProcedure[]]
        >(
          (acc, customSurgeryProcedure) => {
            if (isNullOrUndefined(customSurgeryProcedure.id)) {
              acc.at(0)?.push(customSurgeryProcedure);
            } else {
              acc.at(1)?.push(customSurgeryProcedure);
            }

            return acc;
          },
          [[], []]
        );

      Promise.all([
        updateSurgery(user.accessToken, surgery.surgeryId, updatedSurgeryData),
        deleteProcedures(
          user.accessToken,
          surgery.surgeryId,
          cptSurgeryProceduresToDelete,
          customSurgeryProceduresToDelete
        ),
        createProcedures(
          user.accessToken,
          surgery.surgeryId,
          cptProceduresToCreate,
          customProceduresToCreate
        ),
        updateProcedures(
          user.accessToken,
          surgery.surgeryId,
          cptProceduresToUpdate,
          customProceduresToUpdate
        )
      ]).then(() => {
        setCptSurgeryProceduresToDelete([]);
        setCustomSurgeryProceduresToDelete([]);
        setLoading(false);
        modalClose(true);
      });
    }
  };

  const onModalClose = () => {
    setCptSurgeryProcedures(surgery.plannedProcedures.cptProcedures);
    setCustomSurgeryProcedures(surgery.plannedProcedures.customProcedures);
    reset({
      surgeryDate: moment(surgery.surgeryDate, 'YYYY-MM-DD'),
      surgeonName: surgery.surgeon.surgeonName,
      facilityName: surgery.facility.facilityName,
      plannedProcedures: surgery.plannedProcedures
    });

    modalClose();
  };

  const checkForEmptyCustomProcedures = (
    procedures: CustomSurgeryProcedure[]
  ) => {
    const hasEmptyProcedure = procedures.some(
      procedure => procedure.customProcedure.procedure.trim() === ''
    );
    setIsError(hasEmptyProcedure);
  };

  return (
    <div className="surgery-details-edit-modal">
      <EditModal
        visible={editModalVisible}
        title="Edit Surgery Details"
        onClose={onModalClose}>
        <form
          className="edit-patient-demographics-form"
          onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="surgeryDate"
            control={control}
            rules={{
              required: true,
              validate: date => {
                const minDate = moment().subtract(125, 'years');
                return moment(date).isSameOrAfter(minDate);
              }
            }}
            render={({ field }) => (
              <KaliberDateOfBirthPicker
                editable={true}
                className="asp-datepicker-size"
                label="Surgery Date"
                name="surgeryDate"
                errors={errors}
                errorMessage="Surgery date is required"
                value={field.value ?? null}
                onChange={date => field.onChange(date)}
                dataTestId="test-dob"
              />
            )}
          />
          <div className="edit-patient-demographics-form__name-fields">
            <Controller
              name="surgeonName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <KaliberSelect
                  label="Surgeon"
                  placeholder=""
                  select={field.value}
                  errors={errors}
                  includeOther={false}
                  onChange={e => {
                    field.onChange(e);
                    setValue('facilityName', '');
                  }}
                  list={surgeons.map(surgeon => surgeon.surgeonName)}
                />
              )}
            />
            <Controller
              name="facilityName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <KaliberSelect
                  label="Surgery Center"
                  placeholder=""
                  select={field.value}
                  errors={errors}
                  includeOther={false}
                  onChange={e => field.onChange(e)}
                  list={facilities.map(facility => facility.facilityName)}
                />
              )}
            />
          </div>

          <EditProcedures
            cptCodes={cptCodes}
            cptProcedures={cptSurgeryProcedures}
            onChangeCptProcedures={newCptProcedures => {
              const plannedProcedures = getValues('plannedProcedures');
              setValue(
                'plannedProcedures',
                { ...plannedProcedures, cptProcedures: newCptProcedures },
                { shouldDirty: true }
              );

              setCptSurgeryProcedures(newCptProcedures);
            }}
            onRemoveCptProcedure={removedCptProcedure => {
              if (removedCptProcedure.id) {
                setCptSurgeryProceduresToDelete(
                  cptSurgeryProceduresToDelete.concat([removedCptProcedure])
                );
              }
            }}
            customProcedures={customSurgeryProcedures}
            onChangeCustomProcedures={newCustomProcedures => {
              const plannedProcedures = getValues('plannedProcedures');
              setValue(
                'plannedProcedures',
                { ...plannedProcedures, customProcedures: newCustomProcedures },
                { shouldDirty: true }
              );

              setCustomSurgeryProcedures(newCustomProcedures);
              checkForEmptyCustomProcedures(newCustomProcedures);
            }}
            onRemoveCustomProcedure={removedCustomProcedure => {
              if (removedCustomProcedure.id) {
                setCustomSurgeryProceduresToDelete(
                  customSurgeryProceduresToDelete.concat([
                    removedCustomProcedure
                  ])
                );
              }
            }}
            procedureType="planned"
          />
          <div className="edit-modal__buttons">
            <KaliberButton
              type="submit"
              disabled={!isDirty || isError}
              loading={loading}
              buttonClass="btn--primary">
              Save Changes
            </KaliberButton>
            <KaliberButton
              type="button"
              onClick={onModalClose}
              buttonClass="btn--cancel">
              Cancel
            </KaliberButton>
          </div>
        </form>
      </EditModal>
    </div>
  );
}

interface SurgeryDetailsCardProps {
  surgery: Surgery;
  setReloadSurgery?: (shouldReload: boolean) => void;
}

export default function SurgeryDetails({
  surgery,
  setReloadSurgery
}: SurgeryDetailsCardProps): ReactElement {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [user, surgeons, cptCodes]: [KLUser, KLSurgeon[], KLCptCode[]] =
    useSelector((state: KLState) => [
      state.user,
      state.surgeons,
      state.cptCodes
    ]);
  const [facilitiesList, setFacilitiesList] = useState<KLFacility[]>([]);
  const [selectedSurgeon, setSelectedSurgeon] = useState<string | null>(null);

  useEffect(() => {
    if (user.accessToken) {
      getSurgeons(user.accessToken);
      getCptCodes(user.accessToken);
    }
  }, []);

  useEffect(() => {
    const getFilteredFacilities = async () => {
      if (user.accessToken && selectedSurgeon) {
        const surgeon = surgeons.find(
          element => element.surgeonName === selectedSurgeon
        );
        if (surgeon) {
          const facilities = await getFacilitiesBySurgeon(
            user.accessToken,
            surgeon.surgeonId
          );
          setFacilitiesList(facilities);
        }
      }
    };

    getFilteredFacilities();
  }, [user.accessToken, selectedSurgeon]);

  const handleSurgeonSelection = useCallback((surgeonName: string) => {
    setSelectedSurgeon(surgeonName);
  }, []);

  const handleModalOpen = () => {
    setEditModalVisible(true);
  };

  const handleModalClose = (dataChanged = false) => {
    setEditModalVisible(false);

    if (setReloadSurgery && dataChanged) {
      setReloadSurgery(true);
    }
  };

  return (
    <>
      <Display surgery={surgery} user={user} modalOpen={handleModalOpen} />
      <Edit
        surgery={surgery}
        surgeons={surgeons}
        facilities={facilitiesList}
        cptCodes={cptCodes}
        editModalVisible={editModalVisible}
        modalClose={handleModalClose}
        onSurgeonSelect={handleSurgeonSelection}
      />
    </>
  );
}
