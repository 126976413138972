import { ReactElement } from 'react';

import './RadioButton.scss';

interface RadioButtonProps {
  onClick?: (value: string) => void;
  className?: string;
  disabled?: boolean;
  label: string;
  checked?: boolean;
  nameGroup: string;
  value: string;
  id?: string;
}
export default function RadioButton({
  onClick,
  label,
  checked = false,
  nameGroup,
  value,
  disabled = false,
  className,
  id
}: RadioButtonProps): ReactElement {
  function handleClick() {
    if (onClick) {
      onClick(value);
    }
  }

  return (
    <div className={disabled ? 'radio-control disabled' : 'radio-control'}>
      <input
        type="radio"
        id={id}
        name={nameGroup}
        checked={checked}
        disabled={disabled}
        value={value}
        onChange={handleClick}
      />
      <label
        htmlFor={nameGroup}
        className={`radio-control--label ${className}`}>
        {label}
      </label>
    </div>
  );
}
