/* eslint-disable @typescript-eslint/no-explicit-any */
import { camelCase } from 'camel-case';
import { ReactElement } from 'react';
import { Controller, Path } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import './KaliberPhoneField.scss';

interface KaliberPhoneFieldProps {
  label: Path<any>;
  required?: boolean;
  value?: string;
  errors: any;
  disabled?: boolean;
  errorMessage?: string;
  control: any;
  name: string;
  minLength?: number;
  placeholder?: boolean;
  dataTestId?: string;
  fullWidth?: boolean;
}

const DEFAULT_MIN_LENGTH = 14;
const ERROR_MINLENGTH_MESSAGE = 'Please input a valid number';

export default function KaliberPhoneField({
  label,
  control,
  required,
  errors,
  errorMessage,
  name,
  minLength = DEFAULT_MIN_LENGTH,
  placeholder = true,
  dataTestId,
  fullWidth = false,
  ...options
}: KaliberPhoneFieldProps): ReactElement {
  return (
    <Controller
      name={name}
      rules={{ required, minLength }}
      control={control}
      render={({ field }) => (
        <div className="kpf-container">
          <div className="kpf-text-container">
            <label htmlFor={name} className="kpf-label">
              {label}
            </label>
          </div>

          <div
            className={`kpf-input-container ${
              fullWidth ? 'kpf-input-container__full-width' : ''
            } ${errors[camelCase(name)] && 'kpf-input-error-border'}`}>
            <MaskedInput
              id={name}
              className={'kpf-input-text'}
              mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]}
              guide={false}
              placeholder={placeholder ? '(###) ###-####' : ''}
              onChange={e => {
                field.onChange(e);
              }}
              value={field.value}
              data-testid={dataTestId ? dataTestId : 'k-phone-field'}
              {...options}
            />
          </div>
          {errors[camelCase(name)] &&
            errors[camelCase(name)].type === 'required' && (
              <div className="kpf-input-error">{errorMessage}</div>
            )}
          {errors[camelCase(name)] &&
            errors[camelCase(name)].type === 'minLength' && (
              <div className="kpf-input-error">{ERROR_MINLENGTH_MESSAGE}</div>
            )}
          {errors.phone && (
            <div className="kpf-input-error">{errors.phone.message}</div>
          )}
        </div>
      )}
    />
  );
}
