/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import EnvelopeSimpleIcon from '../../../../assets/icons/EnvelopeSimple.svg';
import HouseIcon from '../../../../assets/icons/House.svg';
import PhoneCallIcon from '../../../../assets/icons/PhoneCall.svg';
import KaliberCard from '../../../../components/card/KaliberCard';
import EditModal from '../../../../components/modal/EditModal';
import KaliberSelect from '../../../../components/multiselect/KaliberSelect';
import KaliberPhoneField from '../../../../components/phoneField/KaliberPhoneField';
import KaliberTextField from '../../../../components/textField/KaliberTextField';
import { STATE_OPTIONS } from '../../../../constants';
import { updatePatient } from '../../../../redux/actions/patientActions';
import { KLState, KLUser } from '../../../../redux/types';
import { PatientCardProps } from '../patientProfile/PatientProfilePage';
import './PatientContact.scss';
import { KaliberButton, OptionalValueDisplay } from '../../../../components';
import { INVALID_PHONE_MESSAGE } from '../../../../utils/textFormat';

interface DisplayProps extends PatientCardProps {
  user: KLUser;
  modalOpen: () => void;
}

function Display({ patient, modalOpen, user }: DisplayProps) {
  return (
    <div className="patient-contact-container">
      <KaliberCard
        title="Patient Contact"
        titleAlignment="left"
        editButton={
          user.role === 'Patient' ||
          user.role === 'Kaliber Admin' ||
          user.role === 'Surgeon' ||
          user.role === 'Surgical Clinical Team' ||
          user.role === 'Surgical Office Staff'
        }
        onClickEdit={modalOpen}>
        <div className="patient-contact">
          <div className="patient-contact__info">
            <img src={HouseIcon} className="patient-contact__icon" />
            <OptionalValueDisplay value={patient.address}>
              <div className="patient-contact__text">
                {patient.address}
                <br />
                {patient.city}, {patient.state} {patient.zipcode}
              </div>
            </OptionalValueDisplay>
          </div>
          <div className="patient-contact__info">
            <img src={PhoneCallIcon} className="patient-contact__icon" />
            <OptionalValueDisplay value={patient.phone}>
              <div className="patient-contact__text">{patient.phone}</div>
            </OptionalValueDisplay>
          </div>
          <div className="patient-contact__info">
            <img src={EnvelopeSimpleIcon} className="patient-contact__icon" />
            <OptionalValueDisplay value={patient.email}>
              <div className="patient-contact__text">{patient.email}</div>
            </OptionalValueDisplay>
          </div>
        </div>
      </KaliberCard>
    </div>
  );
}

interface EditProps extends PatientCardProps {
  editModalVisible: boolean;
  modalClose: (dataChanged?: boolean) => void;
}

function Edit({ patient, editModalVisible, modalClose }: EditProps) {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    setError,
    formState: { errors, isDirty, isValid }
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      address: patient.address,
      city: patient.city,
      state: patient.state,
      zipcode: patient.zipcode,
      phone: patient.phone,
      email: patient.email
    }
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    if (user.accessToken && patient.id) {
      updatePatient(user.accessToken, patient.id, data)
        .then(() => {
          setLoading(false);
          modalClose(true);
        })
        .catch(error => {
          if (error.message === INVALID_PHONE_MESSAGE) {
            setError('phone', {
              type: 'manual',
              message: INVALID_PHONE_MESSAGE
            });
          }
        });
    }
  };

  return (
    <EditModal
      visible={editModalVisible}
      title="Edit Patient Contact"
      onClose={() => modalClose()}>
      <form
        className="edit-patient-contact-form"
        onSubmit={handleSubmit(onSubmit)}>
        <KaliberTextField
          label="Address"
          name="address"
          placeholder=""
          register={register}
          registerObject={{ required: true }}
          errors={errors}
          errorMessage="Address is required."
        />
        <div className="locality-information-container">
          <div className="city">
            <KaliberTextField
              label="City"
              name="city"
              placeholder=""
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="City is required."
            />
          </div>
          <Controller
            name="state"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <KaliberSelect
                label="State"
                placeholder=""
                select={field.value}
                errors={errors[field.name]}
                includeOther={false}
                onChange={e => field.onChange(e)}
                list={Object.keys(STATE_OPTIONS)}
                fullWidth
              />
            )}
          />
          <div className="zipcode">
            <KaliberTextField
              label="Zip Code"
              name="zipcode"
              placeholder=""
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="Zip Code is required."
            />
          </div>
        </div>
        <KaliberPhoneField
          label="Mobile Phone (recommended)"
          name="phone"
          placeholder={false}
          errors={errors}
          control={control}
          fullWidth={false}
        />
        <div className="email-text-field">
          <KaliberTextField
            label="Email (recommended)"
            placeholder=""
            name="email"
            register={register}
            errors={errors}
            disabled={Boolean(patient.email)}
          />
          {Boolean(patient.email) && (
            <div className="helper-text">
              {'To change this email address, please contact '}
              <a
                href="mailto:support@kaliber.ai"
                className="helper-text__email-address">
                support@kaliber.ai
              </a>
            </div>
          )}
        </div>
        <div className="edit-modal__buttons">
          <KaliberButton
            type="submit"
            disabled={!isDirty || !isValid}
            loading={loading}
            buttonClass="btn--primary">
            Save Changes
          </KaliberButton>
          <KaliberButton
            type="button"
            onClick={() => modalClose()}
            buttonClass="btn--cancel">
            Cancel
          </KaliberButton>
        </div>
      </form>
    </EditModal>
  );
}

export default function PatientContact({
  patient,
  setReloadSurgery
}: PatientCardProps): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const handleModalOpen = () => {
    setEditModalVisible(true);
  };

  const handleModalClose = (dataChanged = false) => {
    setEditModalVisible(false);

    if (setReloadSurgery && dataChanged) {
      setReloadSurgery(true);
    }
  };

  return (
    <>
      <Display patient={patient} user={user} modalOpen={handleModalOpen} />{' '}
      {editModalVisible && (
        <Edit
          patient={patient}
          editModalVisible={editModalVisible}
          modalClose={handleModalClose}
        />
      )}
    </>
  );
}
