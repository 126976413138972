import React, { ReactElement } from 'react';
import './KLSideDrawer.scss';

type SideDrawerProps = {
  isOpen: boolean;
  toggleSideDrawer: () => void;
  children?: React.ReactNode;
};
export default function SideDrawer({
  isOpen,
  toggleSideDrawer,
  children
}: SideDrawerProps): ReactElement {
  return (
    <>
      <div
        className={`side-drawer${isOpen ? ' open' : ''}`}
        role="presentation">
        {children}
      </div>
      <div
        className={`overlay${isOpen ? ' show' : ''}`}
        onClick={toggleSideDrawer}
        role="presentation"
      />
    </>
  );
}
