/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { KLState } from '../redux/types';

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

export default function PrivateRoute({
  path,
  exact,
  component
}: PrivateRouteProps): any {
  const user = useSelector((state: KLState) => state.user);

  return user.accessToken ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <Redirect to="/login" />
  );
}
