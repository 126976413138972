import {
  BaseSyntheticEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { firstBy } from 'thenby';
import PlusCircle from '../../assets/icons/PlusCircleGreen.svg';
import { KLSnackbar, KaliberButton } from '../../components';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import { getAllPostOpInstructions } from '../../redux/actions/postOpInstructionsActions';
import { getSurgeons } from '../../redux/actions/surgeonsActions';
import {
  clearSurgeries,
  deleteSurgery,
  getSurgeriesForLoggedInUser,
  setPendingDeleteConfirmation,
  setPendingSurgery,
  setPostDeleteConfirmation
} from '../../redux/actions/surgeriesActions';

import { getReportViewedDate } from '../../redux/actions/reportActions';
import {
  setReportStatusFilters,
  setSurgeryFilters
} from '../../redux/actions/uiActions';
import {
  KLState,
  KLSurgeon,
  KLSurgeries,
  KLSurgery,
  KLUi,
  KLUser
} from '../../redux/types';
import {
  currentDateSort,
  formatStatusValue,
  reportStatusSort,
  surgeryDateSort
} from '../../utils/dataOrganization';
import './SurgeriesPage.scss';
import {
  Fab,
  FiltersMenu,
  PendingDeleteModal,
  PostDeleteModal,
  Searchbar,
  SurgeryCard,
  SurgeryCardSkeleton
} from './components';
import ReportStatusFilter from './components/filters/reportStatusFilter/ReportStatusFilter';

export interface filterData {
  search: string;
  surgeonName: string;
  facilityName: string;
  surgeryDate: string;
}

export interface ReportStatus {
  notStarted: boolean;
  inProgress: boolean;
  processing: boolean;
  readyForReview: boolean;
  sentToPatient: boolean;
}

export default function SurgeriesPage(): ReactElement {
  const [surgeries, user, ui, surgeons]: [
    KLSurgeries,
    KLUser,
    KLUi,
    KLSurgeon[]
  ] = useSelector((state: KLState) => [
    state.surgeries,
    state.user,
    state.ui,
    state.surgeons
  ]);
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [showPendingDeleteModal, setShowPendingDeleteModal] = useState(false);
  const [showPostDeleteModal, setShowPostDeleteModal] = useState(false);
  const [pendingDeleteSurgeryData, setPendingDeleteSurgeryData] =
    useState<KLSurgery>({} as KLSurgery);
  const [showSurgerySuccessSnackbar, setShowSurgerySuccessSnackbar] =
    useState(false);
  const [filter, setFilter] = useState({
    search: '',
    surgeonName: '',
    facilityName: '',
    surgeryDate: ''
  });
  const [reportStatus, setReportStatus] = useState<ReportStatus>({
    notStarted: false,
    inProgress: false,
    processing: false,
    readyForReview: false,
    sentToPatient: false
  });

  const [reportViewedFlag, setReportViewedFlag] = useState<{
    [surgeryId: number]: boolean | null;
  } | null>(null);

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const filteredData = useMemo(() => {
    return filterData();
  }, [surgeries, filter, reportStatus]);

  useEffect(() => {
    setFullstoryPage('Surgeries Page');

    // Set permanent query params, filter, and status
    setQueryParamsOffExisting();
    setReportStatusOffExisting();
    setFilterOffExisting();

    const surgerySuccessfullyCreated = (
      history.location.state as { surgerySuccessfullyCreated: boolean }
    )?.surgerySuccessfullyCreated;

    if (surgerySuccessfullyCreated) {
      setShowSurgerySuccessSnackbar(true);
      history.replace({
        ...history.location,
        state: history.location.state
          ? { ...history.location.state, surgerySuccessfullyCreated: false }
          : { surgerySuccessfullyCreated: false }
      });

      const timeoutId = setTimeout(() => {
        setShowSurgerySuccessSnackbar(false);
      }, 8000);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  // Get initial surgeries list
  useEffect(() => {
    if (user.accessToken) {
      setLoading(true);
      Promise.all([
        getSurgeons(user.accessToken),
        getAllPostOpInstructions(user.accessToken),
        getSurgeriesForLoggedInUser(user.accessToken)
      ]).then(() => {
        setLoading(false);
      });
    }
    return () => {
      clearSurgeries();
    };
  }, [user.accessToken]);

  useEffect(() => {
    if (user.accessToken && showPostDeleteModal) {
      setLoading(true);
      Promise.resolve(getSurgeriesForLoggedInUser(user.accessToken)).then(
        () => {
          setLoading(false);
        }
      );
    }
  }, [showPostDeleteModal]);

  useEffect(() => {
    const getReportViewedStatus = async (surgery: KLSurgery) => {
      const userRoles = [
        'Kaliber Admin',
        'Surgeon',
        'Surgical Clinical Team',
        'Surgical Office Staff'
      ];
      if (
        user.accessToken &&
        userRoles.includes(user.role) &&
        surgery.surgeryStatus === 'Sent to Patient'
      ) {
        const status = await reportViewedStatus(user.accessToken, surgery);
        setReportViewedFlag(prevStatus => ({
          ...prevStatus,
          [surgery.surgeryId]: status
        }));
      }
    };

    if (surgeries.surgeries && surgeries.surgeries.length > 0) {
      surgeries.surgeries.forEach((surgery: KLSurgery) => {
        getReportViewedStatus(surgery);
      });
    }
  }, [user.accessToken, surgeries.surgeries]);

  useEffect(() => {
    if (surgeries.pendingDeleteSurgery) {
      setPendingDeleteSurgeryData(
        surgeries.surgeries.find(
          element => element.surgeryId === surgeries.pendingDeleteSurgery
        ) ?? ({} as KLSurgery)
      );
    }
    setShowPendingDeleteModal(surgeries.pendingDeleteConfirmation ?? false);
    setShowPostDeleteModal(surgeries.postDeleteConfirmation ?? false);
  }, [surgeries]);

  /**
   * Sets query parameters based on existing surgery filters and statuses.
   *
   * @return {void} No return value
   */
  function setQueryParamsOffExisting(): void {
    Object.keys(ui.surgeryFilters).forEach(key => {
      ui.surgeryFilters[key as keyof filterData] != '' &&
        setQueryParams(key, ui.surgeryFilters[key as keyof filterData] ?? '');
    });

    Object.keys(ui.surgeryStatus).forEach(key => {
      ui.surgeryStatus[key as keyof ReportStatus] &&
        setQueryParams(
          key,
          ui.surgeryStatus[key as keyof ReportStatus] ? 'true' : 'false'
        );
    });
  }

  /**
   * Sets the report status based on the existing query parameters and UI surgery status.
   *
   * @return {void} This function does not return anything.
   */
  function setReportStatusOffExisting(): void {
    const statuses = {} as ReportStatus;
    Object.keys(reportStatus).forEach(key => {
      const status =
        queryParams.get(key) === 'true'
          ? true
          : queryParams.get(key) === 'false'
          ? false
          : ui.surgeryStatus[key as keyof ReportStatus];
      statuses[key as keyof ReportStatus] = status;
      setReportStatusFilters(key, status);
    });
    setReportStatus(statuses);
  }

  /**
   * Sets the filter based on the existing query parameters and UI surgery filters.
   *
   * @return {void} This function does not return anything.
   */
  function setFilterOffExisting(): void {
    const filters = {} as filterData;
    Object.keys(filter).forEach(key => {
      const fitler =
        queryParams.get(key) ?? ui.surgeryFilters[key as keyof filterData];
      filters[key as keyof filterData] = fitler;
      setSurgeryFilters(key, fitler);
    });
    setFilter(filters);
  }

  /**
   * Sorts the given array of KLSurgery objects based on multiple criteria.
   *
   * @param {KLSurgery[]} data - the array of KLSurgery objects to be sorted
   * @return {KLSurgery[]} the sorted array of KLSurgery objects
   */
  function customSort(data: KLSurgery[]): KLSurgery[] {
    return data.sort(
      firstBy(currentDateSort)
        .thenBy(surgeryDateSort)
        .thenBy(reportStatusSort)
        .thenBy('patientLastName')
    );
  }

  /**
   * Handle the search event and update the filter and query parameters.
   *
   * @param {BaseSyntheticEvent} e - the synthetic event object
   * @return {void}
   */
  function handleSearch(e: BaseSyntheticEvent) {
    const value = e.target.value || '';
    setFilter({ ...filter, search: value });
    setQueryParams('search', value);
  }

  /**
   * Handles the filter data and updates the filter state and query parameters.
   *
   * @param {filterData} filterData - the filter data to be applied
   * @return {void}
   */
  function handleFilters(filterData: filterData) {
    setFilter({ ...filter, ...filterData });
    for (const key in filterData) {
      setQueryParams(key, filterData[key as keyof filterData]);
      setSurgeryFilters(key, filterData[key as keyof filterData]);
    }
  }

  /**
   * Sets a query parameter in the URL and updates the browser history.
   *
   * @param {string} key - the key of the query parameter
   * @param {string} value - the value of the query parameter
   */
  function setQueryParams(key: string, value: string) {
    // setSurgeryFilters(key, value);
    if (value) {
      queryParams.set(key, value);
      history.replace({
        pathname: location.pathname,
        search: queryParams.toString()
      });
    } else {
      queryParams.delete(key);
      history.replace({
        pathname: location.pathname,
        search: queryParams.toString()
      });
    }
  }

  /**
   * Updates the report status and sets the query parameters based on the given value.
   *
   * @param {ReportStatus} value - the new report status
   * @return {void}
   */
  function handleReportStatus(value: ReportStatus) {
    setReportStatus(value);
    for (const key in value) {
      if (value[key as keyof ReportStatus]) {
        setQueryParams(key, 'true');
        setReportStatusFilters(key, true);
      } else {
        setQueryParams(key, '');
        setReportStatusFilters(key, false);
      }
    }
  }

  /**
   * Filters the given KLSurgery array based on the reportStatus object.
   *
   * @param {KLSurgery[]} data - The array of KLSurgery objects to filter
   * @return {KLSurgery[]} The filtered KLSurgery array based on the reportStatus
   */
  function filterByStatus(data: KLSurgery[]) {
    if (Object.values(reportStatus).every(v => v === false)) return data;
    return data.filter(item => {
      return Object.entries(reportStatus).some(([key, value]) => {
        return value && item.surgeryStatus === formatStatusValue(key);
      });
    });
  }

  /**
   * Filters the given array of KLSurgery objects based on the search criteria.
   *
   * @param {KLSurgery[]} data - the array of KLSurgery objects to filter
   * @return {KLSurgery[]} the filtered array of KLSurgery objects
   */
  function filterBySearch(data: KLSurgery[]) {
    return data.filter(item => {
      return `${item.patientFirstName.toLocaleLowerCase()} ${item.patientLastName.toLocaleLowerCase()}`.includes(
        filter.search.toLocaleLowerCase()
      );
    });
  }

  /**
   * Filters the input data array based on the provided filter menu options.
   *
   * @param {KLSurgery[]} data - The input data array to be filtered
   * @return {KLSurgery[]} The filtered data array based on the filter menu options
   */
  function filterByFilterMenu(data: KLSurgery[]) {
    let result = data;

    // Filter by Surgeon Name
    if (filter.surgeonName)
      result = result.filter(item => item.surgeonName === filter.surgeonName);

    // Filter by Facility
    if (filter.facilityName)
      result = result.filter(item => item.facilityName === filter.facilityName);

    // Filter by Surgery Date
    if (filter.surgeryDate)
      result = result.filter(item => item.surgeryDate === filter.surgeryDate);

    return result;
  }

  const reportViewedStatus = async (
    token: string,
    surgery: KLSurgery
  ): Promise<boolean | null> => {
    try {
      const date = await getReportViewedDate(token, surgery.surgeryId);
      return date === '' ? false : true;
    } catch (err) {
      return null;
    }
  };

  /**
   * Filters the data by sorting, filter menu, search, and status.
   *
   * @param {} -
   * @return {type} the filtered data
   */
  function filterData() {
    const data = customSort(surgeries.surgeries);
    const filterMenuResult = filterByFilterMenu(data);
    const searchResult = filterBySearch(filterMenuResult);
    return filterByStatus(searchResult);
  }

  const handleDeleteSurgery = async (surgeryId: number) => {
    if (user.accessToken) {
      await deleteSurgery(user.accessToken, surgeryId);
    }
  };

  return (
    <div className="surgeries-page">
      <div className="surgeries-page__title-container">
        <div className="surgeries-page__title">Surgeries</div>
        {showSurgerySuccessSnackbar ? (
          <KLSnackbar text="New surgery added" />
        ) : null}
        <Searchbar onChange={handleSearch} value={filter.search} />
      </div>
      <div className="surgeries-page__filters-container">
        <div className="surgeries-page__filters">
          <FiltersMenu
            onSubmit={handleFilters}
            filter={filter}
            surgeonNames={surgeons.map(surgeon => surgeon.surgeonName)}
            facilities={[
              ...new Set(surgeries.surgeries.map(s => s.facilityName))
            ]}
          />
          <ReportStatusFilter
            reportStatus={reportStatus}
            onChange={handleReportStatus}
          />
        </div>
        <KaliberButton
          onClick={() => history.push('/add-surgery')}
          buttonClass="btn--secondary surgeries-page__add-surgery"
          type="button">
          <img src={PlusCircle} />
          Add Surgery
        </KaliberButton>
      </div>
      {loading ? (
        <div className="surgeries-page">
          <SurgeryCardSkeleton />
          <SurgeryCardSkeleton />
          <SurgeryCardSkeleton />
          <SurgeryCardSkeleton />
          <SurgeryCardSkeleton />
        </div>
      ) : (
        <div>
          <div className="surgeries-page__entries">
            {filteredData.length}{' '}
            {filteredData.length === 1 ? 'entry' : 'entries'}
          </div>
          <Fab onClick={() => history.push('/add-surgery')} />
          {filteredData.map(s => {
            return (
              <SurgeryCard
                key={s.surgeryId}
                showDelete={
                  user.role === 'Surgeon' ||
                  user.role === 'Surgical Clinical Team' ||
                  user.role === 'Surgical Office Staff' ||
                  user.role === 'Kaliber Admin'
                }
                handleDelete={(surgeryId: number) => {
                  setPendingDeleteConfirmation(true);
                  setPendingSurgery(surgeryId);
                }}
                surgery={s}
                isReportViewed={
                  reportViewedFlag && reportViewedFlag[s.surgeryId]
                }
                user={user}
              />
            );
          })}
          {filteredData.length === 0 && !ui.loading && (
            <div className="Empty-text">No patients matching this criteria</div>
          )}
        </div>
      )}
      <div>
        {/* TODO: move to different page (potentially surgery card page) */}
        <PendingDeleteModal
          surgery={pendingDeleteSurgeryData}
          visible={showPendingDeleteModal}
          onClickDelete={async () => {
            await handleDeleteSurgery(pendingDeleteSurgeryData.surgeryId);
            setPendingDeleteConfirmation(false);
            setPostDeleteConfirmation(true);
          }}
          onClickCancel={() => {
            setPendingDeleteConfirmation(false);
            setPendingSurgery(0);
          }}
        />
      </div>
      <div>
        {/* TODO: move to different page (potentially surgery card page) */}
        <PostDeleteModal
          visible={showPostDeleteModal}
          onClick={() => {
            setPostDeleteConfirmation(false);
            setPendingSurgery(0);
            history.push('/surgeries');
          }}
        />
      </div>
    </div>
  );
}
