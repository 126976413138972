import { ReactElement, useState } from 'react';

import { ReactComponent as CaretDown } from '../../../../../assets/icons/CaretDown.svg';
import { ReactComponent as CaretUp } from '../../../../../assets/icons/CaretUp.svg';
import './AccordionComponent.scss';

type AccordionComponentProps = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  className?: string;
};

export default function AccordionComponent({
  title,
  children,
  className
}: AccordionComponentProps): ReactElement {
  const [showContent, setShowContent] = useState(false);

  return (
    <div className={`accordion ${className}`}>
      <button
        type="button"
        className="title"
        onClick={() => setShowContent(!showContent)}>
        <span className="title__text">{title}</span>
        <div className="title__icon">
          {showContent ? <CaretUp /> : <CaretDown />}
        </div>
      </button>
      {/* Body */}
      {showContent && <div className="content">{children}</div>}
    </div>
  );
}
