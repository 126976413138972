import { AnyAction } from 'redux';
import { Staff } from '../types';
import camelcaseKeys from 'camelcase-keys';

export default function StaffReducer(state = [], action: AnyAction): Staff[] {
  switch (action.type) {
    case 'SET_STAFF': {
      return action.payload.map((item: Staff) =>
        camelcaseKeys(item, { deep: true })
      );
    }
    case 'CLEAR_STAFF': {
      return [];
    }
    default:
      return state;
  }
}
