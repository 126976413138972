/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '../store';
import {
  AuthorizationError,
  BadRequestError,
  callStratusApiV2,
  RequestMethod
} from '../../api';
import { KLFacility } from '../types';
import camelcaseKeys from 'camelcase-keys';
import { setLoading } from './uiActions';
import snakecaseKeys from 'snakecase-keys';

export async function getFacilities(accessToken: string): Promise<string> {
  let message = 'ok';
  let data: KLFacility[] = [];
  const names: string[] = [];
  try {
    const res = await callStratusApiV2<{ data: KLFacility[] }>('facilities', {
      accessToken,
      method: RequestMethod.Get
    });

    data = camelcaseKeys(res.data);
    data.forEach(element => {
      names.push(element.facilityName);
    });
  } catch (error) {
    if (error instanceof BadRequestError) {
      message = 'Review your data and try again';
    } else if (error instanceof AuthorizationError) {
      message =
        "Seems you don't have authorization to see this information, please contact your administrator.";
    } else if (error instanceof Error) {
      message = JSON.parse(error.message).message;
    }
  }

  store.dispatch({
    type: 'SET_FACILITIES',
    payload: data
  });
  store.dispatch({
    type: 'SET_FACILITIES_NAMES',
    payload: names
  });

  return message;
}

export async function createNewFacility(
  accessToken: string,
  facility: KLFacility
): Promise<void> {
  setLoading(true);
  await callStratusApiV2<{ data: KLFacility }>('facilities', {
    accessToken,
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(snakecaseKeys(facility as any))
  });

  setLoading(false);
}

export async function updateFacility(
  accessToken: string,
  facilityId: number,
  facilityData: KLFacility
): Promise<void> {
  await callStratusApiV2<Record<string, null>>(`facilities/${facilityId}`, {
    accessToken,
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(snakecaseKeys(stripFacilityId(facilityData) as any))
  });
}

function stripFacilityId(facilityData: KLFacility): KLFacility {
  // Disabling the no-unused-vars lint so we can use this property
  // of object destructuring to remove `facilityId` from `facilityData`.
  //  - mstallmo 07/01/22
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { facilityId, ...updateData } = facilityData;
  return updateData;
}
