import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function topStart({ history }) {
  useEffect(() => {
    const top = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      top();
    };
  }, []);
  return null;
}

export default withRouter(topStart);
