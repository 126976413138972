import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { KLTermsModal } from './KLTermsModal';
import TermsOfUsePage from '../../pages/termsOfUse/TermsOfUsePage';
import SurgeonTermsOfUsePage from '../../pages/termsOfUse/SurgeonTermsOfUsePage';
import { KLState, KLUser, KLUserTermsAgreement } from '../../redux/types';
import {
  getAgreedTermsOfUse,
  saveAgreementTermsOfUse
} from '../../redux/actions/userActions';

export default function KaliberTermsOfUse(): ReactElement {
  const [user, userTermsOfUse]: [KLUser, KLUserTermsAgreement[]] = useSelector(
    (state: KLState) => [state.user, state.termsOfUseAgreed]
  );

  const [pendingTermsOfUse, setPendingTermsOfUse] = useState<
    KLUserTermsAgreement[]
  >([]);
  const [pendingTermsOfUseIndex, setPendingTermsOfUseIndex] = useState(0);

  useEffect(() => {
    if (user.accessToken && user.uid) {
      getAgreedTermsOfUse(user.accessToken, user.uid);
    }
  }, [user.accessToken, user.uid]);

  useEffect(() => {
    if (userTermsOfUse) {
      setPendingTermsOfUse(
        userTermsOfUse.filter(termsOfUse => !termsOfUse.agreed)
      );
    }
  }, [userTermsOfUse]);

  const setNextPendingTermsOfUse = () => {
    const nextIdx = pendingTermsOfUseIndex + 1;
    if (pendingTermsOfUse.length > nextIdx) {
      setPendingTermsOfUseIndex(nextIdx);
    } else {
      setPendingTermsOfUse([]);
    }
  };

  const termsOfUseModal = () => {
    const activeTermsOfUse = pendingTermsOfUse[pendingTermsOfUseIndex];

    return (
      <KLTermsModal
        onCloseHandler={setNextPendingTermsOfUse}
        onAcceptHandler={async () => {
          if (user.accessToken && user.uid) {
            return await saveAgreementTermsOfUse(
              user.accessToken,
              user.uid,
              activeTermsOfUse.updateId
            );
          }

          return Promise.reject('User does not have a uid');
        }}>
        {activeTermsOfUse.audience === 'surgeon' ? (
          <SurgeonTermsOfUsePage />
        ) : (
          <TermsOfUsePage />
        )}
      </KLTermsModal>
    );
  };

  return <>{pendingTermsOfUse.length > 0 && termsOfUseModal()}</>;
}
