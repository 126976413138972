import React, { ReactElement } from 'react';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import './ErrorPage.scss';
import KaliberButton from '../../components/button/KaliberButton';
import { useHistory } from 'react-router';
import { KLState, KLUser } from '../../redux/types';
import { useSelector } from 'react-redux';

const UNAUTHENTICATED_ERROR_MESSAGE =
  "Sorry, we can't seem to find the page you're looking for.";

interface ErrorPageProps {
  title?: string;
  body?: string;
  isFullPage?: boolean;
}

export default function ErrorPage({
  isFullPage = true,
  title = '404',
  body = "Sorry, we can't seem to find the page you're looking for. Try one of the links in the navigation menu or go back."
}: ErrorPageProps): ReactElement {
  const history = useHistory();

  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);

  if (isFullPage) {
    setFullstoryPage('Error Page');
  }

  return (
    <div className="error-page">
      <div className="error-card">
        <div
          className={`error-card__title ${
            title === '404' ? 'error-card__title--large' : ''
          }`}>
          {title}
        </div>
        <div className="error-card__body">
          {user.accessToken !== '' ? body : UNAUTHENTICATED_ERROR_MESSAGE}
        </div>
        <div className="error-page-button">
          <KaliberButton
            type="button"
            buttonClass="btn--primary"
            onClick={() => {
              history.goBack();
            }}>
            Go Back
          </KaliberButton>
        </div>
      </div>
    </div>
  );
}
