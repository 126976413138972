/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PatientHeader } from '../../components';
import KLSnackbar from '../../components/snackbar/KLSnackbar';
import { getCptCodes } from '../../redux/actions/cptCodesActions';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import { getPostOpInstructions } from '../../redux/actions/postOpInstructionsActions';
import {
  createProcedures,
  deleteProcedures,
  updateProcedures
} from '../../redux/actions/proceduresActions';
import { getQuickNotes } from '../../redux/actions/quickNoteActions';
import {
  finalizeReviewById,
  getReviewDataById,
  getSurgeryDataById,
  updateSurgery
} from '../../redux/actions/surgeriesActions';
import {
  CptSurgeryProcedure,
  CustomSurgeryProcedure,
  KLCptCode,
  KLQuickNote,
  KLReview,
  KLState,
  KLUser
} from '../../redux/types';
import { formatSex } from '../../utils/dataOrganization';
import { surgeryInfoToKLSurgery } from '../../utils/surgeryData';
import {
  hasProcedures,
  transformToPerformed
} from '../../utils/surgeryProcedures';
import { capitalizeEveryWord } from '../../utils/textFormat';
import { isNullOrUndefined } from '../../utils/validations';
import './ReviewPage.scss';
import {
  InternalImageReviewComponent,
  SurgeryDetailsComponent
} from './components';

type SurgeryURLParams = {
  id: string;
};

export default function InternalReviewPage(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const surgeryId = parseInt(useParams<SurgeryURLParams>().id, 10);
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const selectedTab = queryParams.get('tab');

  const [user, review, quickNotes, cptCodes]: [
    KLUser,
    KLReview,
    KLQuickNote[],
    KLCptCode[]
  ] = useSelector((state: KLState) => [
    state.user,
    state.surgeries.review,
    state.quickNotes,
    state.cptCodes
  ]);

  // Page State
  const [loading, setLoading] = useState(false);
  const [updatingSurgery, setUpdatingSurgery] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submittingReview, setSubmittingReview] = useState(false);

  // Persisted Component State
  const [cptSurgeryProcedures, setCptSurgeryProcedures] = useState<
    CptSurgeryProcedure[]
  >([]);
  const [cptSurgeryProceduresToDelete, setCptSurgeryProceduresToDelete] =
    useState<CptSurgeryProcedure[]>([]);
  const [customSurgeryProcedures, setCustomSurgeryProcedures] = useState<
    CustomSurgeryProcedure[]
  >([]);
  const [customSurgeryProceduresToDelete, setCustomSurgeryProceduresToDelete] =
    useState<CustomSurgeryProcedure[]>([]);
  const [selectedPostOpInstructionIds, setSelectedPostOpInstructionIds] =
    useState<number[]>([]);

  useEffect(() => {
    setFullstoryPage('Review Page');

    if (!selectedTab) {
      setActiveTab('surgeryDetails');
    }
  }, []);

  useEffect(() => {
    if (user.accessToken && surgeryId !== null) {
      setLoading(true);
      Promise.all([
        getSurgeryDataById(user.accessToken, surgeryId),
        getReviewDataById(user.accessToken, surgeryId),
        getCptCodes(user.accessToken)
      ]);
    }
  }, [user.accessToken, surgeryId]);

  useEffect(() => {
    if (user.accessToken && review.surgeryInfo.surgeonId) {
      setLoading(true);
      Promise.all([
        getPostOpInstructions(user.accessToken, review.surgeryInfo.surgeonId),
        getQuickNotes(user.accessToken, review.surgeryInfo.surgeonId)
      ]).then(() => {
        setLoading(false);
      });
    }

    if (review.surgeryInfo.postOpInstructionsId) {
      setSelectedPostOpInstructionIds(review.surgeryInfo.postOpInstructionsId);
    }

    if (
      review.surgeryInfo.performedProcedures &&
      review.surgeryInfo.plannedProcedures
    ) {
      const proceduresToSet = hasProcedures(
        review.surgeryInfo.performedProcedures
      )
        ? review.surgeryInfo.performedProcedures
        : transformToPerformed(review.surgeryInfo.plannedProcedures);

      setCptSurgeryProcedures(proceduresToSet.cptProcedures);
      setCustomSurgeryProcedures(proceduresToSet.customProcedures);
    }
  }, [user.accessToken, review.surgeryInfo]);

  const setActiveTab = (activeTab: string) => {
    queryParams.set('tab', activeTab);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const handleSendReport = async () => {
    if (user.accessToken) {
      try {
        setSubmittingReview(true);
        await finalizeReviewById(user.accessToken, surgeryId, false);
        setSubmittingReview(false);

        history.replace('/surgeries');
      } catch (e) {
        setShowError(true);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      customSurgeryProcedures.forEach(customSurgeryProcedure => {
        if (customSurgeryProcedure.customProcedure.procedure === '') {
          throw new Error('Custom procedure text cannot be empty');
        }
      });

      if (user.accessToken) {
        setUpdatingSurgery(true);

        await updateSurgery(user.accessToken, surgeryId, {
          ...surgeryInfoToKLSurgery(review.surgeryInfo),
          postOpInstructionsId: selectedPostOpInstructionIds
        });

        await deleteProcedures(
          user.accessToken,
          surgeryId,
          cptSurgeryProceduresToDelete,
          customSurgeryProceduresToDelete
        );
        setCptSurgeryProceduresToDelete([]);
        setCustomSurgeryProceduresToDelete([]);

        const [cptProceduresToCreate, cptProceduresToUpdate] =
          cptSurgeryProcedures.reduce<
            [CptSurgeryProcedure[], CptSurgeryProcedure[]]
          >(
            (acc, cptSurgeryProcedure) => {
              if (isNullOrUndefined(cptSurgeryProcedure.id)) {
                acc.at(0)?.push(cptSurgeryProcedure);
              } else {
                acc.at(1)?.push(cptSurgeryProcedure);
              }

              return acc;
            },
            [[], []]
          );

        const [customProceduresToCreate, customProceduresToUpdate] =
          customSurgeryProcedures.reduce<
            [CustomSurgeryProcedure[], CustomSurgeryProcedure[]]
          >(
            (acc, customSurgeryProcedure) => {
              if (isNullOrUndefined(customSurgeryProcedure.id)) {
                acc.at(0)?.push(customSurgeryProcedure);
              } else {
                acc.at(1)?.push(customSurgeryProcedure);
              }

              return acc;
            },
            [[], []]
          );

        await createProcedures(
          user.accessToken,
          surgeryId,
          cptProceduresToCreate,
          customProceduresToCreate
        );
        await updateProcedures(
          user.accessToken,
          surgeryId,
          cptProceduresToUpdate,
          customProceduresToUpdate
        );

        await getSurgeryDataById(user.accessToken, surgeryId);

        setUpdatingSurgery(false);
      }

      setActiveTab('imageReview');
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <div className="review-page">
      <div className="review-page-container">
        {loading ? (
          <div className="review-page__skeleton review-page__skeleton--header" />
        ) : (
          <PatientHeader
            firstName={review.surgeryInfo.patientFirstName}
            lastName={review.surgeryInfo.patientLastName}
            preferredName={review.surgeryInfo.patientPreferredName ?? ''}
            city={review.surgeryInfo.patientCity ?? ''}
            state={review.surgeryInfo.patientState ?? ''}
            address={capitalizeEveryWord(
              review.surgeryInfo.patientAddress ?? ''
            )}
            middleName={review.surgeryInfo.patientMiddleName ?? ''}
            dob={review.surgeryInfo.patientDob}
            sex={formatSex(review.surgeryInfo.patientSex) ?? 'N/A'}
            zipcode={review.surgeryInfo.patientZipcode ?? ''}
          />
        )}

        {showError && (
          <KLSnackbar
            text="Error updating surgery. Please check the page for any issues."
            onClose={() => setShowError(false)}
          />
        )}
        <div className="review-page-container__body">
          {selectedTab === 'surgeryDetails' &&
            (loading ? (
              <>
                <div className="review-page__skeleton review-page__skeleton--info" />
                <div className="review-page__skeleton review-page__skeleton--details" />
              </>
            ) : (
              <>
                <SurgeryDetailsComponent
                  cptCodes={cptCodes}
                  review={review}
                  selectedPostOpInstructionIds={selectedPostOpInstructionIds}
                  setSelectedPostOpInstructionIds={
                    setSelectedPostOpInstructionIds
                  }
                  cptProcedures={cptSurgeryProcedures}
                  onCptProceduresChange={setCptSurgeryProcedures}
                  onRemoveCptProcedure={removedCptProcedure => {
                    if (removedCptProcedure.id) {
                      setCptSurgeryProceduresToDelete(
                        cptSurgeryProceduresToDelete.concat([
                          removedCptProcedure
                        ])
                      );
                    }
                  }}
                  customProcedures={customSurgeryProcedures}
                  onCustomProceduresChange={setCustomSurgeryProcedures}
                  onRemoveCustomProcedure={removedCustomProcedure => {
                    if (removedCustomProcedure.id) {
                      setCustomSurgeryProceduresToDelete(
                        customSurgeryProceduresToDelete.concat([
                          removedCustomProcedure
                        ])
                      );
                    }
                  }}
                  onSurgeryDetailsSubmit={handleSubmit}
                  updatingSurgery={updatingSurgery}
                />
                <div className="cpt-copyright-notice">
                  CPT copyright 2021 American Medical Association. All rights
                  reserved.
                </div>
              </>
            ))}
          {selectedTab === 'imageReview' &&
            (loading ? (
              <div className="review-page__skeleton review-page__skeleton--images" />
            ) : (
              <InternalImageReviewComponent
                handleSendReport={handleSendReport}
                quickNotes={quickNotes}
                review={review}
                submittingReview={submittingReview}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
