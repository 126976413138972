import camelcaseKeys from 'camelcase-keys';
import { AnyAction } from 'redux';
import {
  KLPostOpInstructions,
  KLPostOpOtherDocs,
  KLSurgeonPostOpInstructions
} from '../types';

const initialState = {
  pdfList: [],
  all: [],
  defaults: [],
  other: []
};

export default function PostOpInstructionsReducer(
  state = initialState,
  action: AnyAction
): KLPostOpInstructions {
  switch (action.type) {
    case 'SET_ALL_PDF_LIST': {
      return {
        ...state,
        all: camelcaseKeys(action.payload)
      };
    }
    case 'SET_PDF_LIST': {
      const defaults = action.payload.filter(
        (element: KLSurgeonPostOpInstructions) =>
          element['procedure'].includes('Default')
      );
      const pdfList = action.payload.filter(
        (element: KLSurgeonPostOpInstructions) =>
          !element['procedure'].includes('Default')
      );
      return {
        ...state,
        defaults: camelcaseKeys(defaults),
        pdfList: camelcaseKeys(pdfList)
      };
    }
    case 'UPDATE_POST_OP_CUSTOM_URL': {
      let index = 0;
      if (action.payload.procedure.includes('Default')) {
        index = state.defaults.findIndex(
          element =>
            element['procedure'] === action.payload.procedure &&
            element['region'] === action.payload.region
        );
        const list = state.defaults as KLSurgeonPostOpInstructions[];
        list[index].customUrl = action.payload.customUrl;

        return {
          ...state,
          defaults: list
        };
      } else if (action.payload.procedure.includes('other')) {
        const list = state.other as KLPostOpOtherDocs[];
        list.push(action.payload);

        return {
          ...state,
          other: list
        };
      } else {
        index = state.pdfList.findIndex(
          element =>
            element['procedure'] === action.payload.procedure &&
            element['region'] === action.payload.region
        );
        const list = state.pdfList as KLSurgeonPostOpInstructions[];
        list[index].customUrl = action.payload.customUrl;

        return {
          ...state,
          pdfList: list
        };
      }
    }
    case 'SET_OTHER_DOCS': {
      return {
        ...state,
        other: camelcaseKeys(action.payload)
      };
    }
    default:
      return state;
  }
}
