import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  KLPostOpInstructions,
  KLState,
  KLSurgery
} from '../../../../../../redux/types';
import ThreeDots from '../../../../../../assets/icons/DotsThreeVertical.svg';
import './KaliberActionMenu.scss';
import { useSelector } from 'react-redux';
import { isNotEmpty } from '../../../../../../utils/validations';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DEFAULT_POST_OP_INSTRUCTION_URL } from '../../../../../../utils/surgeryData';
interface KaliberActionMenuProps {
  surgery: KLSurgery;
  handleDelete: (surgeryId: number) => void;
  showDelete: boolean;
}

export default function KaliberActionMenu({
  surgery,
  handleDelete,
  showDelete
}: KaliberActionMenuProps): ReactElement {
  const [postOpInstructions]: [KLPostOpInstructions] = useSelector(
    (state: KLState) => [state.postOpInstructions]
  );
  const { showPostOpLinkInActionMenu } = useFlags();

  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const useOutsideClick = (show: boolean, setShow: (show: boolean) => void) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    useEffect(() => {
      if (show) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [show, setShow]);

    return { menuRef, buttonRef };
  };

  const { menuRef, buttonRef } = useOutsideClick(
    showBurgerMenu,
    setShowBurgerMenu
  );

  const [postOpLinks, setPostOpLinks] = useState<string[]>([]);

  const getPostOpLink = () => {
    const postOpInstructionsIds = surgery.postOpInstructionsId;

    let linkArray: string[] = [];
    if (
      postOpInstructionsIds &&
      postOpInstructionsIds.length > 0 &&
      postOpInstructions.all
    ) {
      linkArray = postOpInstructionsIds
        .map(postOpInstructionId => {
          const postOp = postOpInstructions.all.find(
            element => element.postOpInstructionsId === postOpInstructionId
          );

          if (postOp) {
            return postOp.customPdfSelected
              ? postOp?.customUrl
              : postOp?.defaultPostOpInstructionsUrl;
          } else {
            return '';
          }
        })
        .filter(postOpLink => postOpLink !== '');
    } else {
      linkArray.push(DEFAULT_POST_OP_INSTRUCTION_URL);
    }

    return linkArray;
  };

  useEffect(() => {
    if (surgery.region !== 'shoulder' && surgery.region !== 'knee') {
      setPostOpLinks([]);
    } else {
      setPostOpLinks(getPostOpLink());
    }
  }, [surgery, postOpInstructions.all]);

  const openMultiplePostOpLinks = (postOpLinks: string[]) => {
    postOpLinks.forEach(postOpLink => {
      window.open(postOpLink, '_blank');
    });
  };

  return (
    <div className="action-menu">
      <button
        ref={buttonRef}
        className="burger-button"
        onClick={() => setShowBurgerMenu(prev => !prev)}>
        <img src={ThreeDots} />
      </button>

      {showBurgerMenu && (
        <div className="menu-list" ref={menuRef}>
          {surgery.rawPdfUrl && (
            <div className="menu-list__link">
              <a href={surgery.rawPdfUrl}>
                <div className="menu-list__text">View Raw Images</div>
              </a>
            </div>
          )}
          {showPostOpLinkInActionMenu &&
            surgery.surgeryStatus === 'Sent to Patient' &&
            isNotEmpty(postOpLinks) && (
              <div className="menu-list__link">
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openMultiplePostOpLinks(postOpLinks);
                  }}>
                  <div className="menu-list__text">
                    View Post-Op Instructions
                  </div>
                </a>
              </div>
            )}
          {/*  ---- the below code is to be uncommeted when we have post op Protocols ---  */}
          {/* <div className="menu-list__link">
            <Link
              to={{
                pathname: '/edit-surgery',
                search: `surgeryId=${surgery.surgeryId}`
              }}>
              <div className="menu-list__text">View Post-Op Protocols</div>
            </Link>
          </div> */}
          {showDelete && (
            <div className="menu-list__link">
              <button
                onClick={() => {
                  handleDelete(surgery.surgeryId);
                }}>
                <div className="menu-list__text menu-list__text--delete">
                  Delete Surgery
                </div>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
