/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as PictureAsPdfIcon } from '../../../assets/icons/FilePdf.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { ReactElement, useEffect, useState } from 'react';
import { KLPostOpOtherDocs } from '../../../redux/types';
import './PostOpInstructionsOtherDocs.scss';
import KaliberButton from '../../../components/button/KaliberButton';

interface OtherDocsProps {
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    procedure: string,
    region: string
  ) => void;
  handleDelete: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any,
    region: string,
    filename: string,
    customUrl: string
  ) => void;
  otherDocs: KLPostOpOtherDocs[];
  region: string;
}

export default function PostOpInstructionsOtherDocs({
  handleChange,
  handleDelete,
  otherDocs,
  region
}: OtherDocsProps): ReactElement {
  const [uploadedDocuments, setUploadedDocuments] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  }>([]);
  const [initStateOpen, setInitStateOpen] = useState<{ [k: string]: any }>([]);
  const getDefaultFileName = (url: string) => {
    const splitUrl = url.split('/');
    return splitUrl[splitUrl.length - 1];
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: { [k: string]: any } = {};

    if (otherDocs.length > 0) {
      otherDocs.forEach((element: KLPostOpOtherDocs) => {
        const url = element.customUrl;
        obj[`${url}`] = false;
      });
      setInitStateOpen(obj);
      setUploadedDocuments(obj);
    }
  }, [otherDocs]);

  const handleOpen = (e: any, customUrl: string) => {
    setUploadedDocuments({
      ...uploadedDocuments,
      [customUrl]: true
    });
  };

  return (
    <div className="od-outer-container">
      <div className="od-default-container">
        {otherDocs.map(element => {
          return (
            <KaliberButton
              key={getDefaultFileName(element.customUrl)}
              buttonClass="btn--primary"
              type="button">
              <div className="od-icon-container">
                <PictureAsPdfIcon className="od-icon" />
              </div>
              <div className="od-text-container">
                <div className="od-file-text">
                  {getDefaultFileName(element.customUrl)}
                </div>
              </div>
              <div className="od-icon-container">
                {uploadedDocuments[element.customUrl] && (
                  <div>
                    <div
                      className="overlay"
                      onClick={() => setUploadedDocuments(initStateOpen)}></div>
                    <div className="od-pop-over-icon-container">
                      <div className="od-pop-over-icon-container-inner">
                        <div
                          onClick={() =>
                            window.open(element.customUrl, '_blank')
                          }>
                          <VisibilityIcon className="od-icon od-eye-icon" />
                        </div>
                        <div
                          onClick={e =>
                            handleDelete(
                              e,
                              element.region,
                              getDefaultFileName(element.customUrl),
                              element.customUrl
                            )
                          }>
                          <DeleteIcon className="od-icon od-delete-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <MoreVertIcon
                  className="od-icon"
                  onClick={e => handleOpen(e, element.customUrl)}
                />
              </div>
            </KaliberButton>
          );
        })}
      </div>
      <div className="od-custom-container">
        <KaliberButton type="button" buttonClass="btn--primary">
          <div className="od-icon-container od-upload-icon">
            <PictureAsPdfIcon className="od-icon" />
          </div>
          <div className="od-text-container">
            <div className="od-upload-text">
              Click to upload customized PDF.
            </div>
          </div>
          <input
            type="file"
            multiple
            hidden
            onChange={e => {
              handleChange(e, 'other', region);
            }}
            data-testid={'inputSelect'}
          />
        </KaliberButton>
      </div>
    </div>
  );
}
