/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { Controller } from 'react-hook-form';
import { camelCase } from 'camel-case';
import './KLNpiField.scss';

interface KaliberNpiFieldProps {
  placeholder?: string;
  name: string;
  label?: string;
  id?: string;
  required?: boolean;
  errors: any;
  fieldSize?: 'small' | 'medium' | 'large';
  dataTestId?: string;
  disabled?: boolean;
  control: any;
  errorMessage?: string;
  value?: string;
}

const ERROR_MINLENGTH_MESSAGE = 'Please provide a 10 digits NPI';

export default function KaliberNpiField({
  label,
  required,
  errorMessage,
  name,
  placeholder,
  errors,
  disabled = false,
  control,
  value,
  ...options
}: KaliberNpiFieldProps): ReactElement {
  const minLength = 10;
  const [inputValue, setInputValue] = useState(value);

  return (
    <Controller
      name={name}
      rules={{ required, minLength }}
      defaultValue={value}
      control={control}
      render={({ field }) => (
        <div className="kpf-container">
          <div className="kpf-text-container">
            <label className="kpf-label">{label}</label>
          </div>

          <div
            className={`kpf-input-container ${
              errors[camelCase(name)] && 'kpf-input-error-border'
            }`}>
            <MaskedInput
              className={'kpf-input-text'}
              disabled={disabled}
              {...options}
              // Temporarily disabling warning:
              // Fix involves working with 3rd party library.
              // Jira Card: https://kaliberlabs.atlassian.net/browse/ENG-629
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              mask={[
                /[1-9]/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]}
              guide={false}
              onChange={e => {
                field.onChange(e);
                setInputValue(e.target.value);
              }}
              placeholder={placeholder}
              value={inputValue}
            />
          </div>
          {errors[camelCase(name)] &&
            errors[camelCase(name)].type === 'required' && (
              <div className="kpf-input-error">{errorMessage}</div>
            )}
          {errors[camelCase(name)] &&
            errors[camelCase(name)].type === 'minLength' && (
              <div className="kpf-input-error">{ERROR_MINLENGTH_MESSAGE}</div>
            )}
        </div>
      )}
    />
  );
}
