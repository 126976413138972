import CloseIcon from '@mui/icons-material/Close';
import React, { ReactElement, useState } from 'react';
import './KLAlert.scss';

interface KLAlertProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  label?: string;
  visible: boolean;
}
export default function KLAlert({
  children,
  label,
  visible
}: KLAlertProps): ReactElement {
  const [expand, setExpand] = useState<boolean>();
  const [hide, setHide] = useState<boolean>(false);

  const toggleExpand = () => {
    const currentExpand = expand;
    setExpand(!currentExpand);
  };

  const handleClose = () => {
    setHide(true);
  };

  return (
    <>
      {visible && !hide && (
        <div className="alert-pop-up">
          <div className="alert-title" onClick={toggleExpand}>
            {label}
            <span className="alert-icon" onClick={handleClose}>
              <CloseIcon />
            </span>
          </div>
          <div className={expand ? 'show-children' : 'hide-children'}>
            {children}
          </div>
        </div>
      )}
    </>
  );
}
