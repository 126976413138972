/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormRegister,
  useForm
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import KaliberCard from '../../../../components/card/KaliberCard';
import KaliberDateOfBirthPicker from '../../../../components/datepicker/KaliberDateOfBirthPicker';
import EditModal from '../../../../components/modal/EditModal';
import KaliberSelect from '../../../../components/multiselect/KaliberSelect';
import KaliberPhoneField from '../../../../components/phoneField/KaliberPhoneField';
import KaliberTextField from '../../../../components/textField/KaliberTextField';
import { RACE_OPTIONS, STATE_OPTIONS } from '../../../../constants';
import { updatePatient } from '../../../../redux/actions/patientActions';
import { KLState, KLUser, Patient } from '../../../../redux/types';
import { formatPronouns, formatSex } from '../../../../utils/dataOrganization';
import { isMinor } from '../../../../utils/validations';
import { PatientCardProps } from '../patientProfile/PatientProfilePage';
import './PatientDemographics.scss';
import { KaliberButton, OptionalValueDisplay } from '../../../../components';

interface DisplayProps extends PatientCardProps {
  modalOpen: () => void;
  user: KLUser;
}

function Display({ patient, modalOpen, user }: DisplayProps): ReactElement {
  const patientDisplayName = (patient: Patient): string => {
    return patient.preferredName
      ? `${patient.preferredName} (${patient.firstName}) ${patient.lastName}`
      : `${patient.firstName} ${patient.lastName}`;
  };

  return (
    <KaliberCard
      title={patientDisplayName(patient)}
      titleAlignment="left"
      editButton={
        user.role === 'Patient' ||
        user.role === 'Kaliber Admin' ||
        user.role === 'Surgeon' ||
        user.role === 'Surgical Clinical Team' ||
        user.role === 'Surgical Office Staff'
      }
      subTitle={patient.pronouns && formatPronouns(patient.pronouns)}
      onClickEdit={modalOpen}>
      <div className="patient-demographics__container">
        <div className="patient-demographics__info">
          <span className="patient-demographics__label">Date of Birth</span>
          <span className="patient-demographics__text">
            {moment(patient.dob).format('MM/DD/YYYY')}
          </span>
        </div>
        <div className="patient-demographics__info">
          <span className="patient-demographics__label">Sex</span>
          <OptionalValueDisplay value={patient.sex}>
            <span className="patient-demographics__text">
              {formatSex(patient.sex)}
            </span>
          </OptionalValueDisplay>
        </div>
        <div className="patient-demographics__info">
          <span className="patient-demographics__label">Race</span>
          <OptionalValueDisplay value={patient.race}>
            <span className="patient-demographics__text">{patient.race}</span>
          </OptionalValueDisplay>
        </div>
      </div>
    </KaliberCard>
  );
}

interface EditProps extends PatientCardProps {
  editModalVisible: boolean;
  modalClose: (dataChanged?: boolean) => void;
}

function Edit({
  patient,
  editModalVisible,
  modalClose
}: EditProps): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors, isDirty }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>({
    defaultValues: {
      firstName: patient.firstName,
      middleName: patient.middleName,
      lastName: patient.lastName,
      preferredName: patient.preferredName,
      dob: patient.dob,
      sex: formatSex(patient.sex),
      pronouns: formatPronouns(patient.pronouns),
      race: patient.race,
      emergencyContact: patient.emergencyContact ?? {
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zipcode: ''
      }
    }
  });

  const dob = watch('dob');
  const patient_state = watch('state') as string;
  const needAditionalInformation =
    isMinor(moment(dob, 'MM DD YYYY'), patient_state) && patient.dob !== dob;

  useEffect(() => {
    if (needAditionalInformation) {
      setError('dob', {
        type: 'string',
        message:
          'Caution: The Date of Birth changes made will convert this patient from an adult to a minor. Additional information is required.'
      });
    } else {
      clearErrors('dob');
    }
  }, [dob]);

  const onSubmit = (data: any) => {
    setLoading(true);
    if (user.accessToken && patient.id) {
      updatePatient(user.accessToken, patient.id, {
        ...data,
        sex: formatSex(data.sex),
        pronouns: formatPronouns(data.pronouns),
        dob: moment(data.dob).format('yyyy-MM-DD')
      }).then(() => {
        setLoading(false);
        modalClose(true);
      });
    }
  };

  return (
    <EditModal
      visible={editModalVisible}
      title="Edit Patient Information"
      onClose={() => modalClose()}>
      <form
        className="edit-patient-demographics-form"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="edit-patient-demographics-form__name-fields">
          <KaliberTextField
            label="Patient Name"
            name="firstName"
            placeholder="First Name"
            register={register}
            registerObject={{ required: true }}
            errors={errors}
            errorMessage="First name is required"
            tabIndex={0}
          />
          <div className="middle-name">
            <KaliberTextField
              name="middleName"
              placeholder="Middle Name (optional)"
              register={register}
              errors={errors}
              tabIndex={0}
            />
          </div>
          <div className="last-name">
            <KaliberTextField
              name="lastName"
              placeholder="Last Name"
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="Last name is required"
              tabIndex={0}
            />
          </div>
        </div>
        <div className="preferred-name">
          <KaliberTextField
            label="Preferred Name (optional)"
            name="preferredName"
            placeholder="Preferred Name (optional)"
            register={register}
            errors={errors}
            tabIndex={0}
          />
        </div>
        <Controller
          name="dob"
          control={control}
          rules={{
            required: true,
            validate: date => {
              const parsedDate = moment(date).toDate();
              const maxDate = moment().toDate();
              const minDate = moment().subtract(125, 'years').toDate();
              return (
                parsedDate >= minDate &&
                parsedDate <= maxDate &&
                parsedDate.getFullYear().toString().length === 4
              );
            }
          }}
          render={({ field }) => (
            <KaliberDateOfBirthPicker
              editable={true}
              className="datepicker-size"
              name="dob"
              label="Date of Birth"
              errors={errors}
              errorMessage="Date of birth is required"
              value={field.value ?? null}
              onChange={date => field.onChange(date)}
              dataTestId="test-dob"
              maxDate={moment().toDate()}
              minDate={moment().subtract(125, 'years').toDate()}
            />
          )}
        />
        <Controller
          name="sex"
          control={control}
          render={({ field }) => (
            <KaliberSelect
              label="Sex (optional)"
              select={field.value}
              placeholder=""
              errors={errors[field.name]}
              includeOther={false}
              onChange={e => {
                if (e === '') {
                  field.onChange('');
                } else {
                  field.onChange(e);
                }
              }}
              list={['', 'Male', 'Female', 'Prefer not to answer']}
              fullWidth
            />
          )}
        />
        <Controller
          name="pronouns"
          control={control}
          render={({ field }) => (
            <KaliberSelect
              label="Pronouns (optional)"
              placeholder=""
              select={field.value}
              includeOther={false}
              onChange={e => {
                if (e === '') {
                  field.onChange('');
                } else {
                  field.onChange(e);
                }
              }}
              list={['', 'He/Him', 'She/Her', 'They/Them', 'Name']}
              fullWidth
            />
          )}
        />
        <Controller
          name="race"
          control={control}
          render={({ field }) => (
            <KaliberSelect
              label="Race (optional)"
              placeholder=""
              select={field.value}
              includeOther={false}
              onChange={e => {
                if (e === '') {
                  field.onChange(null);
                } else {
                  field.onChange(e);
                }
              }}
              list={RACE_OPTIONS}
              fullWidth
            />
          )}
        />
        {needAditionalInformation && (
          <EmergencyContact
            register={register}
            control={control}
            errors={errors}
          />
        )}
        <div className="edit-modal__buttons">
          <KaliberButton
            type="submit"
            disabled={!isDirty}
            loading={loading}
            buttonClass="btn--primary">
            Save Changes
          </KaliberButton>
          <KaliberButton
            type="button"
            onClick={() => modalClose()}
            buttonClass="btn--cancel">
            Cancel
          </KaliberButton>
        </div>
      </form>
    </EditModal>
  );
}

interface EmergencyContactFormProps {
  register: UseFormRegister<any>;
  control: Control<any, any>;
  errors: FieldErrorsImpl<any>;
}

function EmergencyContact({
  register,
  control,
  errors
}: EmergencyContactFormProps) {
  return (
    <div className="edit-patient-demographics-form--aditional-information">
      <span className="edit-patient-demographics-form--aditional-information__header">
        Parent/Guardian Contact
      </span>
      <div className="edit-patient-demographics-form--aditional-information__divider"></div>
      <KaliberTextField
        label="Parent/Guardian Name"
        name="emergencyContact.name"
        placeholder=""
        register={register}
        errors={errors}
        errorMessage="Parent/Guardian name is required"
        tabIndex={0}
      />
      <KaliberTextField
        label="Address"
        name="emergencyContact.address"
        placeholder=""
        register={register}
        errors={errors}
        errorMessage="Address is required."
      />
      <KaliberTextField
        label="City"
        name="emergencyContact.city"
        placeholder=""
        register={register}
        errors={errors}
        errorMessage="City is required."
      />
      <Controller
        name="emergencyContact.state"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <KaliberSelect
            label="State"
            placeholder=""
            select={field.value}
            errors={errors[field.name]}
            includeOther={false}
            onChange={e => field.onChange(e)}
            list={Object.keys(STATE_OPTIONS)}
            fullWidth
          />
        )}
      />
      <KaliberTextField
        label="Zipcode"
        name="emergencyContact.zipcode"
        placeholder=""
        register={register}
        errors={errors}
        errorMessage="Zipcode is required."
      />
      <KaliberPhoneField
        label="Mobile Phone (recommended)"
        name="emergencyContact.phone"
        placeholder={false}
        errors={errors}
        control={control}
      />
      <KaliberTextField
        label="Email (recommended)"
        placeholder=""
        name="emergencyContact.email"
        register={register}
        errors={errors}
      />
    </div>
  );
}

export default function PatientDemographics({
  patient,
  setReloadSurgery
}: PatientCardProps): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const handleModalOpen = () => {
    setEditModalVisible(true);
  };

  const handleModalClose = (dataChanged = false) => {
    setEditModalVisible(false);

    if (setReloadSurgery && dataChanged) {
      setReloadSurgery(true);
    }
  };

  return (
    <>
      <Display patient={patient} user={user} modalOpen={handleModalOpen} />
      {editModalVisible && (
        <Edit
          patient={patient}
          editModalVisible={editModalVisible}
          modalClose={handleModalClose}
        />
      )}
    </>
  );
}
