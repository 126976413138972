import { ReactElement, useState } from 'react';

import KaliberModal from '../modal/KaliberModal';
import KaliberButton from '..//button/KaliberButton';
import { ReactComponent as PlusCircleGreen } from '../../assets/icons/PlusCircleGreen.svg';
import './AddCustomProcedureComponent.scss';

interface AddCustomProcedureComponentProps {
  onCustomProcedureAdded: (customProcedure: string) => void;
}

export default function AddCustomProcedureComponent({
  onCustomProcedureAdded
}: AddCustomProcedureComponentProps): ReactElement {
  const [showCustomInputModal, setShowCustomInputModal] = useState(false);
  const [customProcedureInput, setCustomProcedureInput] = useState('');

  function handleCancelCustomProcedure() {
    setCustomProcedureInput('');
    setShowCustomInputModal(false);
  }

  function handleAddCustomProcedure() {
    onCustomProcedureAdded(customProcedureInput);
    setCustomProcedureInput('');
    setShowCustomInputModal(false);
  }

  return (
    <>
      <div className="custom-procedure__add-button-wrapper">
        <div
          className="custom-procedure__add-button"
          onClick={() => setShowCustomInputModal(true)}>
          <PlusCircleGreen />
          Add custom entry
        </div>
      </div>
      <div className="custom-procedure__modal-container">
        <KaliberModal
          visible={showCustomInputModal}
          title="Enter custom input"
          titleAlignment="left"
          size="medium"
          closeIcon={true}
          onClose={() => setShowCustomInputModal(false)}>
          <div className="custom-procedure__modal-text">
            Enter custom input. Please note that manual custom entries will not
            be tied to any specific anatomy or stored in the database.
          </div>
          <div className="custom-procedure__modal-input-container">
            <textarea
              className="custom-procedure__modal-input-text"
              value={customProcedureInput}
              onChange={e => setCustomProcedureInput(e.target.value)}
              placeholder={'Enter custom input'}
              rows={1}
            />
          </div>
          <div className="custom-procedure__modal-buttons">
            <KaliberButton
              buttonClass="btn--cancel"
              type="button"
              onClick={handleCancelCustomProcedure}>
              Cancel
            </KaliberButton>
            <KaliberButton
              buttonClass="btn--primary"
              disabled={customProcedureInput == ''}
              type="button"
              onClick={handleAddCustomProcedure}>
              Continue
            </KaliberButton>
          </div>
        </KaliberModal>
      </div>
    </>
  );
}
