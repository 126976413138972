import { ReactElement, useEffect } from 'react';
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';
import {
  KaliberChipMultiSelect,
  KaliberTextArea,
  SegmentedControl
} from '../../../../../../../../components';

const REGIONS_OF_TEAR = ['N/A', 'Medial', 'Lateral'];
const DEFAULT_NA_VALUE = ['N/A'];
const REGIONS_OF_MENISCUS = ['Anterior Third', 'Mid Third', 'Posterior Third'];
import './MeniscectomyComponent.scss';

type RegionUsedComponentProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  controllerName: string;
  label: string;
};

function RegionUsedComponent({
  control,
  controllerName,
  label
}: RegionUsedComponentProps): ReactElement {
  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <>
          <div className="multi-select-container">
            <KaliberChipMultiSelect
              label={label}
              select={field.value}
              onChange={regionOfMeniscus => {
                field.onChange(regionOfMeniscus);
              }}
              list={REGIONS_OF_MENISCUS}
            />
          </div>
        </>
      )}
    />
  );
}

export interface MeniscectomyDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  cptProcedureIndex: number;
  regionOfTear: string;
  cptCode: string;
}

export default function MeniscectomyDetailCard({
  control,
  register,
  setValue,
  cptProcedureIndex,
  regionOfTear,
  cptCode
}: MeniscectomyDetailCardProps): ReactElement {
  const updateRegionOfMeniscusValues = (
    previousSelection: string[],
    newSelection: string[]
  ) => {
    let name;
    if (newSelection[0] === 'Medial') {
      name = `cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfMedialMeniscus`;
    } else {
      name = `cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfLateralMeniscus`;
    }
    setValue(name, []);
  };

  // Manually set the regionOfTear to ['Medial', 'Lateral'] if it is 29880 since we are preventing the user from choosing
  useEffect(() => {
    if (cptCode === '29880') {
      setValue(
        `cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfTear`,
        ['Medial', 'Lateral']
      );
    }
  }, []);

  return (
    <div className="meniscectomy-component-container">
      <input
        type="hidden"
        {...register(`cptProcedures.${cptProcedureIndex}.procedureDetail.id`, {
          setValueAs: v => (v === '' ? null : parseInt(v, 10))
        })}
      />
      <input
        type="hidden"
        {...register(
          `cptProcedures.${cptProcedureIndex}.procedureDetail.type`,
          {
            value: 'ProcedureDetails::MeniscectomyDetail'
          }
        )}
      />

      <div className="meniscectomy-detail-card-container">
        {cptCode === '29881' && (
          <Controller
            name={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfTear`}
            control={control}
            defaultValue={DEFAULT_NA_VALUE}
            render={({ field }) => {
              return (
                <SegmentedControl
                  label="Region of Tear"
                  name="regionOfTearUsed"
                  segments={REGIONS_OF_TEAR}
                  //Select only the first element since field can be an array
                  selected={field.value[0]}
                  onSegmentChange={regionOfTear => {
                    updateRegionOfMeniscusValues(field.value, [regionOfTear]);
                    field.onChange([regionOfTear]);
                  }}
                />
              );
            }}
          />
        )}
        {cptCode === '29880' && (
          <>
            <RegionUsedComponent
              control={control}
              controllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfMedialMeniscus`}
              label={'Region of Medial Meniscus (optional)'}
            />
            <RegionUsedComponent
              control={control}
              controllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfLateralMeniscus`}
              label={'Region of Lateral Meniscus (optional)'}
            />
          </>
        )}
        {cptCode === '29881' && regionOfTear[0] === 'Medial' && (
          <RegionUsedComponent
            control={control}
            controllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfMedialMeniscus`}
            label={'Region of Medial Meniscus (optional)'}
          />
        )}
        {cptCode === '29881' && regionOfTear[0] === 'Lateral' && (
          <RegionUsedComponent
            control={control}
            controllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfLateralMeniscus`}
            label={'Region of Lateral Meniscus (optional)'}
          />
        )}
        <div className="additional-details">
          <KaliberTextArea
            label="Additional Details (optional)"
            name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
            register={register}
            errors={{}}
            rows={3}
            placeholder="Additional notes about size, repair details, tissue quality, anchor count etc"
          />
        </div>
      </div>
    </div>
  );
}
