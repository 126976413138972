import { AnyAction } from 'redux';
import { KLQuickNote } from '../types';

const initialState: KLQuickNote[] = [];

export default function QuickNotesReducer(
  state = initialState,
  action: AnyAction
): KLQuickNote[] {
  switch (action.type) {
    case 'SET_QUICK_NOTES': {
      return action.payload;
    }
    case 'CLEAR_QUICK_NOTES': {
      return [];
    }
    default:
      return state;
  }
}
