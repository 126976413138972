import { ReactElement } from 'react';
import { KaliberButton } from '../../../../../../components';
import './ReviewFooter.scss';

type ReviewFooterProps = {
  isInternalUser: boolean;
  surgeryStatus: string;
  includedImagesCount: number;
  totalImagesCount: number;
  onSendReport: () => void;
  submittingReview: boolean;
};

export default function ReviewFooter({
  isInternalUser,
  surgeryStatus,
  includedImagesCount,
  totalImagesCount,
  onSendReport,
  submittingReview
}: ReviewFooterProps): ReactElement {
  const handleSubmit = () => {
    onSendReport();
  };
  return (
    <div className="review-footer">
      <div className="messages">
        <span className="messages__reminder">
          Please review all images before sending to{' '}
          {isInternalUser && surgeryStatus === 'Internal Review'
            ? 'surgeon'
            : 'patient'}
        </span>
        <span className="messages__include-status">
          {`${includedImagesCount} images included (of ${totalImagesCount} images total)`}
        </span>
      </div>
      <KaliberButton
        buttonClass="btn--primary review-footer__button"
        type="button"
        onClick={handleSubmit}
        loading={submittingReview}>
        {isInternalUser && surgeryStatus === 'Internal Review'
          ? 'Send to surgeon'
          : 'Send report to patient'}
      </KaliberButton>
    </div>
  );
}
