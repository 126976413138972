import { StagePrediction } from '../redux/types';

export function sortPredictions(
  stagePredictions: StagePrediction[]
): StagePrediction[] {
  return stagePredictions.sort((a, b) => {
    if (a.confidenceValue > b.confidenceValue) {
      return -1;
    } else if (a.confidenceValue < b.confidenceValue) {
      return 1;
    } else {
      return 0;
    }
  });
}

// Default value to filter predictions against for regions
// that aren't yet explicitly supported by a model.
const DEFAULT_EVALUATION_CONFIDENCE_THRESHOLD = 100.0;
const SHOULDER_EVALUATION_CONFIDENCE_THRESHOLD = 110.0;

export function evaluationPredictions(
  stagePredictions: StagePrediction[],
  region: string
): StagePrediction[] {
  let confidenceThreshold = DEFAULT_EVALUATION_CONFIDENCE_THRESHOLD;
  if (region === 'shoulder') {
    confidenceThreshold = SHOULDER_EVALUATION_CONFIDENCE_THRESHOLD;
  }

  return stagePredictions.filter(
    stagePrediction =>
      stagePrediction.text !== '__' &&
      stagePrediction.confidenceValue > confidenceThreshold
  );
}

export function postTreatmetPredictions(
  stagePredictions: StagePrediction[]
): StagePrediction[] {
  return stagePredictions.filter(
    stagePrediction => stagePrediction.text !== '__'
  );
}
