import { ReactElement, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useSelector } from 'react-redux';
import {
  AnnotatedData,
  KLReview,
  KLState,
  KLUser
} from '../../../../redux/types';
import './ReportSurgeryImages.scss';
import { normalizeText } from '../../../../utils/textFormat';
import { sortAnnotatedData } from '../../../../utils/annotations';
import ReportPrimalPictures from '../reportPrimalPictures/ReportPrimalPictures';
import { useFlags } from 'launchdarkly-react-client-sdk';

const evaluationDescription =
  'Images categorized as "evaluation" are where we examined your anatomy to gain a more detailed understanding of the treatment to be performed.';
const treatmentDescription =
  'After the evaluation, we moved onto the treatment portion of the procedure, during which we perform activities such as shaving, suturing, and anchoring to address your problem. Images labeled "Treatment" fit into this category.';
const postTreatmentDescription =
  'If your "Evaluation" images represent the "before" of your surgery, the "Post-treatment" images reflect the "after" and show what was accomplished. "Post-treatment" images will often show the surgical materials used in the procedure.';

export default function ReportSurgeryImages(): ReactElement {
  const [filteredAnnotations, setFilteredAnnotations] = useState<
    AnnotatedData[]
  >([]);
  const [sortedImages, setSortedImages] = useState<AnnotatedData[]>([]);
  const [evaluationAnnotations, setEvaluationAnnotations] = useState<
    AnnotatedData[]
  >([]);
  const [treatmentAnnotations, setTreatmentAnnotations] = useState<
    AnnotatedData[]
  >([]);
  const [postTreatmentAnnotations, setPostTreatmentAnnotations] = useState<
    AnnotatedData[]
  >([]);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const [imageData, setImageData] = useState({
    filename: '',
    phase: '',
    quickNote: '',
    surgeonNote: ''
  });
  const { showPrimalPictures } = useFlags();

  const [review, user]: [KLReview, KLUser] = useSelector((state: KLState) => [
    state.surgeries.review,
    state.user
  ]);

  useEffect(() => {
    setFilteredAnnotations(
      review.annotatedData
        .filter(item => item.mediaType == 'image' && item.include)
        .sort(sortAnnotatedData)
    );
  }, [review.annotatedData]);

  useEffect(() => {
    if (filteredAnnotations[0]) {
      sortImages(filteredAnnotations);
    }
  }, [filteredAnnotations]);
  useEffect(() => {
    if (sortedImages[0]) {
      setImageData({
        filename: sortedImages[0].filename,
        phase: sortedImages[0].mediaLabels.surgeryStage,
        quickNote: sortedImages[0].mediaLabels.surgeryNotes.split('\n')[0],
        surgeonNote:
          sortedImages[0].mediaLabels.surgeryNotes.split('\n')[1] || ''
      });
    }
  }, [sortedImages]);

  const surgeryRegion = review.surgeryInfo.region;

  const sortImages = (filteredAnnotations: AnnotatedData[]) => {
    const evaluation = filteredAnnotations.filter(
      item => item.mediaLabels.surgeryStage === 'evaluation'
    );
    setEvaluationAnnotations(evaluation);
    const treatment = filteredAnnotations.filter(
      item => item.mediaLabels.surgeryStage === 'treatment'
    );
    setTreatmentAnnotations(treatment);
    const postTreatment = filteredAnnotations.filter(
      item => item.mediaLabels.surgeryStage === 'post-treatment'
    );
    setPostTreatmentAnnotations(postTreatment);
    setSortedImages([...evaluation, ...treatment, ...postTreatment]);
  };

  const getImagesLength = (phase: string) => {
    return filteredAnnotations.filter(
      item => item.mediaLabels.surgeryStage === phase
    ).length;
  };
  const getIndexOfImage = (imageName: string, phase: string) => {
    if (phase === 'evaluation') {
      return (
        evaluationAnnotations.findIndex(item => item.filename == imageName) + 1
      );
    } else if (phase === 'treatment') {
      return (
        treatmentAnnotations.findIndex(item => item.filename == imageName) + 1
      );
    } else if (phase === 'post-treatment') {
      return (
        postTreatmentAnnotations.findIndex(item => item.filename == imageName) +
        1
      );
    }
  };

  const changeSurgeryPhase = (surgeryStage: string) => {
    const imgFile = sortedImages.find(
      img => img.mediaLabels.surgeryStage === surgeryStage
    );

    if (imgFile) {
      const imgFileIdx = sortedImages.indexOf(imgFile);
      const filename = imgFile.filename;

      const surgeryNotes = imgFile.mediaLabels.surgeryNotes.split('\n');
      setImageData({
        filename,
        phase: surgeryStage,
        quickNote: surgeryNotes[0],
        surgeonNote: surgeryNotes[1] || ''
      });
      setCarouselIndex(imgFileIdx);
    } else {
      alert('this surgery state does not exist yet!');
    }
  };

  const performedCPTSurgeries =
    review?.surgeryInfo?.performedProcedures?.cptProcedures?.map(
      procedure => procedure.cptCode.code
    );

  return (
    <div className="surgery-images-container">
      <div className="images-title">
        {user.role !== 'Patient' && <h3>Images from this surgery</h3>}
        {user.role === 'Patient' && <h3>Images from your surgery</h3>}
      </div>
      <div className="stage-title">
        <h5 id="fs-mask">
          <span className="grey-font surgery-phase-header">
            Image category{' '}
          </span>
          <span className="grey-font">| </span>
          {'  '}
          {normalizeText(imageData.phase)}
        </h5>
      </div>
      <div className="block-group">
        <button
          className={`block-divider ${
            imageData.phase === 'evaluation' && 'current-block'
          }`}
          onClick={() => changeSurgeryPhase('evaluation')}
        />
        <button
          className={`block-divider ${
            imageData.phase === 'treatment' && 'current-block'
          }`}
          onClick={() => changeSurgeryPhase('treatment')}
        />
        <button
          className={`block-divider ${
            imageData.phase === 'post-treatment' && 'current-block'
          }`}
          onClick={() => changeSurgeryPhase('post-treatment')}
        />
      </div>
      <h5 id="fs-mask">
        {imageData.phase === 'evaluation'
          ? evaluationDescription
          : imageData.phase === 'treatment'
          ? treatmentDescription
          : postTreatmentDescription}
      </h5>
      <div className="images-container">
        <div className="images-carousel">
          <Carousel
            indicatorIconButtonProps={{
              style: {
                pointerEvents: 'none'
              }
            }}
            index={carouselIndex}
            navButtonsAlwaysVisible={true}
            autoPlay={false}
            className="carousel"
            next={(next: number) => {
              setImageData({
                filename: sortedImages[next].filename,
                phase: sortedImages[next].mediaLabels.surgeryStage,
                quickNote:
                  sortedImages[next].mediaLabels.surgeryNotes.split('\n')[0],
                surgeonNote:
                  sortedImages[next].mediaLabels.surgeryNotes.split('\n')[1] ||
                  ''
              });
              setCarouselIndex(next);
            }}
            prev={(prev: number) => {
              setImageData({
                filename: sortedImages[prev].filename,
                phase: sortedImages[prev].mediaLabels.surgeryStage,
                quickNote:
                  sortedImages[prev].mediaLabels.surgeryNotes.split('\n')[0],
                surgeonNote:
                  sortedImages[prev].mediaLabels.surgeryNotes.split('\n')[1] ||
                  ''
              });
              setCarouselIndex(prev);
            }}>
            {sortedImages.map(item => {
              return (
                <img
                  id="fs-exclude"
                  title={item.filename}
                  src={item.mediaUrl}
                  key={item.filename}
                  className="carousel-image"
                />
              );
            })}
          </Carousel>
          <h6 className="grey-font center" id="fs-mask">
            {normalizeText(imageData.phase)}{' '}
            {getIndexOfImage(imageData.filename, imageData.phase)}/
            {getImagesLength(imageData.phase)}
          </h6>
          <div className="image-body-text" id="fs-mask">
            <h5 className="center">
              {imageData.quickNote !== '' ? (
                <>
                  {imageData.quickNote} <br />
                </>
              ) : null}
              {imageData.surgeonNote !== '' ? (
                <>{imageData.surgeonNote}</>
              ) : null}
            </h5>
          </div>
        </div>
      </div>
      {showPrimalPictures && performedCPTSurgeries && (
        <ReportPrimalPictures
          surgeryRegion={surgeryRegion}
          surgeryPerformedProcedures={performedCPTSurgeries}
        />
      )}
    </div>
  );
}
