import React, { ReactElement } from 'react';
import './KaliberIconButton.scss';

interface KaliberIconButtonProps {
  onClick?: (val: React.SyntheticEvent) => void;
  icon: string;
}

export default function KaliberIconButton({
  onClick,
  icon
}: KaliberIconButtonProps): ReactElement {
  return (
    <div>
      <button className="icon-button" onClick={onClick} type="button">
        <img src={icon}></img>
      </button>
    </div>
  );
}
