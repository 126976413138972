import { Box, Modal } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import LinearProgress from '@mui/material/LinearProgress';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AuthorizationError, BadRequestError, FatalError } from '../../api';
import KaliberButton from '../../components/button/KaliberButton';
import KLUploader from '../../components/uploader/KLUploader';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import {
  cancelUpload,
  getReviewDataById,
  getSurgeryDataById,
  setUploadError,
  toggleShowConfirmation,
  uploadFiles
} from '../../redux/actions/surgeriesActions';
import { setTotalUpload } from '../../redux/actions/uiActions';
import { KLState, KLUi, KLUpload, KLUser } from '../../redux/types';
import './Uploader.scss';

type SurgeryUrlParms = {
  id: string;
};

export default function Uploader(): ReactElement {
  setFullstoryPage('Upload Page');
  const surgeryId = parseInt(useParams<SurgeryUrlParms>().id, 10);
  const [user, upload, ui]: [KLUser, KLUpload, KLUi] = useSelector(
    (state: KLState) => [state.user, state.surgeries.upload, state.ui]
  );
  const {
    showConfirmation,
    patientName,
    dob,
    surgeryDescription,
    surgeryDate,
    currentUploadFileIdx,
    numFilesToUpload,
    uploadError
  } = upload;

  const { accessToken } = user;
  const [files, setFiles] = useState<File[]>([]);
  const [hasValidFiles, setHasValidFiles] = useState(false);
  const [totalProgress, setTotalProgress] = useState(0);
  const [currentFile, setCurrentFile] = useState(0);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    if (user.accessToken) {
      Promise.all([
        getSurgeryDataById(user.accessToken, surgeryId),
        getReviewDataById(user.accessToken, surgeryId)
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [accessToken, surgeryId]);

  useEffect(() => {
    if (showConfirmation) {
      handleCloseProgressModal();
      handleOpenCompleteOrErrorModal();
    } else {
      setOpenConfirmationModal(false);
    }
  }, [upload]);

  useEffect(() => {
    if (uploadError instanceof FatalError) {
      // eslint-disable-next-line no-console
      console.error(`Fatal upload error ${uploadError.message}`);
    } else if (uploadError instanceof BadRequestError) {
      // eslint-disable-next-line no-console
      console.error(`Bad request error ${uploadError.message}`);
    } else if (uploadError instanceof AuthorizationError) {
      // eslint-disable-next-line no-console
      console.error(`Unauthorized error ${uploadError.message}`);
    } else if (uploadError instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(`generic error ${uploadError.message}`);
    }
  }, [uploadError]);

  const isValidVideoFormat = (filename: string) =>
    filename.endsWith('.mp4') ||
    filename.endsWith('.mpeg') ||
    filename.endsWith('.mpg');

  const isValidImageFormat = (filename: string) =>
    filename.endsWith('.jpeg') ||
    filename.endsWith('.jpg') ||
    filename.endsWith('.png') ||
    filename.endsWith('.bmp') ||
    filename.endsWith('.tga') ||
    filename.endsWith('.tiff');

  useEffect(() => {
    setHasValidFiles(false);

    const validFilesCount = files.reduce((validFilesCount, file) => {
      if (isValidImageFormat(file.name) || isValidVideoFormat(file.name)) {
        return validFilesCount + 1;
      } else {
        return validFilesCount;
      }
    }, 0);

    if (validFilesCount === files.length) {
      setHasValidFiles(true);
    }
  }, [files]);

  function totalSizeFiles() {
    let totalSize = 0;

    for (const file of files) {
      totalSize += file.size;
    }
    setTotalUpload(totalSize / 1e6);
  }

  useEffect(() => {
    if (currentFile !== currentUploadFileIdx && files.length > 0) {
      setTotalProgress(
        totalProgress + files[currentUploadFileIdx - 1].size / 1e6
      );
      setCurrentFile(currentUploadFileIdx);
    }
  }, [currentUploadFileIdx]);

  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  const [openProgressModal, setOpenProgressModal] = React.useState(false);
  const handleOpenProgressModal = () => setOpenProgressModal(true);
  const handleCloseProgressModal = () => setOpenProgressModal(false);
  const [loading, setLoading] = useState(true);
  const [openUploadCompleteModal, setOpenUploadCompleteModal] =
    React.useState(false);
  const [openUploadErrorModal, setOpenUploadErrorModal] = React.useState(false);
  const handleOpenCompleteOrErrorModal = () => {
    if (uploadError) {
      setOpenUploadErrorModal(true);
    } else {
      setOpenUploadCompleteModal(true);
    }
  };
  const handleCloseErrorModal = () => {
    setFiles([]);
    toggleShowConfirmation(false);
    setOpenUploadErrorModal(false);
    setUploadError(null);
  };

  const handleReturnToSurgeryList = () => {
    toggleShowConfirmation(false);
    history.push('/surgeries');
  };
  const handleReturnToPatientReport = () => {
    toggleShowConfirmation(false);
    history.push(`/surgeries/${surgeryId}/patient-report`);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const currFiles: File[] = Object.values(e.target.files);

      setFiles([...files, ...currFiles]);
    }
  };

  const handleRemove = (name: string) => {
    const newFiles: File[] = files.filter(file => file.name !== name);
    setFiles(newFiles);
  };

  const confirmationModal = (
    <Modal open={openConfirmationModal}>
      <Box className="confirmation-modal">
        <h6 className="modal-title">Confirm Patient Information</h6>
        <div className="body1 modal-body-text">
          The following files you selected will be uploaded and attached to the
          surgery of the following patient:
        </div>
        <div className="details-container">
          <div className="body1 details-container-patient-name" id="fs-mask">
            {patientName}
          </div>
          <div className="body1" id="fs-mask">
            DOB: {dob}
          </div>
          <div className="body1" id="fs-mask">
            {surgeryDescription}
          </div>
          <div className="body1" id="fs-mask">
            Surgery Date: {surgeryDate}
          </div>
        </div>
        <div className="modal-button-container">
          <KaliberButton
            buttonClass="btn--cancel"
            type="button"
            onClick={handleCloseConfirmationModal}>
            Cancel
          </KaliberButton>
          <KaliberButton
            buttonClass="btn--primary"
            type="button"
            onClick={() => {
              if (surgeryId && accessToken) {
                handleCloseConfirmationModal();
                handleOpenProgressModal();
                totalSizeFiles();
                uploadFiles(accessToken, surgeryId, files);
              }
            }}
            dataTestId="continueUploaderButton">
            Continue
          </KaliberButton>
        </div>
      </Box>
    </Modal>
  );

  const progressModal = (
    <Modal open={openProgressModal} data-testid="uploader-progress-modal">
      <Box className="progress-modal">
        <div className="caption modal-title">
          Upload manager -{' '}
          {(totalProgress + ui.progressUpload.loaded).toFixed(3)} bytes from{' '}
          {ui.progressUpload.total.toFixed(3)} bytes
        </div>
        <div className="body1 modal-body-text" id="fs-mask">
          {currentUploadFileIdx} of {numFilesToUpload} images uploaded
        </div>
        <div className="details-container">
          <LinearProgress
            variant="determinate"
            value={
              ((totalProgress + ui.progressUpload.loaded) * 100) /
              ui.progressUpload.total
            }
          />
        </div>
        <div className="modal-button-container">
          <KaliberButton
            type="button"
            buttonClass="btn--primary"
            onClick={() => {
              cancelUpload();
              handleCloseProgressModal();
            }}>
            Cancel upload
          </KaliberButton>
        </div>
      </Box>
    </Modal>
  );

  const uploadCompleteModal = (
    <Modal open={openUploadCompleteModal}>
      <Box className="upload-complete-modal">
        <h6 className="modal-title">Upload Complete</h6>
        <div className="body1 modal-body-text">
          All files have been uploaded. They are now being processed and will
          appear in the patient’s Rekap report.
        </div>
        <div className="upload-complete-modal-button-container">
          <KaliberButton
            buttonClass="btn--primary"
            type="button"
            onClick={handleReturnToSurgeryList}>
            OK
          </KaliberButton>
        </div>
      </Box>
    </Modal>
  );

  const uploadErrorModal = (
    <Modal open={openUploadErrorModal}>
      <Box
        className="upload-error-modal"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <WarningIcon className="MuiSvgIcon-fontSizeLarge" color="error" />
        <h6 className="modal-title">Upload Error</h6>
        <div className="modal-body-text">
          <div className="body1 center">
            Files failed to upload - go back or retry
          </div>
        </div>
        <div className="upload-error-modal-button-container">
          <KaliberButton
            type="button"
            buttonClass="btn--primary"
            onClick={handleReturnToPatientReport}>
            Back
          </KaliberButton>
          <KaliberButton
            buttonClass="btn--primary"
            type="button"
            onClick={handleCloseErrorModal}>
            Retry
          </KaliberButton>
        </div>
      </Box>
    </Modal>
  );

  return (
    <div>
      <div className="page-container">
        {loading ? (
          <div className="uploader__container">
            <div className="uploader__skeleton uploader__skeleton--icon" />
            <div className="uploader__button-container">
              <div className="uploader__skeleton uploader__skeleton--button" />{' '}
              <div className="uploader__skeleton uploader__skeleton--button" />
            </div>
          </div>
        ) : (
          <KLUploader
            files={files}
            handleChange={handleChange}
            handleOpenConfirmationModal={handleOpenConfirmationModal}
            handleRemove={handleRemove}
            patientName={patientName}
            setFiles={setFiles}
            hasValidFiles={hasValidFiles}
          />
        )}
        <div>{confirmationModal}</div>
        <div>{progressModal}</div>
        <div>{uploadCompleteModal}</div>
        <div>{uploadErrorModal}</div>
      </div>
    </div>
  );
}
