import camelcaseKeys from 'camelcase-keys';
import { callStratusApiV2, RequestMethod } from '../../api';
import { setLoading } from './uiActions';

interface CheckoutSession {
  url: string;
}

export async function purchaseAdvancedReport(
  accessToken: string,
  surgeryId: number
): Promise<void> {
  setLoading(true, 'Redirecting to checkout page...');

  const res = camelcaseKeys(
    await callStratusApiV2<{ checkout_session: CheckoutSession }>(
      `payments/checkout?surgery_id=${surgeryId}`,
      {
        accessToken,
        method: RequestMethod.Get,
        headers: { 'Content-Type': 'application/json' }
      }
    )
  );

  window.location.replace(res.checkoutSession.url);

  return;
}
