import { AnyAction } from 'redux';
import { KLSurgeon } from '../types';
import camelcaseKeys from 'camelcase-keys';

export default function SurgeonsReducer(
  state = [],
  action: AnyAction
): KLSurgeon[] {
  switch (action.type) {
    case 'SET_SURGEONS': {
      return action.payload.map((surgeon: KLSurgeon) =>
        camelcaseKeys(surgeon, { deep: true })
      );
    }
    default:
      return state;
  }
}
