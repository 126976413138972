import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../utils/react';
import KaliberButton from '../../components/button/KaliberButton';
import { toggleNavbarStatus } from '../../redux/actions/surgeriesActions';
import './PaymentSuccessPage.scss';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';

export default function PaymentSuccessPage(): ReactElement {
  const history = useHistory();
  const query = useQuery();

  const [surgeryId, setSurgeryId] = useState<number | null>(null);
  useEffect(() => {
    setFullstoryPage('Payment Success Page');
  }, []);
  useEffect(() => {
    const queryParam = query.get('surgeryId');
    if (queryParam && queryParam !== 'undefined') {
      setSurgeryId(parseInt(queryParam, 10));
    }
  }, [query]);

  return (
    <div className="payment-success">
      <div className="payment-success__verbiage">
        <div className="payment-success__title">
          Thank you for purchasing the Rekap Advanced Report
        </div>
        <div className="payment-success__message">
          You will receive an email shortly confirming your purchase. Head over
          to the report page to view your report!
        </div>
      </div>
      <div className="payment-success__report-button">
        <KaliberButton
          type="button"
          buttonClass="btn--primary"
          disabled={surgeryId === null}
          onClick={() => {
            toggleNavbarStatus('report');
            history.push(`/surgeries/${surgeryId}/patient-report`);
          }}>
          View Report
        </KaliberButton>
      </div>
    </div>
  );
}
