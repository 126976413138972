import React, { ReactElement } from 'react';
import MagnifyingGlass from '../../../../assets/icons/MagnifyingGlass.svg';
import './Searchbar.scss';

interface SearchbarProps {
  onChange?: (val: React.SyntheticEvent) => void;
  className?: string;
  disabled?: boolean;
  value?: string;
}

export default function Searchbar({
  onChange,
  value,
  ...options
}: SearchbarProps): ReactElement {
  return (
    <div className="searchbar">
      <input
        className="searchbar__input"
        autoComplete="off"
        type="search"
        value={value}
        placeholder="Search patient name"
        onChange={onChange}
        {...options}
      />
      <div className="searchbar__icon">
        <img src={MagnifyingGlass} />
      </div>
    </div>
  );
}
