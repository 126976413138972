/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import React, { ReactElement } from 'react';
import KLTextField from '../textField/KLTextField';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../assets/icons/CalendarBlank.svg';
import './KLDateOfBirthPicker.scss';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#44546a',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '1rem',
    letterSpacing: '0.25px',
    fontSize: '14px',
    margin: '0 0 0.25rem 0'
  },
  optional: {
    fontSize: 9,
    marginLeft: 10,
    fontWeight: 500
  },
  noBorder: {
    border: 'none'
  },
  fixPadding: {
    padding: '10px 0'
  },
  disabledColor: {
    color: theme.palette.text.primary
  }
}));
export interface KLDateOfBirthPickerProps {
  value: Date | null;
  label?: string;
  name?: string;
  className?: string;
  editable: boolean;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  dataTestId?: string;
  errors?: any;
  errorMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (val: any) => void;
}
const ERROR_INVALID_DATE_MESSAGE = 'Please input a valid date';

export default function KLDateOfBirthPicker(
  props: KLDateOfBirthPickerProps
): ReactElement {
  const { onChange, label, className } = props;
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateChange = (newValue: Date | null) => {
    if (
      props.name !== 'dob' &&
      newValue?.getFullYear().toString().length === 2
    ) {
      const today = new Date();
      const yearFrontTwoDigits = today.getFullYear().toString().substring(0, 2);
      newValue.setFullYear(
        Number(`${yearFrontTwoDigits}${newValue.getFullYear()}`)
      );
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={`kl-datepicker ${className}`}>
      <div className={classes.titleContainer}>
        <div className="caption">{label}</div>
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {dateComponent(props, 'kl-dob-textfield', handleDateChange)}
      </LocalizationProvider>
      {hasRequiredError(props) && (
        <div className="kl-dob-input-error">{props.errorMessage}</div>
      )}
      {hasValidationError(props) && (
        <div className="kl-dob-input-error">{ERROR_INVALID_DATE_MESSAGE}</div>
      )}
    </div>
  );
}

function dateComponent(
  props: KLDateOfBirthPickerProps,
  textFieldClass: string,
  changeHandler: (newValue: Date | null) => void
): ReactElement {
  if (props.editable) {
    return editableComponent(props, textFieldClass, changeHandler);
  } else {
    return uneditableComponent(props.value, props.dataTestId);
  }
}

function editableComponent(
  props: KLDateOfBirthPickerProps,
  textFieldClass: string,
  onChange: (newValue: Date | null) => void
): ReactElement {
  return (
    <div>
      <DatePicker
        inputFormat="MM/dd/yyyy"
        minDate={props.minDate}
        maxDate={props.maxDate}
        onChange={onChange}
        className={'kl-dob-error-border'}
        value={props.value}
        components={{
          OpenPickerIcon: CalendarIcon
        }}
        renderInput={(params: any) => {
          params.inputProps = {
            ...params.inputProps,
            'data-testid': props.dataTestId
              ? props.dataTestId
              : 'input-date-test',
            autoComplete: 'bday'
          };
          return (
            <TextField
              id="fs-mask"
              onChange={event => {
                onChange(new Date(event.target.value));
              }}
              className={`${textFieldClass} ${
                hasError(props) && 'kl-dob-error-border'
              }`}
              {...params}
            />
          );
        }}
      />
    </div>
  );
}

function uneditableComponent(
  value: Date | null,
  dataTestId?: string
): ReactElement {
  return (
    <KLTextField
      id="fs-mask"
      editable={false}
      dataTestId={dataTestId ? dataTestId : 'input-date-test'}
      value={
        value !== null && isValidDate(value)
          ? moment(value).format('MM/DD/YYYY')
          : ''
      }
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.getTime());
}

function hasError(props: KLDateOfBirthPickerProps) {
  return props.name && props?.errors?.[props.name];
}

function hasRequiredError(props: KLDateOfBirthPickerProps) {
  return props.name && props?.errors?.[props.name]?.type === 'required';
}

function hasValidationError(props: KLDateOfBirthPickerProps) {
  return props.name && props?.errors?.[props.name]?.type === 'validate';
}
