import React, { ReactElement } from 'react';
import './KLCustomTabs.scss';

interface Props {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  selected?: boolean;
}

export default function TabTitle({
  title,
  index,
  setSelectedTab,
  selected = false
}: Props): ReactElement {
  return (
    <li>
      <button
        onClick={() => setSelectedTab(index)}
        className={selected ? 'selected' : ''}>
        {title}
      </button>
    </li>
  );
}
