import { ReactElement, useState } from 'react';
import { capitalizeFirstLetter } from '../../../../../../utils/textFormat';
import { KLSurgery, SurgeryProcedures } from '../../../../../../redux/types';
import { KaliberTag, KaliberTextButton } from '../../../../../../components';
import './GroupedProcedures.scss';
interface ProcedureDetail {
  region: string;
  procedures: string[];
  showMore: boolean;
}
/**
 * The function "procedureDetail" takes a surgery object as input and returns an array of
 * ProcedureDetail objects based on the performed or planned procedures in the surgery object.
 * @param {KLSurgery} surgery - The parameter `surgery` is of type `KLSurgery`.
 * @returns an array of ProcedureDetail objects.
 */
function procedureDetail(surgery: KLSurgery): ProcedureDetail[] {
  if (
    surgery.performedProcedures.cptProcedures.length > 0 ||
    surgery.performedProcedures.customProcedures.length > 0
  ) {
    return mapSurgeryProcedureToProcedureDetail(surgery.performedProcedures);
  } else if (
    surgery.plannedProcedures.cptProcedures.length > 0 ||
    surgery.plannedProcedures.customProcedures.length > 0
  ) {
    return mapSurgeryProcedureToProcedureDetail(surgery.plannedProcedures);
  } else {
    return [
      {
        region: `${capitalizeFirstLetter(surgery.side)} ${capitalizeFirstLetter(
          surgery.region
        )}`,
        procedures: surgery.surgeryType,
        showMore: false
      }
    ];
  }
}

/**
 * The function maps surgery procedures to procedure details by extracting relevant information from
 * CPT procedures and custom procedures.
 * @param {SurgeryProcedures} surgeryProcedures - The `surgeryProcedures` parameter is an object that
 * contains two properties: `cptProcedures` and `customProcedures`.
 * @returns an array of ProcedureDetail objects.
 */
function mapSurgeryProcedureToProcedureDetail(
  surgeryProcedures: SurgeryProcedures
): ProcedureDetail[] {
  const procedures: ProcedureDetail[] = [];

  surgeryProcedures.cptProcedures.forEach(cptProcedure => {
    insertProcedure(
      procedures,
      cptProcedure.cptCode.anatomy ?? '',
      cptProcedure.cptCode.commonName ?? ''
    );
  });

  surgeryProcedures.customProcedures.forEach(customProcedure => {
    insertProcedure(
      procedures,
      'custom',
      customProcedure.customProcedure.procedure
    );
  });

  return procedures;
}

/**
 * The function inserts a procedure name into an array of procedure details based on the specified
 * region.
 * @param {ProcedureDetail[]} procedures - An array of objects representing procedure details. Each
 * object has the following properties:
 * @param {string} region - A string representing the region where the procedure will be inserted.
 * @param {string} name - The `name` parameter is a string that represents the name of a procedure to
 * be inserted into the `procedures` array.
 */
function insertProcedure(
  procedures: ProcedureDetail[],
  region: string,
  name: string
) {
  const index = procedures.findIndex(e => e.region.toLowerCase() === region);
  if (index !== -1) {
    procedures[index].procedures.push(name);
  } else {
    procedures.push({
      region: capitalizeFirstLetter(region),
      procedures: [name],
      showMore: false
    });
  }
}

export default function GroupedProcedures(surgery: KLSurgery): ReactElement {
  const procedures = procedureDetail(surgery);
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="grouped-procedures">
      {procedures.map((item, index) => {
        if (index <= 1 || showMore) {
          return (
            <span key={index}>
              <div className="procedure-detail">
                <div className="procedure-detail__region-label">
                  {item.region}
                </div>
                <div className="procedure-tag-container">
                  {item.procedures.map((procedure, index) => {
                    return (
                      <KaliberTag key={`${procedure}-${index}`}>
                        {procedure}
                      </KaliberTag>
                    );
                  })}
                </div>
              </div>
            </span>
          );
        } else {
          return <></>;
        }
      })}
      {procedures.length > 2 && (
        <KaliberTextButton onClick={() => setShowMore(!showMore)}>
          {showMore ? 'show less' : 'show more'}
        </KaliberTextButton>
      )}
    </div>
  );
}
