import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { KLFacilityFormConfirmationModal } from '../../components/facilityForm/KLFacilityFormConfirmationModal';
import { KLFacility, KLState, KLUser } from '../../redux/types';
import KLModal from '../../components/modal/KLModal';
import { createNewFacility } from '../../redux/actions/facilitiesActions';
import { BadRequestError, UnprocessableEntityError } from '../../api';
import { FACILITY_OPTIONS } from '../../constants';
import './AddFacilityPage.scss';
import KaliberNpiField from '../../components/npiField/KaliberNpiField';
import KaliberCard from '../../components/card/KaliberCard';
import KaliberButton from '../../components/button/KaliberButton';
import KaliberTextField from '../../components/textField/KaliberTextField';
import { Controller, useForm } from 'react-hook-form';
import KaliberSelect from '../../components/multiselect/KaliberSelect';
import KaliberPhoneField from '../../components/phoneField/KaliberPhoneField';
import PageHeader from '../../components/pageHeader/PageHeader';

export default function AddFacilityPage(): ReactElement {
  const history = useHistory();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>();

  const user: KLUser = useSelector((state: KLState) => state.user);

  const [newFacilityInfo, setNewFacilityInfo] = useState<KLFacility>({
    facilityName: '',
    facilityType: '',
    facilityAddress: '',
    facilityPhone: '',
    facilityFax: '',
    facilityEmail: '',
    facilityAfterHoursPhone: '',
    facilityWebsite: '',
    facilityNpi: ''
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openSaveCompletedModal, setOpenSaveCompletedModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState(
    'An error occured, please check your data and try again'
  );
  const [loading, setLoading] = useState(false);

  /* --------------------------- handle fields end ---------------------------------- */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (data: KLFacility) => {
    setNewFacilityInfo(data);
    setOpenConfirmationModal(true);
  };

  const submitForm = async () => {
    setLoading(true);
    try {
      if (user.accessToken) {
        await createNewFacility(user.accessToken, newFacilityInfo);
        setOpenSaveCompletedModal(true);
        setLoading(false);
      }
    } catch (error) {
      if (
        error instanceof BadRequestError &&
        error.message === 'email is already in use'
      ) {
        setErrorModalMessage(
          'The facility email is already in use, please try another one'
        );
      } else if (
        error instanceof UnprocessableEntityError &&
        error.message.includes('duplicate key value violates unique constraint')
      ) {
        setErrorModalMessage(
          'The NPI is already in use, please try another one'
        );
      } else {
        setErrorModalMessage(
          'An error occured, please check your data and try again'
        );
      }

      setOpenErrorModal(true);
      setLoading(false);
    }
  };

  return (
    <div className="add-facility-container" data-testid="add-surgery-div">
      <PageHeader title="Add facility" pageToRedirect="/facilities" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <KaliberCard title="Facility information" titleAlignment="left">
          <div className="kaliber-card-content">
            <KaliberTextField
              label="Facility Name"
              name="facilityName"
              placeholder="Facility Name"
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="Facility name is required"
              tabIndex={0}
            />
            <Controller
              name="facilityType"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <KaliberSelect
                  label="Facility type*"
                  placeholder=""
                  errors={errors}
                  includeOther={false}
                  onChange={e => field.onChange(e)}
                  list={FACILITY_OPTIONS}
                />
              )}
            />
            <KaliberTextField
              label="Facility address"
              name="facilityAddress"
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="Facility address is required"
              tabIndex={0}
            />
            <KaliberNpiField
              required
              placeholder=""
              label="NPI*"
              name="facilityNpi"
              errorMessage="Please provide an NPI"
              errors={errors}
              control={control}
            />
            <KaliberPhoneField
              label="Office phone*"
              name="facilityPhone"
              required
              errors={errors}
              placeholder={false}
              errorMessage="Office phone is required"
              control={control}
            />
            <KaliberTextField
              label="Fax"
              name="facilityFax"
              register={register}
              errors={errors}
              tabIndex={0}
            />
            <KaliberTextField
              label="E-Mail*"
              name="facilityEmail"
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="Email is required"
            />
            <KaliberPhoneField
              label="After hours phone*"
              name="facilityAfterHoursPhone"
              required
              errors={errors}
              placeholder={false}
              errorMessage="After hours phone is required"
              control={control}
            />
            <KaliberTextField
              label="Website*"
              name="facilityWebsite"
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="Website is required"
            />
          </div>
        </KaliberCard>

        <div className="button-container">
          <div className="single-button">
            <KaliberButton
              type="submit"
              buttonClass="btn--primary"
              loading={loading}>
              Add facility
            </KaliberButton>
          </div>
        </div>
      </form>
      <KLFacilityFormConfirmationModal
        visible={openConfirmationModal}
        newFacility={newFacilityInfo}
        onConfirmHandler={() => {
          submitForm();
        }}
        onCloseHandler={() => {
          setOpenConfirmationModal(false);
        }}
      />
      <KLModal
        message="Facility saved correctly"
        visible={openSaveCompletedModal}
        type="info"
        onCloseHandler={() => {
          history.push('/facilities');
        }}
      />
      <KLModal
        message={errorModalMessage}
        visible={openErrorModal}
        type="error"
        onCloseHandler={() => {
          setOpenErrorModal(false);
        }}
      />
    </div>
  );
}
