import { ReactElement } from 'react';

import KaliberCard from '../../../../components/card/KaliberCard';
import { NewSurgeryData } from '../../../../redux/types';
import '../../AddSurgeryPage.scss';
import { OptionalValueDisplay } from '../../../../components';

interface GuardianContactDisplayComponentProps {
  newSurgeryData: NewSurgeryData;
}

export default function GuardianContactDisplayComponent({
  newSurgeryData
}: GuardianContactDisplayComponentProps): ReactElement {
  return (
    <KaliberCard title="Parent/Guardian Contact" titleAlignment="left">
      <div className="ascp-info-section-container">
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Parent/Guardian</div>
            <OptionalValueDisplay value={newSurgeryData.emergencyContactName}>
              <div className="ascp-info-value">
                {newSurgeryData.emergencyContactName}
              </div>
            </OptionalValueDisplay>
          </div>
        </div>
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Mobile Phone</div>
            <OptionalValueDisplay value={newSurgeryData.emergencyContactPhone}>
              <div className="ascp-info-value">
                {newSurgeryData.emergencyContactPhone}
              </div>
            </OptionalValueDisplay>
          </div>
          <div className="ascp-info-card">
            <div className="ascp-info-label">Email</div>

            <OptionalValueDisplay value={newSurgeryData.emergencyContactEmail}>
              <div className="ascp-info-value">
                {newSurgeryData.emergencyContactEmail}
              </div>
            </OptionalValueDisplay>
          </div>
        </div>
      </div>
    </KaliberCard>
  );
}
