import './KLDatepicker.scss';
import React, { ReactElement, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { setSurgeryFilters } from '../../redux/actions/uiActions';
import { KLState, KLUi } from '../../redux/types';
import { useSelector } from 'react-redux';

interface KLDatePickerProps {
  labelName: string;
  setFilter: (columnId: string, filterValue: string) => void;
}

const KLDatePicker = ({
  labelName,
  setFilter
}: KLDatePickerProps): ReactElement => {
  const [date, setDate] = useState<Date>();
  const datepickerId = labelName.replace('-', '/').toLowerCase();

  const handleDate = (date: Date) => {
    setDate(date);
    if (date === null) {
      setFilter('surgeryDate', '');
      setSurgeryFilters('surgeryDate', '');
    } else {
      setFilter('surgeryDate', moment(date).format('YYYY-MM-DD'));
      setSurgeryFilters('surgeryDate', moment(date).format('YYYY-MM-DD'));
    }
  };

  const ui: KLUi = useSelector((state: KLState) => state.ui);

  useEffect(() => {
    if (ui.surgeryFilters.surgeryDate !== '') {
      setDate(moment(ui.surgeryFilters.surgeryDate, 'YYYY-MM-DD').toDate());
    }
  }, []);

  return (
    <div className="date-picker-container">
      <label htmlFor={datepickerId} className="date-label">
        {labelName}
      </label>
      <DatePicker
        id={datepickerId}
        className="date-picker"
        placeholderText="Select a date"
        closeOnScroll={true}
        selected={date}
        onChange={(date: Date) => handleDate(date)}
        isClearable={true}
        autoComplete="off"
      />
    </div>
  );
};

export default KLDatePicker;
