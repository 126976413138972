import { ReactElement } from 'react';
import RedWarning from '../../../../assets/icons/warning-red.svg';
import { KaliberButton } from '../../../../components';
import { KLSurgery } from '../../../../redux/types';
import { isNotEmpty } from '../../../../utils/validations';
import { GroupedProcedures } from '../surgeryCard/components';
import './DeleteModal.scss';

interface DeleteModalProps {
  visible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickDelete: (val: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickCancel: (val: any) => void;
  surgery: KLSurgery;
}
export default function PendingDeleteModal({
  surgery,
  visible,
  onClickDelete,
  onClickCancel
}: DeleteModalProps): ReactElement {
  return (
    <div className={`modal-container ${visible ? 'show-modal' : ''}`}>
      <div className="modal-container__container">
        <div className="pending-delete-modal">
          <div className="pending-delete-modal__header-container">
            <div className="pending-delete-modal__icon">
              <img
                src={RedWarning}
                alt="Warning Icon"
                className="pending-delete-modal__icon"
              />
            </div>
            <div className="pending-delete-modal__title">Delete Surgery?</div>
          </div>
          <div className="pending-delete-modal__message">
            The following surgery will be deleted. This cannot be undone.
          </div>
          <div className="procedures">
            <div className="procedures__patient-info">
              <div className="procedures__patient-name">
                {surgery.patientFirstName} {surgery.patientMiddleName}{' '}
                {surgery.patientLastName}
              </div>
              <div className="dates-container">
                <div className="dates-container__item">
                  <div className="dates-container__label">Date of Birth</div>
                  <div className="dates-container__date">
                    {surgery.patientDob}
                  </div>
                </div>
                <div className="dates-container__item">
                  <div className="dates-container__label">Surgery Date</div>
                  <div className="dates-container__date">
                    {surgery.surgeryDate}
                  </div>
                </div>
              </div>
            </div>
            <div className="procedures__grouped-procedures">
              <div className="procedures__grouped-label">Procedures</div>
              {isNotEmpty(surgery) && <GroupedProcedures {...surgery} />}
            </div>
          </div>
          <div className="pending-delete-modal__buttons">
            <KaliberButton
              buttonClass="pending-delete-modal__buttons--danger"
              type="button"
              onClick={onClickDelete}>
              Delete
            </KaliberButton>
            <KaliberButton
              type="button"
              buttonClass="pending-delete-modal__buttons--cancel"
              onClick={onClickCancel}>
              Cancel
            </KaliberButton>
          </div>
        </div>
      </div>
    </div>
  );
}
