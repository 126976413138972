import { callStratusApiV2, RequestMethod } from '../../api';
import store from '../store';
import {
  KLPostOpInstructions,
  KLPostOpOtherDocs,
  KLSurgeonPostOpInstructions
} from '../types';
import snakecaseKeys from 'snakecase-keys';

/**
 * Gets post op instructions for given surgeon uid.
 * Sets values in redux to be used on settings page.
 * @param accessToken
 * @param surgeonUid
 */
export async function getPostOpInstructions(
  accessToken: string,
  surgeonId: number
): Promise<KLSurgeonPostOpInstructions[]> {
  const customList = await callStratusApiV2<{
    data: KLSurgeonPostOpInstructions[];
  }>(`surgeons/${surgeonId}/post_op_instructions`, {
    accessToken,
    method: RequestMethod.Get
  });

  store.dispatch({
    type: 'SET_PDF_LIST',
    payload: customList.data
  });
  return customList.data;
}

export async function getAllPostOpInstructions(
  accessToken: string
): Promise<void> {
  const customList = await callStratusApiV2<{ data: KLPostOpInstructions[] }>(
    `post_op_instructions`,
    {
      accessToken,
      method: RequestMethod.Get
    }
  );

  store.dispatch({
    type: 'SET_ALL_PDF_LIST',
    payload: customList.data
  });
}

export async function uploadCustomPdf(
  accessToken: string,
  surgeonId: number,
  file: File,
  procedure: string,
  region: string
): Promise<string> {
  const formData = new FormData();
  formData.append('post_op_file', file, file.name);
  formData.append('procedure', procedure);
  formData.append('region', region);

  const resp = await callStratusApiV2<{
    media_info: { uri: string; url: string };
  }>(`surgeons/${surgeonId}/post_op_instructions`, {
    accessToken,
    method: RequestMethod.Post,
    body: formData
  });

  const url = resp.media_info.url;
  await updatePostOpCustomUrl(procedure, region, url);
  return url;
}

export async function updatePostOpCustomUrl(
  procedure: string,
  region: string,
  customUrl: string
): Promise<void> {
  store.dispatch({
    type: 'UPDATE_POST_OP_CUSTOM_URL',
    payload: { procedure, region, customUrl }
  });
}

export async function setSurgeonPostOpInstructions(
  accessToken: string,
  postOpInstructions: KLSurgeonPostOpInstructions[]
): Promise<KLSurgeonPostOpInstructions[]> {
  const data = {
    postOpInstructions: postOpInstructions
  };

  const res = await callStratusApiV2<KLSurgeonPostOpInstructions[]>(
    `/post_op_instructions/create_or_update`,
    {
      accessToken,
      method: RequestMethod.Post,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(snakecaseKeys(data))
    }
  );

  return res;
}

export async function setPostOpOtherDocs(
  accessToken: string,
  surgeonId: number,
  otherDocs: KLPostOpOtherDocs[]
): Promise<KLPostOpOtherDocs[]> {
  const res = await callStratusApiV2<KLPostOpOtherDocs[]>(
    `surgeons/${surgeonId}/other_docs`,
    {
      accessToken,
      method: RequestMethod.Post,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(snakecaseKeys({ other_docs: otherDocs }))
    }
  );

  return res;
}

export async function getPostOpOtherDocs(
  accessToken: string,
  surgeonId: number
): Promise<KLPostOpOtherDocs[]> {
  const customList = await callStratusApiV2<{ data: KLPostOpOtherDocs[] }>(
    `surgeons/${surgeonId}/other_docs`,
    {
      accessToken,
      method: RequestMethod.Get
    }
  );

  store.dispatch({
    type: 'SET_OTHER_DOCS',
    payload: customList.data
  });

  return customList.data;
}

export async function deletePostOpInstructions(
  accessToken: string,
  procedure: string,
  region: string,
  surgeonId: number,
  filename: string
): Promise<void> {
  const data = {
    procedure,
    region,
    filename
  };
  await callStratusApiV2<string>(`surgeons/${surgeonId}/post_op_instructions`, {
    accessToken,
    method: RequestMethod.Delete,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
}

export async function deleteOtherDocsByFilename(
  accessToken: string,
  region: string,
  surgeonId: number,
  filename: string,
  customUrl: string
): Promise<void> {
  const data = {
    region,
    filename,
    customUrl
  };
  await callStratusApiV2<string>(`surgeons/${surgeonId}/other_docs`, {
    accessToken,
    method: RequestMethod.Delete,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(snakecaseKeys(data))
  });
}
