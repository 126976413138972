import snakecaseKeys from 'snakecase-keys';
import { callStratusApiV2, RequestMethod } from '../../api';
import store from '../store';
import { CptSurgeryProcedure, CustomSurgeryProcedure } from '../types';
import camelcaseKeys from 'camelcase-keys';

export enum ProcedureVariant {
  Planned = 'planned',
  Performed = 'performed'
}

export async function getProcedures(
  accessToken: string,
  surgeryId: number,
  variant: ProcedureVariant
): Promise<void> {
  const res = await callStratusApiV2<{
    cpt_procedures: CptSurgeryProcedure[];
    custom_procedures: CustomSurgeryProcedure[];
  }>(`surgeries/${surgeryId}/procedures?variant=${variant}`, {
    accessToken,
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });

  store.dispatch({
    type: 'SET_PROCEDURES',
    payload: camelcaseKeys(res, { deep: true })
  });
}

function isCptSurgeryProcedure(
  procedure: CptSurgeryProcedure | CustomSurgeryProcedure
): procedure is CptSurgeryProcedure {
  return (procedure as CptSurgeryProcedure).cptCode !== undefined;
}

export async function createProcedure(
  accessToken: string,
  surgeryId: number,
  procedure: CptSurgeryProcedure | CustomSurgeryProcedure
): Promise<void> {
  let params = {};

  if (isCptSurgeryProcedure(procedure)) {
    params = { cpt_procedure: procedure };
  } else {
    params = { custom_procedure: procedure };
  }

  await callStratusApiV2(`surgeries/${surgeryId}/procedures`, {
    accessToken,
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(snakecaseKeys(params, { deep: true }))
  });
}

export async function updateProcedure(
  accessToken: string,
  surgeryId: number,
  procedure: CptSurgeryProcedure | CustomSurgeryProcedure
): Promise<void> {
  let params = {};
  if (isCptSurgeryProcedure(procedure)) {
    params = { cpt_procedure: procedure };
  } else {
    params = { custom_procedure: procedure };
  }

  await callStratusApiV2(`surgeries/${surgeryId}/procedures/${procedure.id}`, {
    accessToken,
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(snakecaseKeys(params, { deep: true }))
  });
}

export enum ProcedureTypeVariant {
  CptCodeProcedure = 'cpt_code_procedure',
  CustomProcedure = 'custom_procedure'
}

export async function deleteProcedure(
  accessToken: string,
  surgeryId: number,
  procedureId: number,
  procedureType: ProcedureTypeVariant
): Promise<void> {
  await callStratusApiV2(
    `surgeries/${surgeryId}/procedures/${procedureId}?type=${procedureType}`,
    {
      accessToken,
      method: RequestMethod.Delete,
      headers: { 'Content-Type': 'application/json' }
    }
  );
}

// Helper functions to work with multiple procedures at once

export async function createProcedures(
  accessToken: string,
  surgeryId: number,
  cptProceduresToCreate: CptSurgeryProcedure[],
  customProceduresToCreate: CustomSurgeryProcedure[]
): Promise<void> {
  const cptSurgeryPromises = cptProceduresToCreate.map(
    async cptProcedureToCreate => {
      await createProcedure(accessToken, surgeryId, cptProcedureToCreate);
    }
  );

  const customSurgeryPromises = customProceduresToCreate.map(
    async customProcedureToCreate => {
      await createProcedure(accessToken, surgeryId, customProcedureToCreate);
    }
  );

  await Promise.all([...cptSurgeryPromises, ...customSurgeryPromises]);
}

export async function updateProcedures(
  accessToken: string,
  surgeryId: number,
  cptProceduresToUpdate: CptSurgeryProcedure[],
  customProceduresToUpdate: CustomSurgeryProcedure[]
): Promise<void> {
  const cptSurgeryPromises = cptProceduresToUpdate.map(
    async cptProcedureToUpdate => {
      await updateProcedure(accessToken, surgeryId, cptProcedureToUpdate);
    }
  );

  const customSurgeryPromises = customProceduresToUpdate.map(
    async customProcedureToUpdate => {
      await updateProcedure(accessToken, surgeryId, customProcedureToUpdate);
    }
  );

  await Promise.all([...cptSurgeryPromises, ...customSurgeryPromises]);
}

export async function deleteProcedures(
  accessToken: string,
  surgeryId: number,
  cptProceduresToDelete: CptSurgeryProcedure[],
  customProceduresToDelete: CustomSurgeryProcedure[]
): Promise<void> {
  const cptSurgeryPromises = cptProceduresToDelete.map(
    async cptSurgeryProcedureToDelete => {
      if (cptSurgeryProcedureToDelete.id) {
        await deleteProcedure(
          accessToken,
          surgeryId,
          cptSurgeryProcedureToDelete.id,
          ProcedureTypeVariant.CptCodeProcedure
        );
      }
    }
  );

  const customSurgeryPromises = customProceduresToDelete.map(
    async customSurgeryProcedureToDelete => {
      if (customSurgeryProcedureToDelete.id) {
        await deleteProcedure(
          accessToken,
          surgeryId,
          customSurgeryProcedureToDelete.id,
          ProcedureTypeVariant.CustomProcedure
        );
      }
    }
  );

  await Promise.all([...cptSurgeryPromises, ...customSurgeryPromises]);
}
