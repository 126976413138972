import { ReactElement, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';

import Thumbnail from './components/thumbnail/ThumbnailComponent';
import {
  KaliberButton,
  ToggleGroup,
  ToggleButton,
  KaliberSelect,
  SegmentedControl,
  EditModal
} from '../../../../../../components';
import EvaluationIcon from '../../../../../../assets/icons/evaluation-icon.svg';
import TreatmentIcon from '../../../../../../assets/icons/treatment-icon.svg';
import PostTreatmentIcon from '../../../../../../assets/icons/post-treatment-icon.svg';
import './ReviewImageComponent.scss';

import {
  AnnotatedData,
  CptSurgeryProcedure,
  QuickNotesList
} from '../../../../../../redux/types';
import { CPTProcedureCategorizer } from '../../../../../../utils/surgeryProcedures';
import { isNotEmpty } from '../../../../../../utils/validations';

type ReviewImageComponentProps = {
  quickNotes: QuickNotesList;
  performedCptProcedures: CptSurgeryProcedure[];
  annotation: AnnotatedData;
  onAnnotationChanged: (annotation: AnnotatedData) => void;
};

export default function InternalReviewImageComponent({
  quickNotes,
  performedCptProcedures,
  annotation,
  onAnnotationChanged
}: ReviewImageComponentProps): ReactElement {
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [reviewAnnotation, setReviewAnnotation] = useState(annotation);
  const [quickResponseOptions, setQuickResponseOptions] = useState<string[]>(
    []
  );
  const [topOption, setTopOption] = useState('');
  const [surgeryRegion, setSurgeryRegion] = useState('');
  const [saveChanges, setSaveChanges] = useState(false);
  const categorizer = new CPTProcedureCategorizer(performedCptProcedures);

  const notChanged = _.isEqual(reviewAnnotation, annotation);

  const phaseIcon = useMemo(() => {
    switch (reviewAnnotation.mediaLabels.surgeryStage) {
      case 'evaluation':
        return EvaluationIcon;
      case 'treatment':
        return TreatmentIcon;
      case 'post-treatment':
        return PostTreatmentIcon;
      default:
        return '';
    }
  }, [reviewAnnotation.mediaLabels.surgeryStage]);

  useEffect(() => {
    setReviewAnnotation(annotation);
  }, [annotation]);

  useEffect(() => {
    setSurgeryRegion(categorizer.getSurgeryRegion());

    const quickNotesOptions = (() => {
      if (surgeryRegion === 'shoulder') {
        switch (reviewAnnotation.mediaLabels.surgeryStage) {
          case 'evaluation':
            return quickNotes.shoulder.evaluationResponses;
          case 'treatment':
            return quickNotes.shoulder.treatmentResponses;
          case 'post-treatment':
            return quickNotes.shoulder.postTreatmentResponses;
          default:
            return [];
        }
      } else if (surgeryRegion === 'knee') {
        switch (reviewAnnotation.mediaLabels.surgeryStage) {
          case 'evaluation':
            return quickNotes.knee.evaluationResponses;
          case 'treatment':
            return quickNotes.knee.treatmentResponses;
          case 'post-treatment':
            return quickNotes.knee.postTreatmentResponses;
          default:
            return [];
        }
      } else {
        return []; // Mixed or other non-knee/shoulder anatomies
      }
    })();

    // Add empty option at the beginning to de-select
    quickNotesOptions.unshift('');
    setQuickResponseOptions(quickNotesOptions);
  }, [
    quickNotes,
    performedCptProcedures,
    reviewAnnotation.mediaLabels.surgeryStage
  ]);

  const selectImageCategory = (surgeryStage: string) => {
    setReviewAnnotation({
      ...reviewAnnotation,
      mediaLabels: {
        ...reviewAnnotation.mediaLabels,
        surgeryStage: surgeryStage
      }
    });
  };

  const handleLabelChange = (newLabels: string[]) => {
    setReviewAnnotation({
      ...reviewAnnotation,
      mediaLabels: {
        ...reviewAnnotation.mediaLabels,
        labels: newLabels
      }
    });
  };

  const handleTopOptionChange = (newTopOption: string) => {
    const newStagePredictions = [
      ...(reviewAnnotation.mediaLabels.stagePredictions ?? []),
      { text: newTopOption, confidenceValue: 10000000 }
    ].filter(
      stagePrediction =>
        stagePrediction.text !== topOption && isNotEmpty(stagePrediction.text)
    );

    setReviewAnnotation({
      ...reviewAnnotation,
      mediaLabels: {
        ...reviewAnnotation.mediaLabels,
        stagePredictions: newStagePredictions
      }
    });
    setTopOption(newTopOption);
  };

  const saveReview = (annotationData: AnnotatedData) => {
    onAnnotationChanged(annotationData);
    setReviewModalVisible(false);
  };

  const cancelReview = () => {
    setReviewAnnotation(annotation);
    setReviewModalVisible(false);
  };

  const handleDeletePredictions = () => {
    const updatedAnnotations = {
      ...annotation,
      mediaLabels: {
        ...annotation.mediaLabels,
        stagePredictions: [] // Clear stagePredictions
      }
    };

    onAnnotationChanged(updatedAnnotations);
    handleTopOptionChange('');
  };

  return (
    <>
      <Thumbnail
        quickNote=""
        detailedNote=""
        phaseIcon={phaseIcon}
        imageUrl={annotation.mediaUrl ?? ''}
        included={annotation.include}
        annotation={annotation}
        quickNotesForRegion={quickResponseOptions}
        onClick={() => setReviewModalVisible(true)}
        onClickDeletePredictions={handleDeletePredictions}
      />
      <EditModal
        title={annotation.filename}
        visible={reviewModalVisible}
        onClose={cancelReview}>
        <div className="review-container">
          <div className="image-content">
            <img className="image-content__image" src={annotation.mediaUrl} />
            <label className="image-content__checkbox">
              <input
                className="image-content__checkbox__input"
                type="checkbox"
                checked={reviewAnnotation.include}
                onChange={() =>
                  setReviewAnnotation({
                    ...reviewAnnotation,
                    include: !reviewAnnotation.include
                  })
                }
              />
              Include image in report
            </label>
          </div>
          <div className="image-category">
            <span className="image-category__label">Image Category</span>
            <div className="image-category__selection">
              <SegmentedControl
                name="surgeryStage"
                segments={['evaluation', 'treatment', 'post-treatment']}
                selected={reviewAnnotation.mediaLabels.surgeryStage}
                onSegmentChange={selectImageCategory}
              />
            </div>
          </div>
          <div className="show-labels">
            <span className="show-labels__label">Show Labels</span>
            <div className="show-labels__anatomy-labels">
              <ToggleGroup
                id="fs-exclude"
                value={reviewAnnotation.mediaLabels.labels}
                onChange={handleLabelChange}>
                {reviewAnnotation.originalLabels?.labels?.map(label => (
                  <ToggleButton
                    className="label-button"
                    key={label}
                    value={label}>
                    {label}
                  </ToggleButton>
                ))}
              </ToggleGroup>
            </div>
          </div>
          {surgeryRegion &&
            (surgeryRegion.toLowerCase() === 'shoulder' ||
              surgeryRegion.toLowerCase() === 'knee') && (
              <div className="notes">
                <span className="notes__label">Notes</span>
                <KaliberSelect
                  label="Select top option to display"
                  list={quickResponseOptions}
                  includeOther={false}
                  select={topOption}
                  onChange={handleTopOptionChange}
                />
              </div>
            )}
          <div className="action-buttons">
            <KaliberButton
              type="button"
              buttonClass="btn--primary action-buttons__save"
              disabled={notChanged}
              loading={saveChanges}
              onClick={() => {
                setSaveChanges(true);
                saveReview(reviewAnnotation);
                setSaveChanges(false);
              }}>
              Save Changes
            </KaliberButton>
            <KaliberButton
              type="button"
              buttonClass="btn--cancel action-buttons__cancel"
              onClick={cancelReview}>
              Cancel
            </KaliberButton>
          </div>
        </div>
      </EditModal>
    </>
  );
}
