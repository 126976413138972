import { Container } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import { useQuery } from '../../utils/react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KLEmptyNavBar from '../../components/navbar/KLEmptyNavBar';
import { resendVerificationEmail } from '../../redux/actions/userActions';
import KaliberButton from '../../components/button/KaliberButton';
export default function UnverifiedEmail(): ReactElement {
  setFullstoryPage('Unverified Email Page');

  const [userEmail, setUserEmail] = useState('');
  const [sentEmail, setSentEmail] = useState(false);

  const query = useQuery();
  useEffect(() => {
    const queryParam = query.get('email');
    setUserEmail(queryParam || '');
  }, [query]);

  const handleResendButton = async () => {
    resendVerificationEmail(userEmail);
    setSentEmail(true);
  };
  return (
    <div>
      <KLEmptyNavBar />
      <Container maxWidth="md">
        <div className="page-container">
          <h4>Please verify your email before logging in.</h4>
          <p>
            Verification instructions have been sent to the email address on
            file, to resend the verification email please click the button
            below.
          </p>
          {sentEmail ? (
            <KaliberButton buttonClass="btn--primary" type="button">
              <CheckCircleOutlineIcon color="success" className="left" />
              Sent!
            </KaliberButton>
          ) : (
            <KaliberButton
              buttonClass="btn--primary"
              type="button"
              onClick={handleResendButton}>
              Resend email
            </KaliberButton>
          )}
        </div>
      </Container>
    </div>
  );
}
