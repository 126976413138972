/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef, ReactElement } from 'react';

import { ReactComponent as SearchIcon } from '../../assets/icons/MagnifyingGlass.svg';
import './AutoCompleteSearch.scss';

import { KLCptCode } from '../../redux/types';
import { escapeRegExp } from '../../utils/textFormat';

type SearchObject = {
  searchString: string;
  cptCode: KLCptCode;
};

interface CptAutoCompleteSearchProps {
  title: string;
  suggestions: KLCptCode[];
  minimumInputCharacters: number;
  selected: KLCptCode[];
  onSelected: (value: KLCptCode) => void;
}

export default function CptAutoCompleteSearch({
  title,
  suggestions,
  minimumInputCharacters,
  selected,
  onSelected
}: CptAutoCompleteSearchProps): ReactElement {
  const [internalSuggestions, setInternalSuggestions] = useState<
    SearchObject[]
  >([]);
  const [internalSelected, setInternalSelected] = useState<string[]>([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    SearchObject[]
  >([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [error, setError] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setInternalSuggestions(
      suggestions.map(suggestion => ({
        searchString: `${suggestion.code} - ${suggestion.description}`,
        cptCode: suggestion
      }))
    );
  }, [suggestions]);

  useEffect(() => {
    setInternalSelected(
      selected.map(el => `${el.code} - ${el.description.toLowerCase()}`)
    );
  }, [selected]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.currentTarget.value;

    if (userInput.length >= minimumInputCharacters) {
      const activeSuggestions = internalSuggestions.filter(suggestion => {
        const searchString = suggestion.searchString.toLowerCase();

        return (
          searchString.includes(userInput.toLowerCase()) &&
          !internalSelected.some(
            selected => selected.toLowerCase() === searchString
          )
        );
      });
      setActiveSuggestion(0);
      setFilteredSuggestions(activeSuggestions);

      if (activeSuggestions.length !== 0) {
        setShowSuggestions(true);
        setError(false);
      } else {
        setShowSuggestions(false);
        setError(true);
      }
    } else {
      setFilteredSuggestions([]);
      setError(false);
    }

    setUserInput(userInput);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const currentSelection = filteredSuggestions.at(activeSuggestion);
      if (currentSelection) {
        handleClick(currentSelection);
      }
    } else if (e.key === 'ArrowUp') {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.key === 'ArrowDown') {
      if (activeSuggestion === filteredSuggestions.length - 1) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const handleClick = (searchObject: SearchObject) => {
    setUserInput(searchObject.searchString);
    setShowSuggestions(false);
    onSelected(searchObject.cptCode);
    setUserInput('');
  };

  const boldMatchedCharacters = (item: string, keyword: string) => {
    const regex = new RegExp('(' + escapeRegExp(keyword) + ')', 'gi');
    const match = item.match(regex);
    if (match !== null) {
      const parts = item.split(regex);
      return (
        <div>
          {parts[0]}
          <span style={{ fontWeight: 'bold' }}>{match}</span>
          {parts[2]}
        </div>
      );
    }
  };

  return (
    <div className="autocomplete-search-container" ref={containerRef}>
      <div className="autocomplete-search-title"> {title} </div>
      <div className="autocomplete-search-input-row">
        <div className="autocomplete-search-input-container">
          <div className="autocomplete-search-input-field-container">
            <input
              className="autocomplete-search-input"
              type="text"
              ref={inputRef}
              value={userInput}
              placeholder="Search by CPT code or procedure name"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <span className="search-icon">
              <SearchIcon />
            </span>
          </div>
          {showSuggestions && filteredSuggestions.length > 0 && (
            <ul ref={selectRef}>
              {filteredSuggestions.map((suggestion, index) => {
                return (
                  <li
                    key={`${index}-${suggestion}`}
                    className={
                      index === activeSuggestion ? 'active' : undefined
                    }
                    onClick={() => handleClick(suggestion)}>
                    {boldMatchedCharacters(suggestion.searchString, userInput)}
                  </li>
                );
              })}
            </ul>
          )}

          {error && (
            <div className="autocomplete-search-input-error">
              CPT code not found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
