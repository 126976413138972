import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import './Settings.scss';
import NotificationsSettings from './notificationsSettings/NotificationsSettings';

export default function OtherSettingsPage(): ReactElement {
  return (
    <div>
      <Container maxWidth="xl">
        <div className="page-container settings-container">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <NotificationsSettings />
          </Box>
        </div>
      </Container>
    </div>
  );
}
