import { ReactElement, useEffect, useState } from 'react';
import { KLFacility, KLSurgeon, Staff } from '../../redux/types';
import { sentenceCase } from '../../utils/textFormat';
import { isNullOrUndefined } from '../../utils/validations';
import './StaffTable.scss';
import {
  StaffTableDataComponent,
  StaffTableFiltersComponent
} from './components';

function staffFacilities(staff: Staff[]) {
  return staff
    .flatMap(staff => staff.facilities)
    .filter(
      (facility: KLFacility | undefined): facility is KLFacility =>
        !isNullOrUndefined(facility)
    )
    .map((facility: KLFacility) => {
      return facility.facilityName;
    })
    .reduce((uniqueArray: string[], item: string): string[] => {
      return uniqueArray.includes(item) ? uniqueArray : [...uniqueArray, item];
    }, []);
}

export interface Filter {
  column: string;
  value: string | number;
}

export interface StaffTableProps {
  staff: Staff[];
  surgeons: KLSurgeon[];
}

export default function StaffTable({
  staff,
  surgeons
}: StaffTableProps): ReactElement {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    const rolesArray: string[] = [];
    staff.forEach(staff => {
      rolesArray.indexOf(staff.role) === -1 && rolesArray.push(staff.role);
    });

    if (surgeons.length > 0) {
      rolesArray.push('surgeon');
    }

    const options = rolesArray.map(v => sentenceCase(v));
    setRoles(options.filter(Boolean));
  }, [staff, surgeons]);

  return (
    <div className="Table">
      <StaffTableFiltersComponent
        roles={roles}
        facilities={staffFacilities(staff)}
        onFiltersChange={setFilters}
      />
      <StaffTableDataComponent
        staff={staff}
        surgeons={surgeons}
        filters={filters}
      />
    </div>
  );
}
