import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import moment from 'moment';

import KaliberButton from '../../components/button/KaliberButton';
import AddSurgeryConfirmationPage from './AddSurgeryConfirmationPage';
import PageHeader from '../../components/pageHeader/PageHeader';
import { SurgeryDetailsFormComponent } from './components/surgeryDetails';
import { PatientInformationFormComponent } from './components/patientInformation';
import { GuardianContactFormComponent } from './components/guardianContact';
import { PatientAdditionalDetailsFormComponent } from './components/patientContact';
import { MissingContactInfoComponent } from './components/missingContactInfo';
import './AddSurgeryPage.scss';

import { getFacilitiesBySurgeon } from '../../redux/actions/surgeonsActions';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import { getSurgeons } from '../../redux/actions/surgeonsActions';
import { getCptCodes } from '../../redux/actions/cptCodesActions';
import {
  KLSurgeon,
  KLState,
  KLUser,
  KLCptCode,
  NewSurgeryData,
  CptSurgeryProcedure,
  KLFacility
} from '../../redux/types';
import { formatPronouns, formatSex } from '../../utils/dataOrganization';
import { isMinor } from '../../utils/validations';
import { EmergencyContactFormComponent } from './components/patientContact/emergencyContact';
import { MissingProcedureInfoComponent } from './components/missingProcedureInfo';

export default function AddSurgeryPage(): ReactElement {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
    setError,
    setValue
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>();
  const [user, surgeons, cptCodes]: [KLUser, KLSurgeon[], KLCptCode[]] =
    useSelector((state: KLState) => [
      state.user,
      state.surgeons,
      state.cptCodes
    ]);

  const [newSurgeryData, setNewSurgeryData] = useState({} as NewSurgeryData);
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const [
    showMissingContactInformationModal,
    setShowMissingContactInformationModal
  ] = useState(false);
  const [
    showMissingProcedureInformationModal,
    setShowMissingProcedureInformationModal
  ] = useState(false);
  const [customProcedures, setCustomProcedures] = useState<string[]>([]);
  const [selectedProcedures, setSelectedProcedures] = useState<
    CptSurgeryProcedure[]
  >([]);
  const [facilitiesList, setFacilitiesList] = useState<KLFacility[]>([]);
  const selectedSurgeon = watch('surgeonName');

  useEffect(() => {
    setFullstoryPage('Add Surgery Page');
  }, []);

  useEffect(() => {
    if (user.accessToken) {
      getSurgeons(user.accessToken);
      getCptCodes(user.accessToken);
    }
  }, [user.accessToken]);

  useEffect(() => {
    const getFilteredFacilities = async () => {
      if (user.accessToken && surgeons && selectedSurgeon) {
        const surgeon = surgeons.find(
          element => element.surgeonName === selectedSurgeon
        );
        if (surgeon) {
          const facilities = await getFacilitiesBySurgeon(
            user.accessToken,
            surgeon.surgeonId
          );
          setFacilitiesList(facilities);
        }
      }
    };

    getFilteredFacilities();
  }, [user.accessToken, surgeons, selectedSurgeon]);

  function handleNavigateBack(error?: boolean) {
    setShowConfirmationPage(false);

    if (error) {
      setError('email', {
        type: 'focus'
      });
    }
  }

  const handleContinueWithMissingInformation = () => {
    setShowMissingContactInformationModal(false);
    setShowConfirmationPage(true);
  };

  // Not implementing this as a useEffect per the reccomendation of the `react-hook-form`
  // library authors. Values from the `watch` hook are ment to be used directly in the
  // `render` function making useEffect behave unexpectedly.
  // https://react-hook-form.com/docs/useform/watch
  const dob = watch('dob');
  const patient_state = watch('state') as string;

  function patientIsMinor(): boolean {
    return isMinor(moment(dob, 'MM DD YYYY'), patient_state);
  }

  function transformFormData(data: NewSurgeryData): NewSurgeryData {
    const facility = facilitiesList.filter(
      e => e.facilityName === data.facilityName
    );
    const surgeon = surgeons.filter(e => e.surgeonName === data.surgeonName);

    // Use the emergency contact email and phone as the patient's email and phone if the patient is a minor
    if (patientIsMinor()) {
      data.email = data.emergencyContactEmail;
      data.phone = data.emergencyContactPhone;
    }

    return {
      ...data,
      side: data.side === undefined ? 'N/A' : data.side,
      dob: moment(data.dob).format('yyyy-MM-DD'),
      surgeryDate: moment(data.surgeryDate).format('yyyy-MM-DD'),
      facilityId: facility[0].facilityId ?? 0,
      surgeonId: surgeon[0].surgeonId,
      sex: formatSex(data.sex),
      pronouns: formatPronouns(data.pronouns) ?? '',
      email: data.email?.trim().toLowerCase(),
      plannedProcedures: {
        cptProcedures: selectedProcedures,
        customProcedures: customProcedures.map(procedure => ({
          customProcedure: { procedure: procedure },
          procedureType: 'planned'
        }))
      }
    };
  }

  function isContactInformationFilled(data: NewSurgeryData) {
    if (patientIsMinor()) {
      return emergencyContactInformationFilled(data);
    } else {
      return patientContactInformationFilled(data);
    }
  }

  function patientContactInformationFilled(data: NewSurgeryData): boolean {
    const emailPresent = Boolean(data.email && data.email.length > 0);
    const phonePresent = Boolean(data.phone && data.phone.length > 0);

    return emailPresent || phonePresent;
  }

  function emergencyContactInformationFilled(data: NewSurgeryData): boolean {
    const emailPresent = Boolean(
      data.emergencyContactEmail && data.emergencyContactEmail.length > 0
    );
    const phonePresent = Boolean(
      data.emergencyContactPhone && data.emergencyContactPhone.length > 0
    );

    return emailPresent || phonePresent;
  }

  function isProcedureInformationEmpty(data: NewSurgeryData): boolean {
    return (
      data.plannedProcedures.cptProcedures.length === 0 &&
      data.plannedProcedures.customProcedures.length === 0
    );
  }

  function confirmFormData(data: NewSurgeryData) {
    if (isProcedureInformationEmpty(data)) {
      setShowMissingProcedureInformationModal(true);
    } else if (!isContactInformationFilled(data)) {
      setShowMissingContactInformationModal(true);
    } else {
      setShowConfirmationPage(true);
    }
  }

  const onSubmit = (data: NewSurgeryData) => {
    const newSurgeryData = transformFormData(data);
    setNewSurgeryData(newSurgeryData);

    confirmFormData(newSurgeryData);
  };

  return (
    <div className="add-surgery-page-container">
      {showConfirmationPage ? (
        <AddSurgeryConfirmationPage
          newSurgeryData={newSurgeryData}
          isMinor={patientIsMinor()}
          navigateBack={error => handleNavigateBack(error)}
        />
      ) : (
        <div className="add-surgery-container">
          <PageHeader title="Add Surgery" pageToRedirect="/surgeries" />
          <div data-testid="add-surgery-div">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="asp-card-container">
                <PatientInformationFormComponent
                  isMinor={patientIsMinor()}
                  register={register}
                  errors={errors}
                  control={control}
                />
                <PatientAdditionalDetailsFormComponent
                  register={register}
                  errors={errors}
                  control={control}
                />
                {patientIsMinor() ? (
                  <GuardianContactFormComponent
                    register={register}
                    errors={errors}
                    control={control}
                  />
                ) : (
                  <EmergencyContactFormComponent
                    register={register}
                    errors={errors}
                    control={control}
                  />
                )}
                <SurgeryDetailsFormComponent
                  control={control}
                  errors={errors}
                  surgeonNames={surgeons.map(surgeon => surgeon.surgeonName)}
                  facilitiesNames={facilitiesList.map(
                    facility => facility.facilityName
                  )}
                  cptCodes={cptCodes}
                  selectedProcedures={selectedProcedures}
                  setSelectedProcedures={setSelectedProcedures}
                  customProcedures={customProcedures}
                  setCustomProcedures={setCustomProcedures}
                  setValue={setValue}
                />
              </div>
              <div className="asp-button-container">
                <div className="asp-inner-button-container">
                  <KaliberButton buttonClass="btn--primary" type="submit">
                    Next
                  </KaliberButton>
                </div>
              </div>
            </form>
          </div>
          <MissingContactInfoComponent
            visible={showMissingContactInformationModal}
            isMinor={patientIsMinor()}
            onEdit={() => setShowMissingContactInformationModal(false)}
            onContinue={handleContinueWithMissingInformation}
            setShowMissingContactInformationModal={
              setShowMissingContactInformationModal
            }
          />
          <MissingProcedureInfoComponent
            visible={showMissingProcedureInformationModal}
            setShowMissingProcedureInformationModal={
              setShowMissingProcedureInformationModal
            }
            onEdit={() => setShowMissingProcedureInformationModal(false)}
          />
          <div className="ascp-disclaimer">
            <div className="ascp-paragraph">
              CPT copyright 2021 American Medical Association. All rights
              reserved.
            </div>
            <div className="ascp-paragraph">
              Fee schedules, relative value units, conversion factors and/or
              related components are not assigned by the AMA, are not part of
              CPT, and the AMA is not recommending their use. The AMA does not
              directly or indirectly practice medicine or dispense medical
              services. The AMA assumes no liability for data contained or not
              contained herein.
            </div>
            <div className="ascp-paragraph">
              CPT is a registered trademark of the American Medical Association.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
