import React, { ReactElement } from 'react';
import './CarouselItem.scss';

interface CarouselItemProps {
  titleText: string;
  bodyText: string | JSX.Element;
  imageSrc: string;
  dataTestId?: string;
  dataTestIdImg?: string;
}
export default function CarouselItem({
  titleText,
  bodyText,
  imageSrc,
  dataTestId,
  dataTestIdImg
}: CarouselItemProps): ReactElement {
  return (
    <div
      className="how-to-content-container"
      data-testid={dataTestId ? dataTestId : 'carousel-item-component-div'}>
      <div className="image-container">
        <img
          src={imageSrc}
          className="how-to-photo"
          data-testid={dataTestIdImg ? dataTestIdImg : 'data-test-img'}
        />
      </div>
      <div className="text-container">
        <div className="inner-container">
          <h4 className="carousel-title">{titleText}</h4>
          <h5 className="text-body">{bodyText}</h5>
        </div>
      </div>
    </div>
  );
}
