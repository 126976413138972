import { ReactElement } from 'react';

import KaliberModal from '../../../../components/modal/KaliberModal';
import KaliberButton from '../../../../components/button/KaliberButton';
import Warning from '../../../../assets/icons/warning.svg';
import '../missingContactInfo/MissingContactInfoComponent.scss';

interface MissingProcedureInfoComponentProps {
  visible: boolean;
  onEdit: () => void;
  setShowMissingProcedureInformationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export default function MissingProcedureInfoComponent({
  visible,
  onEdit,
  setShowMissingProcedureInformationModal
}: MissingProcedureInfoComponentProps): ReactElement {
  //Note: this component is using the scss styling from MissingContactInfoComponent.scss
  return (
    <div className="asp-missing-contact-information-modal-container">
      <KaliberModal
        visible={visible}
        closeIcon={true}
        onClose={() => setShowMissingProcedureInformationModal(false)}
        title={'Missing Procedure Information'}
        titleIcon={<img src={Warning} alt="" />}
        titleIconAlignment="left"
        titleAlignment="left"
        size="medium">
        <div className="asp-missing-contact-information-modal-text">
          This patient is missing a surgery procedure.
          <br />
          Use the search bar to add a CPT Code, or click on &quot;Add custom
          entry&quot; to add a custom procedure.
        </div>
        <div className="asp-missing-contact-information-buttons-container">
          <KaliberButton
            type="button"
            onClick={() => onEdit()}
            buttonClass="btn--edit-information">
            Edit Information
          </KaliberButton>
        </div>
      </KaliberModal>
    </div>
  );
}
