import React, { ReactElement } from 'react';
import Carousel from 'react-material-ui-carousel';
import PersonDoctor from '../../../../assets/howToIllustrations/person-doctor.png';
import PersonPhone from '../../../../assets/howToIllustrations/person-phone.png';
import ThreeImagePhone from '../../../../assets/howToIllustrations/three-image-phone.png';
import PersonChecklist from '../../../../assets/howToIllustrations/person-checklist.png';
import PersonTherapist from '../../../../assets/howToIllustrations/person-therapist.png';
import CarouselItem from './CarouselItem';
import './ReportHowTo.scss';
import { KLReview, KLState } from '../../../../redux/types';
import { useSelector } from 'react-redux';

export default function ReportHowTo(): ReactElement {
  const [review]: [KLReview] = useSelector((state: KLState) => [
    state.surgeries.review
  ]);

  const reportIncludeList = () => {
    if (
      review.surgeryInfo.region === 'shoulder' ||
      review.surgeryInfo.region === 'knee'
    ) {
      return (
        <>
          <li>An illustrated guide to your anatomy</li>
          <li>Labeled images from your surgery</li>
        </>
      );
    }

    return (
      <>
        <li>Images from your surgery</li>
        <li>Notes from your surgeon about your surgery images</li>
      </>
    );
  };

  const items = [
    {
      title: 'What is Rekap?',
      body: 'Rekap provides a visual overview of your surgery to support your recovery. Our goal is to help you better understand what required surgical intervention, how we treated it during surgery, and what you need to do to recover as quickly as possible.',
      image: PersonPhone
    },
    {
      title: 'What does my Rekap report include?',
      body: (
        <div>
          This report contains useful resources related to your surgery and
          care, including:
          <ul>
            {reportIncludeList()}
            <li>Information about your surgeon</li>
          </ul>
        </div>
      ),
      image: ThreeImagePhone
    },
    {
      title: 'How should I use Rekap?',
      body: 'Taking some time to look through your Rekap report and videos ahead of your post-operative appointment will enhance your understanding of your condition and care and facilitate our communication. We may also refer to Rekap as a visual aid during your follow-up appointment.',
      image: PersonDoctor
    },
    {
      title:
        'Can I share my Rekap report with my physical therapist or primary care provider?',
      body: 'Absolutely. Sharing your Rekap report with your physical therapist or primary care physician can give your healthcare team a more complete picture of your condition and surgery as they develop and adjust your recovery plan.',
      image: PersonTherapist
    },
    {
      title: 'What if I have questions?',
      body: 'As you go through the report, write down any medical questions that come up and bring them to your post-operative appointment. Please consult with me or other qualified medical personnel for all explanations and interpretations.',
      image: PersonChecklist
    }
  ];

  return (
    <div className="how-to-container">
      <h3 className="how-to-header">How to use this surgery report</h3>
      <Carousel autoPlay={false}>
        {items.map(item => (
          <CarouselItem
            key={item.title}
            titleText={item.title}
            bodyText={item.body}
            imageSrc={item.image}
          />
        ))}
      </Carousel>
    </div>
  );
}
