import camelcaseKeys from 'camelcase-keys';
import {
  callStratusApiV2,
  callStratusApiV2Unauthenticated,
  RequestMethod
} from '../../api';
import store from '../store';
import { KLUserTermsAgreement } from '../types';

export async function getUserAccessToken(): Promise<void> {
  // TODO: Create type definition for User
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const res: { token: string; user: any } =
    await callStratusApiV2Unauthenticated(`users/user_token`, {
      method: RequestMethod.Get,
      headers: { 'Content-Type': 'application/json' }
    });

  store.dispatch({ type: 'SET_USER', payload: res });
}

export async function setNotifications(
  accessToken: string,
  notificationType: string,
  enable: boolean
): Promise<void> {
  const data = {
    notificationType: notificationType,
    enable: enable
  };

  await callStratusApiV2<Record<string, null>>(`users/notifications/settings`, {
    accessToken,
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
}

export async function getNotificationsSettings(
  accessToken: string
): Promise<void> {
  const res = await callStratusApiV2(`users/notifications/settings`, {
    accessToken,
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });
  store.dispatch({
    type: 'USER_SET_NOTIFICATIONS_SETTINGS',
    payload: res
  });
}

export async function setContactInformation(
  accessToken: string,
  email: string,
  phone: string
): Promise<void> {
  const data = {
    email: email,
    phone: phone
  };

  const res = await callStratusApiV2(`users/contact_info`, {
    accessToken,
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });

  store.dispatch({
    type: 'USER_SET_CONTACT_SETTINGS',
    payload: res
  });
}

export function clearNotificationData(): void {
  store.dispatch({
    type: 'CLEAR_USER_NOTIFICATIONS'
  });
}

export async function resendVerificationEmail(email: string): Promise<void> {
  const req = { email: email };
  await callStratusApiV2Unauthenticated<void>('users/email/verify', {
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req)
  });
}

export async function getAgreedTermsOfUse(
  accessToken: string,
  uid: string
): Promise<void> {
  try {
    const agreements = await callStratusApiV2<KLUserTermsAgreement[]>(
      `users/${uid}/terms_of_use_agreements`,
      {
        accessToken,
        method: RequestMethod.Get,
        headers: { 'Content-Type': 'application/json' }
      }
    );

    store.dispatch({
      type: 'USER_SET_TERMS_AGREEMENT',
      payload: agreements.map(agreement => camelcaseKeys(agreement))
    });
  } catch (e) {
    const errorAgreement: KLUserTermsAgreement = {
      updateId: -1,
      agreed: true,
      agreedCount: 2,
      date: '',
      description: "couldn't load the terms"
    };
    store.dispatch({
      type: 'USER_SET_TERMS_AGREEMENT',
      payload: [errorAgreement]
    });
  }
}

export function clearTermsOfUseData(): void {
  store.dispatch({
    type: 'CLEAR_SET_TERMS_AGREEMENT'
  });
}

export async function saveAgreementTermsOfUse(
  accessToken: string,
  uid: string,
  updateId: number
): Promise<boolean> {
  const req = { update_id: updateId };
  try {
    await callStratusApiV2<KLUserTermsAgreement>(
      `users/${uid}/terms_of_use_agreements`,
      {
        accessToken,
        method: RequestMethod.Post,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
      }
    );
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * The `loginUser` function is an asynchronous function that takes an email and password as parameters,
 * makes a request to an API to log in the user, and then dispatches actions to update the user state
 * in the store.
 * @param {string} email - A string representing the user's email address.
 * @param {string} password - The `password` parameter is a string that represents the user's password.
 */
export async function loginUser(
  email: string,
  password: string
): Promise<void> {
  const loginUserResponse = await callStratusApiV2Unauthenticated<{
    token: string;
    // TODO: Create type definition for User
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    user: any;
  }>(`users/login`, {
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: email,
      password: password
    })
  });

  if (loginUserResponse.token !== undefined) {
    store.dispatch({
      type: 'SET_USER',
      payload: loginUserResponse
    });
    store.dispatch({
      type: 'CLEAR_AUTH_ERROR',
      payload: null
    });
  }
}

/**
 * The function logs out the user, redirects to the login page, clears the user data from the store,
 * and displays an error message if the user provided incorrect credentials.
 * @param {boolean} [is401] - The `is401` parameter is a boolean flag that indicates whether the user
 * is logging out due to a 401 Unauthorized error. If `is401` is `true`, it means that the user's email
 * or password was incorrect, and an error message will be displayed. If `is401`
 */
export async function logoutUser(is401?: boolean): Promise<void> {
  await callStratusApiV2Unauthenticated(`users/logout`, {
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' }
  });

  if (window.location.pathname !== '/login') {
    window.location.replace('/login');
  }

  store.dispatch({ type: 'CLEAR_USER' });

  if (is401) {
    store.dispatch({
      type: 'SET_AUTH_ERROR',
      payload:
        'The email or password you entered do not match our records. Please try again.'
    });
  }
}

/**
 * The `resetPassword` function sends a reset password email to the specified email address.
 * @param {string} email - The `email` parameter is a string that represents the email address of the
 * user for whom the password reset email should be sent.
 */
export async function startResetPassword(email: string): Promise<void> {
  await callStratusApiV2Unauthenticated(`users/send_reset_password_email`, {
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email
    })
  });
}

export async function resetPassword(
  resetToken: string,
  newPassword: string
): Promise<void> {
  await callStratusApiV2Unauthenticated(
    `users/reset_password?token=${resetToken}`,
    {
      method: RequestMethod.Post,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: newPassword })
    }
  );
}
