import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ExpandMoreIcon } from '../../assets/icons/CaretDown.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/cross-medium.svg';
import IconButton from '@mui/material/IconButton';
import React, { ReactElement, useEffect } from 'react';
import './KLSelect.scss';

export interface MenuObject {
  display: string;
  value: string | number;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  menuItemPlaceholder: {
    display: 'none'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'theme.palette.text.secondary',
    marginLeft: 1
  },
  title: {
    fontWeight: 400,
    color: '#44546a',
    fontFamily: 'Inter',
    lineHeight: '1rem',
    letterSpacing: '0.25px',
    fontSize: '14px',
    margin: '0 0 0.25rem 0'
  },
  noBorder: {
    border: 'none'
  },
  fixPadding: {
    padding: '10px 0'
  },
  disabledColor: {
    color: theme.palette.text.primary
  }
}));

interface KLSelectProps {
  value: string | number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (val: any) => void;
  name?: string;
  helperText?: string;
  label?: string;
  id?: string;
  placeholder?: string;
  menuItems: string[] | number[] | MenuObject[];
  error?: boolean;
  onClick?: (val: React.SyntheticEvent) => void;
  selectStyle?: React.CSSProperties;
  disabled?: boolean;
  selectClass?: string;
  editable: boolean;
  alwaysShowDownIcon?: boolean;
  dataTestId?: string;
}

export default function KLSelect({
  onChange,
  label,
  value,
  placeholder,
  menuItems,
  selectStyle,
  editable,
  selectClass,
  alwaysShowDownIcon = false,
  dataTestId,
  ...options
}: KLSelectProps): ReactElement {
  const classes = useStyles();
  const [icon, setIcon] = React.useState({
    clearIcon: (
      <IconButton
        className="display-none"
        onClick={() => {
          handleChange('');
        }}>
        <ClearIcon />
      </IconButton>
    ),
    downIcon: <ExpandMoreIcon className="expand-more-icon" />,
    noIcon: <ExpandMoreIcon className="display-none" />
  });

  useEffect(() => {
    selectValidation('');
  }, [label]);

  useEffect(() => {
    selectValidation(value);
  }, [value]);

  const selectValidation = (value: string | number | null) => {
    if (alwaysShowDownIcon) {
      setIcon({
        clearIcon: (
          <IconButton
            className="display-none"
            onClick={() => {
              handleChange('');
            }}>
            <ClearIcon />
          </IconButton>
        ),
        downIcon: <ExpandMoreIcon className="expand-more-icon" />,
        noIcon: <ExpandMoreIcon className="display-none" />
      });
    } else if (value !== '' && value !== 0) {
      setIcon({
        clearIcon: (
          <IconButton
            className="icon-btn"
            onClick={() => {
              handleChange('');
            }}>
            <ClearIcon />
          </IconButton>
        ),
        downIcon: <ExpandMoreIcon className="display-none" />,
        noIcon: <ExpandMoreIcon className="display-none" />
      });
    } else {
      setIcon({
        clearIcon: (
          <IconButton
            className="display-none"
            onClick={() => {
              handleChange('');
            }}>
            <ClearIcon />
          </IconButton>
        ),
        downIcon: <ExpandMoreIcon className="expand-more-icon" />,
        noIcon: <ExpandMoreIcon className="display-none" />
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: string | number) => {
    onChange(value);
  };
  return (
    <div>
      <div className={classes.titleContainer}>
        <div className={`caption ${classes.title}`}>{label}</div>
      </div>
      <FormControl className={`${classes.formControl} ${selectClass}`}>
        {editable ? (
          <TextField
            style={selectStyle}
            fullWidth
            select
            id="fs-mask"
            className="select-input"
            SelectProps={{
              displayEmpty: true,
              IconComponent: () => icon.downIcon
            }}
            variant="outlined"
            onChange={({ target: { value } }) => {
              handleChange(value);
            }}
            value={value}
            InputProps={{
              endAdornment: <ExpandMoreIcon className="expand-more-icon" />
            }}
            inputProps={{
              'data-testid': dataTestId ? dataTestId : 'test-kl-select'
            }}
            {...options}>
            <MenuItem
              className=".menu-item"
              classes={{ root: classes.menuItemPlaceholder }}
              value=""
              id="fs-mask"
              disabled>
              {placeholder}
            </MenuItem>
            {menuItems.map(
              (item: string | number | MenuObject, index: number) => {
                if (typeof item === 'object') {
                  return (
                    <MenuItem key={index} value={item.value} id="fs-mask">
                      {item.display}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={index} value={item} id="fs-mask">
                      {item}
                    </MenuItem>
                  );
                }
              }
            )}
          </TextField>
        ) : (
          <TextField
            id="fs-mask"
            disabled={true}
            style={selectStyle}
            fullWidth
            select
            SelectProps={{
              displayEmpty: true,
              IconComponent: () => icon.noIcon
            }}
            variant="outlined"
            onChange={({ target: { value } }) => {
              handleChange(value);
            }}
            value={value}
            InputProps={{
              classes: {
                notchedOutline: classes.noBorder,
                input: classes.fixPadding,
                disabled: classes.disabledColor
              }
            }}
            inputProps={{
              'data-testid': dataTestId ? dataTestId : 'test-kl-select'
            }}
            {...options}>
            <MenuItem
              id="fs-mask"
              className="menu-item"
              classes={{ root: classes.menuItemPlaceholder }}
              value=""
              disabled>
              {placeholder}
            </MenuItem>
            {menuItems.map(
              (item: string | number | MenuObject, index: number) => {
                if (typeof item === 'object') {
                  return (
                    <MenuItem key={index} value={item.value} id="fs-mask">
                      {item.display}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={index} value={item} id="fs-mask">
                      {item}
                    </MenuItem>
                  );
                }
              }
            )}
          </TextField>
        )}
      </FormControl>
    </div>
  );
}
