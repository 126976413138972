import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import KaliberButton from '../../../../components/button/KaliberButton';
import {
  CptSurgeryProcedure,
  CustomSurgeryProcedure,
  KLCptCode,
  KLReview
} from '../../../../redux/types';
import { formatSex } from '../../../../utils/dataOrganization';
import './SurgeryDetailsComponent.scss';
import PatientInfoComponent from './components/patientInfo/PatientInfoComponent';
import ProcedureDetailsComponent from './components/procedureDetail/ProcedureDetailsComponent';
import SurgeryInfoComponent from './components/surgeryInfo/SurgeryInfoComponent';

export type SurgeryDetailsFormData = {
  cptProcedures: CptSurgeryProcedure[];
};

interface SurgeryDetailsProps {
  cptCodes: KLCptCode[];
  review: KLReview;
  selectedPostOpInstructionIds: number[];
  setSelectedPostOpInstructionIds: (postOpInstructionIds: number[]) => void;
  cptProcedures: CptSurgeryProcedure[];
  onCptProceduresChange: (cptProcedures: CptSurgeryProcedure[]) => void;
  onRemoveCptProcedure: (remvoedCptProcedure: CptSurgeryProcedure) => void;
  customProcedures: CustomSurgeryProcedure[];
  onCustomProceduresChange: (
    customProcedures: CustomSurgeryProcedure[]
  ) => void;
  onRemoveCustomProcedure: (
    removedCustomProcedure: CustomSurgeryProcedure
  ) => void;
  onSurgeryDetailsSubmit: () => void;
  updatingSurgery: boolean;
}

export default function SurgeryDetails({
  cptCodes,
  review,
  selectedPostOpInstructionIds,
  setSelectedPostOpInstructionIds,
  cptProcedures,
  onCptProceduresChange,
  onRemoveCptProcedure,
  customProcedures,
  onCustomProceduresChange,
  onRemoveCustomProcedure,
  onSurgeryDetailsSubmit,
  updatingSurgery
}: SurgeryDetailsProps): ReactElement {
  const { handleSubmit, register, reset, setValue, control, watch } =
    useForm<SurgeryDetailsFormData>({
      defaultValues: {
        cptProcedures: cptProcedures
      }
    });

  const cptProceduresObserver = watch('cptProcedures');
  useEffect(() => {
    onCptProceduresChange(cptProceduresObserver);
  }, [cptProceduresObserver]);

  // The onSubmit handler swollows the submitted FormData
  // in this instance. Because we're mixing the form
  // with other, non-form, components it makes it more
  // consistent to use the `useState` tracked data in the
  // `ReviewPageRedesign` component itself rather than
  // a blend of form data and outside data.
  const onSubmit = (_formData: SurgeryDetailsFormData) => {
    onSurgeryDetailsSubmit();
  };

  return (
    <>
      <div className="container-review">
        <PatientInfoComponent
          firstName={review.surgeryInfo.patientFirstName}
          lastName={review.surgeryInfo.patientLastName}
          dob={review.surgeryInfo.patientDob}
          sex={
            formatSex(review.surgeryInfo.patientSex) ?? 'Prefer no to answer'
          }
          middleName={review.surgeryInfo.patientMiddleName ?? ''}
          preferredName={review.surgeryInfo.patientPreferredName ?? ''}
          race={review.surgeryInfo.patientRace}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-review">
          <SurgeryInfoComponent
            cptCodes={cptCodes}
            surgeryInfo={review.surgeryInfo}
            selectedPostOpInstructionIds={selectedPostOpInstructionIds}
            cptProcedures={cptProcedures}
            customProcedures={customProcedures}
            onChangePostOp={setSelectedPostOpInstructionIds}
            onChangeCptProcedures={newCptProcedures => {
              reset({ cptProcedures: newCptProcedures });
              onCptProceduresChange(newCptProcedures);
            }}
            onRemoveCptProcedure={onRemoveCptProcedure}
            onChangeCustomProcedures={onCustomProceduresChange}
            onRemoveCustomProcedure={onRemoveCustomProcedure}
          />
        </div>

        {cptProcedures.length > 0 && (
          <div className="container-review">
            <ProcedureDetailsComponent
              register={register}
              control={control}
              setValue={setValue}
            />
          </div>
        )}

        <div className="next-button container-review">
          <KaliberButton
            buttonClass="btn--primary"
            type="submit"
            loading={updatingSurgery}>
            Next
          </KaliberButton>
        </div>
      </form>
    </>
  );
}
