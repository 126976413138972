import { TextField, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import React, { ReactElement, useEffect } from 'react';
import './KLMultiSelect.scss';

export interface MenuObject {
  display: string;
  value: string | number;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  menuItemPlaceholder: {
    display: 'none'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'theme.palette.text.secondary',
    marginLeft: 1
  },
  title: {
    fontWeight: 400,
    color: '#44546a',
    fontFamily: 'Inter',
    lineHeight: '1rem',
    letterSpacing: '0.25px',
    fontSize: '14px',
    margin: '0 0 0.25rem 0'
  },
  noBorder: {
    border: 'none'
  },
  fixPadding: {
    padding: '10px 0'
  },
  disabledColor: {
    color: theme.palette.text.primary
  }
}));

interface KLSelectProps {
  value: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (val: any) => void;
  name?: string;
  helperText?: string;
  label?: string;
  id?: string;
  placeholder?: string;
  menuItems: string[] | number[] | MenuObject[];
  error?: boolean;
  onClick?: (val: React.SyntheticEvent) => void;
  selectStyle?: React.CSSProperties;
  disabled?: boolean;
  selectClass?: string;
  editable: boolean;
  alwaysShowDownIcon?: boolean;
}

export default function KLMultiSelect({
  onChange,
  label,
  value,
  placeholder,
  menuItems,
  selectStyle,
  editable,
  selectClass,
  alwaysShowDownIcon = false,
  ...options
}: KLSelectProps): ReactElement {
  const classes = useStyles();
  const [icon, setIcon] = React.useState({
    clearIcon: (
      <IconButton
        className="display-none"
        onClick={() => {
          handleChange([] as string[]);
        }}>
        <ClearIcon />
      </IconButton>
    ),
    downIcon: <ExpandMoreIcon className="expand-more-icon" />,
    noIcon: <ExpandMoreIcon className="display-none" />
  });

  useEffect(() => {
    selectValidation([] as string[]);
  }, [label]);

  useEffect(() => {
    selectValidation(value);
  }, [value]);

  const selectValidation = (value: string[]) => {
    if (alwaysShowDownIcon) {
      setIcon({
        clearIcon: (
          <IconButton
            className="display-none"
            onClick={() => {
              handleChange([] as string[]);
            }}>
            <ClearIcon />
          </IconButton>
        ),
        downIcon: <ExpandMoreIcon className="expand-more-icon" />,
        noIcon: <ExpandMoreIcon className="display-none" />
      });
    } else if (value.length !== 0) {
      setIcon({
        clearIcon: (
          <IconButton
            className="icon-btn"
            onClick={() => {
              handleChange([] as string[]);
            }}>
            <ClearIcon />
          </IconButton>
        ),
        downIcon: <ExpandMoreIcon className="display-none" />,
        noIcon: <ExpandMoreIcon className="display-none" />
      });
    } else {
      setIcon({
        clearIcon: (
          <IconButton
            className="display-none"
            onClick={() => {
              handleChange([] as string[]);
            }}>
            <ClearIcon />
          </IconButton>
        ),
        downIcon: <ExpandMoreIcon className="expand-more-icon" />,
        noIcon: <ExpandMoreIcon className="display-none" />
      });
    }
  };

  const handleChange = (value: string[] | string) => {
    if (Array.isArray(value)) {
      onChange(value.filter(i => i !== ''));
    }
  };

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="caption">
          {label}
        </Typography>
      </div>
      <FormControl
        className={`${classes.formControl} ${selectClass}`}
        data-testid="form-control">
        {editable ? (
          <TextField
            id="fs-mask"
            style={selectStyle}
            fullWidth
            select
            SelectProps={{
              displayEmpty: true,
              IconComponent: () => icon.downIcon,
              multiple: true
            }}
            variant="outlined"
            onChange={({ target: { value } }) => {
              handleChange(value);
            }}
            value={value.length === 0 ? [''] : value}
            InputProps={{
              endAdornment: icon.clearIcon
            }}
            {...options}>
            <MenuItem
              id="fs-mask"
              className="menu-item"
              classes={{ root: classes.menuItemPlaceholder }}
              value=""
              disabled>
              {placeholder}
            </MenuItem>
            {menuItems.map(
              (item: string | number | MenuObject, index: number) => {
                if (typeof item === 'object') {
                  return (
                    <MenuItem key={index} id="fs-mask" value={item.value}>
                      {item.display}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={index} id="fs-mask" value={item}>
                      {item}
                    </MenuItem>
                  );
                }
              }
            )}
          </TextField>
        ) : (
          <TextField
            id="fs-mask"
            disabled={true}
            fullWidth
            value={value.toString()}
            variant="outlined"
            InputProps={{
              classes: {
                notchedOutline: classes.noBorder,
                input: classes.fixPadding,
                disabled: classes.disabledColor
              }
            }}
            inputProps={{
              maxLength: 50
            }}
            {...options}
          />
        )}
      </FormControl>
    </div>
  );
}
