import { ReactElement } from 'react';

import KaliberModal from '../../../../components/modal/KaliberModal';
import KaliberButton from '../../../../components/button/KaliberButton';
import Warning from '../../../../assets/icons/warning.svg';
import './MissingContactInfoComponent.scss';

interface MissingContactInfoComponentProps {
  isMinor: boolean;
  visible: boolean;
  onEdit: () => void;
  onContinue: () => void;
  setShowMissingContactInformationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export default function MissingContactInfoComponent({
  isMinor,
  visible,
  onEdit,
  onContinue,
  setShowMissingContactInformationModal
}: MissingContactInfoComponentProps): ReactElement {
  return (
    <div className="asp-missing-contact-information-modal-container">
      <KaliberModal
        visible={visible}
        closeIcon={true}
        onClose={() => setShowMissingContactInformationModal(false)}
        title={
          isMinor
            ? 'Missing parent/guardian contact information'
            : 'Missing patient contact information'
        }
        titleIcon={<img src={Warning} alt="" />}
        titleIconAlignment="left"
        titleAlignment="left"
        size="medium">
        <div className="asp-missing-contact-information-modal-text">
          {isMinor
            ? 'This patient is missing an email address and phone number of an emergency contact.'
            : 'This patient is missing an email address and phone number.'}
          <br />
          They will not be able to access their surgery report or other
          documents through the online portal.
        </div>
        <div className="asp-missing-contact-information-buttons-container">
          <KaliberButton
            type="button"
            onClick={() => onEdit()}
            buttonClass="btn--edit-information">
            Edit Information
          </KaliberButton>
          <KaliberButton
            type="submit"
            onClick={() => onContinue()}
            buttonClass="btn--primary">
            Continue
          </KaliberButton>
        </div>
      </KaliberModal>
    </div>
  );
}
