import { ReactElement, useState } from 'react';
import { ReactComponent as ClearWhite } from '../../assets/icons/ClearWhite.svg';
import { capitalizeFirstLetter } from '../../utils/textFormat';
import './KLSnackbar.scss';

interface KLSnackbarProps {
  text: string;
  loading?: boolean;
  onClose?: () => void;
}

export default function KLSnackbar({
  text,
  onClose
}: KLSnackbarProps): ReactElement {
  const [showSnackbar, setShowSnackbar] = useState(true);
  function closeSnackbar() {
    setShowSnackbar(false);

    if (onClose) {
      onClose();
    }
  }

  return (
    <div>
      {showSnackbar ? (
        <div className="kl-snackbar-container">
          <span className="kl-snackbar-text">
            {capitalizeFirstLetter(text)}
          </span>

          <ClearWhite
            className="kl-snackbar-close-icon"
            onClick={() => closeSnackbar()}
          />
        </div>
      ) : null}
    </div>
  );
}
