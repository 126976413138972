/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as SparkMD5 from 'spark-md5';

export function getKeyByValue(object: any, value: any): any {
  return Object.keys(object).find(key => object[key] === value);
}

export function computeChecksumMd5(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();

    // when it's available in memory, process it
    const frOnload = function (e: any): void {
      spark.append(e.target.result); // Accumulate chunk to md5 computation
      currentChunk++;
      if (currentChunk < chunks) {
        processChunk();
      } else {
        resolve(spark.end(true));
      }
    };

    const frOnError = function (): void {
      reject('MD5 computation failed - error reading the file');
    };

    // read chunk starting at `cursor` into memory
    function processChunk(): void {
      const fileReader = new FileReader();
      fileReader.onload = frOnload;
      fileReader.onerror = frOnError;
      const start = currentChunk * chunkSize;
      const end =
        start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(file.slice(start, end));
    }

    processChunk();
  });
}

export function objDeepEqual(obj1: any, obj2: any): boolean {
  let objectsAreSame = true;
  for (const propertyName in obj1) {
    if (
      obj1[propertyName as keyof typeof obj1] !==
      obj2[propertyName as keyof typeof obj2]
    ) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}

export const sleep = async (ms: any) =>
  new Promise(resolve => setTimeout(resolve, ms));

export function validatePassword(password: string) {
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  const hasSpecial = /[!@#$%^&*(),.?":{}|<>~+\-=\\[\];'/`_]/.test(password);

  const validCount = [hasUppercase, hasLowercase, hasDigit, hasSpecial].filter(
    Boolean
  ).length;

  return validCount >= 2;
}
