import React, { ReactElement, useState, useEffect } from 'react';
import { UnprocessableEntityError } from '../../api';
import KaliberButton from '../../components/button/KaliberButton';
import { createSurgery } from '../../redux/actions/surgeriesActions';
import { KLState, KLUser, NewSurgeryData } from '../../redux/types';
import { useSelector } from 'react-redux';
import './AddSurgeryConfirmationPage.scss';
import PageHeader from '../../components/pageHeader/PageHeader';
import { useHistory } from 'react-router-dom';
import KLSnackbar from '../../components/snackbar/KLSnackbar';
import { SurgeryDetailsDisplayComponent } from './components/surgeryDetails';
import { PatientInformationDisplayComponent } from './components/patientInformation';
import { GuardianContactDisplayComponent } from './components/guardianContact';
import { PatientAdditionalDetailsDisplayComponent } from './components/patientContact';
import { EmergencyContactDisplayComponent } from './components/patientContact/emergencyContact';

interface AddSurgeryConfirmationPageProps {
  newSurgeryData: NewSurgeryData;
  isMinor: boolean;
  navigateBack: (error?: boolean) => void;
}

export default function AddSurgeryConfirmationPage({
  newSurgeryData,
  isMinor,
  navigateBack
}: AddSurgeryConfirmationPageProps): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);

  const [loading, setLoading] = useState(false);
  const [showLoadingSnackbar, setShowLoadingSnackbar] = useState(false);
  const [currentDate, setCurrentDate] = useState<string>('');

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options: Intl.DateTimeFormatOptions = {
      timeZone: userTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    const dateArray = new Date()
      .toLocaleDateString('en-US', options)
      .split('/');
    const formattedDate = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  // Allow user to navigate back to Add Surgery Page using browser's back button
  useEffect(() => {
    const unblock = history.block((_, action) => {
      if (action === 'POP') {
        navigateBack();
        return false;
      }
    });

    return unblock;
  }, [navigateBack]);

  const handleSubmitSurgery = async () => {
    try {
      if (user.accessToken) {
        setLoading(true);
        setShowLoadingSnackbar(true);
        await createSurgery(user.accessToken, newSurgeryData, currentDate);
        setLoading(false);
        history.push('/surgeries', { surgerySuccessfullyCreated: true });
      }
    } catch (error) {
      setShowLoadingSnackbar(false);
      if (
        error instanceof UnprocessableEntityError &&
        error.message == 'Email already exists'
      ) {
        navigateBack(true);
      }
    }
  };

  return (
    <div className="add-surgery-container">
      <PageHeader title="Confirm New Surgery Details" onBack={navigateBack} />
      <div className="ascp__header-title">
        Please review all patient and surgery details for accuracy.
      </div>
      {showLoadingSnackbar ? (
        <KLSnackbar text="Processing..." loading={true} />
      ) : null}
      <div className="ascp__card">
        <PatientInformationDisplayComponent
          newSurgeryData={newSurgeryData}
          isMinor={isMinor}
        />
        <PatientAdditionalDetailsDisplayComponent
          newSurgeryData={newSurgeryData}
        />
        {isMinor ? (
          <GuardianContactDisplayComponent newSurgeryData={newSurgeryData} />
        ) : (
          <EmergencyContactDisplayComponent newSurgeryData={newSurgeryData} />
        )}
        <SurgeryDetailsDisplayComponent newSurgeryData={newSurgeryData} />
      </div>
      <KaliberButton
        buttonClass="btn--primary"
        type="button"
        loading={loading}
        onClick={handleSubmitSurgery}>
        Submit
      </KaliberButton>
      <div className="ascp__disclaimer">
        CPT copyright 2021 American Medical Association. All rights reserved.
      </div>
    </div>
  );
}
