/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { combineReducers } from 'redux';
import FacilitiesReducer from './facilitiesReducer';
import PatientReducer from './patientReducer';
import StaffReducer from './staffReducer';
import SurgeonsReducer from './surgeonsReducer';
import SurgeriesReducer from './surgeriesReducer';
import UiReducer from './uiReducer';
import UserReducer from './userReducer';
import QuickNotesReducer from './quickNotesReducer';
import PostOpInstructionsReducer from './postOpInstructionsReducer';
import TermsOfUseReducer from './termsOfUseReducer';
import CptCodesReducer from './cptCodesReducer';
import ProceduresReducer from './proceduresReducer';

const reducers = combineReducers({
  facilities: FacilitiesReducer,
  patient: PatientReducer,
  surgeries: SurgeriesReducer,
  ui: UiReducer,
  user: UserReducer,
  staff: StaffReducer,
  surgeons: SurgeonsReducer,
  quickNotes: QuickNotesReducer,
  postOpInstructions: PostOpInstructionsReducer,
  termsOfUseAgreed: TermsOfUseReducer,
  cptCodes: CptCodesReducer,
  procedures: ProceduresReducer
});

// Added a `RESET_APP` action to reset the redux store back
// to the initial state during testing. Implementation was taken from
// https://www.digitalocean.com/community/tutorials/redux-reset-state-redux
const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
