import React, { ReactElement } from 'react';
import './StyleGuide.scss';

export default function SGTypography(): ReactElement {
  return (
    <div className="scss-colors">
      <div>Font Sizes</div>
      <div className="color-set">
        <div className="fonts">
          <div className="fontSizeXXXXXL"> Font Size XXXXXL</div>
          <div className="fontSizeXXXXL"> Font Size XXXXL</div>
          <div className="fontSizeXXXL"> Font Size XXXL</div>
          <div className="fontSizeXXL"> Font Size XXL</div>
          <div className="fontSizeXL"> Font Size XL</div>
          <div className="fontSizeL"> Font Size L</div>
          <div className="fontSizeM"> Font Size M</div>
          <div className="fontSizeS"> Font Size S</div>
          <div className="fontSizeXS"> Font Size XS</div>
          <div className="fontSizeXXS"> Font Size XXS</div>
        </div>
      </div>
    </div>
  );
}
