import moment from 'moment';
import { ReactElement } from 'react';
import {
  EmergencyContact,
  PatientContact,
  PatientDemographics,
  SurgeryDetails
} from '..';
import { Patient, Surgery } from '../../../../redux/types';
import { isMinor } from '../../../../utils/validations';
import GuardianContact from '../guardianContact/GuardianContact';
import './PatientProfilePage.scss';

export interface PatientCardProps {
  patient: Patient;
  setReloadSurgery?: (shouldReload: boolean) => void;
}

interface PatientProfilePageProps {
  surgery: Surgery;
  setReloadSurgery: (shouldReload: boolean) => void;
  loading: boolean;
}

export default function PatientProfilePage({
  surgery,
  setReloadSurgery,
  loading
}: PatientProfilePageProps): ReactElement {
  return (
    <>
      {loading ? (
        <div className="quick-switch__container">
          <div className="surgery-page__skeleton surgery-page__skeleton--content" />
        </div>
      ) : (
        <div className="patient-profile-page-container">
          <PatientDemographics
            patient={surgery.patient}
            setReloadSurgery={setReloadSurgery}
          />
          <div className="patient-profile-contact__container">
            {isMinor(
              moment(surgery.patient.dob, 'MM DD YYYY'),
              surgery.patient.state ?? 'CA'
            ) ? (
              <GuardianContact
                patient={surgery.patient}
                setReloadSurgery={setReloadSurgery}
              />
            ) : (
              <>
                <PatientContact
                  patient={surgery.patient}
                  setReloadSurgery={setReloadSurgery}
                />
                <EmergencyContact
                  patient={surgery.patient}
                  setReloadSurgery={setReloadSurgery}
                />
              </>
            )}
          </div>
          <SurgeryDetails
            surgery={surgery}
            setReloadSurgery={setReloadSurgery}
          />
          <div className="patient-profile-page-container__disclaimer">
            CPT copyright 2021 American Medical Association. All rights
            reserved.
          </div>
        </div>
      )}
    </>
  );
}
