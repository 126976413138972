import { setProgress } from '../redux/actions/uiActions';
import { API_HOST_V2 } from '../constants';
import { DirectUpload } from './directUpload';
import { logoutUser } from '../redux/actions/userActions';

export { DirectUpload };

export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE'
}

export interface RequestOptions {
  accessToken?: string;
  method: RequestMethod;
  headers?: Record<string, string>;
  body?: BodyInit;
  signal?: AbortSignal;
}

export interface RequestOptionsUpload {
  accessToken?: string;
  method: RequestMethod;
  headers?: Record<string, string>;
  body?: XMLHttpRequestBodyInit;
  signal?: AbortSignal;
}

export class FatalError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'FatalError';
  }
}

export class BadRequestError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'BadRequest';
  }
}

export class AuthorizationError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'AuthorizationError';
  }
}

export class UnprocessableEntityError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'UnprocessableEntityError';
  }
}

export class DeletedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'DeletedError';
  }
}

/*
======== INFORMATION ABOUT CREDENTIALS AND MODE OPTIONS =======

When making requests to the Rails API via `callStratusAPIV2`
and `callStratusApiV2Unauthenticated` we always need to include
the options `credentials: 'include'` and `mode: 'coors'`. These
options make sure that the cookies that we send back from the
server are set and sent properly when making requests.

This is important because without these options users would be
logged out every time they refresh the app. We use an encrypted
cookie set from the server to store the user's token so we can
re-set the token value on the frontend incase of a refresh.

===============================================================
*/

export const callStratusApiV2 = async <T>(
  route: string,
  options: RequestOptions
): Promise<T> => {
  const { accessToken, method, body, headers, signal } = options;
  if (accessToken) {
    const res = await window.fetch(`${API_HOST_V2}/${route}`, {
      method,
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
      },
      body,
      signal,
      credentials: 'include',
      mode: 'cors'
    });
    return parseAPIResponse(res);
  } else {
    throw new AuthorizationError(
      'Called authenticated API without accesstoken'
    );
  }
};

export const callStratusApiV2Unauthenticated = async <T>(
  route: string,
  options: RequestOptions
): Promise<T> => {
  const { method, body, headers, signal } = options;

  const res = await window.fetch(`${API_HOST_V2}/${route}`, {
    method,
    headers: {
      ...headers
    },
    body,
    signal,
    credentials: 'include',
    mode: 'cors'
  });

  return parseAPIResponse(res);
};

export const callS3Upload = async (
  upload_url: string,
  options: RequestOptionsUpload
): Promise<void> => {
  return new Promise(function (resolve, reject) {
    const { method, body, headers, signal } = options;
    const xhttp = new XMLHttpRequest();
    xhttp.open(method, upload_url, true);
    if (headers) {
      Object.keys(headers).map(key => {
        xhttp.setRequestHeader(key, `${headers[key as keyof typeof headers]}`);
      });
    }

    xhttp.upload.onprogress = function (pe) {
      if (pe.lengthComputable) {
        const loadedMegabytes = pe.loaded / 1e6;
        setProgress(parseFloat(loadedMegabytes.toFixed(3)));
      }
    };

    xhttp.upload.onload = function () {
      resolve(xhttp.response);
    };
    xhttp.upload.onerror = function () {
      reject({
        status: this.status,
        statusText: xhttp.statusText
      });
    };
    xhttp.send(body);

    if (signal?.aborted) {
      xhttp.abort();
    }
  });
};

const parseAPIResponse = async <T>(response: Response): Promise<T> => {
  const responseBody = await response.json();
  if (!response.ok) {
    if (response.status === 500) {
      throw new FatalError(responseBody.error);
    } else if (response.status === 400) {
      throw new BadRequestError(responseBody.error);
    } else if (response.status === 401) {
      await logoutUser(true);
    } else if (response.status === 403) {
      throw new AuthorizationError(responseBody.error);
    } else if (response.status === 410) {
      throw new DeletedError(responseBody.error);
    } else if (response.status === 422) {
      throw new UnprocessableEntityError(responseBody.error);
    } else {
      throw new Error(responseBody.error);
    }
  }

  return Promise.resolve(responseBody);
};

export interface AccessTokenOptions {
  audience: string;
  scope?: string;
}

export interface AccessTokenResponse {
  accessToken: string;
  refresh: () => void;
}
