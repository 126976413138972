import { ReactElement } from 'react';
import {
  Controller,
  Control,
  FieldErrorsImpl,
  UseFormRegister
} from 'react-hook-form';

import KaliberCard from '../../../../components/card/KaliberCard';
import KaliberTextField from '../../../../components/textField/KaliberTextField';
import KaliberSelect from '../../../../components/multiselect/KaliberSelect';
import { RACE_OPTIONS } from '../../../../constants';
import '../../AddSurgeryPage.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface PatientAdditionalDetailsFormComponentProps {
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<any>;
  control: Control<any, any>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function PatientAdditionalDetailsFormComponent({
  register,
  errors,
  control
}: PatientAdditionalDetailsFormComponentProps): ReactElement {
  return (
    <div className="patient-add-details-container">
      <KaliberCard title="Additional Details (optional)" titleAlignment="left">
        <div className="asp-form-inputs">
          <div className="asp-patient-details">
            <KaliberTextField
              label="Preferred Name (optional)"
              placeholder=""
              name="preferredName"
              register={register}
              errors={errors}
            />

            <Controller
              name="sex"
              control={control}
              render={({ field }) => (
                <KaliberSelect
                  label="Sex (optional)"
                  select={field.value}
                  placeholder=""
                  includeOther={false}
                  onChange={e => {
                    if (e === '') {
                      field.onChange('');
                    } else {
                      field.onChange(e);
                    }
                  }}
                  list={['', 'Male', 'Female']}
                />
              )}
            />
            <Controller
              name="pronouns"
              control={control}
              render={({ field }) => (
                <KaliberSelect
                  label="Pronouns (optional)"
                  placeholder=""
                  select={field.value}
                  includeOther={false}
                  onChange={e => {
                    if (e === '') {
                      field.onChange('');
                    } else {
                      field.onChange(e);
                    }
                  }}
                  list={['', 'He/Him', 'She/Her', 'They/Them', 'Name']}
                />
              )}
            />
            <Controller
              name="race"
              control={control}
              render={({ field }) => (
                <KaliberSelect
                  label="Race (optional)"
                  placeholder=""
                  select={field.value}
                  includeOther={false}
                  onChange={e => {
                    if (e === '') {
                      field.onChange(null);
                    } else {
                      field.onChange(e);
                    }
                  }}
                  list={RACE_OPTIONS}
                />
              )}
            />
          </div>
        </div>
      </KaliberCard>
    </div>
  );
}
