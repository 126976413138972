import { AnyAction } from 'redux';
import { KLFacilities } from '../types';
import camelcaseKeys from 'camelcase-keys';

const initialState = {
  facilities: [],
  facilitiesNames: []
};

export default function FacilitiesReducer(
  state = initialState,
  action: AnyAction
): KLFacilities {
  switch (action.type) {
    case 'SET_FACILITIES': {
      return {
        ...state,
        facilities: camelcaseKeys(action.payload)
      };
    }
    case 'SET_FACILITIES_NAMES': {
      return {
        ...state,
        facilitiesNames: action.payload
      };
    }
    default:
      return state;
  }
}
