import { ReactElement } from 'react';
import {
  Controller,
  Control,
  FieldErrorsImpl,
  UseFormRegister
} from 'react-hook-form';
import moment from 'moment';

import KaliberCard from '../../../../components/card/KaliberCard';
import KaliberTextField from '../../../../components/textField/KaliberTextField';
import KaliberDateOfBirthPicker from '../../../../components/datepicker/KaliberDateOfBirthPicker';
import KaliberSelect from '../../../../components/multiselect/KaliberSelect';
import { STATE_OPTIONS } from '../../../../constants';
import '../../AddSurgeryPage.scss';
import KaliberPhoneField from '../../../../components/phoneField/KaliberPhoneField';
import { isDefinedAndNotNull } from '../../../../utils/validations';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface PatientInformationFromComponentProps {
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<any>;
  control: Control<any, any>;
  isMinor: boolean;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function PatientInformationFormComponent({
  register,
  errors,
  control,
  isMinor
}: PatientInformationFromComponentProps): ReactElement {
  const hasNameError =
    isDefinedAndNotNull(errors.firstName) ||
    isDefinedAndNotNull(errors.lastName);

  return (
    <KaliberCard title="Patient Information" titleAlignment="left">
      <div
        className={`asp-form-inputs ${hasNameError ? 'has-name-error' : ''}`}>
        <div
          className={`asp-name-fields ${hasNameError ? 'has-name-error' : ''}`}>
          <div className="first-name">
            <KaliberTextField
              label="Patient Name"
              name="firstName"
              placeholder="First Name"
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="First name is required"
              tabIndex={0}
            />
          </div>
          <div className="middle-name">
            <KaliberTextField
              placeholder="Middle Name (optional)"
              name="middleName"
              register={register}
              errors={errors}
            />
          </div>
          <div className="last-name">
            <KaliberTextField
              name="lastName"
              placeholder="Last Name"
              register={register}
              registerObject={{ required: true }}
              errors={errors}
              errorMessage="Last name is required"
            />
          </div>
        </div>
        <div className="asp-patient-details">
          <div className="asp-patient-details-subcontainer">
            <Controller
              name="dob"
              control={control}
              rules={{
                required: true,
                validate: date => {
                  const maxDate = moment().toDate();
                  const minDate = moment().subtract(125, 'years').toDate();
                  return (
                    !isNaN(date) &&
                    date >= minDate &&
                    date <= maxDate &&
                    date.getFullYear().toString().length === 4
                  );
                }
              }}
              render={({ field }) => (
                <KaliberDateOfBirthPicker
                  editable={true}
                  className="asp-datepicker-size"
                  name="dob"
                  label="Date of Birth"
                  errors={errors}
                  errorMessage="Date of birth is required"
                  value={field.value ?? null}
                  onChange={date => field.onChange(date)}
                  dataTestId="test-dob"
                  maxDate={moment().toDate()}
                  minDate={moment().subtract(125, 'years').toDate()}
                />
              )}
            />
            <div className="asp-address-container">
              <KaliberTextField
                label="Address"
                placeholder=""
                name="address"
                register={register}
                registerObject={{ required: true }}
                errors={errors}
                errorMessage="Address is required"
              />
            </div>
            <div className="asp-locality-container">
              <KaliberTextField
                label="City"
                placeholder=""
                name="city"
                register={register}
                registerObject={{ required: true }}
                errors={errors}
                errorMessage="City is required"
              />
              <Controller
                name="state"
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <KaliberSelect
                    label="State"
                    placeholder=""
                    select={field.value}
                    errors={errors}
                    hasError={!!error}
                    includeOther={false}
                    onChange={e => field.onChange(e)}
                    list={Object.keys(STATE_OPTIONS)}
                    fullWidth
                  />
                )}
              />
              <div className="asp-zip-container">
                <KaliberTextField
                  label="ZIP Code"
                  placeholder=""
                  name="zipcode"
                  register={register}
                  registerObject={{ required: true }}
                  errors={errors}
                  errorMessage="Zipcode is required"
                />
              </div>
            </div>
            {!isMinor && (
              <>
                <div className="asp-mobile-phone-field">
                  <KaliberPhoneField
                    label="Mobile Phone (recommended)"
                    name="phone"
                    placeholder={false}
                    errors={errors}
                    control={control}
                  />
                </div>
                <div className="asp-email-field">
                  <KaliberTextField
                    label="Email (recommended)"
                    placeholder=""
                    name="email"
                    register={register}
                    errorMessage="This email is already in use. Please enter a new one."
                    errors={errors}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </KaliberCard>
  );
}
