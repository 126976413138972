import { ReactElement } from 'react';

import KaliberCard from '../../../../components/card/KaliberCard';
import { NewSurgeryData } from '../../../../redux/types';
import '../../AddSurgeryPage.scss';
import { OptionalValueDisplay } from '../../../../components';

interface PatientAdditionalDetailsDisplayComponentProps {
  newSurgeryData: NewSurgeryData;
}

export default function PatientAdditionalDetailsDisplayComponent({
  newSurgeryData
}: PatientAdditionalDetailsDisplayComponentProps): ReactElement {
  return (
    <KaliberCard title="Additional Details" titleAlignment="left">
      <div className="ascp-info-section-container">
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Preferred Name</div>
            <OptionalValueDisplay value={newSurgeryData.preferredName}>
              <span className="ascp-info-value">
                {newSurgeryData.preferredName}
              </span>
            </OptionalValueDisplay>
          </div>
        </div>
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Sex</div>
            <OptionalValueDisplay value={newSurgeryData.sex}>
              <div className="ascp-info-value">
                {/* TODO: update to use formatter */}
                {newSurgeryData.sex === ''
                  ? ''
                  : newSurgeryData.sex === 'F'
                  ? 'Female'
                  : 'Male'}
              </div>
            </OptionalValueDisplay>
          </div>
        </div>
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Pronouns</div>
            <OptionalValueDisplay value={newSurgeryData.pronouns}>
              <div className="ascp-info-value">
                {newSurgeryData.pronouns.replace('_', '/')}
              </div>
            </OptionalValueDisplay>
          </div>
        </div>
        <div className="ascp-row-container">
          <div className="ascp-info-card">
            <div className="ascp-info-label">Race</div>
            <OptionalValueDisplay value={newSurgeryData.race}>
              <div className="ascp-info-value">{newSurgeryData.race}</div>
            </OptionalValueDisplay>
          </div>
        </div>
      </div>
    </KaliberCard>
  );
}
