import React, { ReactElement } from 'react';
import deleteSVG from '../../assets/icons/delete.svg';
import kaliberUSB from '../../assets/icons/kaliber-thumbdrive.svg';
import { useHistory } from 'react-router';
import './KLUploader.scss';
import KaliberButton from '../button/KaliberButton';

interface KLUploaderProps {
  patientName: string | undefined | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: File[];
  setFiles: (val: File[]) => void;
  hasValidFiles: boolean;
  handleRemove: (val: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenConfirmationModal: () => void;
}

export default function KLUploader({
  patientName,
  files,
  setFiles,
  hasValidFiles,
  handleRemove,
  handleChange,
  handleOpenConfirmationModal
}: KLUploaderProps): ReactElement {
  const history = useHistory();

  return (
    <div className="upload-container">
      <h4 className="upload-title" id="fs-mask">
        Insert Kaliber USB for {patientName}
      </h4>
      <img src={kaliberUSB} alt="kaliber usb" className="kaliber-usb" />
      {files.length !== 0 && !hasValidFiles && (
        <div className="error-text">
          Invalid image format. Please only upload .jpg, .jpeg, and .png image
          files
        </div>
      )}
      <div className="uploader-btn-container">
        {files.length !== 0 && hasValidFiles ? (
          <div className="uploader-button">
            <KaliberButton
              buttonClass="btn--primary"
              type="button"
              onClick={handleOpenConfirmationModal}
              data-testid={'inputUpload'}>
              Upload Files
            </KaliberButton>
          </div>
        ) : (
          <div className="btn--primary">
            <label className="btn__text">
              Select Files
              <input
                type="file"
                multiple
                hidden
                onChange={handleChange}
                data-testid={'inputSelect'}
              />
            </label>
          </div>
        )}
        <KaliberButton
          type="button"
          buttonClass="btn--cancel"
          onClick={() => {
            setFiles([]);
            history.goBack();
          }}>
          Cancel
        </KaliberButton>
      </div>
      <div className="upload-view">
        <div className="upload-header">Files selected for uploading:</div>

        {files.length !== 0 ? (
          <div className="upload-success">
            {files.map((file, index) => (
              <div key={index} className="file-row">
                <div className="file-content">
                  <div className="files-header">
                    {index + 1}. {file.name}
                  </div>
                  <img
                    className="remove-btn"
                    src={deleteSVG}
                    alt="delete"
                    onClick={() => handleRemove(file.name)}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="files-header">No file uploaded</div>
        )}
      </div>
    </div>
  );
}
