/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';

import './KaliberTextField.scss';

interface KaliberTextFieldProps {
  label?: Path<any>;
  name: string;
  register?: UseFormRegister<any>;
  registerObject?: any;
  errors?: any;
  disabled?: boolean;
  placeholder?: string;
  errorMessage?: string;
  tabIndex?: number;
  dataTestId?: string;
  type?: string;
  children?: any;
  onChange?: (val: any) => void;
  value?: string;
  restrictToDecimals?: boolean;
  id?: string;
}
export default function KaliberTextField({
  label,
  name,
  register,
  errors = {},
  errorMessage,
  tabIndex,
  dataTestId,
  registerObject,
  type = 'text',
  children,
  restrictToDecimals,
  id,
  disabled = false,
  ...options
}: KaliberTextFieldProps): ReactElement {
  const isError = Boolean(errors[name]);

  const includeRegister = () => {
    if (register) {
      return register(name, registerObject);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow only one decimal point and numbers
    const charCode = event.charCode;
    const value = event.currentTarget.value;
    const hasDecimalPoint = value.includes('.');
    if (
      restrictToDecimals &&
      (charCode !== 46 || hasDecimalPoint) &&
      (charCode < 48 || charCode > 57)
    ) {
      // charCode 46 is the period, charCode 48-57 are numbers 0-9
      event.preventDefault();
    }
  };

  return (
    <div className="k-text-field">
      <div>
        {label && (
          <div className="k-text-field__label-container">
            <label
              htmlFor={id || name}
              className={`k-text-field__label-text ${
                disabled ? 'k-text-field__label-text--disabled' : ''
              }`}>
              {label}
            </label>
          </div>
        )}
      </div>
      <div
        className={`k-text-field__input-container ${
          isError ? 'k-text-field__input-container--error' : ''
        }`}>
        <input
          id={id || name}
          type={type}
          data-testid={dataTestId ? dataTestId : 'k-text-input'}
          className={`k-text-field__input-text ${
            disabled ? 'k-text-field__input-text--disabled' : ''
          }`}
          tabIndex={tabIndex}
          inputMode={restrictToDecimals ? 'decimal' : 'text'} // Brings up numeric keypad on mobile devices
          onKeyPress={restrictToDecimals ? handleKeyPress : undefined}
          disabled={disabled}
          {...includeRegister()}
          {...options}
        />
        {children}
      </div>
      {isError && (
        <div
          className="k-text-field__input-error"
          data-testid={`${dataTestId}-error-message`}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}
