import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import KLAlert from '../../components/alert/KLAlert';
import KaliberCard from '../../components/card/KaliberCard';
import KLNpiField from '../../components/npiField/KLNpiField';
import KLPhoneField from '../../components/phoneField/KLPhoneField';
import KLSelect from '../../components/select/KLSelect';
import KLTextField from '../../components/textField/KLTextField';
import ToggleButton from '../../components/toggleButtonGroup/ToggleButton';
import ToggleButtonGroup from '../../components/toggleButtonGroup/ToggleGroup';
import {
  getFacilities,
  updateFacility
} from '../../redux/actions/facilitiesActions';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import { getStaffListByFacility } from '../../redux/actions/staffActions';
import { setEditing, setLoading } from '../../redux/actions/uiActions';
import {
  KLFacilities,
  KLFacility,
  KLState,
  KLUi,
  KLUser,
  Staff
} from '../../redux/types';
import { useQuery } from '../../utils/react';
import { isEmpty, isNotEmpty } from '../../utils/validations';
import ErrorPage from '../errorPage/ErrorPage';
import './FacilityDetail.scss';
import { StaffViewComponent } from './components';

export default function FacilityDetail(): ReactElement {
  useEffect(() => {
    setFullstoryPage('Facility Page');
  }, []);

  const [user, facilities, ui, staffList]: [
    KLUser,
    KLFacilities,
    KLUi,
    Staff[]
  ] = useSelector((state: KLState) => [
    state.user,
    state.facilities,
    state.ui,
    state.staff
  ]);

  useEffect(() => {
    return () => {
      setEditing(false);
    };
  }, []);

  const [toggleStatus, setToggleStatus] = React.useState<string>('overview');

  const query = useQuery();
  const queryParam = query.get('facilityId');

  const [facilityId, setFacilityId] = useState<number | null>(null);
  const [facilityType, setFacilityType] = useState('');

  const [previouslyEditing, setPreviouslyEditing] = useState(false);
  const [noFacilityError, setNoFacilityError] = useState(false);
  const [facilityInfo, setFacilityInfo] = useState<KLFacility>({
    facilityId: 0,
    facilityName: '',
    facilityType: '',
    facilityAddress: '',
    facilityPhone: '',
    facilityFax: '',
    facilityEmail: '',
    facilityAfterHoursPhone: '',
    facilityWebsite: '',
    facilityNpi: ''
  });

  const [facilityErrors, setFacilityErrors] = useState({
    facilityName: false,
    facilityType: false,
    facilityAddress: false,
    facilityPhone: false,
    facilityFax: false,
    facilityEmail: false,
    facilityAfterHoursPhone: false,
    facilityWebsite: false,
    facilityNpi: false
  });

  const [isForbidden, setForbidden] = useState(false);

  useEffect(() => {
    const noError = Object.values(facilityErrors).every(item => item === false);

    let notEmpty = false;
    if (
      isNotEmpty(facilityInfo.facilityName) &&
      isNotEmpty(facilityInfo.facilityAddress) &&
      isNotEmpty(facilityInfo.facilityPhone) &&
      isNotEmpty(facilityInfo.facilityEmail) &&
      isNotEmpty(facilityInfo.facilityAfterHoursPhone) &&
      isNotEmpty(facilityInfo.facilityWebsite) &&
      isNotEmpty(facilityInfo.facilityNpi) &&
      ui.editing
    ) {
      notEmpty = true;
    }

    setNoFacilityError(noError && notEmpty);
  }, [facilityErrors, facilityInfo, ui.editing]);

  useEffect(() => {
    if (queryParam) {
      setFacilityId(parseInt(queryParam, 10));
    }
  }, [query]);

  useEffect(() => {
    setLoading(true);
    const staff = async () => {
      if (user.accessToken && facilityId) {
        const message = await getStaffListByFacility(
          user.accessToken,
          facilityId
        );

        if (
          message ===
            "Seems you don't have authorization to see this information, please contact your administrator" ||
          message === 'No facilities associated with this user'
        ) {
          setForbidden(true);
        } else {
          if (
            message !== 'ok' &&
            !message.includes('No staff associated with this facility.')
          ) {
            setAlertMessage(message);
            setShowAlert(true);
          }
        }
        setLoading(false);
      }
    };

    staff();
  }, [user.accessToken, facilityId]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleFacilityType = (val: string) => {
    setFacilityType(val);
    if (val !== '') {
      setFacilityInfo({
        ...facilityInfo,
        facilityType: val
      });
    }
  };

  const handleToggle = (updateToggle: string | string[]) => {
    if (updateToggle !== null && typeof updateToggle === 'string') {
      setToggleStatus(updateToggle);
    }
  };

  useEffect(() => {
    setLoading(true);
    const facilities = async () => {
      if (facilityId && user.accessToken) {
        const message = await getFacilities(user.accessToken);
        if (
          message === 'No facilities associated with this user' ||
          message ===
            "Seems you don't have authorization to see this information, please contact your administrator."
        ) {
          setForbidden(true);
          setLoading(false);
        } else {
          if (message !== 'ok') {
            setAlertMessage(message);
            setShowAlert(true);
            setLoading(false);
          }
        }
      }
    };

    facilities();
  }, [user.accessToken, facilityId]);

  useEffect(() => {
    if (facilities.facilities.length > 0) {
      const selectedFacility = facilities.facilities.filter(
        element => element.facilityId === facilityId
      );
      if (selectedFacility.length > 0) {
        setFacilityInfo(selectedFacility[0]);
        setFacilityType(selectedFacility[0].facilityType);
        setForbidden(false);
      } else {
        setForbidden(true);
      }
      setLoading(false);
    }
  }, [facilityId, facilities]);

  useEffect(() => {
    if (!ui.editing && user.accessToken && previouslyEditing && facilityId) {
      updateFacility(user.accessToken, facilityId, facilityInfo);
    }
    setPreviouslyEditing(ui.editing);
  }, [ui.editing, facilityId]);

  return isForbidden ? (
    <ErrorPage />
  ) : (
    <div className="page-container">
      <KLAlert visible={showAlert} label={alertMessage} />
      <Prompt
        when={ui.editing}
        message="There are unsaved changes, are you sure you want to leave?"
      />
      <div className="facility-container">
        <div className="nav-toggle">
          <ToggleButtonGroup
            value={toggleStatus}
            onChange={handleToggle}
            exclusive>
            <ToggleButton value="overview">Overview</ToggleButton>
            <ToggleButton value="staff">Staff</ToggleButton>
          </ToggleButtonGroup>
        </div>

        {toggleStatus === 'overview' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Box component="form" noValidate sx={{ width: '100%' }}>
              <KaliberCard
                title="Facility information"
                titleAlignment="left"
                editButton={
                  user.role === 'Kaliber Admin' || user.role === 'Surgeon'
                }
                onClickEdit={() => {
                  if (ui.editing) {
                    if (noFacilityError) {
                      setEditing(!ui.editing);
                    }
                  } else {
                    setEditing(!ui.editing);
                  }
                }}>
                <div className="info-container">
                  <Grid
                    className="info-row"
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={2} sm={2} md={3}>
                      <KLTextField
                        editable={ui.editing}
                        isRequired
                        placeholder="Facility name"
                        label="Facility name*"
                        name="FacilityName"
                        value={facilityInfo?.facilityName}
                        dataTestId="pp-first-name"
                        onChange={val => {
                          setFacilityInfo({
                            ...facilityInfo,
                            facilityName: val.target.value
                          });
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityName: isEmpty(val.target.value)
                              ? true
                              : false
                          });
                        }}
                        onBlur={() =>
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityName: isEmpty(facilityInfo.facilityName)
                              ? true
                              : false
                          })
                        }
                        error={facilityErrors.facilityName}
                        helperText={
                          facilityErrors.facilityName
                            ? 'Please provide a facility name.'
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={3}>
                      <KLSelect
                        editable={ui.editing}
                        label="Facility type*"
                        value={facilityType}
                        onChange={handleFacilityType}
                        placeholder="Select"
                        menuItems={['Clinic', 'Surgery Center', 'Practice']}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    className="info-row"
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={2} sm={2} md={3}>
                      <KLTextField
                        editable={ui.editing}
                        isRequired
                        placeholder="Address"
                        label="Facility address*"
                        name="FacilityAddress"
                        value={facilityInfo?.facilityAddress}
                        onChange={val => {
                          setFacilityInfo({
                            ...facilityInfo,
                            facilityAddress: val.target.value
                          });
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityAddress: isEmpty(
                              facilityInfo.facilityAddress
                            )
                              ? true
                              : false
                          });
                        }}
                        onBlur={() =>
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityAddress: isEmpty(
                              facilityInfo.facilityAddress
                            )
                              ? true
                              : false
                          })
                        }
                        error={facilityErrors.facilityAddress}
                        helperText={
                          facilityErrors.facilityAddress
                            ? 'Please provide a facility address.'
                            : ''
                        }
                      />
                    </Grid>

                    <Grid item xs={1} sm={2} md={3}>
                      <KLNpiField
                        editable={ui.editing}
                        isRequired
                        placeholder="NPI"
                        label="NPI*"
                        value={
                          facilityInfo.facilityNpi !== undefined
                            ? facilityInfo.facilityNpi
                            : ''
                        }
                        helperText={
                          facilityErrors.facilityNpi
                            ? 'Please provide a 10 digits NPI'
                            : ''
                        }
                        onChange={val => {
                          setFacilityInfo({
                            ...facilityInfo,
                            facilityNpi: val.target.value.toLowerCase()
                          });
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityNpi:
                              val.target.value === '' ||
                              val.target.value.length < 10
                          });
                        }}
                        error={facilityErrors.facilityNpi}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    className="info-row"
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={2} sm={2} md={3}>
                      <KLPhoneField
                        editable={ui.editing}
                        id="officeNumber"
                        label="Office phone*"
                        placeholder="(XXX) XXX-XXXX"
                        value={facilityInfo?.facilityPhone}
                        isRequired
                        onChange={val => {
                          setFacilityInfo({
                            ...facilityInfo,
                            facilityPhone: val.target.value
                          });
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityPhone: isEmpty(facilityInfo.facilityPhone)
                              ? true
                              : false
                          });
                        }}
                        onBlur={() =>
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityPhone: isEmpty(facilityInfo.facilityPhone)
                              ? true
                              : false
                          })
                        }
                        error={facilityErrors.facilityPhone}
                        helperText={
                          facilityErrors.facilityPhone
                            ? 'Please provide a facility phone.'
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={3}>
                      <KLPhoneField
                        editable={ui.editing}
                        id="faxNumber"
                        label="Fax"
                        placeholder="(XXX) XXX-XXXX"
                        value={facilityInfo.facilityFax}
                        onChange={val => {
                          setFacilityInfo({
                            ...facilityInfo,
                            facilityFax: val.target.value
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={3}>
                      <KLTextField
                        editable={ui.editing}
                        isRequired
                        type="email"
                        placeholder="Email"
                        label="Email*"
                        name="Email"
                        value={facilityInfo.facilityEmail.toLowerCase()}
                        onChange={val => {
                          setFacilityInfo({
                            ...facilityInfo,
                            facilityEmail: val.target.value
                          });
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityEmail: isEmpty(facilityInfo.facilityEmail)
                              ? true
                              : false
                          });
                        }}
                        onBlur={() =>
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityEmail: isEmpty(facilityInfo.facilityEmail)
                              ? true
                              : false
                          })
                        }
                        error={facilityErrors.facilityEmail}
                        helperText={
                          facilityErrors.facilityEmail
                            ? 'Please provide a facility email.'
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    className="info-row"
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={2} sm={2} md={3}>
                      <KLPhoneField
                        editable={ui.editing}
                        id="afterHours"
                        label="After hours*"
                        placeholder="(XXX) XXX-XXXX"
                        value={facilityInfo.facilityAfterHoursPhone}
                        isRequired
                        onChange={val => {
                          setFacilityInfo({
                            ...facilityInfo,
                            facilityAfterHoursPhone: val.target.value
                          });
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityAfterHoursPhone: isEmpty(
                              facilityInfo.facilityAfterHoursPhone
                            )
                              ? true
                              : false
                          });
                        }}
                        onBlur={() =>
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityAfterHoursPhone: isEmpty(
                              facilityInfo.facilityAfterHoursPhone
                            )
                              ? true
                              : false
                          })
                        }
                        error={facilityErrors.facilityAfterHoursPhone}
                        helperText={
                          facilityErrors.facilityAfterHoursPhone
                            ? 'Please provide a facility after hours phone.'
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={3}></Grid>
                    <Grid item xs={2} sm={2} md={3}>
                      <KLTextField
                        editable={ui.editing}
                        isRequired
                        placeholder="Website"
                        label="Website*"
                        name="Website"
                        value={facilityInfo.facilityWebsite}
                        onChange={val => {
                          setFacilityInfo({
                            ...facilityInfo,
                            facilityWebsite: val.target.value
                          });
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityWebsite: isEmpty(
                              facilityInfo.facilityWebsite
                            )
                              ? true
                              : false
                          });
                        }}
                        onBlur={() =>
                          setFacilityErrors({
                            ...facilityErrors,
                            facilityWebsite: isEmpty(
                              facilityInfo.facilityWebsite
                            )
                              ? true
                              : false
                          })
                        }
                        error={facilityErrors.facilityWebsite}
                        helperText={
                          facilityErrors.facilityWebsite
                            ? 'Please provide a facility website.'
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </KaliberCard>
            </Box>
          </Box>
        ) : (
          <StaffViewComponent staffList={staffList} />
        )}
      </div>
    </div>
  );
}
