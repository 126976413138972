import store from '../store';
import {
  callStratusApiV2,
  RequestMethod,
  BadRequestError,
  AuthorizationError
} from '../../api';
import { Staff } from '../types';

export async function getStaff(accessToken: string): Promise<string> {
  let message = 'ok';
  let data: Staff[] = [];
  try {
    const res = await callStratusApiV2<{ data: Staff[] }>('staffs', {
      accessToken,
      method: RequestMethod.Get
    });

    data = res.data;
  } catch (error) {
    if (error instanceof BadRequestError) {
      message = 'Review your data and try again';
    } else if (error instanceof AuthorizationError) {
      message =
        "Seems you don't have authorization to see this information, please contact your administrator.";
    } else if (error instanceof Error) {
      message = JSON.parse(error.message).message;
    }
  }

  store.dispatch({
    type: 'SET_STAFF',
    payload: data
  });

  return message;
}

export async function getStaffListByFacility(
  accessToken: string,
  facilityId: number
): Promise<string> {
  let message = 'ok';
  let data: Staff[] = [];
  try {
    const res = await callStratusApiV2<{ data: Staff[] }>(
      `facilities/${facilityId}/staffs`,
      {
        accessToken,
        method: RequestMethod.Get
      }
    );
    data = res.data;
  } catch (error) {
    if (error instanceof BadRequestError) {
      message = 'Review your data and try again';
    } else if (error instanceof AuthorizationError) {
      message =
        "Seems you don't have authorization to see this information, please contact your administrator.";
    } else if (error instanceof Error) {
      message = `${error}`;
    }
  }
  store.dispatch({
    type: 'SET_STAFF',
    payload: data
  });
  return message;
}

export async function getSurgeonByUid(
  accessToken: string,
  uid: number
): Promise<number> {
  const res = await callStratusApiV2<{ surgeon_id: number }>(
    `surgeons/${uid}`,
    {
      accessToken,
      method: RequestMethod.Get
    }
  );
  return res.surgeon_id;
}
