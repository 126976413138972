import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import KaliberButton from '../../../components/button/KaliberButton';
import KaliberCard from '../../../components/card/KaliberCard';
import KLRadioBox from '../../../components/radioBox/KLRadioBox';
import {
  deleteOtherDocsByFilename,
  deletePostOpInstructions,
  getPostOpInstructions,
  getPostOpOtherDocs,
  setPostOpOtherDocs,
  setSurgeonPostOpInstructions,
  uploadCustomPdf
} from '../../../redux/actions/postOpInstructionsActions';
import { getSurgeonByUid } from '../../../redux/actions/staffActions';
import {
  KLPostOpInstructions,
  KLPostOpOtherDocs,
  KLState,
  KLSurgeonPostOpInstructions,
  KLUser
} from '../../../redux/types';
import { isNotEmpty } from '../../../utils/validations';
import './../Settings.scss';
import PostOpInstructionsOtherDocs from './PostOpInstructionsOtherDocs';
import KaliberModal from '../../../components/modal/KaliberModal';
import { DUPLICATE_PDF_ERROR_MESSAGE } from '../../../constants';

export default function PostOpInstructions(): ReactElement {
  const [user, postOpInstructions]: [KLUser, KLPostOpInstructions] =
    useSelector((state: KLState) => [state.user, state.postOpInstructions]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [shoulder, setShoulder] = useState<KLSurgeonPostOpInstructions[]>([]);
  const [knee, setKnee] = useState<KLSurgeonPostOpInstructions[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [otherDocsShoulder, setOtherDocsShoulder] = useState<
    KLPostOpOtherDocs[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otherDocsKnee, setOtherDocsKnee] = useState<KLPostOpOtherDocs[]>([]);

  const [defaultShoulder, setDefaultShoulder] =
    useState<KLSurgeonPostOpInstructions>({} as KLSurgeonPostOpInstructions);
  const [defaultKnee, setDefaultKnee] = useState<KLSurgeonPostOpInstructions>(
    {} as KLSurgeonPostOpInstructions
  );

  const [canSave, setCanSave] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [surgeonId, setSurgeonId] = useState<number>(0);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const getDefaultFileName = (url: string) => {
    const splitUrl = url.split('/');
    return splitUrl[splitUrl.length - 1];
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user.accessToken && user.role === 'Surgeon') {
        const id = await getSurgeonByUid(
          user.accessToken,
          parseInt(user.uid ?? '0')
        );
        setSurgeonId(id);
      }
    };
    fetchData();
  }, [user.accessToken]);

  useEffect(() => {
    if (user.accessToken && user.role === 'Surgeon' && surgeonId !== 0) {
      getPostOpOtherDocs(user.accessToken, surgeonId);
      getPostOpInstructions(user.accessToken, surgeonId).then(() => {
        setDataLoaded(true);
      });
    }
  }, [user.accessToken, surgeonId]);

  useEffect(() => {
    const tempKnee: KLSurgeonPostOpInstructions[] = [];
    const tempShoulder: KLSurgeonPostOpInstructions[] = [];

    const tempOtherDocsKnee: KLPostOpOtherDocs[] = [];
    const tempOtherDocsShoulder: KLPostOpOtherDocs[] = [];

    if (dataLoaded && postOpInstructions.pdfList) {
      postOpInstructions.pdfList.forEach(element => {
        element.surgeonId = surgeonId;
        switch (element.region) {
          case 'knee':
            tempKnee.push({ ...element });
            break;
          case 'shoulder':
            tempShoulder.push({ ...element });
            break;
        }
      });

      postOpInstructions.other.forEach(element => {
        element.surgeonId = surgeonId;
        switch (element.region) {
          case 'knee':
            tempOtherDocsKnee.push({ ...element });
            break;
          case 'shoulder':
            tempOtherDocsShoulder.push({ ...element });
            break;
        }
      });
      setKnee([...tempKnee]);
      setShoulder([...tempShoulder]);

      setDefaultShoulder(
        postOpInstructions.defaults.filter(e => e.region === 'shoulder')[0]
      );
      setDefaultKnee(
        postOpInstructions.defaults.filter(e => e.region === 'knee')[0]
      );
      setOtherDocsShoulder([...tempOtherDocsShoulder]);
      setOtherDocsKnee([...tempOtherDocsKnee]);
    }
  }, [dataLoaded, postOpInstructions]);

  const handleExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleRadioButton(event: any, procedure: string, region: string) {
    let index = 0;
    let list = [] as KLSurgeonPostOpInstructions[];
    let defaultPdf = {} as KLSurgeonPostOpInstructions;

    switch (region) {
      case 'shoulder':
        if (procedure.includes('Default')) {
          defaultPdf = postOpInstructions.defaults.filter(
            e => e.region === 'shoulder'
          )[0];
          defaultPdf.customPdfSelected =
            event.target.value === 'custom' ? true : false;
          setDefaultShoulder({ ...defaultPdf });
        } else {
          list = shoulder;
          index = shoulder.findIndex(
            element => element['procedure'] === procedure
          );
          list[index].customPdfSelected =
            event.target.value === 'custom' ? true : false;
          setShoulder([...list]);
        }
        break;
      case 'knee':
        if (procedure.includes('Default')) {
          defaultPdf = postOpInstructions.defaults.filter(
            e => e.region === 'knee'
          )[0];
          defaultPdf.customPdfSelected =
            event.target.value === 'custom' ? true : false;
          setDefaultKnee({ ...defaultPdf });
        } else {
          index = knee.findIndex(element => element['procedure'] === procedure);
          list = knee;
          list[index].customPdfSelected =
            event.target.value === 'custom' ? true : false;
          setKnee([...list]);
        }
        break;
    }
    setCanSave(true);
  }

  function handleDelete(
    event: React.ChangeEvent<HTMLInputElement>,
    procedure: string,
    region: string,
    filename: string
  ) {
    let index = 0;
    let defaultPdf = {} as KLSurgeonPostOpInstructions;
    const list: KLSurgeonPostOpInstructions[] = [
      ...shoulder,
      ...knee,
      defaultShoulder,
      defaultKnee
    ];

    switch (region) {
      case 'shoulder':
        if (procedure.includes('Default')) {
          defaultPdf = list.filter(
            e => e.region === 'shoulder' && e.procedure.includes('Default')
          )[0];
          defaultPdf.customUrl = '';
          defaultPdf.customPdfSelected = false;
          setDefaultShoulder({ ...defaultPdf });
        } else {
          index = list.findIndex(
            element =>
              element['procedure'] === procedure &&
              element.region === 'shoulder'
          );
          list[index].customUrl = '';
          list[index].customPdfSelected = false;
        }
        break;
      case 'knee':
        if (procedure.includes('Default')) {
          defaultPdf = list.filter(
            e => e.region === 'knee' && e.procedure.includes('Default')
          )[0];
          defaultPdf.customUrl = '';
          defaultPdf.customPdfSelected = false;
          setDefaultKnee({ ...defaultPdf });
        } else {
          index = knee.findIndex(
            element =>
              element['procedure'] === procedure && element.region === 'knee'
          );
          list[index].customUrl = '';
          list[index].customPdfSelected = false;
        }
        break;
    }
    if (user.accessToken) {
      deletePostOpInstructions(
        user.accessToken,
        procedure,
        region,
        surgeonId,
        filename
      );
    }
    handleSavePermissions(list);
  }

  async function handleDeleteOtherDocs(
    event: React.ChangeEvent<HTMLInputElement>,
    region: string,
    filename: string,
    customUrl: string
  ) {
    if (user.accessToken) {
      setLoading(true);
      deleteOtherDocsByFilename(
        user.accessToken,
        region,
        surgeonId,
        filename,
        customUrl
      );
      await getPostOpOtherDocs(user.accessToken, surgeonId);
      setLoading(false);
    }
  }

  async function handleSavePermissions(
    toSavePDFs: KLSurgeonPostOpInstructions[]
  ) {
    if (user.accessToken) {
      setLoading(true);
      await setSurgeonPostOpInstructions(user.accessToken, toSavePDFs);
      await getPostOpInstructions(user.accessToken, surgeonId);
      setLoading(false);
    }
  }

  async function handleChange(
    e: React.ChangeEvent<HTMLInputElement>,
    procedure: string,
    region: string
  ) {
    if (e.target.files && user.accessToken) {
      const customFile: File = Object.values(e.target.files)[0];
      await uploadCustomPdf(
        user.accessToken,
        surgeonId,
        customFile,
        procedure,
        region
      );
      await setSurgeonPostOpInstructions(user.accessToken, [
        ...postOpInstructions.pdfList,
        ...postOpInstructions.defaults
      ]);
      await getPostOpInstructions(user.accessToken, surgeonId);
    }
  }

  async function handleOtherDocsUpload(
    e: React.ChangeEvent<HTMLInputElement>,
    procedure: string,
    region: string
  ) {
    if (e.target.files && user.accessToken) {
      const customFile: File = Object.values(e.target.files)[0];

      // Get the list of current file names
      let currentFileNames;
      if (region === 'knee') {
        currentFileNames = otherDocsKnee.map(otherDoc =>
          getDefaultFileName(otherDoc.customUrl)
        );
      } else {
        currentFileNames = otherDocsShoulder.map(otherDoc =>
          getDefaultFileName(otherDoc.customUrl)
        );
      }

      // Throw an error if the file name already exists
      if (currentFileNames.includes(customFile.name)) {
        setShowErrorModal(true);
        return;
      }

      const customUrl = await uploadCustomPdf(
        user.accessToken,
        surgeonId,
        customFile,
        procedure,
        region
      );

      await setPostOpOtherDocs(user.accessToken, surgeonId, [
        {
          surgeonId: surgeonId,
          region: region,
          customUrl: customUrl,
          procedure: procedure
        }
      ]);
    }
  }

  return (
    <KaliberCard title="Customize post-op instructions" titleAlignment={'left'}>
      <Box
        className="box-container"
        component="form"
        noValidate
        sx={{ width: '100%' }}>
        <div className="post-op-container">
          <div className={`column-container`}>
            <div className="body2 joint-title">Shoulder</div>
            {isNotEmpty(defaultShoulder) && (
              <Accordion
                key="shoulder-default"
                expanded={expanded === defaultShoulder.procedure}
                onChange={handleExpanded(defaultShoulder.procedure)}
                className="notes-accordion-container">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                  aria-controls="panel1bh-content"
                  id="t-shoulder-bh-header"
                  className={
                    expanded === defaultShoulder.procedure
                      ? 'summary expanded'
                      : 'summary'
                  }>
                  <div className="body1">Default shoulder</div>
                </AccordionSummary>
                <AccordionDetails className="details">
                  <div className="post-op-selection-container">
                    <div className="single-note-container">
                      <KLRadioBox
                        handleChange={handleChange}
                        element={defaultShoulder}
                        handleRadioButton={handleRadioButton}
                        handleDelete={handleDelete}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            {shoulder.map(element => {
              return (
                <Accordion
                  key={element.procedure}
                  expanded={expanded === `${element.procedure}-s`}
                  onChange={handleExpanded(`${element.procedure}-s`)}
                  className="notes-accordion-container">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls="panel1bh-content"
                    id="t-shoulder-bh-header"
                    className={
                      expanded === `${element.procedure}-s`
                        ? 'summary expanded'
                        : 'summary'
                    }>
                    <div className="body1">{element.procedure}</div>
                  </AccordionSummary>
                  <AccordionDetails className="details">
                    <div className="post-op-selection-container">
                      <div className="single-note-container">
                        <KLRadioBox
                          handleChange={handleChange}
                          element={element}
                          handleRadioButton={handleRadioButton}
                          handleDelete={handleDelete}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <Accordion
              key="other-docs-shoulder"
              expanded={expanded === 'other-docs-shoulder'}
              onChange={handleExpanded('other-docs-shoulder')}
              className="notes-accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="panel1bh-content"
                id="t-shoulder-bh-header"
                className={
                  expanded === 'other-docs-shoulder'
                    ? 'summary expanded'
                    : 'summary'
                }>
                <div className="body1">Other Docs</div>
              </AccordionSummary>
              <AccordionDetails className="details">
                <div className="post-op-selection-container">
                  <div className="single-note-container">
                    <PostOpInstructionsOtherDocs
                      handleChange={handleOtherDocsUpload}
                      otherDocs={otherDocsShoulder}
                      handleDelete={handleDeleteOtherDocs}
                      region="shoulder"
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={`column-container`}>
            <div className="body2 joint-title">Knee</div>
            {isNotEmpty(defaultKnee) && (
              <Accordion
                key="shoulder-default"
                expanded={expanded === defaultKnee.procedure}
                onChange={handleExpanded(defaultKnee.procedure)}
                className="notes-accordion-container">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                  aria-controls="panel1bh-content"
                  id="t-shoulder-bh-header"
                  className={
                    expanded === defaultKnee.procedure
                      ? 'summary expanded'
                      : 'summary'
                  }>
                  <div className="body1">Default knee</div>
                </AccordionSummary>
                <AccordionDetails className="details">
                  <div className="post-op-selection-container">
                    <div className="single-note-container">
                      <KLRadioBox
                        handleChange={handleChange}
                        element={defaultKnee}
                        handleRadioButton={handleRadioButton}
                        handleDelete={handleDelete}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            {knee.map(element => {
              return (
                <Accordion
                  key={element.procedure}
                  expanded={expanded === `${element.procedure}-k`}
                  onChange={handleExpanded(`${element.procedure}-k`)}
                  className="notes-accordion-container">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls="panel1bh-content"
                    id="knee-header"
                    className={
                      expanded === `${element.procedure}-k`
                        ? 'summary expanded'
                        : 'summary'
                    }>
                    <div className="body1">{element.procedure}</div>
                  </AccordionSummary>
                  <AccordionDetails className="details">
                    <div className="post-op-selection-container">
                      <div className="single-note-container">
                        <KLRadioBox
                          handleChange={handleChange}
                          element={element}
                          handleRadioButton={handleRadioButton}
                          handleDelete={handleDelete}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <Accordion
              key="other-docs-knee"
              expanded={expanded === 'other-docs-knee'}
              onChange={handleExpanded('other-docs-knee')}
              className="notes-accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                className={
                  expanded === 'other-docs-knee'
                    ? 'summary expanded'
                    : 'summary'
                }>
                <div className="body1">Other Docs</div>
              </AccordionSummary>
              <AccordionDetails className="details">
                <div className="post-op-selection-container">
                  <div className="single-note-container">
                    <PostOpInstructionsOtherDocs
                      handleChange={handleOtherDocsUpload}
                      otherDocs={otherDocsKnee}
                      handleDelete={handleDeleteOtherDocs}
                      region="knee"
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="btn-container">
          <KaliberButton
            type="button"
            buttonClass="btn--primary"
            onClick={() => {
              //   handleCancel();
            }}
            disabled={!canSave}>
            Cancel
          </KaliberButton>
          <KaliberButton
            type="submit"
            buttonClass="btn--primary save-post-op-instructions-btn"
            onClick={() => {
              const toSavePDFs: KLSurgeonPostOpInstructions[] = [
                ...shoulder,
                ...knee,
                defaultShoulder,
                defaultKnee
              ];
              handleSavePermissions(toSavePDFs);
            }}
            loading={loading}
            disabled={!canSave}>
            Save post op instructions
          </KaliberButton>
        </div>
      </Box>
      <KaliberModal
        visible={showErrorModal}
        title="Error"
        titleAlignment="center"
        onClose={() => setShowErrorModal(false)}>
        <div className="error-message">{DUPLICATE_PDF_ERROR_MESSAGE}</div>
      </KaliberModal>
    </KaliberCard>
  );
}
