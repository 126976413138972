import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { ReactElement } from 'react';
import './KLTextField.scss';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'theme.palette.text.secondary'
  },
  title: {
    fontWeight: 400,
    color: '#44546a',
    fontFamily: 'Inter',
    lineHeight: '1rem',
    letterSpacing: '0.25px',
    fontSize: '14px',
    margin: '0 0 0.25rem 0'
  },
  optional: {
    fontSize: 9,
    marginLeft: 10,
    fontWeight: 500
  },
  field: {
    height: 42,
    width: 286
  },
  noBorder: {
    border: 'none'
  },
  fixPadding: {
    padding: '0 0',
    fontWeight: '500 !important' as 'bold'
  },
  disabledColor: {
    color: theme.palette.text.primary
  },
  inputPadding: {
    padding: '0.75rem 0.5rem'
  }
}));

interface KLTextFieldProps {
  value: string;
  onChange?: (val: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoFocus?: boolean;
  name?: string;
  type?: 'email' | 'password' | 'text';
  helperText?: string;
  autoComplete?: string;
  label?: string;
  id?: string;
  isRequired?: boolean;
  error?: boolean;
  className?: string;
  optionalText?: string;
  multiLine?: boolean;
  fieldStyle?: React.CSSProperties;
  disabled?: boolean;
  editable: boolean;
  dataTestId?: string;
  onBlur?: () => void;
  maxLength?: number;
}

// TODO: Find and replace all instances of this component with KaliberTextField
export default function KLTextField({
  onChange,
  label,
  isRequired,
  className,
  optionalText,
  multiLine,
  disabled,
  fieldStyle,
  editable,
  dataTestId,
  onBlur,
  maxLength,
  ...options
}: KLTextFieldProps): ReactElement {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <div className={className}>
      <div className={classes.titleContainer}>
        <div className={`caption ${classes.title}`}>{label}</div>

        <div className={`caption ${classes.optional}`}>{optionalText}</div>
      </div>
      {editable ? (
        <TextField
          id="fs-mask"
          disabled={disabled}
          fullWidth
          multiline={multiLine ? true : false}
          rows={4}
          onBlur={handleBlur}
          variant="outlined"
          required={isRequired}
          style={fieldStyle}
          className="MuiOutlinedInput-input-edit"
          onChange={handleChange}
          inputProps={{
            maxLength: maxLength ?? 50,
            'data-testid': dataTestId ? dataTestId : 'test-kl-textfield'
          }}
          InputProps={{
            classes: {
              input: classes.inputPadding
            }
          }}
          {...options}
        />
      ) : (
        <TextField
          id="fs-mask"
          disabled={true}
          fullWidth
          multiline={multiLine ? true : false}
          rows={4}
          variant="outlined"
          required={isRequired}
          style={fieldStyle}
          InputProps={{
            classes: {
              notchedOutline: classes.noBorder,
              input: classes.fixPadding,
              disabled: classes.disabledColor
            }
          }}
          inputProps={{
            maxLength: 50,
            'data-testid': dataTestId ? dataTestId : 'test-kl-textfield'
          }}
          {...options}
        />
      )}
    </div>
  );
}
