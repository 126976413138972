import {
  getSurgeryDataById,
  setExternalReviewData,
  updateSurgery
} from './surgeriesActions';
import { getReviewDataById } from './surgeriesActions';
import { updatePatient } from './patientActions';
import { SurgeryInfo } from '../types';
import { patientFromSurgeryInfo } from '../../utils/dataOrganization';
import { setLoading } from './uiActions';
import {
  callStratusApiV2Unauthenticated,
  callStratusApiV2,
  RequestMethod
} from '../../api';

export async function updateReportInfoSurgeon(
  accessToken: string,
  surgeryInfo: SurgeryInfo
): Promise<void> {
  setLoading(true);
  await updateSurgery(accessToken, surgeryInfo.surgeryId, surgeryInfo);

  const patient = patientFromSurgeryInfo(surgeryInfo);
  await updatePatient(accessToken, surgeryInfo.patientId, patient);
  await Promise.all([
    getReviewDataById(accessToken, surgeryInfo.surgeryId),
    getSurgeryDataById(accessToken, surgeryInfo.surgeryId)
  ]);
  setLoading(false);
}

export async function updateReportInfoPatient(
  accessToken: string,
  surgeryInfo: SurgeryInfo
): Promise<void> {
  setLoading(true);
  const patient = patientFromSurgeryInfo(surgeryInfo);
  await updatePatient(accessToken, surgeryInfo.patientId, patient);
  await Promise.all([
    getReviewDataById(accessToken, surgeryInfo.surgeryId),
    getSurgeryDataById(accessToken, surgeryInfo.surgeryId)
  ]);
  setLoading(false);
}

export async function sharedReportLink(
  accessToken: string,
  surgeryId: number,
  sharedEmail: string
): Promise<string> {
  const endpoint = `shared_reports/${surgeryId}`;
  const apiFunction = callStratusApiV2;

  const options = {
    accessToken,
    method: RequestMethod.Post,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: sharedEmail })
  };

  // Make the API call
  const link = await apiFunction<{ report_shared_link: string }>(
    endpoint,
    options
  );

  return link.report_shared_link;
}

export async function getExternalReport(
  key: string,
  email: string
): Promise<boolean> {
  const encodedEmail = encodeURIComponent(email);

  const apiFunction = callStratusApiV2Unauthenticated;
  const endpoint = `shared_reports/${key}?email=${encodedEmail}`;

  const link = await apiFunction<{ report_url: string }>(endpoint, {
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });

  if (!link) {
    return false;
  }

  const res = await window.fetch(link['report_url'], {
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });
  const jsonBody = await res.json();
  setExternalReviewData(jsonBody);

  return true;
}

export async function updateSharedReportVisited(
  cryptoKey: string
): Promise<void> {
  await callStratusApiV2Unauthenticated<void>(`shared_reports/${cryptoKey}`, {
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      shared_report: {
        visited: true
      }
    })
  });
}

export async function checkReportExpiry(key: string): Promise<void> {
  await callStratusApiV2Unauthenticated(`shared_reports/check_expiry/${key}`, {
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });
}

export async function getReportViewedDate(
  accessToken: string,
  surgeryId: number
): Promise<string> {
  const response = await callStratusApiV2<{
    last_viewed_date: string;
  }>(`metrics/report/viewed_date/${surgeryId}`, {
    accessToken,
    method: RequestMethod.Get,
    headers: { 'Content-Type': 'application/json' }
  });

  return response.last_viewed_date;
}
