import { TextField, makeStyles } from '@material-ui/core';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Fab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllPostOpInstructions } from '../../../redux/actions/postOpInstructionsActions';
import { sharedReportLink } from '../../../redux/actions/reportActions';
import { getReportViewedDate } from '../../../redux/actions/reportActions';
import { KLReview, KLState, KLUser } from '../../../redux/types';
import { isEmpty } from '../../../utils/validations';
import '../PatientReport.scss';
import { triggerCustomGAEvent } from '../../../utils/googleAnalytics';
import KaliberButton from '../../../components/button/KaliberButton';
import './PatientReportStepper.scss';
import { useQuery } from '../../../utils/react';

interface StepperProps {
  scrollTo: (index: number) => void;
  rawImagePDF?: string;
  patientReportPDF?: string;
  sharedReport?: boolean;
  className?: string;
  patientReportPatientViewHasLoaded?: boolean;
  setPatientReportPatientViewHasLoaded?: (value: boolean) => void;
  onResendReport?: () => void;
}

export default function PatientReportStepper({
  scrollTo,
  sharedReport = false,
  className,
  patientReportPatientViewHasLoaded,
  setPatientReportPatientViewHasLoaded,
  onResendReport
}: StepperProps): ReactElement {
  const [user, review]: [KLUser, KLReview] = useSelector((state: KLState) => [
    state.user,
    state.surgeries.review
  ]);
  const defaultErrorMessage =
    'An error occurred while sharing the report, please try again';
  const [open, setOpen] = useState(false);
  const [ptEmail, setPtEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [reportShared, setReportShared] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(0);
  const [resendReport, setResendReport] = useState(false);
  const [reportViewedDate, setReportViewedDate] = useState('');
  const [reportViewedStatus, setReportViewedStatus] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', updateStepper);

    return () => {
      document.removeEventListener('scroll', updateStepper);
    };
  }, []);

  const updateStepper = () => {
    [
      { id: 'report-overview', step: 0, offset: 0 },
      { id: 'report-howto', step: 1, offset: 0 },
      { id: 'report-images', step: 2, offset: 80 },
      { id: 'report-videos', step: 3, offset: 0 },
      { id: 'report-surgeonbio', step: 4, offset: 250 }
    ].map(item => {
      const element = document.getElementById(item.id);
      if (element) {
        if (isInView(element, item.offset)) {
          setStep(item.step);
        }
      }
    });
  };

  const isInView = (element: HTMLElement, offset: number) => {
    if (!element) {
      return false;
    }
    const rect = element.getBoundingClientRect();

    return rect.top >= 0 - offset && rect.bottom <= window.innerHeight + offset;
  };

  useEffect(() => {
    if (user.accessToken) {
      getAllPostOpInstructions(user.accessToken);
    }
  }, [user.accessToken]);

  useEffect(() => {
    const viewedDate = async () => {
      if (user.accessToken && review) {
        const date = await getReportViewedDate(
          user.accessToken,
          review.surgeryInfo.surgeryId
        );
        if (date === '') {
          setReportViewedDate('Patient has not viewed the report yet.');
          setReportViewedStatus(false);
        } else {
          setReportViewedDate('Last viewed by patient: ' + date);
          setReportViewedStatus(true);
        }
      }
    };

    viewedDate();
  }, [user.accessToken, review]);

  const query = useQuery();
  useEffect(() => {
    const queryParam = query.get('shareModal');
    if (
      !patientReportPatientViewHasLoaded &&
      queryParam &&
      queryParam == 'open'
    ) {
      setOpen(true);
      if (setPatientReportPatientViewHasLoaded) {
        setPatientReportPatientViewHasLoaded(true);
      }
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReportShared(false);
    setShowError(false);
  };

  useEffect(() => {
    if (isEmpty(ptEmail) || !ptEmail.includes('@')) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [ptEmail]);

  async function handleSubmit() {
    setLoading(true);
    try {
      if (user.accessToken) {
        await sharedReportLink(
          user.accessToken,
          review.surgeryInfo.surgeryId,
          ptEmail
        );

        if (review) {
          const userUid = user.uid;
          const surgeryId = review.surgeryInfo.surgeryId;
          triggerCustomGAEvent(
            'patient_share_report_success',
            `role: ${user.role}(uid: ${userUid})`,
            `surgeryId: ${surgeryId}`
          );
        }

        setReportShared(true);
      }
    } catch (e) {
      if ((e as Error).message === 'Invalid email') {
        setErrorMessage(
          'The email you have entered is invalid. Please re-enter a valid email.'
        );
      } else {
        setErrorMessage(defaultErrorMessage);
      }
      setShowError(true);
    }
    setLoading(false);
  }

  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiStepLabel-iconContainer .Mui-active': {
        color: '#008383',
        fontWeight: 400
      },
      '& .MuiStepLabel-iconContainer .Mui-completed': {
        color: '#008383',
        fontWeight: 400
      },
      '& .MuiStepIcon-root': {
        color: '#697688',
        width: '2rem',
        height: '2rem'
      },
      '& .Mui-disabled .MuiStepIcon-root': {
        color: '#697688'
      },
      '& .MuiStepLabel-labelContainer .Mui-disabled': {
        color: '#313d4e'
      },
      '& .MuiStepLabel-label.Mui-active': {
        color: '#313d4e',
        fontWeight: 500
      },
      '& .MuiStepLabel-label.Mui-completed': {
        color: '#313d4e',
        fontWeight: 500
      },
      '& .MuiStepIcon-text': {
        fontFamily: 'Inter',
        fontSize: '0.625rem',
        lineHeight: '1rem',
        letterSpacing: '0.25px'
      }
    }
  }));

  const c = useStyles();

  const handleResendReport = () => {
    setResendReport(true);
    onResendReport?.();
    setResendReport(false);
  };

  const reportStatusRoles = [
    'Kaliber Admin',
    'Surgeon',
    'Surgical Clinical Team',
    'Surgical Office Staff'
  ];

  return (
    <div className={`floating-stepper-container ${className}`}>
      <div className="floating-stepper">
        <Stepper
          activeStep={step}
          orientation="vertical"
          color="primary"
          className={c.root}>
          <Step key={0}>
            <div
              onClick={() => {
                setStep(0);
                scrollTo(0);
              }}
              data-testid="overview-div">
              <StepLabel>Overview</StepLabel>
            </div>
          </Step>
          <Step key={1}>
            <StepLabel>
              <div
                onClick={() => {
                  setStep(1);
                  scrollTo(1);
                }}
                data-testid="how-to-div">
                How to use this report
              </div>
            </StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>
              <div
                onClick={() => {
                  setStep(2);
                  scrollTo(2);
                }}
                data-testid="surgery-images-div">
                Surgery images
              </div>
            </StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel
              onClick={() => {
                setStep(3);
                scrollTo(3);
              }}
              data-testid="surgery-video-div">
              Surgery video
            </StepLabel>
          </Step>
          <Step key={4}>
            <StepLabel
              onClick={() => {
                setStep(4);
                scrollTo(4);
              }}
              data-testid="about-doctor-div">
              About doctor
            </StepLabel>
          </Step>
        </Stepper>
        {reportStatusRoles.includes(user.role) && (
          <div
            data-testid="report-viewed-status"
            className={
              reportViewedStatus
                ? 'report-read-status__viewed'
                : 'report-read-status__not-viewed'
            }>
            {reportViewedDate}
          </div>
        )}
        {onResendReport && user.role !== 'Patient' && (
          <div
            className={`pdfOpenBtn ${
              reportStatusRoles.includes(user.role) ? 'report-status' : ''
            }`}>
            <KaliberButton
              buttonClass="btn--primary"
              type="button"
              loading={resendReport}
              onClick={handleResendReport}>
              Resend Report
            </KaliberButton>
          </div>
        )}
        {user.role === 'Patient' && !sharedReport && (
          <div className="pdfOpenBtn">
            <KaliberButton
              buttonClass="btn--primary"
              type="button"
              onClick={() => handleClickOpen()}>
              Share Report
            </KaliberButton>
          </div>
        )}
      </div>
      {user.role === 'Patient' && !sharedReport ? (
        <Fab
          className="share-mobile"
          variant="circular"
          onClick={() => {
            handleClickOpen();
          }}>
          <IosShareIcon fontSize="medium" />
        </Fab>
      ) : null}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="dialog-title">Share report</DialogTitle>
        {!loading && !reportShared && !showError ? (
          <DialogContent>
            <DialogContentText className="dialog-content-text">
              Please enter the email address of the person you would like to
              share this report with.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              onChange={e => setPtEmail(e.target.value.trim().toLowerCase())}
            />
          </DialogContent>
        ) : loading ? (
          <DialogContent>
            <DialogContentText className="dialog-content-text">
              Sending the report to the email provided.
            </DialogContentText>
            <div className="full-loading">
              <CircularProgress />
            </div>
          </DialogContent>
        ) : showError ? (
          <DialogContent>
            <div className="error-message">{errorMessage}</div>
          </DialogContent>
        ) : (
          <DialogContent>Report has been shared successfully!</DialogContent>
        )}

        <DialogActions>
          <KaliberButton
            type="button"
            buttonClass="btn--primary"
            onClick={handleClose}>
            {reportShared || showError ? 'Close' : 'Cancel'}
          </KaliberButton>
          {!reportShared && !showError ? (
            <KaliberButton
              type="button"
              buttonClass="btn--primary"
              disabled={emailError}
              loading={loading}
              onClick={handleSubmit}>
              Submit
            </KaliberButton>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}
