import React, {
  ReactElement,
  ReactNode,
  useState,
  useRef,
  useEffect
} from 'react';
import './KLTermsModal.scss';
import { Checkbox } from '@material-ui/core';
import KaliberButton from '../button/KaliberButton';

export interface KLModalProps {
  children: ReactNode;
  onCloseHandler: () => void;
  onAcceptHandler: () => Promise<boolean>;
}

export function KLTermsModal(props: KLModalProps): ReactElement {
  const [checked, setChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingCheckBox, setLoadingCheckBox] = useState(false);
  const touRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (touRef && touRef.current) {
      touRef.current.scrollTop = 0;
    }
  }, [props.children]);

  return (
    <div className="terms-background">
      <div className="terms-of-use">
        <div className="tou-title">Please read our Terms of Use.</div>
        <div className="tou-content">
          Welcome to Kaliber Rekap! Please take the time to review Terms of Use
          and by clicking below to indicate your agreement to be bound by the
          Terms of Use and continuing using the features of the platform.
        </div>
        <div className="tou" ref={touRef}>
          {props.children}
        </div>
        <div className="tou-accept">
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(event, checked) => setChecked(checked)}
          />
          <div>I have read and accept the Terms of Use.</div>
        </div>
        <div className="tou-accept-button">
          <KaliberButton
            type="button"
            buttonClass="btn--primary"
            disabled={!checked || loadingCheckBox}
            onClick={async () => {
              setLoadingCheckBox(true);
              const saved = await props.onAcceptHandler();
              setLoadingCheckBox(false);
              if (saved) {
                props.onCloseHandler();
                setChecked(false);
              } else {
                setShowError(true);
              }
            }}>
            {loadingCheckBox ? 'Saving' : 'Continue'}
          </KaliberButton>
        </div>
        {showError && (
          <div className="error-message">
            An error occurred while saving the changes please try again
          </div>
        )}
      </div>
    </div>
  );
}
