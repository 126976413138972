/* -------------------------------------------------------------------------- */
/*                                 STATE TYPES                                */
/* -------------------------------------------------------------------------- */
export interface KLState {
  facilities: KLFacilities;
  patient: KLPatientRoles;
  surgeries: KLSurgeries;
  ui: KLUi;
  user: KLUser;
  staff: Staff[];
  surgeons: KLSurgeon[];
  surgeonFacilityTeams: KLSurgeonFacilityTeam[];
  quickNotes: KLQuickNote[];
  postOpInstructions: KLPostOpInstructions;
  cptCodes: KLCptCode[];
  termsOfUseAgreed: KLUserTermsAgreement[];
  procedures: SurgeryProcedures;
}

/* -------------------------------------------------------------------------- */
/*                                 USER TYPES                                 */
/* -------------------------------------------------------------------------- */

export interface KLUser {
  accessToken: string | null;
  email: string;
  role: string;
  uid?: string;
  emailNotifications?: boolean;
  textNotifications?: boolean;
  contactEmail?: null | string;
  contactPhone?: null | string;
  error?: null | string;
}

export enum PaymentPreference {
  Patient = 'patient',
  Surgeon = 'surgeon',
  FreeTrial = 'free_trial'
}

export interface KLSurgeon {
  surgeonId: number;
  surgeonName: string;
  surgeonEmail: string;
  surgeonPhone: string;
  surgeonNpi: string;
  paymentPreference: PaymentPreference;
  optedIn: boolean;
  facilities: KLFacility[];
}

export interface SurgeonBio {
  bio: string;
  bioQuote: string;
  headshotUri: string;
  certifications: string;
  memberships: string;
  bannerUrl?: string;
}

export interface KLSurgeonFacilityTeam {
  surgeon: KLSurgeon | undefined;
  facility: KLFacility | undefined;
}

export interface KLFacilities {
  facilities: KLFacility[];
  facilitiesNames: string[];
}

export interface KLFacility {
  facilityId?: number;
  facilityName: string;
  facilityType: string;
  facilityAddress: string;
  facilityPhone: string;
  facilityFax: string;
  facilityEmail: string;
  facilityAfterHoursPhone: string;
  facilityWebsite: string;
  facilityNpi?: string;
}

/* -------------------------------------------------------------------------- */
/*                                  UI TYPES                                  */
/* -------------------------------------------------------------------------- */
export interface KLSort {
  id: string;
  desc: boolean;
}

export interface KLUi {
  loading: boolean;
  loadingMessage: string;
  // Temporarily disabling warning:
  // Fix involves greater understanding of errors from Olympus.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
  filter: {
    id: string;
    value: string;
  };
  surgeryFilters: {
    patientName: string;
    surgeonName: string;
    surgeryDate: string;
    facilityName: string;
    search: string;
  };
  surgeryStatus: {
    notStarted: boolean;
    inProgress: boolean;
    processing: boolean;
    readyForReview: boolean;
    sentToPatient: boolean;
  };
  showUpdateAccountPage: boolean;
  editing: boolean;
  sorting: KLSort[];
  progressUpload: {
    total: number;
    loaded: number;
  };
}

/* -------------------------------------------------------------------------- */
/*                                  Surgery TYPES                             */
/* -------------------------------------------------------------------------- */
export interface KLSurgeries {
  surgeries: KLSurgery[];
  surgery: Surgery;
  review: KLReview;
  upload: KLUpload;
  pendingDeleteConfirmation?: boolean;
  postDeleteConfirmation?: boolean;
  pendingDeleteSurgery?: number;
}

export interface KLSurgery {
  surgeryId: number;
  patientFirstName: string;
  patientMiddleName?: string;
  patientLastName: string;
  patientDob: string;
  patientPhone?: string;
  patientEmail?: string;
  preferredCommunication?: string;
  patientRace?: string;
  patientSex?: string;
  region: string;
  side: string;
  surgeryType: string[];
  surgeryDate: string;
  surgeonName: string;
  facilityName: string;
  surgeryStatus: string;
  postOpInstructionsId?: number[];
  surgeonEmail?: string;
  surgeonId: number;
  paymentPreference: PaymentPreference;
  facilityId: number;
  plannedProcedures: SurgeryProcedures;
  performedProcedures: SurgeryProcedures;
  rawPdfUrl?: null | string;
  oldPerformedProcedures: string[];
}

export interface KLReview {
  annotatedData: AnnotatedData[];
  surgeryInfo: SurgeryInfo;
  surgeonBio: SurgeonBio;
}

export interface KLExternalReview extends KLReview {
  post_op_instructions: KLSurgeonPostOpInstructions[];
}

export enum PaymentStatus {
  Unpaid = 'unpaid',
  Paid = 'paid'
}

export interface SurgeryInfo {
  cptCodesPerformed: KLCptCode[];
  customProceduresPerformed: CustomProcedure[];
  emergencyContact?: EmergencyContact;
  emergencyContactAddress?: string;
  emergencyContactCity?: string;
  emergencyContactEmail?: string;
  emergencyContactName?: string;
  emergencyContactPhone?: string;
  emergencyContactState?: string;
  emergencyContactZipcode?: string;
  facilityAddress: string;
  facilityAfterHoursPhone: string;
  facilityEmail: string;
  facilityFax: string;
  facilityId: number;
  facilityName: string;
  facilityPhone: string;
  facilityType: string;
  facilityWebsite: string;
  optedIn: boolean;
  patientAddress?: string;
  patientCity?: string;
  patientDob: string;
  patientEmail?: string;
  patientFirstName: string;
  patientId: number;
  patientLastName: string;
  patientMiddleName?: string;
  patientPhone?: string;
  patientPreferredCommunication: string;
  patientPreferredName?: string;
  patientPronouns?: string;
  patientRace: string;
  patientSex?: string;
  patientState?: string;
  patientUid: number;
  patientZipcode?: string;
  paymentPreference: PaymentPreference;
  paymentStatus: PaymentStatus;
  performedProcedures: SurgeryProcedures;
  plannedProcedures: SurgeryProcedures;
  postOpInstructionsId?: number[];
  region: string;
  side: string;
  surgeonEmail: string;
  surgeonId: number;
  surgeonName: string;
  surgeonNpi?: number;
  surgeonPhone: string;
  surgeonUid: number;
  surgeryDate: string;
  surgeryDescription: SurgeryType[];
  surgeryId: number;
  surgeryStatus: string;
  surgeryType: string[];
  oldPerformedProcedures: string[];
}

export interface SurgeryType {
  surgeryType: string;
  surgerySubType?: string;
  repairLocation?: string;
  repairLength?: string;
}

export interface AnnotatedData {
  filename: string;
  mediaLabels: {
    labels?: string[];
    surgeryNotes: string;
    surgeryStage: string;
    stagePredictions?: StagePrediction[];
  };
  mediaUrl?: string;
  mediaType?: string;
  include: boolean;
  originalLabels?: {
    labels?: string[];
    surgeryNotes: string;
    surgeryStage: string;
  };
}

export interface StagePrediction {
  text: string;
  confidenceValue: number;
}

export interface KLCptCode {
  code: string;
  description: string;
  commonName: string;
  anatomy: string;
}

export interface CustomProcedure {
  id?: number;
  procedure: string;
}

/* -------------------------------------------------------------------------- */
/*                                UPLOAD TYPES                                */
/* -------------------------------------------------------------------------- */
export interface KLUpload extends UploadSurgeryData {
  showConfirmation: boolean;
  surgeryId: number;
  uploadError: Error | null;
  currentUploadFileIdx: number;
  numFilesToUpload: number;
  cancelUpload: boolean;
  navbarStatus?: string;
  navbarShow?: boolean;
}

export interface UploadSurgeryData {
  patientName: string;
  dob: string;
  surgeryDescription: string;
  surgeryDate: string;
  gender?: string;
  surgeonName: string;
}

/* -------------------------------------------------------------------------- */
/*                                PATIENT TYPES                               */
/* -------------------------------------------------------------------------- */

export interface KLPatientRoles {
  newPatient: KLPatient;
  currentPatient: KLPatient;
}

export interface KLPatient {
  dob: string;
  email?: string;
  emergencyContact?: KLEmergencyContact;
  firstName: string;
  lastName: string;
  middleName?: string;
  id?: number;
  uid?: number;
  phone?: string;
  preferredCommunication: string;
  preferredName?: string;
  pronouns?: string;
  race: string;
  sex?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  surgeries?: KLSurgery[];
}

export interface KLEmergencyContact {
  address?: string;
  city?: string;
  email: string;
  name: string;
  phone: string;
  state?: string;
  zipcode?: string;
}

// TODO: Depricate after completing redesign
export interface KLNewSurgery {
  surgeryId?: string;
  surgeryDate: string;
  region: string;
  surgeonId: number;
  patientId?: number;
  facilityId: number;
  surgeryType: string[];
  side: string;
}

export interface CptSurgeryProcedure {
  id?: number;
  cptCode: KLCptCode;
  region: string;
  side: string;
  procedureType: string;
  procedureDetail?:
    | ProcedureDetail
    | AclReconstructionDetail
    | MeniscectomyDetail
    | RotatorCuffRepairDetail
    | MeniscusRepairDetail
    | BankartRepairDetail
    | ChondroplastyDetail
    | CapsularReleaseDetail
    | MicroFxDetail
    | OsteoarticularTransferDetail;
}

export interface ProcedureDetail {
  id?: number;
  type: string;
  additionalDetails?: string;
}

export interface AclReconstructionDetail extends ProcedureDetail {
  graftType: string;
  tendonUsed?: string;
}

export interface RotatorCuffRepairDetail extends ProcedureDetail {
  tearRegion: string[];
  tearSize?: string;
}
export interface MeniscectomyDetail extends ProcedureDetail {
  regionOfTear: string[];
  regionOfMedialMeniscus?: string[];
  regionOfLateralMeniscus?: string[];
}

export interface MeniscusRepairDetail extends ProcedureDetail {
  regionOfTear: string[];
  regionOfMedialMeniscus?: string[];
  regionOfLateralMeniscus?: string[];
  medialRepairLength?: string;
  lateralRepairLength?: string;
  medialRepairLengthUnit?: string;
  lateralRepairLengthUnit?: string;
}

export interface BankartRepairDetail extends ProcedureDetail {
  regionOfTear: string[];
}

export interface ChondroplastyDetail extends ProcedureDetail {
  regionOfCartilageLesion: string[];
}
export interface CapsularReleaseDetail extends ProcedureDetail {
  capsuleLocation: string[];
}

export interface MicroFxDetail extends ProcedureDetail {
  regionOfCartilageLesion: string[];
}

export interface OsteoarticularTransferDetail extends ProcedureDetail {
  regionOfCartilageLesion: string[];
}

export interface CustomSurgeryProcedure {
  id?: number;
  customProcedure: CustomProcedure;
  procedureType: string;
}

export interface SurgeryProcedures {
  cptProcedures: CptSurgeryProcedure[];
  customProcedures: CustomSurgeryProcedure[];
}

export interface KLAnatomySide {
  knee: 'left' | 'right' | 'both';
  shoulder: 'left' | 'right' | 'both';
}

export interface NewSurgeryData {
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  dob: string;
  sex?: string;
  pronouns: string;
  race: string;
  phone?: string | undefined;
  email?: string | undefined;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  emergencyContactName: string;
  emergencyContactAddress: string;
  emergencyContactCity: string;
  emergencyContactState: string;
  emergencyContactZipcode: string;
  emergencyContactPhone: string;
  emergencyContactEmail: string;
  region: string;
  side: string;
  surgeryType: string[];
  surgeryDate: string;
  surgeonName: string;
  facilityName: string;
  surgeonId: number;
  facilityId: number;
  plannedProcedures: SurgeryProcedures;
}

export interface KLOnboarding {
  patient: KLPatient;
}

export interface KLQuickNote {
  quickNoteId: number;
  surgeonUid: number;
  region: string;
  stage: string;
  customNote: string;
  defaultNote: number;
  note: string;
  customNoteSelected: boolean;
}

export interface KLSurgeonPostOpInstructions {
  postOpInstructionsId?: number;
  surgeonId?: number;
  procedure: string;
  region: string;
  defaultPostOpInstructionsUrl: string;
  defaultId: number;
  customPdfSelected: boolean;
  customUrl: string;
}
export interface KLPostOpInstructions {
  pdfList: KLSurgeonPostOpInstructions[];
  all: KLSurgeonPostOpInstructions[];
  defaults: KLSurgeonPostOpInstructions[];
  other: KLPostOpOtherDocs[];
}

export interface KLPostOpOtherDocs {
  postOpInstructionsOtherDocsId?: number;
  surgeonId: number;
  procedure: string;
  region: string;
  customUrl: string;
}
export interface KLPostOpInstructionsRegion {
  defaultPdf: KLPostOpInstructionsPdf;
  procedurePdfList: KLPostOpInstructionsProcedure[];
  otherPdfList: KLPostOpInstructionsPdf[];
}
export interface KLPostOpInstructionsProcedure {
  procedure: string;
  defaultPdf: KLPostOpInstructionsPdf;
  customPdf: KLPostOpInstructionsPdf;
  useDefaultPdf: boolean;
}

export interface KLPostOpInstructionsPdf {
  id: string;
  url: string;
  procedure: string;
  region: string;
}

export interface QuickNotesStages {
  evaluationResponses: string[];
  treatmentResponses: string[];
  postTreatmentResponses: string[];
}

export interface QuickNotesList {
  shoulder: QuickNotesStages;
  knee: QuickNotesStages;
}

export interface KLUserTermsAgreement {
  updateId: number;
  date: string;
  description: string;
  agreed: boolean;
  agreedCount: number;
  audience?: string;
}

// POST-REDESIGN TYPES

// FORM TYPES
export interface SurgeryFormData {
  surgeryDate: string;
  surgeonId?: number;
  facilityId?: number;
  surgeryType: string[];
  side: string;
  patient?: PatientFormData;
  postOpInstructionsId?: number[];
  plannedProcedures: SurgeryProcedures;
  performedProcedures?: SurgeryProcedures;
}

export interface PatientFormData {
  dob: string;
  email?: string;
  emergencyContact?: EmergencyContactFormData;
  firstName: string;
  lastName: string;
  middleName?: string;
  phone?: string;
  preferredCommunication: string;
  preferredName?: string;
  pronouns?: string;
  race?: string;
  sex?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export interface EmergencyContactFormData {
  name: string;
  emergencyContactPhone: string;
  emergencyContactEmail: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export interface Staff {
  staffId: number;
  staffUid: number;
  staffFirstName: string;
  staffLastName: string;
  staffPhone: string;
  staffEmail: string;
  role: string;
  staffPronouns?: string;
  staffTitle?: string;
  staffMiddleName?: string;
  staffPreferredName?: string;
  staffCertifications?: string;
  facilities: KLFacility[];
}

// export interface StaffFormData {}
export interface Surgeon {
  optedIn: boolean;
  paymentPreference: PaymentPreference;
  surgeonEmail: string;
  surgeonId: number;
  surgeonName: string;
  surgeonNpi: number;
  surgeonPhone: string;
}
export interface Surgery {
  facility: Facility;
  patient: Patient;
  paymentStatus: string;
  performedProcedures: SurgeryProcedures;
  plannedProcedures: SurgeryProcedures;
  rawPdfUrl?: null | string;
  region: string;
  side: string;
  surgeon: Surgeon;
  surgeryDate: string;
  surgeryId: number;
  surgeryStatus: string;
  oldPerformedProcedures: string[];
}

export interface Facility {
  facilityAddress: string;
  facilityAfterHoursPhone: string;
  facilityEmail: string;
  facilityFax: string;
  facilityId?: number;
  facilityName: string;
  facilityNpi?: number;
  facilityPhone: string;
  facilityType: string;
  facilityWebsite: string;
}

export interface Patient {
  address?: string;
  city?: string;
  dob: string;
  email?: string;
  emergencyContact?: EmergencyContact;
  firstName: string;
  id?: number;
  lastName: string;
  middleName?: string;
  phone?: string;
  preferredCommunication?: string;
  preferredName?: string;
  pronouns?: string;
  race?: string;
  sex?: string;
  state?: string;
  surgeries?: Surgery[];
  zipcode?: string;
}

export interface EmergencyContact {
  address?: string;
  city?: string;
  email: string;
  name: string;
  phone: string;
  state?: string;
  zipcode?: string;
}
