/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect } from 'react';
import { isNullOrUndefined } from '../../utils/validations';
import './ToggleButtonGroup.scss';

interface toggleGroupProps {
  children: React.ReactNode;
  exclusive?: boolean;
  onChange?: (value: any) => void;
  value?: any;
  id?: string;
}

const ToggleGroup = ({
  exclusive = false,
  children,
  onChange,
  value,
  ...options
}: toggleGroupProps): ReactElement => {
  const [selected, setSelected] = useState<any>();

  useEffect(() => {
    if (!isNullOrUndefined(value)) {
      setSelected(value);
    }
  }, [value]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    buttonValue: string
  ) => {
    if (Array.isArray(selected)) {
      const index = selected && selected.indexOf(buttonValue);
      let newValue;

      if (selected && index >= 0) {
        newValue = selected.slice();
        newValue.splice(index, 1);
      } else {
        newValue = selected ? selected.concat(buttonValue) : [buttonValue];
      }

      if (onChange) {
        onChange(newValue);
      }
      setSelected(newValue);
    }
  };

  const handleExclusiveChange = (
    event: React.MouseEvent<HTMLElement>,
    buttonValue: string
  ) => {
    if (!onChange) {
      setSelected(buttonValue);
    } else {
      onChange(buttonValue);
      setSelected(buttonValue);
    }
  };

  const selectButton = (childValue: string) => {
    if (exclusive) {
      if (childValue === selected) {
        return true;
      } else {
        return false;
      }
    } else if (selected !== undefined && Array.isArray(selected)) {
      return selected.indexOf(childValue) >= 0;
    }
  };

  return (
    <div className="toggle-button-group" {...options}>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child as ReactElement, {
          className: child.props.className,
          onChange: exclusive ? handleExclusiveChange : handleChange,
          selected:
            child.props.selected === undefined
              ? selectButton(child.props.value)
              : child.props.selected
        });
      })}
    </div>
  );
};

export default ToggleGroup;
