import { ReactElement } from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';

import './ChondroplastyComponent.scss';
import {
  KaliberChipMultiSelect,
  KaliberTextArea
} from '../../../../../../../../components';

const REGIONS_OF_CARTILAGE_LESSION = [
  'Medial Femoral Condyle',
  'Lateral Femoral Condyle',
  'Patella',
  'Trochlea'
];

export interface ChondroplastyDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  register: UseFormRegister<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  cptProcedureIndex: number;
}

export default function ChondroplastyDetailCard({
  control,
  register,
  cptProcedureIndex
}: ChondroplastyDetailCardProps): ReactElement {
  return (
    <div className="chondroplasty">
      <input
        type="hidden"
        {...register(`cptProcedures.${cptProcedureIndex}.procedureDetail.id`, {
          setValueAs: v => (v === '' ? null : parseInt(v, 10))
        })}
      />

      <input
        type="hidden"
        {...register(
          `cptProcedures.${cptProcedureIndex}.procedureDetail.type`,
          {
            value: 'ProcedureDetails::ChondroplastyDetail'
          }
        )}
      />

      <div className="multi-select-container">
        <Controller
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfCartilageLesion`}
          control={control}
          render={({ field }) => (
            <KaliberChipMultiSelect
              label="Region of Cartilage Lesion (optional)"
              select={field.value}
              onChange={regionOfCartilageLesion => {
                field.onChange(regionOfCartilageLesion);
              }}
              list={REGIONS_OF_CARTILAGE_LESSION}
            />
          )}
        />
      </div>

      <div className="additional-details">
        <KaliberTextArea
          label="Additional Details (optional)"
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
          register={register}
          errors={{}}
          rows={3}
          placeholder="Additional notes about size, repair details, tissue quality, anchor count etc"
        />
      </div>
    </div>
  );
}
