/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as MoreVertIcon } from '../../assets/icons/more_vert.svg';
import { ReactComponent as PictureAsPdfIcon } from '../../assets/icons/FilePdf.svg';
import { ReactComponent as VisibilityIcon } from '../../assets/icons/visibility.svg';
import { Radio } from '@mui/material';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import KaliberButton from '../button/KaliberButton';
import './KLRadioBox.scss';

interface KLRadioBoxProps {
  element: any;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    procedure: string,
    region: string
  ) => void;
  handleRadioButton: (
    e: React.ChangeEvent<HTMLInputElement>,
    procedure: string,
    region: string
  ) => void;
  handleDelete: (
    e: any,
    procedure: string,
    region: string,
    filename: string
  ) => void;
}
export default function KLRadioBox({
  element,
  handleChange,
  handleRadioButton,
  handleDelete
}: KLRadioBoxProps): ReactElement {
  const [open, setOpen] = useState(false);
  const getDefaultFileName = (url: string) => {
    const splitUrl = url.split('/');
    const fileName = splitUrl[splitUrl.length - 1];
    return fileName;
  };

  const defaultPdfChoice = (
    <>
      <KaliberButton
        onClick={() =>
          window.open(element.defaultPostOpInstructionsUrl, '_blank')
        }
        type="button"
        buttonClass={`rb-container ${
          !element.customPdfSelected ? 'rb-selected' : 'rb-disabled'
        }`}>
        <div className="rb-icon-container">
          <PictureAsPdfIcon
            className={`rb-icon ${
              element.customPdfSelected ? 'rb-icon-pdf' : 'rb-icon-selected'
            }`}
          />
        </div>
        <div className="rb-text-container">
          <div className="rb-file-text">
            {getDefaultFileName(element.defaultPostOpInstructionsUrl)}
          </div>
        </div>
      </KaliberButton>
      <Radio
        checked={!element.customPdfSelected}
        onChange={e => {
          handleRadioButton(e, element.procedure, element.region);
        }}
        value="default"
        name="radio-buttons"
      />
    </>
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadChoice = (
    <>
      <KaliberButton
        buttonClass={`rb-container ${
          element.customPdfSelected ? 'rb-selected' : 'rb-disabled'
        }`}
        type="button"
        onClick={handleButtonClick}>
        <div className="rb-icon-container">
          <PictureAsPdfIcon
            className={`rb-icon ${
              element.customPdfSelected ? 'rb-icon-selected' : 'rb-icon-pdf'
            }`}
          />
        </div>
        <div className="rb-text-container">
          <div className="rb-upload-text">Click to upload customized PDF.</div>
        </div>
      </KaliberButton>
      <input
        type="file"
        multiple
        hidden
        onChange={e => {
          handleChange(e, element.procedure, element.region);
        }}
        data-testid={'inputSelect'}
        ref={fileInputRef}
      />
      <Radio
        disabled
        checked={element.customPdfSelected}
        value="default"
        name="radio-buttons"
      />
    </>
  );

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const customChoice = (
    <>
      <KaliberButton
        type="button"
        buttonClass={`rb-container ${
          element.customPdfSelected ? 'rb-selected' : 'rb-disabled'
        }`}>
        <div className="rb-icon-container">
          <PictureAsPdfIcon className="rb-icon" />
        </div>
        <div className="rb-text-container">
          <div className="rb-file-text">
            {getDefaultFileName(element.customUrl)}
          </div>
        </div>
        <div ref={modalRef} className="rb-icon-container">
          {open && (
            <div>
              <div className="rb-pop-over-icon-container">
                <div className="rb-pop-over-icon-container-inner">
                  <div onClick={() => window.open(element.customUrl, '_blank')}>
                    <VisibilityIcon className="rb-icon rb-eye-icon" />
                  </div>
                  <div
                    onClick={e =>
                      handleDelete(
                        e,
                        element.procedure,
                        element.region,
                        getDefaultFileName(element.customUrl)
                      )
                    }>
                    <DeleteIcon className="rb-icon rb-delete-icon" />
                  </div>
                </div>
              </div>
            </div>
          )}
          <MoreVertIcon className="rb-icon" onClick={() => setOpen(!open)} />
        </div>
      </KaliberButton>
      <Radio
        checked={element.customPdfSelected}
        onChange={e => {
          handleRadioButton(e, element.procedure, element.region);
        }}
        value="custom"
        name="radio-buttons"
      />
    </>
  );

  return (
    <div className="rb-outer-container">
      <div className="rb-default-container">{defaultPdfChoice}</div>
      <div className="rb-custom-container">
        {element.customUrl !== '' ? customChoice : uploadChoice}
      </div>
    </div>
  );
}
