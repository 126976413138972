import { ReactElement } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import SurgeonReviewPage from './ReviewPageRedesign';
import InternalReviewPage from './InternalReviewPage';

import { KLUser, KLState } from '../../redux/types';
import { isInternalUser } from '../../utils/user';

export default function ReviewPageRouter(): ReactElement {
  const { stagePredictionSelections } = useFlags();
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);

  if (stagePredictionSelections && isInternalUser(user)) {
    return <InternalReviewPage />;
  } else {
    return <SurgeonReviewPage />;
  }
}
