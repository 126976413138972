import { Snackbar } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setEditing, setLoading } from '../../../redux/actions/uiActions';
import {
  clearNotificationData,
  getNotificationsSettings,
  setContactInformation,
  setNotifications
} from '../../../redux/actions/userActions';
import { KLState, KLUi, KLUser } from '../../../redux/types';
import '../Settings.scss';

import KaliberCard from '../../../components/card/KaliberCard';
import KLPhoneField from '../../../components/phoneField/KLPhoneField';
import KaliberSwitch from '../../../components/switch/KaliberSwitch';
import KLTextField from '../../../components/textField/KLTextField';

export interface NotificationSettingsProps {
  singleComponent?: boolean;
}

export default function NotificationsSettings({
  singleComponent = true
}: NotificationSettingsProps): ReactElement {
  const [user, ui]: [KLUser, KLUi] = useSelector((state: KLState) => [
    state.user,
    state.ui
  ]);

  const [emailNot, setUserEmailNot] = useState(false);
  const [textNot, setUserTextNot] = useState(false);
  const [openEmail, setEmailOpen] = useState(false);
  const [openText, setTextOpen] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [textMessage, setTextMessage] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>('');
  const [userPhone, setUserPhone] = useState<string | null>('');
  const [previouslyEditing, setPreviouslyEditing] = useState(false);
  const [contactMessage, setContactMessage] = useState('');
  const [openContact, setContactOpen] = useState(false);

  useEffect(() => {
    if (singleComponent === true) {
      setLoading(true);
    }
    return () => {
      clearNotificationData();
    };
  }, []);

  useEffect(() => {
    if (!ui.editing && previouslyEditing) {
      if (!userEmail) {
        setUserEmailNot(false);
        onChangeEmail(false);
      }
      if (!userPhone || userPhone === '(') {
        setUserTextNot(false);
        onChangeText(false);
      }
      handleSaveContactInformation();
    }
    setPreviouslyEditing(ui.editing);
  }, [ui.editing]);

  async function handleSaveContactInformation() {
    if (user.accessToken && userPhone !== null && userEmail !== null) {
      setContactInformation(user.accessToken, userEmail, userPhone).then(() => {
        setContactMessage('Contact information updated');
        setContactOpen(true);
        // After backend update, persist frontend state to reflect the changes
        if (!userEmail) {
          setUserEmailNot(false);
        }
        if (!userPhone || userPhone === '(') {
          setUserTextNot(false);
        }
      });
    } else {
      setContactMessage('Contact information cannot be updated');
      setContactOpen(true);
    }
  }

  useEffect(() => {
    if (singleComponent === true) {
      setLoading(true);
    }
    if (user.accessToken) {
      getNotificationsSettings(user.accessToken).then(() => {
        setDataLoaded(true);
      });
    }
  }, [user.accessToken]);

  useEffect(() => {
    if (dataLoaded) {
      setUserEmailNot(
        user.emailNotifications ? user.emailNotifications : false
      );
      setUserTextNot(user.textNotifications ? user.textNotifications : false);
      setUserEmail(user.contactEmail ? user.contactEmail : null);
      setUserPhone(user.contactPhone ? user.contactPhone : null);
      if (singleComponent === true) {
        setLoading(false);
      }
    }
  }, [
    dataLoaded,
    user.textNotifications,
    user.emailNotifications,
    user.contactEmail,
    user.contactPhone
  ]);

  const handleEmailClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEmailOpen(false);
  };

  const handleTextClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setTextOpen(false);
  };

  const handleContactClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setContactOpen(false);
  };

  async function onChangeEmail(checked: boolean) {
    const message = checked
      ? 'Email notifications enabled'
      : 'Email notifications disabled';

    setUserEmailNot(checked);

    await setNotifications(user.accessToken ?? '', 'email', checked).then(
      () => {
        setEmailMessage(message);
        setEmailOpen(true);
      }
    );
  }

  async function onChangeText(checked: boolean) {
    const message = checked
      ? 'Text notifications enabled'
      : 'Text notifications disabled';

    setUserTextNot(checked);

    await setNotifications(user.accessToken ?? '', 'text', checked).then(() => {
      setTextMessage(message);
      setTextOpen(true);
    });
  }

  return (
    <KaliberCard
      title="General settings"
      titleAlignment={'left'}
      editButton
      onClickEdit={() => {
        setEditing(!ui.editing);
      }}>
      <div className="info-container">
        <Box sx={{ width: '100%' }} style={{ marginTop: 0 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column' }}
            className="switches-container">
            <div className="switches">
              <div className="labels">
                <KaliberSwitch
                  label="Text notifications"
                  onChange={onChangeText}
                  checked={textNot}
                  disabled={
                    (!userPhone && !ui.editing) ||
                    (!user.contactPhone && !ui.editing)
                  }
                />
              </div>
              <KLPhoneField
                editable={ui.editing}
                id="phoneNumber"
                placeholder=""
                value={
                  userPhone !== null
                    ? userPhone
                    : !ui.editing
                    ? 'unavailable'
                    : ''
                }
                isRequired
                className={!ui.editing ? 'data-field disabled' : 'data-field'}
                onChange={val => setUserPhone(val.target.value)}
                disabled={userPhone === null && !ui.editing}
              />
            </div>
            <div className="switches">
              <div className="labels">
                <KaliberSwitch
                  label="Email notifications"
                  onChange={onChangeEmail}
                  checked={emailNot}
                  disabled={
                    (!userEmail && !ui.editing) ||
                    (!user.contactEmail && !ui.editing)
                  }
                />
              </div>
              <KLTextField
                editable={ui.editing}
                value={
                  userEmail !== null
                    ? userEmail
                    : !ui.editing
                    ? 'unavailable'
                    : ''
                }
                disabled={userEmail === null && !ui.editing}
                className={!ui.editing ? 'data-field disabled' : 'data-field'}
                onChange={val => setUserEmail(val.target.value)}
              />
            </div>
          </Box>
        </Box>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openEmail}
        autoHideDuration={3000}
        onClose={handleEmailClose}
        message={emailMessage}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleEmailClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
        key={'sn-email'}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openText}
        onClose={handleTextClose}
        autoHideDuration={3000}
        message={textMessage}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleTextClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
        key={'sn-text'}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openContact}
        onClose={handleContactClose}
        autoHideDuration={3000}
        message={contactMessage}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleContactClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
        key={'contact-text'}
      />
    </KaliberCard>
  );
}
