import _ from 'lodash';
import { ReactElement } from 'react';
import {
  CptSurgeryProcedure,
  CustomSurgeryProcedure,
  KLCptCode
} from '../../redux/types';
import {
  createCustomSurgeryProcedure,
  groupCptSurgeryProceduresByAnatomy,
  updateCustomSurgeryProcedureProcedure
} from '../../utils/surgeryProcedures';
import { capitalizeFirstLetter } from '../../utils/textFormat';
import CptAutoCompleteSearch from '../autoCompleteSearch/CptAutoCompleteSearch';
import KLCptProcedure from '../cptProcedure/KLCptProcedure';
import AddCustomProcedureComponent from '../customProcedure/AddCustomProcedureComponent';
import KLCustomTextInput from '../customTextInput/KLCustomTextInput';
import SegmentedControl from '../segmentedControl/SegmentedControl';
import './EditProcedures.scss';

type RegionLabelProps = {
  label: string;
  showDivider: boolean;
};

function RegionLabel({ label, showDivider }: RegionLabelProps): ReactElement {
  return (
    <>
      {showDivider && <div className="region-line-divider" />}
      <div className="region-label">{label}</div>
    </>
  );
}

type EditProceduresComponentProps = {
  cptCodes: KLCptCode[];
  cptProcedures: CptSurgeryProcedure[];
  customProcedures: CustomSurgeryProcedure[];
  onChangeCptProcedures: (newCptProcedures: CptSurgeryProcedure[]) => void;
  onRemoveCptProcedure: (removedCptProcedure: CptSurgeryProcedure) => void;
  onChangeCustomProcedures: (
    newCustomSurgeryProcedures: CustomSurgeryProcedure[]
  ) => void;
  onRemoveCustomProcedure: (
    removedCustomProcedure: CustomSurgeryProcedure
  ) => void;
  procedureType: 'planned' | 'performed';
};

export default function EditProcedures({
  cptCodes,
  cptProcedures,
  customProcedures,
  onChangeCptProcedures,
  onRemoveCptProcedure,
  onChangeCustomProcedures,
  onRemoveCustomProcedure,
  procedureType
}: EditProceduresComponentProps): ReactElement {
  const handleSelectedCptCode = (selectedCptCode: KLCptCode) => {
    const preExistingCptProcedure = cptProcedures.find(
      selectedProcedure => selectedProcedure.region === selectedCptCode.anatomy
    );

    onChangeCptProcedures([
      ...cptProcedures,
      {
        side: preExistingCptProcedure?.side ?? 'left',
        region: selectedCptCode.anatomy,
        procedureType: procedureType,
        cptCode: selectedCptCode
      }
    ]);
  };

  const handleRemoveSelectedCptProcedure = (
    removedProcedure: CptSurgeryProcedure
  ) => {
    const updatedSelectedProcedures = cptProcedures.filter(
      procedureToUpdate => procedureToUpdate !== removedProcedure
    );
    onChangeCptProcedures(updatedSelectedProcedures);
    onRemoveCptProcedure(removedProcedure);
  };

  const handleSelectedCptCodesBySide = (anatomy: string, side: string) => {
    const updatedSelectedProcedure = cptProcedures.map(procedureToUpdate => {
      const updatedProcedure = _.cloneDeep(procedureToUpdate);
      if (procedureToUpdate.region === anatomy) {
        updatedProcedure.side = side;
      }

      return updatedProcedure;
    });
    onChangeCptProcedures(updatedSelectedProcedure);
  };

  const handleCustomInputChange = (input: string, index: number) => {
    const updatedCustomProcedures = _.cloneDeep(customProcedures);
    updatedCustomProcedures[index] = updateCustomSurgeryProcedureProcedure(
      { ...updatedCustomProcedures[index] },
      input
    );
    onChangeCustomProcedures(updatedCustomProcedures);
  };

  const handleRemoveCustomProcedure = (
    removedProcedure: CustomSurgeryProcedure
  ) => {
    const updatedCustomProcedures = customProcedures.filter(
      procedureToUpdate => procedureToUpdate !== removedProcedure
    );
    onChangeCustomProcedures(updatedCustomProcedures);
    onRemoveCustomProcedure(removedProcedure);
  };

  const addCustomProcedure = (customProcedure: string) => {
    onChangeCustomProcedures([
      ...customProcedures,
      createCustomSurgeryProcedure(customProcedure, procedureType)
    ]);
  };

  const cptProceduresWithoutOtherAnatomy = cptProcedures.filter(
    procedure =>
      procedure.region !== 'other' && procedure.cptCode.anatomy !== 'other'
  );

  const cptProceduresWithOtherAnatomy = cptProcedures.filter(
    procedure =>
      procedure.region === 'other' && procedure.cptCode.anatomy === 'other'
  );

  return (
    <>
      <div className="edit-procedure-details-add-procedure">
        <CptAutoCompleteSearch
          title="Add Procedure"
          suggestions={cptCodes}
          minimumInputCharacters={3}
          selected={cptProcedures.map(cptProcedure => cptProcedure.cptCode)}
          onSelected={handleSelectedCptCode}
        />
        <div className="edit-procedure-details-add-procedure__disclaimer">
          CPT copyright 2021 American Medical Association. All rights reserved.
        </div>
      </div>
      {(cptProcedures.length !== 0 || customProcedures.length !== 0) && (
        <div className="edit-procedure-details-container">
          {cptProcedures.length !== 0 &&
            Object.entries(
              // Only render non "Other" CPT procedures
              groupCptSurgeryProceduresByAnatomy(
                cptProceduresWithoutOtherAnatomy
              )
            ).map(([anatomy, cptSurgeryProcedures], anatomyIndex) => (
              <div key={anatomyIndex}>
                <RegionLabel
                  label={capitalizeFirstLetter(anatomy)}
                  showDivider={anatomyIndex > 0}
                />

                {cptSurgeryProcedures.map(cptSurgeryProcedure => (
                  <KLCptProcedure
                    key={cptSurgeryProcedure.cptCode.code}
                    cptCode={cptSurgeryProcedure.cptCode.code}
                    description={cptSurgeryProcedure.cptCode.description}
                    commonName={cptSurgeryProcedure.cptCode.commonName}
                    onRemove={() =>
                      handleRemoveSelectedCptProcedure(cptSurgeryProcedure)
                    }
                  />
                ))}

                {['knee', 'shoulder'].includes(anatomy) && (
                  <div className="surgery-side-container">
                    <div className="surgery-side-label">Surgery Side</div>
                    <SegmentedControl
                      name="surgerySide"
                      segments={['left', 'right', 'both']}
                      selected={cptSurgeryProcedures[0].side}
                      onSegmentChange={side => {
                        handleSelectedCptCodesBySide(anatomy, side);
                      }}
                    />
                  </div>
                )}
              </div>
            ))}

          {customProcedures.length !== 0 && (
            <div>
              <RegionLabel
                label="Other"
                showDivider={cptProceduresWithoutOtherAnatomy.length > 0}
              />
              <div className="custom-procedures-container">
                {cptProcedures.length !== 0 &&
                  Object.entries(
                    // Only render "other" CPT procedures
                    groupCptSurgeryProceduresByAnatomy(
                      cptProceduresWithOtherAnatomy
                    )
                  ).map(([, cptSurgeryProcedures], anatomyIndex) => (
                    <div key={anatomyIndex}>
                      {cptSurgeryProcedures.map(cptSurgeryProcedure => (
                        <KLCptProcedure
                          key={cptSurgeryProcedure.cptCode.code}
                          cptCode={cptSurgeryProcedure.cptCode.code}
                          description={cptSurgeryProcedure.cptCode.description}
                          commonName={cptSurgeryProcedure.cptCode.commonName}
                          onRemove={() =>
                            handleRemoveSelectedCptProcedure(
                              cptSurgeryProcedure
                            )
                          }
                        />
                      ))}
                    </div>
                  ))}

                <div className="custom-procedures-container__procedures-list">
                  {customProcedures.map((customProcedureInput, index) => (
                    <div
                      key={`${index}-${customProcedureInput.customProcedure.id}`}>
                      <KLCustomTextInput
                        value={customProcedureInput.customProcedure.procedure}
                        onInputChange={e => handleCustomInputChange(e, index)}
                        onRemove={() =>
                          handleRemoveCustomProcedure(customProcedureInput)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div>
        <AddCustomProcedureComponent
          onCustomProcedureAdded={addCustomProcedure}
        />
      </div>
    </>
  );
}
