import store from '../store';

export function setLoading(loading: boolean, message?: string): void {
  store.dispatch({
    type: 'LOADING_UI',
    payload: { isLoading: loading, message: message }
  });
}

export function setSurgeryFilters(id: string, value: string): void {
  const filters = store.getState().ui.surgeryFilters;
  filters[id] = value;
  store.dispatch({
    type: 'SET_SURGERY_FILTERS',
    payload: filters
  });
}

export function setReportStatusFilters(id: string, value: boolean): void {
  const filters = store.getState().ui.surgeryStatus;
  filters[id] = value;
  store.dispatch({
    type: 'SET_REPORT_STATUS_FILTERS',
    payload: filters
  });
}

export function setShowUpdateAccountPage(val: boolean): void {
  store.dispatch({
    type: 'SET_SHOW_UPDATE_ACCOUNT_PAGE',
    payload: val
  });
}
export function setEditing(value: boolean): void {
  store.dispatch({
    type: 'SET_EDITING',
    payload: value
  });
}
export function setProgress(loaded: number): void {
  store.dispatch({
    type: 'SET_PROGRESS',
    payload: loaded
  });
}
export function setTotalUpload(total: number): void {
  store.dispatch({
    type: 'SET_TOTAL_UPLOAD',
    payload: total
  });
}
