import React, { ReactElement } from 'react';
import { ReactComponent as Search } from '../../assets/icons/MagnifyingGlass.svg';
import './KLSearch.scss';

interface KLSearchProps {
  onChange?: (val: React.SyntheticEvent) => void;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
}

export default function KLSearch({
  onChange,
  value,
  ...options
}: KLSearchProps): ReactElement {
  return (
    <div className="search-box-container">
      <input
        className="search-box"
        autoComplete="off"
        type="search"
        value={value}
        onChange={onChange}
        {...options}
      />
      <div className="search-icon">
        <Search />
      </div>
    </div>
  );
}
