import { AnyAction } from 'redux';
import { KLUserTermsAgreement } from '../types';

const initialState: KLUserTermsAgreement[] = [];

export default function TermsOfUseReducer(
  state = initialState,
  action: AnyAction
): KLUserTermsAgreement[] {
  switch (action.type) {
    case 'USER_SET_TERMS_AGREEMENT':
      return action.payload;
    case 'CLEAR_SET_TERMS_AGREEMENT': {
      return [];
    }
    default:
      return state;
  }
}
