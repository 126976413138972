/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import EnvelopeSimpleIcon from '../../../../assets/icons/EnvelopeSimple.svg';
import PhoneCallIcon from '../../../../assets/icons/PhoneCall.svg';
import UserIcon from '../../../../assets/icons/user__gray--x-dark.svg';
import KaliberCard from '../../../../components/card/KaliberCard';
import EditModal from '../../../../components/modal/EditModal';
import KaliberPhoneField from '../../../../components/phoneField/KaliberPhoneField';
import KaliberTextField from '../../../../components/textField/KaliberTextField';
import { updatePatient } from '../../../../redux/actions/patientActions';
import { KLEmergencyContact, KLState, KLUser } from '../../../../redux/types';
import { PatientCardProps } from '../patientProfile/PatientProfilePage';
import './EmergencyContact.scss';
import { KaliberButton, OptionalValueDisplay } from '../../../../components';

interface DisplayProps {
  user: KLUser;
  emergencyContact: KLEmergencyContact;
  modalOpen: () => void;
}

function Display({ emergencyContact, modalOpen, user }: DisplayProps) {
  return (
    <KaliberCard
      title="Emergency Contact"
      titleAlignment="left"
      editButton={
        user.role === 'Patient' ||
        user.role === 'Kaliber Admin' ||
        user.role === 'Surgeon' ||
        user.role === 'Surgical Clinical Team' ||
        user.role === 'Surgical Office Staff'
      }
      onClickEdit={modalOpen}>
      <div className="emergency-contact">
        <div className="emergency-contact__info">
          <img src={UserIcon} className="emergency-contact__icon" />
          <OptionalValueDisplay value={emergencyContact.name}>
            <div className="emergency-contact__text">
              {emergencyContact.name}
            </div>
          </OptionalValueDisplay>
        </div>
        <div className="emergency-contact__info">
          <img src={PhoneCallIcon} className="emergency-contact__icon" />{' '}
          <OptionalValueDisplay value={emergencyContact.phone}>
            <div className="emergency-contact__text">
              {emergencyContact.phone}
            </div>{' '}
          </OptionalValueDisplay>
        </div>
        <div className="emergency-contact__info">
          <img src={EnvelopeSimpleIcon} className="emergency-contact__icon" />{' '}
          <OptionalValueDisplay value={emergencyContact.email}>
            <div className="emergency-contact__text">
              {emergencyContact.email}
            </div>
          </OptionalValueDisplay>
        </div>
      </div>
    </KaliberCard>
  );
}

interface EditProps extends PatientCardProps {
  editModalVisible: boolean;
  modalClose: (dataChanged?: boolean) => void;
}

function Edit({ patient, editModalVisible, modalClose }: EditProps) {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty }
  } = useForm<any>({
    defaultValues: {
      name: patient.emergencyContact?.name,
      phone: patient.emergencyContact?.phone,
      email: patient.emergencyContact?.email
    }
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    if (user.accessToken && patient.id) {
      updatePatient(user.accessToken, patient.id, {
        firstName: patient.firstName,
        lastName: patient.lastName,
        dob: patient.dob,
        emergencyContact: data
      }).then(() => {
        setLoading(false);
        modalClose(true);
      });
    }
  };

  return (
    <EditModal
      visible={editModalVisible}
      title="Edit Emergency Contact"
      onClose={() => modalClose()}>
      <form
        className="edit-emergency-contact-form"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="edit-emergency-contact-form__input-container">
          <KaliberTextField
            label="Name (optional)"
            name="name"
            placeholder=""
            register={register}
            errors={errors}
            errorMessage=""
            tabIndex={0}
          />
          <KaliberPhoneField
            label="Mobile Phone (optional)"
            name="phone"
            placeholder={false}
            errors={errors}
            control={control}
            fullWidth
          />
          <KaliberTextField
            label="Email (optional)"
            placeholder=""
            name="email"
            register={register}
            errors={errors}
          />
        </div>
        <div className="edit-modal__buttons">
          <KaliberButton
            type="submit"
            disabled={!isDirty}
            loading={loading}
            buttonClass="btn--primary">
            Save Changes
          </KaliberButton>
          <KaliberButton
            type="button"
            onClick={() => modalClose()}
            buttonClass="btn--cancel">
            Cancel
          </KaliberButton>
        </div>
      </form>
    </EditModal>
  );
}

export default function EmergencyContact({
  patient,
  setReloadSurgery
}: PatientCardProps): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const handleModalOpen = () => {
    setEditModalVisible(true);
  };

  const handleModalClose = (dataChanged = false) => {
    setEditModalVisible(false);

    if (setReloadSurgery && dataChanged) {
      setReloadSurgery(true);
    }
  };

  if (!patient.emergencyContact) {
    return <></>;
  }

  return (
    <>
      <Display
        user={user}
        emergencyContact={patient.emergencyContact}
        modalOpen={handleModalOpen}
      />
      {editModalVisible && (
        <Edit
          patient={patient}
          editModalVisible={editModalVisible}
          modalClose={handleModalClose}
        />
      )}
    </>
  );
}
