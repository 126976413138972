let API_HOST = '';
let API_HOST_V2 = '';
let GA_MEASUREMENT_ID = '';
let LAUNCHDARKLY_SDK_KEY = '';

if (process.env.REACT_APP_KALIBER_ENV === 'production') {
  API_HOST = process.env.REACT_APP_API_HOST_PROD
    ? process.env.REACT_APP_API_HOST_PROD
    : '';
  API_HOST_V2 = process.env.REACT_APP_API_HOST_V2_PROD
    ? process.env.REACT_APP_API_HOST_V2_PROD
    : '';
  GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID_PROD
    ? process.env.REACT_APP_GA_MEASUREMENT_ID_PROD
    : '';
  LAUNCHDARKLY_SDK_KEY = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_PROD
    ? process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_PROD
    : '';
} else if (process.env.REACT_APP_KALIBER_ENV === 'stage') {
  API_HOST = process.env.REACT_APP_API_HOST_STAGE
    ? process.env.REACT_APP_API_HOST_STAGE
    : '';
  API_HOST_V2 = process.env.REACT_APP_API_HOST_V2_STAGE
    ? process.env.REACT_APP_API_HOST_V2_STAGE
    : '';
  GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID_STAGE
    ? process.env.REACT_APP_GA_MEASUREMENT_ID_STAGE
    : '';
  LAUNCHDARKLY_SDK_KEY = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_STAGE
    ? process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_STAGE
    : '';
} else if (process.env.REACT_APP_KALIBER_ENV === 'test') {
  API_HOST = process.env.REACT_APP_API_HOST_TEST
    ? process.env.REACT_APP_API_HOST_TEST
    : '';
  API_HOST_V2 = process.env.REACT_APP_API_HOST_V2_TEST
    ? process.env.REACT_APP_API_HOST_V2_TEST
    : '';
  LAUNCHDARKLY_SDK_KEY = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_TEST
    ? process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_TEST
    : '';
} else if (
  process.env.REACT_APP_KALIBER_ENV === 'development' ||
  process.env.REACT_APP_KALIBER_ENV === 'remote_development'
) {
  API_HOST = process.env.REACT_APP_API_HOST_DEV
    ? process.env.REACT_APP_API_HOST_DEV
    : '';
  API_HOST_V2 = process.env.REACT_APP_API_HOST_V2_DEV
    ? process.env.REACT_APP_API_HOST_V2_DEV
    : '';
  GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID_DEV
    ? process.env.REACT_APP_GA_MEASUREMENT_ID_DEV
    : '';
  LAUNCHDARKLY_SDK_KEY = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_DEV
    ? process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_DEV
    : '';
} else if (process.env.REACT_APP_KALIBER_ENV === 'demo') {
  API_HOST = process.env.REACT_APP_API_HOST_DEMO
    ? process.env.REACT_APP_API_HOST_DEMO
    : '';
} else {
  API_HOST = process.env.REACT_APP_API_HOST_LOCAL
    ? process.env.REACT_APP_API_HOST_LOCAL
    : '';
  API_HOST_V2 = process.env.REACT_APP_API_HOST_V2_LOCAL
    ? process.env.REACT_APP_API_HOST_V2_LOCAL
    : '';
  LAUNCHDARKLY_SDK_KEY = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_LOCAL
    ? process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID_LOCAL
    : '';
}

const RACE_OPTIONS = [
  '',
  'African American',
  'American Indian',
  'Asian',
  'Caucasian',
  'Hispanic',
  'Pacific Islander',
  'Other'
];

const FACILITY_OPTIONS = ['Surgery Center', 'Clinic', 'Practice'];
const ROLES_OPTIONS = ['Surgical Clinical Team', 'Surgical Office Staff'];

const SURGEON_OPTIONS = ['Dr. Nathan Mall', 'Dr. Ryan Pitts', 'Surgeon Test'];

const PATIENT_PAY_PAID_REPORT_OPTIONS = [
  'Surgeon-selected images',
  'AI-powered anatomy labels',
  'Notes from your surgeon',
  'Video highlights of your surgery',
  'Digital and PDF versions of your report',
  'Intelligently selected anatomical illustrations',
  'Information about your surgeon',
  'Shareable with your physical therapist',
  'Explanation of surgical phases',
  'Continued access to your surgical data'
];
const PATIENT_PAY_FREE_REPORT_OPTIONS = ['Surgeon-selected images'];

const STATE_OPTIONS = {
  AL: 19,
  AK: 18,
  AZ: 18,
  AR: 18,
  CA: 18,
  CO: 18,
  CT: 18,
  DE: 18,
  FL: 18,
  GA: 18,
  HI: 18,
  ID: 18,
  IL: 18,
  IN: 18,
  IA: 18,
  KS: 18,
  KY: 18,
  LA: 18,
  ME: 18,
  MD: 18,
  MA: 18,
  MI: 18,
  MN: 18,
  MS: 21,
  MO: 18,
  MT: 18,
  NE: 19,
  NV: 18,
  NH: 18,
  NJ: 18,
  NM: 18,
  NY: 18,
  NC: 18,
  ND: 18,
  OH: 18,
  OK: 18,
  OR: 18,
  PA: 18,
  RI: 18,
  SC: 18,
  SD: 18,
  TN: 18,
  TX: 18,
  UT: 18,
  VT: 18,
  VA: 18,
  WA: 18,
  WV: 18,
  WI: 18,
  WY: 18
};

// Error Messages
const DEFAULT_ERROR_MESSAGE =
  'An error occured, please check your data and try again';
const EMAIL_DUPLICATED_ERROR_MESSAGE =
  'The patient email is already in use, please try another one';
const SURGEON_FACILITY_COMBINATION_INVALID_MESSAGE =
  'The surgeon does not belong to the provided facility, please try another one';
const NPI_DUPLICATED_ERROR_MESSAGE =
  'This surgeon NPI is already in use, please try another one';
const DUPLICATE_PDF_ERROR_MESSAGE =
  'You have uploaded a PDF with a duplicate file name. Please rename the PDF or upload another PDF.';

export {
  API_HOST_V2,
  API_HOST,
  FACILITY_OPTIONS,
  GA_MEASUREMENT_ID,
  PATIENT_PAY_PAID_REPORT_OPTIONS,
  PATIENT_PAY_FREE_REPORT_OPTIONS,
  RACE_OPTIONS,
  ROLES_OPTIONS,
  SURGEON_OPTIONS,
  STATE_OPTIONS,
  DEFAULT_ERROR_MESSAGE,
  EMAIL_DUPLICATED_ERROR_MESSAGE,
  SURGEON_FACILITY_COMBINATION_INVALID_MESSAGE,
  NPI_DUPLICATED_ERROR_MESSAGE,
  DUPLICATE_PDF_ERROR_MESSAGE,
  LAUNCHDARKLY_SDK_KEY
};
