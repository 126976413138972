import { AnyAction } from 'redux';
import { KLSurgeries, SurgeonBio, SurgeryInfo, Surgery } from '../types';
import camelcaseKeys from 'camelcase-keys';

const initialState = {
  surgeries: [],
  surgery: <Surgery>{},
  review: {
    annotatedData: [],
    surgeryInfo: <SurgeryInfo>{},
    surgeonBio: <SurgeonBio>{}
  },
  upload: {
    navbarStatus: '',
    navbarShow: true,
    showConfirmation: false,
    patientName: '',
    dob: '',
    surgeryDescription: '',
    surgeryDate: '',
    surgeryId: 0,
    uploadError: null,
    numFilesToUpload: 0,
    currentUploadFileIdx: 0,
    surgeonName: '',
    cancelUpload: false
  },
  pendingDeleteConfirmation: false,
  postDeleteConfirmation: false,
  pendingDeleteSurgery: 0
};

export default function SurgeriesReducer(
  state = initialState,
  action: AnyAction
): KLSurgeries {
  switch (action.type) {
    case 'SET_SURGERIES': {
      return {
        ...state,
        surgeries: camelcaseKeys(action.payload)
      };
    }
    case 'CLEAR_SURGERIES': {
      return { ...state, surgeries: initialState.surgeries };
    }
    case 'SET_SURGERY': {
      return {
        ...state,
        surgery: action.payload
      };
    }
    case 'CLEAR_SURGERY': {
      return { ...state, surgery: initialState.surgery };
    }
    case 'SET_UPLOAD_ERROR':
      return {
        ...state,
        upload: { ...state.upload, uploadError: action.payload }
      };
    case 'SET_UPLOAD_DATA':
      return {
        ...state,
        upload: { ...action.payload }
      };
    case 'SET_SHOW_CONFIRMATION':
      return {
        ...state,
        upload: { ...state.upload, showConfirmation: action.payload }
      };
    case 'SET_NAVBAR_STATUS':
      return {
        ...state,
        upload: { ...state.upload, navbarStatus: action.payload }
      };
    case 'SET_TOGGLE_NAVBAR_SHOW':
      return {
        ...state,
        upload: { ...state.upload, navbarShow: action.payload }
      };
    case 'SET_NUM_FILES_TO_UPLOAD':
      return {
        ...state,
        upload: { ...state.upload, numFilesToUpload: action.payload }
      };
    case 'INCREMENT_UPLOAD_PROGRESS':
      return {
        ...state,
        upload: {
          ...state.upload,
          currentUploadFileIdx: state.upload.currentUploadFileIdx + 1
        }
      };
    case 'RESET_UPLOAD_PROGRESS':
      return {
        ...state,
        upload: {
          ...state.upload,
          currentUploadFileIdx: 0,
          cancelUpload: false
        }
      };
    case 'CANCEL_UPLOAD':
      return {
        ...state,
        upload: { ...state.upload, cancelUpload: true }
      };

    case 'SET_SURGERY_DATA': {
      return {
        ...state,
        review: {
          ...state.review,
          surgeryInfo: camelcaseKeys(action.payload.surgery_info, {
            deep: true
          }),
          surgeonBio: camelcaseKeys(action.payload.surgeon_bio_info, {
            deep: true
          })
        }
      };
    }
    case 'SET_REVIEW_DATA': {
      return {
        ...state,
        review: {
          ...state.review,
          annotatedData: camelcaseKeys(action.payload.annotations, {
            deep: true
          })
        }
      };
    }
    case 'CLEAR_REVIEW_DATA': {
      return {
        ...state,
        review: {
          ...initialState.review
        }
      };
    }
    case 'SET_PENDING_DELETE_CONFIRMATION': {
      return {
        ...state,
        pendingDeleteConfirmation: action.payload
      };
    }
    case 'SET_POST_DELETE_CONFIRMATION': {
      return {
        ...state,
        postDeleteConfirmation: action.payload
      };
    }
    case 'SET_PENDING_SURGERY': {
      return {
        ...state,
        pendingDeleteSurgery: action.payload
      };
    }
    default:
      return state;
  }
}
