import { ReactElement } from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import {
  SegmentedControl,
  KaliberTextArea
} from '../../../../../../../../components';

const REGIONS_OF_TEAR = ['N/A', 'Anterior', 'Posterior'];
import './BankartRepairComponent.scss';

export interface BankartDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  register: UseFormRegister<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  cptProcedureIndex: number;
}

export default function BankartDetailCard({
  control,
  register,
  cptProcedureIndex
}: BankartDetailCardProps): ReactElement {
  return (
    <div className="bankart-detail-card">
      <input
        type="hidden"
        {...register(`cptProcedures.${cptProcedureIndex}.procedureDetail.id`, {
          setValueAs: v => (v === '' ? null : parseInt(v, 10))
        })}
      />
      <input
        type="hidden"
        {...register(
          `cptProcedures.${cptProcedureIndex}.procedureDetail.type`,
          {
            value: 'ProcedureDetails::BankartRepairDetail'
          }
        )}
      />

      <div className="bankart-detail-card-container">
        <Controller
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfTear`}
          control={control}
          defaultValue="N/A"
          render={({ field }) => {
            return (
              <SegmentedControl
                label="Region of Tear"
                name="regionOfTearUsed"
                segments={REGIONS_OF_TEAR}
                //field.value should always be string[], however, we are setting the defaultValue="N/A" in the Controller. Hence the need to check if field.value === 'N/A' back here
                selected={field.value === 'N/A' ? 'N/A' : field.value[0]}
                onSegmentChange={regionOfTear => {
                  field.onChange([regionOfTear]);
                }}
              />
            );
          }}
        />

        <div className="additional-details">
          <KaliberTextArea
            label="Additional Details (optional)"
            name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
            register={register}
            errors={{}}
            rows={3}
            placeholder="Additional notes about size, repair details, tissue quality, anchor count etc"
          />
        </div>
      </div>
    </div>
  );
}
