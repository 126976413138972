import { AnyAction } from 'redux';
import { KLCptCode } from '../types';

const initialState: KLCptCode[] = [];

export default function CptCodesReducer(
  state = initialState,
  action: AnyAction
): KLCptCode[] {
  switch (action.type) {
    case 'SET_CPT_CODES': {
      return action.payload;
    }
    default:
      return state;
  }
}
