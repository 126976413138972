import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import KLAlert from '../../components/alert/KLAlert';
import { StaffTableComponent } from '../../components/staffTable';

import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import { getStaff } from '../../redux/actions/staffActions';
import { getSurgeons } from '../../redux/actions/surgeonsActions';
import { KLState, KLSurgeon, KLUser, Staff } from '../../redux/types';
import './StaffPage.scss';

function requestErrorMessage(message: string) {
  return (
    message !== 'No facilities associated with this user' &&
    message !== 'No staff associated with this user.' &&
    message !== 'ok'
  );
}

export default function StaffPage(): ReactElement {
  setFullstoryPage('Staff Page');
  const [staff, surgeons, user]: [Staff[], KLSurgeon[], KLUser] = useSelector(
    (state: KLState) => [state.staff, state.surgeons, state.user]
  );

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRequestErrors = (
    staffRequestMessage: string,
    surgeonsRequestMessage: string
  ) => {
    if (requestErrorMessage(staffRequestMessage)) {
      setAlertMessage(staffRequestMessage);
      setShowAlert(true);
    } else if (requestErrorMessage(surgeonsRequestMessage)) {
      setAlertMessage(surgeonsRequestMessage);
      setShowAlert(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    const staff = async () => {
      if (user.accessToken) {
        const staffRequestMessage = await getStaff(user.accessToken);
        const surgeonsRequestMessage = await getSurgeons(user.accessToken);

        handleRequestErrors(staffRequestMessage, surgeonsRequestMessage);
        setLoading(false);
      }
    };

    staff();
  }, [user.accessToken]);

  return (
    <div className="staff-page">
      {loading ? (
        <div className="staff-page__skeleton-container">
          <div className="staff-page__skeleton staff-page__skeleton--header" />
          <div className="staff-page__skeleton staff-page__skeleton--table" />
        </div>
      ) : (
        <div>
          <KLAlert visible={showAlert} label={alertMessage} />
          <div className="page-container">
            <StaffTableComponent staff={staff} surgeons={surgeons} />
          </div>
        </div>
      )}
    </div>
  );
}
