import { AnyAction } from 'redux';
import { SurgeryProcedures } from '../types';

const initialState = {
  cptProcedures: [],
  customProcedures: []
};

export default function ProceduresReducer(
  state = initialState,
  action: AnyAction
): SurgeryProcedures {
  switch (action.type) {
    case 'SET_PROCEDURES': {
      return {
        ...state,
        cptProcedures: action.payload.cptProcedures,
        customProcedures: action.payload.customProcedures
      };
    }
    default:
      return state;
  }
}
