import { ReactElement, useEffect } from 'react';
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';
import {
  KaliberChipMultiSelect,
  KaliberTextField,
  SegmentedControl,
  KaliberSelect,
  KaliberTextArea
} from '../../../../../../../../components';
import './MeniscusRepairComponent.scss';

const REGIONS_OF_TEAR = ['N/A', 'Medial', 'Lateral'];
const DEFAULT_NA_VALUE = ['N/A'];
const REGIONS_OF_MENISCUS = ['Anterior Third', 'Mid Third', 'Posterior Third'];
const UNIT_MM = 'mm';
const REPAIR_LENGTH_UNITS = [UNIT_MM, 'cm'];

type RegionOfMeniscusComponentProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  controllerName: string;
  label: string;
};

function RegionOfMeniscusComponent({
  control,
  controllerName,
  label
}: RegionOfMeniscusComponentProps): ReactElement {
  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <>
          <div className="multi-select-container">
            <KaliberChipMultiSelect
              label={label}
              select={field.value}
              onChange={regionOfMeniscus => {
                field.onChange(regionOfMeniscus);
              }}
              list={REGIONS_OF_MENISCUS}
            />
          </div>
        </>
      )}
    />
  );
}

type RepairLengthComponentProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  lengthControllerName: string;
  unitControllerName: string;
  label: string;
};

function RepairLengthComponent({
  register,
  control,
  lengthControllerName,
  unitControllerName,
  label
}: RepairLengthComponentProps): ReactElement {
  return (
    <div className="repair-length-component-container">
      <div className="repair-length-component-label">{label}</div>

      <div className="repair-length-and-unit-component-container">
        <KaliberTextField
          name={lengthControllerName}
          register={register}
          errors={{}}
          restrictToDecimals={true}
        />

        <Controller
          name={unitControllerName}
          control={control}
          defaultValue={UNIT_MM}
          render={({ field }) => (
            <KaliberSelect
              label=""
              placeholder=""
              select={field.value ? field.value : UNIT_MM}
              errors={{}}
              includeOther={false}
              onChange={repairLengthUnit => field.onChange(repairLengthUnit)}
              list={REPAIR_LENGTH_UNITS}
            />
          )}
        />
      </div>
    </div>
  );
}

export interface MeniscusRepairDetailCardProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  cptProcedureIndex: number;
  regionOfTear: string;
  cptCode: string;
}

export default function MeniscusRepairDetailCard({
  control,
  register,
  setValue,
  cptProcedureIndex,
  regionOfTear,
  cptCode
}: MeniscusRepairDetailCardProps): ReactElement {
  //Reset repair length values whenever we toggle around
  const resetRepairLengthValues = () => {
    setValue(
      `cptProcedures.${cptProcedureIndex}.procedureDetail.medialRepairLength`,
      ''
    );
    setValue(
      `cptProcedures.${cptProcedureIndex}.procedureDetail.medialRepairLengthUnit`,
      UNIT_MM
    );
    setValue(
      `cptProcedures.${cptProcedureIndex}.procedureDetail.lateralRepairLength`,
      ''
    );
    setValue(
      `cptProcedures.${cptProcedureIndex}.procedureDetail.lateralRepairLengthUnit`,
      UNIT_MM
    );
  };

  // Manually set the regionOfTear to ['Medial', 'Lateral'] if it is 29880 since we are preventing the user from choosing
  useEffect(() => {
    if (cptCode === '29883') {
      setValue(
        `cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfTear`,
        ['Medial', 'Lateral']
      );
    }
  }, []);

  return (
    <div className="meniscus-repair">
      <input
        type="hidden"
        {...register(`cptProcedures.${cptProcedureIndex}.procedureDetail.id`, {
          setValueAs: v => (v === '' ? null : parseInt(v, 10))
        })}
      />
      <input
        type="hidden"
        {...register(
          `cptProcedures.${cptProcedureIndex}.procedureDetail.type`,
          {
            value: 'ProcedureDetails::MeniscusRepairDetail'
          }
        )}
      />
      {cptCode === '29882' && (
        <Controller
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfTear`}
          control={control}
          defaultValue={DEFAULT_NA_VALUE}
          render={({ field }) => {
            return (
              <SegmentedControl
                label="Region of Tear"
                name="regionOfTearUsed"
                segments={REGIONS_OF_TEAR}
                //Select only the first element since field can be an array
                selected={field.value[0]}
                onSegmentChange={regionOfTear => {
                  resetRepairLengthValues();
                  field.onChange([regionOfTear]);
                }}
              />
            );
          }}
        />
      )}
      {cptCode === '29882' && regionOfTear[0] === 'Medial' && (
        <RepairLengthComponent
          register={register}
          control={control}
          lengthControllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.medialRepairLength`}
          unitControllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.medialRepairLengthUnit`}
          label={'Repair Length (optional)'}
        />
      )}
      {cptCode === '29882' && regionOfTear[0] === 'Lateral' && (
        <RepairLengthComponent
          register={register}
          control={control}
          lengthControllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.lateralRepairLength`}
          unitControllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.lateralRepairLengthUnit`}
          label={'Repair Length (optional)'}
        />
      )}

      {cptCode === '29883' && (
        <div className="medial-and-lateral-29883-component">
          <div className="region-of-meniscus-and-repair-length-component">
            <RegionOfMeniscusComponent
              control={control}
              controllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfMedialMeniscus`}
              label={'Region of Medial Meniscus (optional)'}
            />
            <RepairLengthComponent
              register={register}
              control={control}
              lengthControllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.medialRepairLength`}
              unitControllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.medialRepairLengthUnit`}
              label={'Medial Repair Length (optional)'}
            />
          </div>
          <div className="region-of-meniscus-and-repair-length-component">
            <RegionOfMeniscusComponent
              control={control}
              controllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.regionOfLateralMeniscus`}
              label={'Region of Lateral Meniscus (optional)'}
            />
            <RepairLengthComponent
              register={register}
              control={control}
              lengthControllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.lateralRepairLength`}
              unitControllerName={`cptProcedures.${cptProcedureIndex}.procedureDetail.lateralRepairLengthUnit`}
              label={'Lateral Repair Length (optional)'}
            />
          </div>
        </div>
      )}

      <div className="additional-details">
        <KaliberTextArea
          label="Additional Details (optional)"
          name={`cptProcedures.${cptProcedureIndex}.procedureDetail.additionalDetails`}
          register={register}
          errors={{}}
          rows={3}
          placeholder="Additional notes about size, repair details, tissue quality, anchor count etc"
        />
      </div>
    </div>
  );
}
