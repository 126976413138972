import { capitalCase } from 'change-case';
import { AnyAction } from 'redux';
import { KLUser } from '../types';

const initialState = {
  accessToken: null,
  email: '',
  role: '',
  uid: '',
  emailNotifications: false,
  textNotifications: false,
  contactEmail: '',
  contactPhone: '',
  error: ''
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function userInfo(payload: any) {
  return payload.user
    ? {
        email: payload.user.email,
        role: capitalCase(payload.user.role),
        uid: payload.user.uid
      }
    : {
        email: initialState.email,
        role: initialState.role,
        uid: initialState.uid
      };
}

export default function UserReducer(
  state = initialState,
  action: AnyAction
): KLUser {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        accessToken: action.payload.token,
        ...userInfo(action.payload)
      };
    }
    case 'USER_SET_NOTIFICATIONS_SETTINGS': {
      return {
        ...state,
        emailNotifications: action.payload.email_notifications,
        textNotifications: action.payload.text_notifications,
        contactEmail: action.payload.contact_email,
        contactPhone: action.payload.contact_phone
      };
    }
    case 'USER_SET_CONTACT_SETTINGS': {
      return {
        ...state,
        contactEmail: action.payload.contact_email,
        contactPhone: action.payload.contact_phone
      };
    }
    case 'CLEAR_USER': {
      return {
        ...state,
        accessToken: '',
        email: initialState.email,
        role: initialState.role,
        uid: initialState.uid
      };
    }
    case 'CLEAR_USER_NOTIFICATIONS': {
      return {
        ...state,
        emailNotifications: initialState.emailNotifications,
        textNotifications: initialState.textNotifications,
        contactEmail: initialState.contactEmail,
        contactPhone: initialState.contactPhone
      };
    }
    case 'SET_AUTH_ERROR': {
      return {
        ...state,
        error: action.payload
      };
    }
    case 'CLEAR_AUTH_ERROR': {
      return {
        ...state,
        error: action.payload
      };
    }
    default:
      return state;
  }
}
