import React, { ReactElement } from 'react';
import './ErrorLinkPage.scss';

export default function ErrorLinkPage(): ReactElement {
  return (
    <div className="error-link-page">
      <div className="error-link-card">
        <div className="error-link-card__title">
          {'The link you are trying to access has expired.'}
        </div>
        <div className="error-link-card__body">
          The page you are trying to reach has expired. Please contact the
          person who shared this link with you and request a new link.
        </div>
      </div>
    </div>
  );
}
