import {
  AnnotatedData,
  KLSurgery,
  Patient,
  PaymentPreference,
  SurgeryInfo
} from '../redux/types';
import { capitalizeEveryWord, normalizeText } from './textFormat';
import { isNullOrUndefined } from './validations';

export function sortAnnotatedDataByFileName(
  first: AnnotatedData,
  second: AnnotatedData
): number {
  if (first.filename < second.filename) {
    return -1;
  }

  if (first.filename > second.filename) {
    return 1;
  }

  return 0;
}

export function patientFromSurgeryInfo(surgeryInfo: SurgeryInfo): Patient {
  return {
    firstName: surgeryInfo.patientFirstName,
    middleName: surgeryInfo.patientMiddleName,
    lastName: surgeryInfo.patientLastName,
    dob: surgeryInfo.patientDob,
    sex: surgeryInfo.patientSex,
    race: surgeryInfo.patientRace,
    email: surgeryInfo.patientEmail,
    phone: surgeryInfo.patientPhone,
    preferredCommunication: surgeryInfo.patientPreferredCommunication,
    preferredName: surgeryInfo.patientPreferredName,
    pronouns: surgeryInfo.patientPronouns,
    emergencyContact: {
      name: surgeryInfo.emergencyContactName ?? '',
      email: surgeryInfo.emergencyContactEmail ?? '',
      phone: surgeryInfo.emergencyContactPhone ?? ''
    }
  };
}

export function formatSex(data: string | undefined): string | undefined {
  switch (data) {
    case 'Male':
      return 'M';
    case 'Female':
      return 'F';
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    default:
      return undefined;
  }
}
export function formatPronouns(data: string | undefined): string | undefined {
  switch (data) {
    case 'He/Him':
      return 'he_him';
    case 'She/Her':
      return 'she_her';
    case 'They/Them':
      return 'they_them';
    case 'he_him':
      return 'He/Him';
    case 'she_her':
      return 'She/Her';
    case 'they_them':
      return 'They/Them';
    default:
      return data;
  }
}

export interface SelectItem {
  value: string | null;
  label: string;
}

export function getPronounsOptions(): SelectItem[] {
  const pronounsOptions = [
    { value: '', label: 'Select one (optional)' },
    { value: 'he_him', label: 'He/Him' },
    { value: 'she_her', label: 'She/Her' },
    { value: 'they_them', label: 'They/Them' },
    { value: 'null', label: 'Prefer not to answer' },
    { value: 'name', label: 'Name' }
  ];
  return pronounsOptions;
}

export function getPronounsObject(value: string): SelectItem {
  return getPronounsOptions().filter(pronoun => pronoun.value === value)[0];
}

export function getPaymentPreferences(): string[] {
  return Object.keys(PaymentPreference).map(key => {
    return normalizeText(key);
  });
}

export function paymentPreferenceToString(val: PaymentPreference): string {
  return capitalizeEveryWord(val.replace(/_/g, ' '));
}

export function stringToPaymentPreference(val: string): PaymentPreference {
  const paymentPreferenceKey = Object.keys(PaymentPreference).find(key => {
    return key === val.replace(/\s/g, '').trim();
  });
  return PaymentPreference[
    paymentPreferenceKey as keyof typeof PaymentPreference
  ];
}

export function reportStatusSort(a: KLSurgery, b: KLSurgery): number {
  const reportStatus: Record<string, number> = {
    Processing: 0,
    'Not started': 1,
    'Ready for review': 2,
    'Sent to patient': 4,
    'Surgery in': 5
  };

  const aReportStatus = a.surgeryStatus;
  const bReportStatus = b.surgeryStatus;

  const aValue = reportStatus[aReportStatus];
  const bValue = reportStatus[bReportStatus];
  return aValue < bValue ? -1 : 1;
}

/**
 * Sorts the given KLSurgery objects in alphabetical order based on the patient's last name.
 *
 * @param {KLSurgery} a - The first KLSurgery object to compare.
 * @param {KLSurgery} b - The second KLSurgery object to compare.
 * @return {number} Returns a negative number if `a` should be sorted before `b`,
 *                 a positive number if `a` should be sorted after `b`, or 0 if they are equal.
 */
export function alphabeticalSort(a: KLSurgery, b: KLSurgery): number {
  return a.patientLastName.localeCompare(b.patientLastName);
}

/**
 * Sorts the KLSurgery objects based on their surgeryDate property.
 * Returns a negative value if a should be placed before b,
 * a positive value if a should be placed after b,
 * and 0 if a and b are equal.
 *
 * @param a - The first KLSurgery object.
 * @param b - The second KLSurgery object.
 * @returns The comparison result.
 */
export function surgeryDateSort(a: KLSurgery, b: KLSurgery): number {
  // If a.surgeryDate is undefined, place a after b
  if (isNullOrUndefined(a.surgeryDate)) {
    return 1;
  }

  // If b.surgeryDate is undefined, place a before b
  if (isNullOrUndefined(b.surgeryDate)) {
    return -1;
  }

  // Convert surgeryDate strings to timestamps
  const aTimestamp = new Date(a.surgeryDate).getTime();
  const bTimestamp = new Date(b.surgeryDate).getTime();

  // Compare the timestamps and return the result
  return aTimestamp - bTimestamp;
}

/**
 * Sorts surgeries based on their surgery date.
 * Surgeries with undefined surgery dates are considered greater than surgeries with defined surgery dates.
 * Surgeries with surgery dates equal to the current date are considered smaller than surgeries with other surgery dates.
 *
 * @param a - The first surgery to compare
 * @param b - The second surgery to compare
 * @returns - A negative number if a is smaller than b, a positive number if a is greater than b, or 0 if they are equal
 */
export function currentDateSort(a: KLSurgery, b: KLSurgery): number {
  // Check if a's surgeryDate is undefined
  if (isNullOrUndefined(a.surgeryDate)) {
    return 1; // a is considered greater than b
  }

  // Check if b's surgeryDate is undefined
  if (isNullOrUndefined(b.surgeryDate)) {
    return -1; // b is considered greater than a
  }

  // Get the current date
  const currentDate = getCurrentDate();

  // Convert a's surgeryDate to a Date object
  const surgeryDateA = new Date(a.surgeryDate);

  // Compare currentDate and surgeryDateA
  if (currentDate.toUTCString() === surgeryDateA.toUTCString()) {
    return -1; // a is considered smaller than b
  } else {
    return 1; // a is considered greater than b
  }
}

/**
 * Returns the current date with the time set to 00:00:00.
 *
 * @returns {Date} The current date with the time set to 00:00:00.
 */
const getCurrentDate = (): Date => {
  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);
  return currentDate;
};

/**
 * Formats the status value into value used as label.
 *
 * @param {string} value - the status value to be formatted
 * @return {string} the formatted human-readable status value
 */
export function formatStatusValue(value: string): string {
  switch (value) {
    case 'sentToPatient':
      return 'Sent to Patient';
    case 'readyForReview':
      return 'Ready for Review';
    case 'processing':
      return 'Processing';
    case 'notStarted':
      return 'Not Started';
    default:
      return '';
  }
}
