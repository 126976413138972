/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFilters, useSortBy, useTable } from 'react-table';
import { ReactComponent as PlusCircle } from '../../assets/icons/PlusCircle.svg';
import KLAlert from '../../components/alert/KLAlert';
import KaliberButton from '../../components/button/KaliberButton';
import KLSearch from '../../components/search/KLSearch';
import { getFacilities } from '../../redux/actions/facilitiesActions';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import { capitalizeEveryWord, sentenceCase } from '../../utils/textFormat';
import { isEmpty, isNullOrUndefined } from '../../utils/validations';
import './facilitiesPage.scss';

function generateFacilityColumns(nameSortFunction) {
  return [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'facilityName',
      sortType: nameSortFunction,
      Cell: ({ value }) => {
        return sentenceCase(value);
      }
    },
    {
      Header: 'Facility type',
      accessor: 'facilityType',
      Cell: ({ value }) => {
        return sentenceCase(value);
      }
    },
    {
      Header: 'NPI',
      accessor: 'facilityNpi',
      Cell: ({ value }) => {
        return isNullOrUndefined(value) || isEmpty(value)
          ? 'Not available'
          : value;
      }
    },
    {
      Header: 'Address',
      accessor: 'facilityAddress',
      Cell: ({ value }) => {
        return isNullOrUndefined(value) || isEmpty(value)
          ? 'Not available'
          : capitalizeEveryWord(value);
      }
    },
    {
      Header: 'Office phone',
      accessor: 'facilityPhone',
      Cell: ({ value }) => {
        return isNullOrUndefined(value) || isEmpty(value)
          ? 'Not available'
          : value;
      }
    },
    {
      Header: 'Office fax',
      accessor: 'facilityFax',
      Cell: ({ value }) => {
        return isNullOrUndefined(value) || isEmpty(value)
          ? 'Not available'
          : value;
      }
    },
    {
      Header: 'After hours',
      accessor: 'facilityAfterHoursPhone',
      Cell: ({ value }) => {
        return isNullOrUndefined(value) || isEmpty(value)
          ? 'Not available'
          : value;
      }
    },
    {
      Header: 'Email',
      accessor: 'facilityEmail',
      Cell: ({ value }) => {
        return isNullOrUndefined(value) || isEmpty(value)
          ? 'Not available'
          : value;
      }
    },
    {
      Header: 'Website',
      accessor: 'facilityWebsite',
      Cell: ({ value }) => {
        return isNullOrUndefined(value) || isEmpty(value)
          ? 'Not available'
          : value;
      }
    }
  ];
}

const alphabeticalSort = (a, b) => {
  return a.facilityName.toLowerCase().localeCompare(b.facilityName);
};

const customSort = data => {
  const copyArray = data.slice();
  copyArray.sort(alphabeticalSort);

  return copyArray;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function FacilitiesPage() {
  setFullstoryPage('Facilities Page');

  const [facilities, user, ui] = useSelector(state => [
    state.facilities,
    state.user,
    state.ui
  ]);
  const isQAUser = user.role === 'Kaliber Qa';
  const isAdminUser = user.role === 'Kaliber Admin';

  const [facilitiesLoaded, setFacilitiesLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const facilities = async () => {
      if (user.accessToken) {
        const message = await getFacilities(user.accessToken);
        setFacilitiesLoaded(true);

        if (
          message !== 'ok' &&
          message !== 'No facilities associated with this user'
        ) {
          setAlertMessage(message);
          setShowAlert(true);
        }
      }
    };

    facilities();
  }, [user.accessToken]);

  const nameSort = useCallback((rowA, rowB, _columnId, _desc) => {
    if (
      rowA.original.facilityName.toLowerCase() >
      rowB.original.facilityName.toLowerCase()
    ) {
      return 1;
    } else {
      return -1;
    }
  }, []);
  const columns = useMemo(() => generateFacilityColumns(nameSort), []);
  const data = useMemo(
    () => customSort(facilities.facilities),
    [facilities.facilities]
  );

  useEffect(() => {
    if (facilitiesLoaded && facilities.facilities.length === data.length) {
      setLoading(false);
    }
  }, [facilitiesLoaded, data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        filters: [
          {
            id: ui.filter.id,
            value: ui.filter.value
          }
        ]
      }
    },
    useFilters,
    useSortBy
  );

  const [searchInput, setSearchInput] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleFilterChange = e => {
    const value = e.target.value || '';
    setFilter('name', value);
    setSearchInput(value);
  };
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddFacility = () => {
    history.push('/add-facility');
  };
  return (
    <div className="facilities-page page-container">
      {loading ? (
        <div className="facilities-page__skeleton-container">
          <div className="facilities-page__skeleton facilities-page__skeleton--header" />
          <div className="facilities-page__skeleton facilities-page__skeleton--table" />
        </div>
      ) : (
        <div>
          <div className="facility-table">
            <KLAlert visible={showAlert} label={alertMessage} />
            <div className="menu-container">
              {isQAUser ||
                (isAdminUser && <div className="right-container"></div>)}
            </div>
            <div className="filters-container">
              <div className="left-container">
                <div className="filter">
                  <KLSearch
                    value={searchInput}
                    onChange={handleFilterChange}
                    placeholder="Search facilities"
                  />
                </div>
              </div>
            </div>
            {isAdminUser && (
              <div className="right-container">
                <KaliberButton
                  buttonClass="btn--primary"
                  type="button"
                  onClick={handleAddFacility}>
                  <PlusCircle className="left" /> Add facility
                </KaliberButton>
              </div>
            )}
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => {
                  const { key, ...restHeaderGroupProps } =
                    headerGroup.getHeaderGroupProps();
                  return (
                    <tr key={key} {...restHeaderGroupProps}>
                      {headerGroup.headers.map(column => {
                        const { key, ...restColumn } = column.getHeaderProps(
                          column.getSortByToggleProps()
                        );
                        return (
                          <th
                            key={key}
                            className={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'sort-desc'
                                  : 'sort-asc'
                                : 'no-sort'
                            }
                            {...restColumn}>
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  return (
                    <tr
                      id="fs-mask"
                      key={key}
                      {...restRowProps}
                      className="clickable-row"
                      onClick={() =>
                        history.push(
                          `/facility?facilityId=${row.original.facilityId}`
                        )
                      }>
                      {row.cells.map(cell => {
                        const { key, ...restCellProps } = cell.getCellProps();
                        return (
                          <td key={key} {...restCellProps}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {rows.length === 0 && !ui.loading && (
              <div className="Empty-text">
                No facilities matching this criteria
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
