import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import KaliberLogo from '../../assets/logos/kaliber-rekap-logo-TM-dark.png';
import KaliberButton from '../../components/button/KaliberButton';
import KaliberMessagePage from '../../components/messagePage/KaliberMessagePage';
import KaliberModal from '../../components/modal/KaliberModal';
import KaliberPasswordField from '../../components/textField/KaliberPasswordField';
import KaliberTextField from '../../components/textField/KaliberTextField';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import {
  createNewStytchUserAccount,
  getPatientEmailByKey,
  isSignUpLinkExpired,
  resendSignupNotifications
} from '../../redux/actions/patientActions';
import { KLState } from '../../redux/types';
import { useQuery } from '../../utils/react';
import {
  isEmpty,
  isNotEmpty,
  isNullOrUndefined
} from '../../utils/validations';
import ErrorPage from '../errorPage/ErrorPage';
import './CredentialForms.scss';

export interface PatientSignUpFormData {
  email: string;
  signUpPassword: string;
  confirmPassword: string;
}
export default function SignUp(): ReactElement {
  const history = useHistory();
  const user = useSelector((state: KLState) => state.user);
  useEffect(() => {
    setFullstoryPage('Sign Up Page');
  }, []);
  useEffect(() => {
    if (user.accessToken !== '') {
      history.push('/');
    }
  }, [user]);

  const [errorPage, setErrorPage] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState('');
  const [signUpLinkExpiredPage, setSignUpLinkExpiredPage] = useState(false);

  const query = useQuery();
  const queryParamSurgeonId = query.get('surgeonId');
  const queryParamFacilityId = query.get('facilityId');
  const queryParamKey = query.get('key');

  const [resendSignUpLink, setResendSignUpLink] = useState(false);

  const [userEmail, setUserEmail] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      isNullOrUndefined(queryParamSurgeonId) ||
      isNullOrUndefined(queryParamFacilityId) ||
      isEmpty(queryParamSurgeonId) ||
      isEmpty(queryParamFacilityId)
    ) {
      setErrorPage(true);
    } else {
      setErrorPage(false);
    }
  }, [queryParamFacilityId, queryParamSurgeonId]);

  useEffect(() => {
    if (queryParamKey) {
      const checkLinkExpiration = async () => {
        try {
          const expired = await isSignUpLinkExpired(queryParamKey);
          setSignUpLinkExpiredPage(expired);
        } catch (error) {
          setErrorPage(true);
        }
      };

      const getUserEmail = async () => {
        try {
          setUserEmail(await getPatientEmailByKey(queryParamKey));
        } catch (error) {
          setSignUpLinkExpiredPage(true);
        }
      };

      checkLinkExpiration();
      getUserEmail();
    }

    return () => {
      setSignUpLinkExpiredPage(false);
    };
  }, [queryParamKey]);

  const onSubmit = async (signUpFormData: PatientSignUpFormData) => {
    setLoading(true);
    try {
      if (queryParamKey) {
        await createNewStytchUserAccount(
          isNotEmpty(userEmail) ? userEmail : signUpFormData.email,
          signUpFormData.signUpPassword,
          queryParamKey
        );
        setShowConfirmation(true);
        setTimeout(() => {
          history.push('/');
        }, 2000);
        setLoading(false);
      } else {
        throw new Error('queryParamKey is not defined');
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
        setErrorModal(true);
        setLoading(false);
      }
    }
  };

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>();

  const password = watch('signUpPassword');

  return errorPage ? (
    <ErrorPage />
  ) : signUpLinkExpiredPage ? (
    signUpLinkExpiredPrompt({
      queryParamKey,
      resendSignUpLink,
      setResendSignUpLink,
      setErrorPage
    })
  ) : (
    <div>
      <div>
        <div className="credential-container">
          <div className="credential-container__title">
            Welcome to
            <img
              className="credential-container__logo"
              src={KaliberLogo}
              alt=""
            />
          </div>
          <div className="credential-container__form">
            <div className="credential-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="credential-form__header">
                  Create a Rekap Account
                </div>
                <div className="credential-form__sub-header"></div>
                <div className="credential-form__textbox">
                  {userEmail ? (
                    <div className="credential-form__textbox-email">
                      <div className="credential-form__label">Email</div>
                      <div
                        className="credential-form__email"
                        data-testid="credential-email">
                        {userEmail}
                      </div>
                    </div>
                  ) : (
                    <KaliberTextField
                      label="Email"
                      name="email"
                      placeholder=""
                      register={register}
                      registerObject={{ required: true }}
                      errors={errors}
                      errorMessage="This email is already in use. Please use another email."
                      tabIndex={0}
                      dataTestId="sign-up-email"
                    />
                  )}
                </div>
                <div className="credential-form__textbox">
                  <KaliberPasswordField
                    label="Password"
                    name="signUpPassword"
                    register={register}
                    required
                    errors={errors}
                    errorMessage={errors.signUpPassword?.message}
                    dataTestId="sign-up-password"
                  />
                </div>
                <div className="credential-form__textbox">
                  <KaliberPasswordField
                    label="Confirm Password"
                    name="confirmPassword"
                    register={register}
                    required
                    watch={password}
                    errors={errors}
                    errorMessage={errors.confirmPassword?.message}
                    dataTestId="sign-up-confirm-password"
                  />
                </div>
                <div className="credential-form__btn">
                  <KaliberButton
                    buttonClass="btn--primary btn--full-width"
                    type="submit"
                    dataTestId="sign-up-create-account"
                    loading={loading}>
                    Create Account
                  </KaliberButton>
                </div>
              </form>
              <div className="msg msg--login">
                Already have an account?
                <Link to={'/login'}>
                  <span className="msg--link">Login</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="msg msg--agreement">
            By creating an account, you agree to our{' '}
            <Link to={'/terms-of-use'}>
              <span className="msg--link">Terms of Use</span>
            </Link>{' '}
            and{' '}
            <Link to={'/privacy-policy'}>
              <span className="msg--link">Privacy Policy.</span>
            </Link>{' '}
          </div>
        </div>
        <KaliberModal
          title="User created successfully!"
          titleAlignment="center"
          visible={showConfirmation}>
          <div className="modal-text">Redirecting you to login page.</div>
        </KaliberModal>
        <KaliberModal
          title="Account Creation Error"
          titleAlignment="center"
          visible={errorModal}
          closeIcon={true}
          onClose={() => setErrorModal(false)}>
          <div className="modal-text">{error}</div>
        </KaliberModal>
      </div>
    </div>
  );
}

function signUpLinkExpiredPrompt({
  queryParamKey,
  resendSignUpLink,
  setResendSignUpLink,
  setErrorPage
}: {
  queryParamKey: string | null;
  resendSignUpLink: boolean;
  setResendSignUpLink: (sent: boolean) => void;
  setErrorPage: (error: boolean) => void;
}): ReactElement {
  const handleResendButton = async () => {
    if (queryParamKey) {
      const isSignUpNotificationResent = await resendSignupNotifications(
        queryParamKey
      );

      if (isSignUpNotificationResent) {
        setResendSignUpLink(true);
      } else {
        setErrorPage(true);
      }
    }
  };

  return resendSignUpLink ? (
    <KaliberMessagePage
      title="The new link has been sent. "
      body="Please check your email."
    />
  ) : (
    <KaliberMessagePage
      title="The link you are trying to access has expired."
      body="To receive a new secure link, click on the button below."
      hasButton
      buttonText="Resend Report"
      onClick={handleResendButton}
    />
  );
}
