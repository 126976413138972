import { Moment } from 'moment';
import { ReactElement, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/calendar-icon.svg';
import './KaliberSingleDatePicker.scss';

interface KaliberSingleDatePickerProps {
  date: Moment | null;
  id: string;
  label: string;
  onChange: (value: string) => void;
}
export default function KaliberSingleDatePicker({
  date,
  id,
  label,
  onChange
}: KaliberSingleDatePickerProps): ReactElement {
  const [focused, setFocused] = useState(false);

  return (
    <div>
      <div className="date-picker__label-container">
        <label className="date-picker__label">{label}</label>
      </div>
      <SingleDatePicker
        date={date}
        placeholder=""
        onDateChange={date => {
          onChange(date?.format('YYYY-MM-DD') ?? '');
        }}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={id}
        isOutsideRange={() => {
          return false;
        }}
        showDefaultInputIcon
        inputIconPosition="after"
        numberOfMonths={1}
        customInputIcon={<CalendarIcon />}
      />
    </div>
  );
}
