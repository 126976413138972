import { ReactElement, useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import KLAlert from '../../components/alert/KLAlert';
import KaliberButton from '../../components/button/KaliberButton';
import { resendReportToPatient } from '../../redux/actions/surgeriesActions';
import { KLReview, KLState, KLUser } from '../../redux/types';
import PatientReportComplete from './patientReportComplete/PatientReportComplete';
import { triggerCustomGAEvent } from '../../utils/googleAnalytics';
import { isDefinedAndNotNull } from '../../utils/validations';

type ReportIncompleteProps = {
  review: KLReview;
  rawImagePDFUrl: string;
  onOpenPDF: (rawImagePDFUrl: string) => void;
};

function ReportIncomplete({
  review,
  rawImagePDFUrl,
  onOpenPDF
}: ReportIncompleteProps): ReactElement {
  return (
    <div className="surgery-info">
      <h5 className="surgery-doctor" id="fs-mask">
        Performed by {review.surgeryInfo.surgeonName}
      </h5>
      <h5 className="surgery-date" id="fs-mask">
        {moment(review.surgeryInfo.surgeryDate).format('MM/DD/YYYY')}
      </h5>

      {review.surgeryInfo.surgeryStatus !== 'Not Started' &&
        (isDefinedAndNotNull(rawImagePDFUrl) && rawImagePDFUrl.length > 0 ? (
          <div className="report-button-container">
            <KaliberButton
              type="button"
              buttonClass="btn--primary"
              onClick={() => onOpenPDF(rawImagePDFUrl)}>
              Raw image PDF
            </KaliberButton>
          </div>
        ) : null)}
    </div>
  );
}

type ReportContentProps = {
  review: KLReview;
  rawImagePDFUrl: string;
  patientReportPDFUrl: string;
  onOpenPDF: (rawImagePDFUrl: string) => void;
  onResendReport: () => void;
};

function ReportContent({
  review,
  rawImagePDFUrl,
  patientReportPDFUrl,
  onOpenPDF,
  onResendReport
}: ReportContentProps): ReactElement {
  if (
    review.surgeryInfo.optedIn &&
    review.surgeryInfo.surgeryStatus === 'Sent to Patient'
  ) {
    return (
      <PatientReportComplete
        review={review}
        rawImagePDF={rawImagePDFUrl}
        patientReportPDF={patientReportPDFUrl}
        onResendReport={onResendReport}
      />
    );
  } else {
    return (
      <ReportIncomplete
        review={review}
        rawImagePDFUrl={rawImagePDFUrl}
        onOpenPDF={onOpenPDF}
      />
    );
  }
}

type PatientReportSurgeonParams = {
  review: KLReview;
  loading?: boolean;
};

export default function PatientReportSurgeon({
  review,
  loading
}: PatientReportSurgeonParams): ReactElement {
  const [user]: [KLUser] = useSelector((state: KLState) => [state.user]);

  const [rawImagePDF, setRawImagePDF] = useState('');
  const [patientReportPDF, setPatientReportPDF] = useState('');
  const [reportSent, setReportSent] = useState(false);
  const [reportSentMessage, setReportSentMessage] = useState('');

  useEffect(() => {
    const userUid = user.uid;
    triggerCustomGAEvent(
      'patient_report_view',
      `role: ${user.role}(uid: ${userUid})`,
      `surgeryId: ${review.surgeryInfo.surgeryId}`
    );
  }, []);

  useEffect(() => {
    for (const annotation of review.annotatedData) {
      if (
        annotation.mediaType === 'pdf' &&
        annotation.filename ===
          `${review.surgeryInfo.surgeryDate}_${review.surgeryInfo.patientLastName}_${review.surgeryInfo.patientFirstName}_raw.pdf`
      ) {
        if (annotation.mediaUrl) {
          setRawImagePDF(annotation.mediaUrl);
        }
      }

      if (
        annotation.mediaType === 'pdf' &&
        annotation.filename ===
          `${review.surgeryInfo.surgeryDate}_${review.surgeryInfo.patientLastName}_${review.surgeryInfo.patientFirstName}_annotated.pdf`
      ) {
        if (annotation.mediaUrl) {
          setPatientReportPDF(annotation.mediaUrl);
        }
      }
    }
  }, [review]);

  // Disabling this due to buggy behavior -mstallmo 04/08/22
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleResend = async () => {
    if (user.accessToken) {
      const message = await resendReportToPatient(
        user.accessToken,
        review.surgeryInfo.surgeryId
      );

      if (message === 'ok') {
        setReportSentMessage('The report has been resent to the patient.');
      } else {
        setReportSentMessage(message);
      }

      setReportSent(true);
    }
  };

  const openRawImagePDF = () => {
    window.open(rawImagePDF, '_blank');

    const userUid = user.uid;
    triggerCustomGAEvent(
      'raw_image_pdf_click',
      `role: ${user.role}(uid: ${userUid})`,
      `surgeryId: ${review.surgeryInfo.surgeryId}`
    );
  };

  return (
    <>
      {loading ? (
        <div className="quick-switch__container">
          <div className="surgery-page__skeleton surgery-page__skeleton--content" />
        </div>
      ) : (
        <div
          data-testid={
            user.role === 'Patient'
              ? 'patient-report-patient'
              : 'patient-report-surgeon'
          }
          className="patient-report">
          <div className="patient-report-container">
            <KLAlert visible={reportSent} label={reportSentMessage} />
            <ReportContent
              review={review}
              rawImagePDFUrl={rawImagePDF}
              patientReportPDFUrl={patientReportPDF}
              onOpenPDF={openRawImagePDF}
              onResendReport={handleResend}
            />
          </div>
        </div>
      )}
    </>
  );
}
