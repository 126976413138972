/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useMemo, useEffect } from 'react';
import { Column, useFilters, useSortBy, useTable } from 'react-table';

import { KLSurgeon, Staff } from '../../../../redux/types';
import {
  capitalizeEveryWord,
  formatPhoneNumber,
  sentenceCase
} from '../../../../utils/textFormat';
import { isNullOrUndefined, isEmpty } from '../../../../utils/validations';
import { Filter } from '../../StaffTable';

interface StaffTableData {
  name: string;
  role: string;
  phone: string;
  npi?: string;
  email: string;
  facilities: string[];
}

function staffColumns(): Column<any>[] {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value }) => {
        return capitalizeEveryWord(value);
      }
    },

    {
      Header: 'Role',
      accessor: 'role',
      Cell: ({ value }) => {
        return sentenceCase(value);
      }
    },
    {
      Header: 'Phone',
      accessor: 'phone',
      Cell: ({ value }) => {
        return formatPhoneNumber(value);
      }
    },
    {
      Header: 'NPI',
      accessor: 'npi',
      Cell: ({ value }) => {
        return isNullOrUndefined(value) || isEmpty(value)
          ? 'Not available'
          : value;
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => {
        return value.toLowerCase();
      }
    },
    {
      Header: 'Facility',
      accessor: 'facilities',
      Cell: ({ value }) => value.join(', ')
    }
  ];
}

function staffToTableData(staff: Staff[]): StaffTableData[] {
  return staff.map(staffMember => {
    return {
      name: `${staffMember.staffFirstName} ${staffMember.staffLastName}`,
      role: staffMember.role,
      phone: staffMember.staffPhone,
      email: staffMember.staffEmail,
      npi: undefined,
      facilities: (staffMember.facilities || []).map(
        facility => facility.facilityName
      )
    };
  });
}

function surgeonsToTableData(surgeons: KLSurgeon[]): StaffTableData[] {
  return surgeons.map(surgeon => {
    return {
      name: surgeon.surgeonName,
      role: 'Surgeon',
      phone: surgeon.surgeonPhone,
      email: surgeon.surgeonEmail,
      npi: surgeon.surgeonNpi,
      facilities: surgeon.facilities.map(facility => facility.facilityName)
    };
  });
}

interface StaffTableDataComponentProps {
  staff: Staff[];
  surgeons: KLSurgeon[];
  filters: Filter[];
}

export default function StaffTableDataComponent({
  staff,
  surgeons,
  filters
}: StaffTableDataComponentProps): ReactElement {
  const columns = useMemo(() => staffColumns(), []);
  const data = useMemo(() => {
    return [...staffToTableData(staff), ...surgeonsToTableData(surgeons)];
  }, [staff, surgeons]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter
  } = useTable({ columns, data }, useFilters, useSortBy);

  useEffect(() => {
    filters.map(filter => setFilter(filter.column, filter.value));
  }, [filters]);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key, ...restColumn } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );
                  return (
                    <th
                      key={key}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? 'sort-desc'
                            : 'sort-asc'
                          : 'no-sort'
                      }
                      {...restColumn}>
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <td
                      id="fs-mask"
                      className={
                        cell.column.Header === 'Report Status'
                          ? `${cell.value.toLowerCase().split(' ').join('-')}`
                          : ''
                      }
                      key={key}
                      {...restCellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length === 0 && (
        <div className="Empty-text">No staff matching this criteria</div>
      )}
    </>
  );
}
