import React, { ReactElement } from 'react';
import './StyleGuide.scss';

export default function SGComponents(): ReactElement {
  return (
    <div className="scss-colors">
      <div className="sg-subtitle">Rekap Buttons</div> Update with new buttons
      {/* <KLButton size="medium" variant="contained" onClick={handleCloseModal}>
        Medium Button
      </KLButton>
      <code className="html-examples">
        <span>
          &lt;KLButton size=&quot;medium&quot; variant=&quot;contained&quot;&gt;
        </span>
        Medium Button
        <span>&lt;/KLButton&gt;</span>
      </code>
      <KLButton size="large" variant="contained" onClick={handleCloseModal}>
        Large Button
      </KLButton>
      <code className="html-examples">
        <span>
          &lt;KLButton size=&quot;large&quot; variant=&quot;contained&quot;
          onClick=&rcub;handleCloseModal&lcub;&gt;
        </span>
        Large Button
        <span>&lt;/KLButton&gt;</span>
      </code> */}
    </div>
  );
}

export function handleCloseModal(): void {
  // eslint-disable-next-line no-console
  console.log('hello');
}
