import { Container } from '@mui/material';
import React, { ReactElement } from 'react';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import './TermsOfUsePage.scss';

export default function TermsOfUsePage(): ReactElement {
  setFullstoryPage('Terms Of Use Page');
  return (
    <Container maxWidth="xl">
      <div className="page-container container-terms">
        <h3>Kaliber Labs Terms of Use</h3>
        <p>
          <b>KALIBER LABS TERMS OF USE</b>
        </p>
        <p>
          The websites located at kaliber.ai and rekap.kaliber.ai (the “Site”)
          belong to Kaliber Labs Inc. (“Kaliber”, “we”, or “us”). As provided
          below, Kaliber grants you the right to use the Site, our software
          applications, including without limitation, Rekap (collectively
          “Software”), and services provided through the Site or Software
          (together, the “Kaliber Service”), subject to the terms and conditions
          (“Terms of Use” or “Terms”) set forth below. The term “you” refers to
          the person visiting the Site. Users may include visitors to Site,
          patients and healthcare providers.
        </p>
        <p>
          PLEASE READ THESE TERMS OF USE CAREFULLY. BY CLICKING “ACCEPT” OR
          ACCESSING THE KALIBER SERVICE, YOU AGREE TO BE BOUND BY THE TERMS OF
          USE. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS OF USE,YOU MAY NOT
          ACCESS OR USE THE KALIBER SERVICE. BY ACCEPTING THESE TERMS OR BY
          USING THE KALIBER SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ,
          UNDERSTAND, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS AND
          CONDITIONS, INCLUDING <a href="/privacy-policy">THE PRIVACY POLICY</a>{' '}
          (TOGETHER THE “TERMS”). If you are not eligible, or do not agree to
          the Terms of Use, then you do not have permission to use the Kaliber
          Service.
        </p>
        <p>
          THE KALIBER SERVICE IS AVAILABLE FOR REKAP USERS IN THE UNITED STATES.
          HEALTHCARE PROVIDER USERS OF THE KALIBER SERVICE ARE RESPONSIBLE FOR
          THE DATA RECORDED AND STORED BY THE KALIBER SERVICE. PATIENT USERS ARE
          RESPONSIBLE FOR THE DATA THEY UPLOAD THAT IS RECORDED AND STORED BY
          THE KALIBER SERVICE. THE REKAP SERVICE IS NOT INTENDED TO DIAGNOSE ANY
          HEALTH CONDITION OR AUTOMATICALLY ALERT HEALTHCARE PROFESSIONALS OR
          PATIENTS TO POTENTIALLY SERIOUS HEALTH CONDITIONS. THE KALIBER SERVICE
          IS NOT INTENDED FOR CONTINUOUS MONITORING AND WE DO NOT GUARANTEE A
          RESPONSE BY ANY PHYSICIANS TO MESSAGES POSTED OR MEDICAL EVENTS
          REPORTED THROUGH THE KALIBER SERVICE. ALTHOUGH KALIBER SERVICE MAY
          ENABLE YOU TO CONNECT YOUR ACCOUNT TO YOUR HEALTHCARE PROVIDER THROUGH
          OUR REKAP SERVICE, KALIBER DOES NOT AUTOMATICALLY REVIEW, MONITOR,
          EVALUATE, OR ANALYZE ANY INFORMATION GENERATED FROM THE REKAP SERVICE.
          IT IS YOUR RESPONSIBILITY TO PRESENT YOUR MEDICAL DATA TO YOUR
          PHYSICIAN FOR PROPER ANALYSIS AND DIAGNOSIS.
        </p>
        <p>
          Unless you are a consumer located in a jurisdiction that prohibits the
          exclusive use of arbitration for dispute resolution, these Terms
          provide that all disputes between you and Kaliber will be resolved by
          BINDING ARBITRATION. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT to
          assert or defend your rights under this contract, except for matters
          that may be taken to small claims court. Your rights will be
          determined by a NEUTRAL ARBITRATOR and NOT a judge or jury, and your
          claims cannot be brought as a class action. Please review Section 21
          (“Dispute Resolution and Arbitration”) for the details regarding your
          agreement to arbitrate any disputes with Kaliber.
        </p>
        <p>
          {' '}
          <b>1. Use of the Kaliber Service.</b> The Kaliber Service is intended
          only to allow the user (the patient or healthcare provider) to upload,
          view, share data with other healthcare professionals or patients, and
          use certain data as made available by the Kaliber Service. You may not
          access or use the Service for any other purpose. You may use the
          Kaliber Service, including any data presented to you on or by the
          Kaliber Service, or otherwise hosted or stored by Kaliber for you,
          only for lawful and appropriate purposes on your own behalf, and
          subject to your full compliance with these Terms and any other
          guidelines and policies applicable to the Kaliber Service which
          Kaliber may post from time to time.
        </p>
        <p>
          <b>2. Information Tool Only.</b> The Software provides an information
          management tool only. The Software does not have the ability to
          diagnose disease, prescribe treatment, or perform any other tasks that
          constitute the practice of medicine or any other professional service.
          All practice management and patient care decisions made using the
          Software, and the consequences of such decisions, are the exclusive
          responsibility of the health care professionals who use the Software.
          Use of the Software and the data generated by the Software must never
          be a substitute for the health care professional’s personal knowledge
          of a patient, the patient’s medical history, and good clinical
          judgment
        </p>
        <p>
          <b>3. Eligibility.</b> You must be at least 16 years of age to use the
          Kaliber Service without parental or guardian consent. However, if you
          are under 18 years of age, and need parental or guardian consent for
          surgery, you will also need parental or guardian consent to use the
          Kaliber Services. By agreeing to these Terms, you represent and
          warrant to us that: (a) you are at least 16 years of age, (b) you have
          not previously been suspended or removed from the Kaliber Service, and
          (c) your registration and your use of the Kaliber Service is in
          compliance with all applicable laws and regulation in your local
          jurisdiction. If you are using the Kaliber Service on behalf of an
          entity, organization, or company, you represent and warrant that you
          have the authority to bind that organization to these Terms and you
          agree to be bound by these Terms on behalf of that organization.
        </p>
        <p>
          <b>4. Accounts and Registration.</b> To access Rekap, patient users
          must receive an invitation from a healthcare provider. Healthcare
          providers must register for an account to use Kaliber Services. If you
          are a patient that registers for an account, you may be required to
          provide us with some information about yourself including personal
          information such as your name, email address, gender, and birthdate.
          If you are a healthcare provider, you may be required to provide some
          information about yourself, such as your name, email address,
          telephone number, and location. You may choose to provide additional
          information to us. You agree that the information you provide to us is
          accurate and that you will keep it accurate and up-to-date at all
          times. When you register, you will be asked to provide a password. You
          are solely responsible for maintaining the confidentiality of your
          account and password, and you accept responsibility for all activities
          that occur under your account. If you have reason to believe that your
          account is no longer secure, then you must immediately notify us at
          support@kaliberlabs.com. Patient user accounts may automatically
          expire following any period of inactivity associated with your account
          in excess of twelve (12) consecutive months. Healthcare provider
          accounts may automatically expire following any period of inactivity
          associated with your account in excess of twelve (12) consecutive
          months. As described in our{' '}
          <a href="/privacy-policy">Privacy Policy</a>, Protected Health
          Information (PHI) will be retained for at least five years for HIPAA
          compliance purposes.
        </p>
        <p>
          <b>5. Payment.</b> Access to the Kaliber Service, or to certain
          features of the Kaliber Service, may require users to pay fees. These
          fees will be charged separately to healthcare providers under
          licensing or service agreements for the use of the Kaliber software.
          Users are not charged fees when they register for an account. Kaliber
          does not collect or store financial account information as defined in
          the <a href="/privacy-policy">Privacy Policy</a>.
        </p>
        <p>
          <b>6. License.</b> Kaliber owns and operates the Kaliber Service. The
          documents and other information and content available on the Kaliber
          Service (the “Site Content”) are protected by copyright and other
          intellectual property laws throughout the world. All copyright and
          other proprietary notices on any Site Content must be retained on any
          copies made thereof. Any unauthorized reproduction, modification,
          distribution, public display or public performance of any Site Content
          is strictly prohibited. Kaliber and its suppliers reserve all rights
          not granted in these Terms.
        </p>
        <p>
          Subject to the restrictions set forth in these Terms, Kaliber grants
          you a limited, non-exclusive, non-transferable, non-sublicensable,
          revocable license to use Rekap on devices that you own or control,
          solely for use with the Kaliber Services.
        </p>
        <div>
          <b>7. User Representations and Warranties.</b>
          <ol>
            <li>
              You represent, warrant, and covenant to Kaliber that you are a
              resident of the USA.
            </li>
            <li>
              You represent, warrant and covenant to Kaliber that (1) these
              Terms have been executed and delivered by you and constitute a
              valid and binding agreement with you, enforceable against you in
              accordance with their terms; (2) if you are using the Kaliber
              Service on behalf of another entity, you are an authorized
              representative of the entity and have the authority and agree to
              bind the entity to these Terms; (3) you will not access or use the
              Kaliber Service except as expressly permitted by these Terms and
              any additional instructions, guidelines, or policies issued by
              Kaliber, including those posted in the Kaliber Service; (4) you
              will access and use the Kaliber Service in full compliance with
              applicable law; and (5) all of the information, data and other
              materials provided by you in support of your account registration
              are accurate and truthful in all respects.
            </li>
          </ol>
        </div>
        <div>
          <b>8. User Content.</b>
          <ol>
            <li>
              <b>User Content Generally.</b> Certain features of Kaliber Service
              may permit you, your healthcare provider, or other users to upload
              content to the Kaliber Service, including messages, images, data,
              text, location information and other types of information (“User
              Content”) and to publish User Content on the Kaliber Service. You
              retain the copyrights, including any moral rights, and any other
              proprietary rights that you may hold in the User Content that you
              post to the Kaliber Service; provided that if you choose to link
              your account to our Rekap service or information systems offered
              by your healthcare provider, any data provided to your healthcare
              provider may become part of your health record, and that copy of
              such data may be owned and/or controlled by your healthcare
              provider to the extent provided under applicable law.
            </li>
            <li>
              <b>Limited License Grant to Kaliber.</b> By posting or publishing
              User Content, you grant Kaliber a perpetual, irrevocable,
              worldwide, nonexclusive, royalty-free, fully paid, transferable
              right and license (with the right to sublicense) to use, host,
              store, transfer, display, perform, reproduce, modify, create
              derivative works of, and distribute your User Content, in whole or
              in part, for any purpose in accordance with the{' '}
              <a href="/privacy-policy">Privacy Policy</a>, in any media formats
              and through any media channels now known or hereafter developed.
              We may also create anonymized data and images from your User
              Content, and such data and images will no longer be your User
              Content. You irrevocably and forever waive any rights you may have
              regarding your User Content being altered or manipulated in any
              way that may be objectionable to you. Kaliber reserves the right
              to refuse to accept, post, display or transmit any of your User
              Content in its sole discretion.
            </li>
            <li>
              <b>Limited License Grant to Other Users.</b> By posting or sharing
              User Content with other users of the Kaliber Service, or
              connecting your account to your healthcare provider through our
              Rekap service, you grant those users and/or healthcare providers a
              non-exclusive license to access and use that User Content as
              permitted by these Terms and the functionality of the Kaliber
              Service.
            </li>
            <li>
              <b>User Content Representations and Warranties.</b> You are solely
              responsible for your User Content and the consequences of posting
              or publishing User Content. By posting or publishing User Content,
              you affirm, represent, and warrant that:
              <ol>
                <li>
                  You are the creator and owner of the User Content, or have the
                  necessary licenses, rights, consents, and permissions to
                  authorize Kaliber and users of the Kaliber Service to use and
                  distribute your User Content as necessary to exercise the
                  licenses granted by you in this section, in the manner
                  contemplated by Kaliber, the Kaliber Service, and these Terms;
                  and
                </li>
                <li>
                  Your User Content, and the use of your User Content is
                  contemplated by these Terms, does not and will not (i)
                  infringe, violate or misappropriate any third-party right,
                  including any copyright, trademark, patent, trade secret,
                  moral right, privacy right, right of publicity, or any other
                  intellectual property or proprietary right, (ii) slader,
                  defame, libel or invade the right of privacy, publicity or
                  other property rights of any other person, or (iii) cause
                  Kaliber to violate any law or regulation. You agree to pay for
                  any and all royalties, fees, or other monies owing any person
                  by reason of User Content you post on or through Kaliber
                  Service.
                  <p>
                    <b>I. User Content Disclaimer.</b> We are under no
                    obligation to edit or control User Content that you or other
                    users post or publish, and will not be in any way
                    responsible or liable for User Content. Kaliber may,
                    however, at any time and without prior notice, screen
                    remove, edit, or block any User Content that in our sole
                    judgment violates these Terms or is otherwise objectionable.
                    You understand that when using the Kaliber Service you will
                    be exposed to User Content from a variety of sources and
                    acknowledge that User Content may be inaccurate, offensive,
                    indecent, or objectionable. To the fullest extent allowed
                    under applicable law, you agree to waive, and do waive, any
                    legal or equitable right or remedy you have or may have
                    against Kaliber with respect to User Content. We expressly
                    disclaim any and all liability in connection with User
                    Content, including all Rekap content (the Rekap report,
                    videos, photos and other content), to the fullest extent
                    permitted under applicable law. If notified by a user or
                    content owner that User Content allegedly does not conform
                    to these Terms, we may investigate the allegation and
                    determine in our sole discretion whether to remove the User
                    Content, which we reserve the right to do at any time and
                    without notice.
                  </p>
                  <p>
                    <b>II. Procedure for Unlawful User Content.</b> If you
                    believe that any User Content does not conform to these
                    Terms, please notify us.
                  </p>
                  <br />
                  <p>
                    We comply with the provisions of the Digital Millennium
                    Copyright Act (the “DMCA”) applicable to our operations (17
                    U.S.C. 512, as amended). If you have an intellectual
                    property rights related complaint about material posted on
                    the Kaliber Service, you may contact our designated agent at
                    the following address:
                  </p>
                  <br />
                  <address>
                    Kaliber Labs <br />
                    ATTN: Legal (Copyright Notification) <br />
                    188 King Street <br />
                    San Francisco, CA <br />
                    Email: legal@kaliberlabs.com
                  </address>
                  <br />
                  <p>
                    Please note that under applicable law, if you knowingly give
                    false, misleading or inaccurate information that User
                    Content is infringing, you may be subject to civil or
                    criminal penalty.
                  </p>
                  <br />
                  <p>
                    Any notice under the Digital Millennium Copyright Act (the
                    “DMCA”) alleging that materials hosted by or distributed
                    through the Kaliber Service infringe intellectual property
                    rights must include all of the information required by the
                    DMCA for such notices.
                  </p>
                  <br />
                  <p>
                    Repeat infringers: Kaliber may promptly terminate without
                    notice the accounts of users that are determined by Kaliber
                    to be “Repeat Infringers.” A Repeat Infringer is a user who
                    has been notified of infringing activity or has had User
                    Content removed from the Kaliber Service at least three
                    times.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div>
          <b>9. Prohibited Conduct.</b> BY USING THE KALIBER SERVICE YOU AGREE
          NOT TO:
          <ol>
            <li>
              Use or access the Kaliber Service (a) from a jurisdiction where
              such use or access is ot authorized, (b) for any illegal purposes,
              or (c) in violation of any local, state, national, or
              international law;
            </li>
            <li>
              Conduct activities that may be harmful to others or that could
              damage Kaliber’s reputation;
            </li>
            <li>
              Violate or encourage others to violate, any right of a third
              party, including by infringing or misappropriating any third party
              intellectual property right, or disclosing personal information
              about another person;
            </li>
            <li>
              Post, upload, or distribute marketing or advertising links or
              content, or any User content or other content that is unlawful,
              defamatory, libelous, inaccurate, or that a reasonable person
              could deem to be objectionable, profane, indecent, pornographic,
              harassing threatening, embarrassing, hateful, or otherwise
              inappropriate;
            </li>
            <li>
              Use scrapers, robots, or other data gathering devices on or
              through the Kaliber Service, or frame or otherwise provide the
              Kaliber Service to third parties without Kaliber’s permission;
            </li>
            <li>
              Interfere with security-related features of the Kaliber Service,
              including by (a) disabling or circumventing features that prevent
              or limit use or copying of any content; or (b) reverse
              engineering, decompiling, or otherwise attempting to discover the
              source code of any portion of the Service, including the app(s),
              except to the extent that such activity is expressly permitted by
              applicable law notwithstanding this restriction;
            </li>
            <li>
              Interfere with the operation of the Kaliber Service or any user’s
              enjoyment of the Kaliber Service, including by: (a) uploading or
              otherwise disseminating any virus, adware, spyware, worm, or other
              malicious code; (b) making any unsolicited offer or advertisement
              to another user of the Kaliber Service; (c) attempting to collect
              personal information, including without limitation health
              information, about another user or third party without their
              consent; or (d) interfering with or disrupting any network,
              equipment, or server connected to or used to provide the Kaliber
              Service, or violating any regulation, policy, or procedure of any
              such network, equipment, or server;
            </li>
            <li>
              Perform any fraudulent activity including impersonating any person
              or entity, claiming a false affiliation, accessing any other
              Kaliber Service or account without permission, or falsifying your
              account registration information;
            </li>
            <li>
              Modify, translate, or create derivative works, adaptations or
              compilations of, or based on, the Kaliber Service, or part
              thereof, or use, copy, or reproduce the Kaliber Service or any
              part thereof other than as expressly permitted in these Terms;
            </li>
            <li>
              Assign, sublicense, lease, sell, grant a security interest in, or
              otherwise transfer the access granted under these Terms or any
              Materials (as defined in section 12) or any right or ability to
              view, access, or use any Material; or
            </li>
            <li>
              Attempt to do any of the acts described in this Section 10, or
              assist or permit any person in engaging in any of the acts
              described in this Section 10.
            </li>
          </ol>
        </div>
        <p>
          <b>
            10. Termination of Use; Discontinuation and Modification of the
            Service.
          </b>{' '}
          You may terminate your account at any time by following the procedures
          detailed on the Kaliber website or contacting customer service at
          support@kaliberlabs.com. If you violate any provision of these Terms,
          your permission from us to use the Kaliber Service will terminate
          automatically. In addition, Kaliber may in its sole discretion
          terminate your user account on the Kaliber Service or suspend or
          terminate your access to the Kaliber Service at any time if you
          violate any provision of these Terms, if we no longer provide any part
          of the Kaliber Services or for any other reason, with or without
          notice. We also reserve the right to modify or discontinue the Kaliber
          Service at any time (including by limiting or discontinuing certain
          features of the Kaliber Service) temporarily or permanently, without
          notice to you. To the fullest extent permitted under applicable law,
          we will have no liability on account of any change to the Kaliber
          Service or any suspension or termination of your access to or use of
          the Kaliber Service, provided that if Kaliber ceases to operate the
          Kaliber Service and terminates your access to the Kaliber Service
          accordingly, then you will be entitled to a pro-rated refund of any
          prepaid fees that you have paid to Kaliber for use of the Kaliber
          Service. Upon the termination of your account or this agreement for
          any reason, Kaliber may at its option delete any data associated with
          your account.
        </p>
        <div>
          <b>11. Privacy; Additional Terms</b>
          <ol>
            <li>
              Please read our{' '}
              <a href="/privacy-policy">Privacy Policy (“Privacy Policy”)</a>{' '}
              carefully for information relating to our collection, use, storage
              and disclosure of your personal information and for our cookie
              policy. The Privacy Policy is incorporated by this reference into,
              and made a part of these terms. You consent to the collection,
              hosting, use, disclosure and other processing or handling of your
              personal information (including sharing data with third-party
              providers) as described in the Privacy Policy.
            </li>
            <li>
              <b>Additional Terms.</b> Your use of the Kaliber Service is
              subject to all additional terms, policies, rules or guidelines
              applicable to the Kaliber Service (the “Additional Terms”), such
              as end-user license agreements for any downloadable software
              applications, or rules that are applicable to a particular feature
              or content on the Kaliber Service, subject to Section 11. All
              Additional Terms are incorporated by this reference into, and made
              a part of, these Terms.
            </li>
            <li>
              <b>HIPAA.</b> Kaliber acknowledges that, in the course of
              providing the Software, Kaliber may receive or otherwise have
              access to certain information that is required to be kept
              confidential in accordance with the Privacy and Security
              Regulations promulgated by the United States Department of Health
              and Human Services at 45 CFR Parts 160, 162 and 164 that were
              issued pursuant to the Health Insurance Portability and
              Accountability Act of 1996, as amended, and the rules and
              regulations promulgated thereunder (“HIPAA”) and pursuant to the
              provisions of the Health Information Technology for Economic and
              Clinical Health Act of 2009, as amended, and the rules and
              regulations promulgated thereunder (“HITECH” and, together with
              HIPAA and the HIPAA Omnibus Final Rule, the “Regulations”).
              Kaliber shall maintain the privacy, security, and confidentiality
              of all such information in accordance with the Regulations. Upon
              request by a HIPAA covered entity, the parties will negotiate in
              good faith and enter a Business Associate Agreement in compliance
              with the Regulations. To the extent of any conflict between these
              Terms of Use and the Regulations, the Regulations shall govern.
            </li>
            <li>
              <b>De-Identified Data.</b> Kaliber may de-identify and aggregate
              data, including Protected Health Information (as such term is
              defined by HIPAA), and any other data provided in connection with
              this Agreement for any lawful purpose, including, but not limited
              to, for purposes of providing or enhancing product and services
              offerings to its customers, for analytical, statistical or
              benchmarking purposes and for purposes of extracting, obtaining
              and providing information related to the use of the Software,
              provided that such data is compiled and presented in a manner that
              does not permit identification of any individual and has been
              de-identified in accordance with Applicable law (collectively,
              “De-Identified Data”). All right, title and ownership of
              De-Identified Data (including all derivative works thereof) is and
              shall remain solely and exclusively vested in Kaliber Labs Inc.,
              including all proprietary rights relating thereto.
            </li>
          </ol>
        </div>
        <p>
          <b>12. Modifications to these Terms.</b> We reserve the right, at our
          discretion, to change these Terms on an ongoing basis at any time.
          Please check these Terms periodically for changes. If a change to
          these Terms materially modifies your rights or obligations (“Material
          Modifications”), we will notify you of the modified Terms by email to
          the address you provided in your user profile. Material Modifications
          will be effective upon your acceptance of such modified Terms.
          Immaterial modifications are effective upon publication. Disputes
          arising under these Terms will be resolved in accordance with the
          version of these Terms that was in effect at the time the dispute
          arose. Your sole and exclusive remedy if you do not agree with any
          modifications to these Terms is to cancel your account. You may not
          amend or modify these Terms under any circumstances.
        </p>
        <p>
          <b>13. Ownership; Proprietary Rights.</b> The Kaliber Service is owned
          and operated by Kaliber. The visual interfaces, graphics, design,
          compilation, information, data, computer code (including source code
          or object code), products, software, services, and all other elements
          of the Kaliber Service (“Materials”) provided by Kaliber are protected
          by intellectual property and other laws. All Materials included in the
          Kaliber Service are the property of Kaliber or our third party
          licensors. Except as expressly authorized by Kaliber, you may not make
          use of the Materials. Kaliber reserves all rights to the Materials not
          granted expressly in these Terms.
        </p>
        <p>
          <b>14. Subcontractors.</b> You hereby consent to Kaliber’s engagement
          of third parties (including Kaliber’s affiliates) to perform, or
          support the performance of, all or any portion of the Kaliber Service
          or the Kaliber website.
        </p>
        <p>
          <b>15. Feedback.</b> If you choose to provide input and suggestions
          regarding problems with or proposed modifications or improvements to
          the Kaliber Service (“Feedback”), then you hereby grant Kaliber an
          unrestricted, perpetual, irrevocable, non-exclusive, fully paid,
          royalty-free right to exploit the Feedback in any manner and for any
          purpose, including to improve the Kaliber Service and to create other
          products and services.
        </p>
        <p>
          <b>16. Indemnity.</b> To the fullest extent permitted under applicable
          law, you are responsible for your use of the Kaliber Service, and you
          will indemnify, hold harmless, and, if so directed by Kaliber, defend
          Kaliber and its officers, directors, employees, consultants,
          affiliates, subsidiaries and agents (together, the “Kaliber Entities”)
          from and against every claim, liability, damage, loss, and expense,
          including reasonable attorneys’ fees and costs, arising out of or in
          any way connected with: (a) your access to, use of, or alleged use of,
          the Kaliber Service; (b) your violation of any portion of these Terms,
          any representation, warranty, or agreement reference in these Terms,
          or any applicable law or regulation; (c) your violation of any third
          party right, including any intellectual property right or publicity,
          confidentiality, other property, or privacy right; (d) any dispute or
          issue between you and any third party; and (e) all claims arising from
          or alleging fraud, intentional misconduct, criminal acts, or gross
          negligence committed by you. To the fullest extent permitted under
          applicable law, Kaliber reserves the right, at our own expense, to
          assume the exclusive defense and control of any matter otherwise
          subject to indemnification by you (without limiting your
          indemnification obligations with respect to the matter), and in that
          case, you agree to cooperate with our defense of that claim.
        </p>
        <p>
          <b>17. Disclaimers; No Warranties</b>
        </p>
        <p>
          THE KALIBER SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH
          THE KALIBER SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE”
          BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR
          IMPLIED. THE KALIBER ENTITIES DISCLAIM ALL WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, RELATING TO THE KALIBER SERVICE, ALL
          MATERIALS AND CONTENT AVAILABLE THROUGH THE KALIBER SERVICE, AND ANY
          SOFTWARE OR HARDWARE ASSOCIATED OR USED WITH THE KALIBER SERVICE, OR
          THE AVAILABILITY OF ANY OF THE FOREGOING, INCLUDING: (A) ANY IMPLIED
          WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
          QUIET ENJOYMENT, OR NON-INFRINGEMENT; (B) ANY WARRANTY ARISING OUT OF
          COURSE OF DEALING, USAGE, OR TRADE, AND (C) ANY WARRANTY AS TO WHETHER
          THE DATA OR OTHER INFORMATION AVAILABLE ON OR TRANSMITTED BY THE
          KALIBER SERVICE IS TRUE, COMPLETE, OR ACCURATE. YOU SPECIFICALLY
          ACKNOWLEDGE AND AGREE THAT KALIBER IS NOT RESPONSIBLE FOR ANY
          HEALTHCARE OR RELATED DECISIONS MADE BY YOU OR YOUR HEALTHCARE
          PROFESSIONAL BASED UPON DATA COLLECTED, TRANSMITTED OR DISPLAYED BY OR
          ON THE KALIBER SERVICE, WHETHER SUCH DATA IS ACCURATE OR INACCURATE,
          THE KALIBER ENTITIES DO NOT WARRANT THAT THE KALIBER SERVICE OR ANY
          PORTION OF THE KALIBER SERVICE, OR ANY MATERIALS OR CONTENT OFFERED
          THROUGH THE KALIBER SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF
          ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND DO NOT WARRANT THAT
          ANY OF THOSE ISSUES WILL BE CORRECTED.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM THE KALIBER SERVICE OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH
          THE KALIBER SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE
          KALIBER ENTITIES OR THE KALIBER SERVICE THAT IS NOT EXPRESSLY STATED
          IN THESE TERMS. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT
          FROM YOUR USE OF OR ACCESS TO THE KALIBER SERVICE, YOUR DEALING WITH
          ANY OTHER KALIBER SERVICE USER, AND ANY MATERIALS OR CONTENT AVAILABLE
          THROUGH THE KALIBER SERVICE. YOU UNDERSTAND AND AGREE THAT YOU USE THE
          KALIBER SERVICE, AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN
          MATERIALS OR CONTENT THROUGH THE KALIBER SERVICE AND ANY ASSOCIATED
          SITES OR SERVICES, AT YOUR OWN DISCRETION AND RISK, AND THAT YOU ARE
          SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
          COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE KALIBER
          SERVICE), OR THE LOSS OF DATA THAT RESULTS FROM THE USE OF THE KALIBER
          SERVICE OR THE DOWNLOAD OR USE OF THAT MATERIAL OR CONTENT.
        </p>
        <p>
          THE ABOVE PARAGRAPHS APPLY TO THE FULLEST EXTENT PERMITTED UNDER
          APPLICABLE LAW. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF
          WARRANTIES, IN PARTICULAR A DISCLAIMER OF WARRANTIES PROVIDED OR
          IMPLIED BY LAW, AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM
          JURISDICTION TO JURISDICTION.
        </p>
        <p>
          <b>18. Limitation of Liability.</b>
        </p>
        <p>
          IN NO EVENT WILL THE KALIBER ENTITIES BE LIABLE TO YOU FOR ANY DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
          (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER
          INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE
          OF, OR YOUR INABILITY TO ACCESS OR USE, THE KALIBER SERVICE OR ANY
          MATERIALS OR CONTENT ON THE KALIBER SERVICE, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER
          LEGAL THEORY, AND WHETHER OR NOT ANY KALIBER ENTITY HAS BEEN INFORMED
          OF THE POSSIBILITY OF DAMAGE. FOR THE AVOIDANCE OF DOUBT, THE EXCLUDED
          DAMAGES ALSO INCLUDE WITHOUT LIMITATION, LOSS OF SAVINGS OR REVENUE,
          LOSS OF PROFIT, LOSS OF USE, LOSS OF LIFE OR HEALTH, THE CLAIMS OF
          THIRD PARTIES, AND ANY COST OF ANY SUBSTITUTE EQUIPMENT OR SERVICES.
        </p>
        <p>
          EXCEPT AS PROVIDED IN SECTION 22(E), IF KALIBER CANNOT LAWFULLY
          DISCLAIM LIABILITY FOR ANY OF THE FOREGOING DAMAGES, THEN THE
          AGGREGATE LIABILITY OF THE KALIBER ENTITIES TO YOU FOR ALL CLAIMS
          ARISING OUT OF OR RELATING THE USE OF OR ANY INABILITY TO USE ANY
          PORTION OF THE KALIBER SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER
          IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF THE
          AMOUNTS YOU HAVE PAID TO USE THE KALIBER SERVICE OR $100, TO THE
          FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN WHICH CASE SUCH
          LIMITATIONS SHALL APPLY TO YOU TO THE EXTENT PERMITTED IN SUCH
          JURISDICTION. FURTHERMORE, NOTHING IN THESE TERMS LIMTS OR EXCLUDES
          ANY LIABILITY THAT CANNOT BE LIMITED OR EXCLUDED BY LAW, SUCH AS
          LIABILITY FOR INTENTIONAL VIOLATIONS OF THESE TERMS. NOTHING IN THESE
          TERMS AFFECTS YOUR LEGAL RIGHTS AS A CONSUMER.
        </p>
        <p>
          EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
          LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
          INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER
          THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF
          THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE
          AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE
          LIMITATIONS IN THIS SECTION 18 WILL APPLY EVEN IF ANY LIMITED REMEDY
          FAILS OF ITS ESSENTIAL PURPOSE.
        </p>
        <p>
          <b>19. Force Majeure.</b> Kaliber will be excused from performance
          under these Terms for any period that it is prevented from or delayed
          in performing any obligations pursuant to these Terms, in whole or in
          part, as a result of a Force Majeure event. To the fullest extent
          permitted under applicable law, for purposes of this Section, “Force
          Majeure Event” means an event or series of events caused by or
          resulting from any of the following: (1) weather conditions or other
          elements of nature or acts of God; (2) acts of war, acts of terrorism,
          insurrection, riots, civil disorders or rebellion; (3) quarantines or
          embargoes; (4) labor strikes; (5) telecommunications, network,
          computer, server, or internet downtime; (6) unauthorized access to
          Kaliber’s information technology systems by third parties; or (7)
          other causes beyond the reasonable control of Kaliber.
        </p>
        <p>
          <b>20. Governing Law and Competent Courts.</b> To the fullest extent
          permitted pursuant to applicable law, these Terms are governed by the
          laws of the State of California without regard to conflicts of law
          principles. If a lawsuit or court proceeding is permitted under these
          Terms, then you and Kaliber agree to submit to the personal and
          exclusive jurisdiction of the state courts and federal courts located
          within San Francisco County, California for the purpose of litigating
          any dispute. We operate the Kaliber Service from our offices in the
          United States, and we make no representation that Materials included
          in the Kaliber Service are appropriate or available for use in other
          locations.
        </p>
        <p>
          <b>21. General.</b> These Terms, together with the{' '}
          <a href="/privacy-policy">Privacy Policy</a> and any other agreements
          expressly incorporated by reference into these Terms, are the entire
          and exclusive understanding and agreement between you and Kaliber
          regarding your use of the Kaliber Service. Except as expressly
          permitted above, these Terms may be amended only by a written
          agreement signed by authorized representatives of all parties to these
          Terms. You may not assign or transfer these Terms or your rights under
          these Terms, in whole or in part, by operation of law or otherwise,
          without our prior written consent, which may be granted or withheld at
          Kaliber’s sole discretion. Any attempted assignment by you without
          such consent shall be null and void. We may assign these Terms at any
          time without notice or consent, to the fullest extent permitted by
          applicable law. The failure to require performance of any provision
          will not affect our right to require performance at any other time
          after that, nor will a waiver by us of any breach or default of these
          Terms, or any provision of these Terms, be a waiver of any subsequent
          breach or default or a waiver of the provision itself. Use of section
          headers in these Terms is for convenience only and will not have any
          impact on the interpretation of any provision. If any part of these
          Terms is held to be invalid or unenforceable, the unenforceable part
          will be given effect to the greatest extent possible, and the
          remaining parts will remain in full force and effect. Upon termination
          of these Terms, Sections 7-10, 12, 15-17, 19, and 21-23, along with
          the Privacy Policy and any other accompanying agreements, will
          survive.
        </p>
        <p>
          <b>22. Dispute Resolution and Arbitration</b>
        </p>
        <p>
          PLEASE READ THIS SECTION CAREFULLY BECAUSE IT WILL REQUIRE YOU TO
          ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH US AND LIMITS THE MANNER IN
          WHICH YOU MAY SEEK RELIEF.
        </p>
        <ol>
          <li>
            <b>Generally.</b> To the fullest extent permitted under applicable
            law and in the interest of resolving disputes between you and
            Kaliber in the most expedient and cost effective manner, you and
            Kaliber agree that every dispute arising in connection with these
            Terms will be resolved by binding arbitration, unless you are a
            consumer located in a jurisdiction that prohibits the exclusive use
            of arbitration for dispute resolution. Arbitration is less formal
            than a lawsuit in court. Arbitration uses a neutral arbitrator
            instead of a judge or jury, may allow for more limited discovery
            than in court, and can be subject to very limited review by courts.
            Arbitrators can award the same damages and relief that a court can
            award. This agreement to arbitrate disputes includes all claims
            arising out of or relating to any aspect of these Terms, whether
            based in contract, tort, statute, fraud, misrepresentation, or any
            other legal theory, and regardless of whether a claim arises during
            or after the termination of these Terms. YOU UNDERSTAND AND AGREE
            THAT, BY ENTERING INTO THESE TERMS, YOU AND KALIBER ARE EACH WAIVING
            THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, TO
            THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW
          </li>
          <li>
            <b>Opt-Out of Agreement to Arbitrate.</b> You can decline this
            agreement to arbitrate by contacting support@kaliberlabs.com within
            30 days of first accepting these Terms of Use and stating that you
            (include your first and last name, and email address used to
            register for the Kaliber Service) decline this arbitration
            agreement.
          </li>
          <li>
            <b>Exceptions.</b> Despite the provisions of Section 21(A), nothing
            in these Terms will be deemed to waive, preclude, or otherwise limit
            the right of either party to: (a) bring an individual action in
            small claims court; (b) pursue an enforcement action through the
            applicable federal, state or local agency, if that action is
            available; or (c) file suit in a court of law to address an
            intellectual property infringement claim.
          </li>
          <li>
            <b>Arbitrator.</b> To the fullest extent permitted under applicable
            law, any arbitration between you and Kaliber will be settled under
            the Federal Arbitration Act, and governed by the Commercial Dispute
            Resolution Procedures and the Supplementary Procedures for Consumer
            Related Disputes (collectively, “AAA Rules”) of the American
            Arbitration Association (“AAA”), as modified by these Terms, and
            will be administered by the AAA. The AAA Rules and filing forms are
            available online at www.adr.org, by calling the AAA at
            1-800-778-7879, or by contacting Kaliber.
          </li>
          <li>
            <b>Notice; Process.</b> A party who intends to seek arbitration must
            first send a written notice of the dispute to the other party by
            certified U.S. Mail or by Federal Express (signature required) or,
            only if such other party has not provided a current physical address
            then by electronic mail (“Notice”). Kailber’s address for Notice is:
            Kaliber Labs, 188 King Street, San Francisco, CA 94107. The Notice
            must: (a) describe the nature and basis of the claim or dispute; and
            (b) set forth the specific relief sought (“Demand”). The parties
            will make good faith efforts to resolve the claim directly, but if
            the parties do not reach an agreement to do so within 30 days after
            the Notice is received, you or Kaliber may commence an arbitration
            proceeding. During the arbitration, the amount of any settlement
            offer made by you or Kaliber must not be disclosed to the arbitrator
            until after the arbitrator makes a final decision and award, if any.
          </li>
          <li>
            <b>Fees.</b> If you commence arbitration in accordance with these
            Terms, Kaliber will reimburse you for your payment of the filing
            fee, unless your claim is for more than $10,000, in which case the
            payment of any fees will be decided by the AAA rules. Any
            arbitration hearing will take place at a location, to be agreed
            upon, in San Francisco County, California, but if the claim is for
            $10,000 or less, you may choose whether the arbitration is
            conducted: (a) solely on the basis of documents, submitted to the
            arbitrator; (b) through a non-appearance based telephone hearing; or
            (c) through an in-person hearing as established by the AAA Rules in
            the County of your billing address. If the arbitrator finds that
            either the substance of your claim or the relief sought in the
            Demand is frivolous or brought for an improper purpose (as measured
            by the standards set forth in the Federal Rule of Civil Procedure 11
            (b)), then the payment of all fees will be governed by the AAA
            Rules. In that case, you agree to reimburse Kaliber for all monies
            previously disbursed by it that are otherwise your obligation to pay
            under the AAA Rules. Regardless of the manner in which the
            arbitration is conducted, the arbitrator must issue a reasoned
            written decision sufficient to explain the essential findings and
            conclusions on which the decision and award, if any, are based. The
            arbitrator may make rulings and resolve disputes as to the payment
            and reimbursement of fees or expenses at any time during the
            proceeding and upon request from either party made within 14 days of
            the arbitrator’s ruling on the merits
          </li>
          <li>
            <b>No Class Actions.</b> TO THE FULLEST EXTENT PERMITTED UNDER
            APPLICABLE LAW, YOU AND KALIBER AGREE THAT EACH MAY BRING CLAIMS
            AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS
            A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
            PROCEEDING. Further, unless both you and Kaliber agree otherwise,
            the arbitrator may not consolidate more than one person’s claims,
            and may not otherwise preside over any form of a representative or
            class proceeding, the fullest extent permissible pursuant to
            applicable law.
          </li>
          <li>
            <b>Claims.</b> To the fullest extent permitted under applicable law,
            no action arising out of, in connection with, or relating to these
            Terms shall be brought by you more than one (1) year after the
            accrual of the cause of action. This period shall not be extended
            for any reason, except by the written consent of both parties. All
            statutes or provisions of law which would toll or otherwise affect
            the running of the period of limitation are hereby waived, and no
            such statute or provision of law shall operate to extend the period
            limited in this paragraph, to the fullest extent permitted under
            applicable law.
          </li>
          <li>
            <b>Modifications to this Arbitration Provision.</b> If Kaliber makes
            any future change to this arbitration provision, other than a change
            to Kaliber’s address for Notice, you may reject the change by
            sending us written notice within 30 days of the change to Kaliber’s
            address for Notice, in which case your account with Kaliber will be
            immediately terminated and this arbitration provision, as in effect
            immediately prior to the changes you rejected shall survive.
          </li>
          <li>
            <b>Enforceability.</b> If Section 21(G) is found to be unenforceable
            or if the entirety of this Section 21 is found to be unenforceable,
            then the entirety of this Section 21 will be null and void, and, in
            that case, the parties agree that the exclusive jurisdiction and
            venue described in Section 19 will govern any action arising out of
            or related to these Terms.
          </li>
        </ol>
        <p>
          <b>23. Notices: Consent to Electronic Communications.</b> By using the
          Kaliber Service, you consent to receiving certain electronic
          communications from us as further described in the{' '}
          <a href="/privacy-policy">Privacy Policy</a>. Please read the{' '}
          <a href="/privacy-policy">Privacy Policy</a> to learn more about our
          electronic communications practices. You agree that any notices,
          agreements, disclosures, or other communications that we send to you
          electronically will satisfy any legal communication requirements,
          including that those communications be in writing. All communications
          from Kaliber intended for receipt by you will be deemed delivered and
          effective when sent to the email address provided by you during the
          registration process or when posted to and made available to you on
          the Kaliber Service. If you change the email address provided in
          connection with your registration to access and use the Kaliber
          Service, you must update your address in accordance with the
          procedures set forth on the Kaliber Service. By providing your mobile
          number to us, you consent to receive text messages at that number as
          requested for account verification, message notifications, and other
          purposes related to the Kaliber Service. While we do not charge a fee
          for text messages, your carrier may charge standard messaging, data,
          and other fees. You are responsible for those charges. We may send and
          receive text messages through cellular telephone operators or other
          networks, and the level of reliability may vary. We are not
          responsible for the timeliness or final delivery of the message, as
          this is outside our control and is the responsibility of the cellular
          telephone operator or other networks. Notwithstanding the foregoing,
          we will use your mobile number in accordance with the{' '}
          <a href="/privacy-policy">Privacy Policy</a>.
        </p>
        <p>
          <b>Contact Information.</b> The Kaliber Service is offered by Kaliber
          Labs, Inc. You may contact us by emailing us at{' '}
          <a href="mailto:support@kaliberlabs.com">support@kaliberlabs.com</a>.
        </p>
        <p>Last revised [10/19/2023].</p>
      </div>
    </Container>
  );
}
