/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import KaliberLogo from '../../assets/logos/kaliber-rekap-logo-TM-dark.png';
import KaliberButton from '../../components/button/KaliberButton';
import KaliberMessagePage from '../../components/messagePage/KaliberMessagePage';
import KaliberModal from '../../components/modal/KaliberModal';
import KaliberPasswordField from '../../components/textField/KaliberPasswordField';
import { setFullstoryPage } from '../../redux/actions/fullstoryActions';
import { resendSignupNotifications } from '../../redux/actions/patientActions';
import { useQuery } from '../../utils/react';
import { isEmpty, isNullOrUndefined } from '../../utils/validations';
import { resetPassword } from '../../redux/actions/userActions';
import ErrorPage from '../errorPage/ErrorPage';
import './CredentialForms.scss';

export interface FormData {
  email: string;
  password: string;
  confirmPassword: string;
}
export default function ResetPassword(): ReactElement {
  const history = useHistory();
  useEffect(() => {
    setFullstoryPage('Reset Password Page');
  }, []);

  const [errorPage, setErrorPage] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState('');

  const query = useQuery();
  const resetToken = query.get('token');

  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (isNullOrUndefined(resetToken) || isEmpty(resetToken)) {
      setErrorPage(true);
    } else {
      setErrorPage(false);
    }
  }, [resetToken]);

  const onSubmit = async (resetPasswordData: FormData) => {
    try {
      if (resetToken) {
        await resetPassword(resetToken, resetPasswordData.password);

        setShowConfirmation(true);

        setTimeout(() => {
          history.push('/');
        }, 2000);
      } else {
        throw new Error('queryParamKey is not defined');
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
        setErrorModal(true);
      }
    }
  };

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>();

  const password = watch('password');

  return errorPage ? (
    <ErrorPage />
  ) : (
    <div>
      <div className="credential-container">
        <div className="credential-container__title">
          Welcome to
          <img
            className="credential-container__logo"
            src={KaliberLogo}
            alt=""
          />
        </div>
        <div className="credential-container__form">
          <div className="credential-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="credential-form__header">Reset Your Password</div>
              <div className="credential-form__textbox">
                <KaliberPasswordField
                  label="New Password"
                  name="password"
                  register={register}
                  required
                  errors={errors}
                  errorMessage={errors.password?.message}
                  dataTestId="credential-password"
                />
              </div>
              <div className="credential-form__textbox">
                <KaliberPasswordField
                  label="Confirm New Password"
                  name="confirmPassword"
                  register={register}
                  required
                  watch={password}
                  errors={errors}
                  errorMessage="Passwords do not match."
                  dataTestId="credential-confirm-password"
                />
              </div>
              <div className="credential-form__btn">
                <KaliberButton
                  buttonClass="btn--primary btn--full-width"
                  type="submit"
                  dataTestId="credential-reset-password">
                  Reset Password
                </KaliberButton>
              </div>
            </form>
          </div>
        </div>
      </div>
      <KaliberModal
        title="Password successfully changed!"
        titleAlignment="center"
        visible={showConfirmation}>
        <div className="modal-text">Redirecting you to login page.</div>
      </KaliberModal>
      <KaliberModal
        title="Password Reset Error"
        titleAlignment="center"
        visible={errorModal}
        closeIcon={true}
        onClose={() => setErrorModal(false)}>
        <div className="modal-text">{error}</div>
      </KaliberModal>
    </div>
  );
}

function signUpLinkExpiredPrompt({
  queryParamKey,
  resendSignUpLink,
  setResendSignUpLink,
  setErrorPage
}: {
  queryParamKey: string | null;
  resendSignUpLink: boolean;
  setResendSignUpLink: (sent: boolean) => void;
  setErrorPage: (error: boolean) => void;
}): ReactElement {
  const handleResendButton = async () => {
    if (queryParamKey) {
      const isSignUpNotificationResent = await resendSignupNotifications(
        queryParamKey
      );

      if (isSignUpNotificationResent) {
        setResendSignUpLink(true);
      } else {
        setErrorPage(true);
      }
    }
  };

  return resendSignUpLink ? (
    <KaliberMessagePage
      title="The new link has been sent. "
      body="Please check your email."
    />
  ) : (
    <KaliberMessagePage
      title="The link you are trying to access has expired."
      body="To receive a new secure link, click on the button below."
      hasButton
      buttonText="Resend Report"
      onClick={handleResendButton}
    />
  );
}
