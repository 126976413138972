import React, { ReactElement } from 'react';
import Tab from '../../components/tab/KLCustomTab';
import Tabs from '../../components/tab/KLCustomTabs';
import './StyleGuide.scss';
import SGButtons from './StyleGuideButtons';
import SGColors from './StyleGuideColors';
import SGComponents from './StyleGuideComponents';
import SGTypography from './StyleGuideTypography';

export default function StyleGuidePage(): ReactElement {
  return (
    <div className="page-container">
      <div className="sg-title">Rekap Style Guide</div>
      <Tabs>
        <Tab title="Colors">
          <SGColors></SGColors>
        </Tab>
        <Tab title="Buttons">
          <SGButtons></SGButtons>
        </Tab>
        <Tab title="Components">
          <SGComponents></SGComponents>
        </Tab>
        <Tab title="Icons">
          <SGComponents></SGComponents>
        </Tab>
        <Tab title="Typography">
          <SGTypography></SGTypography>
        </Tab>
      </Tabs>
      {/* <SGColors></SGColors>
        <SGButtons></SGButtons> */}
    </div>
  );
}
