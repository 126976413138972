/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { UseFormRegister } from 'react-hook-form';

import KaliberTextField from './KaliberTextField';
import './KaliberTextField.scss';

interface KaliberEmailField {
  register: UseFormRegister<any>;
  required?: boolean;
  errors: any;
  disabled?: boolean;
  tabIndex?: number;
  dataTestId?: string;
}
export default function KaliberEmailField({
  register,
  required,
  errors,
  tabIndex,
  dataTestId
}: KaliberEmailField): ReactElement {
  return (
    <KaliberTextField
      label="Email"
      name="email"
      placeholder=""
      register={register}
      registerObject={{
        required,
        pattern: {
          value: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/i,
          message: 'Please enter a valid email address.'
        }
      }}
      errors={errors}
      errorMessage="Please enter a email address."
      tabIndex={tabIndex}
      dataTestId={dataTestId}></KaliberTextField>
  );
}
