import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { KaliberButton, KaliberCard, RadioButton } from '../../../components';
import './CustomNotes.scss';
import Accordion from './components/accordion/AccordionComponent';

import {
  clearQuickNotes,
  getQuickNotes,
  updateQuickNotes
} from '../../../redux/actions/quickNoteActions';
import { getSurgeonByUid } from '../../../redux/actions/staffActions';
import { KLQuickNote, KLState, KLUser } from '../../../redux/types';
import { objDeepEqual } from '../../../utils/common';

type QuickNoteEditProps = {
  note: KLQuickNote;
  onChange: (changedNote: KLQuickNote) => void;
};

function QuickNoteEdit({ note, onChange }: QuickNoteEditProps): ReactElement {
  const handleSelectChange = (quickNote: KLQuickNote, value: string) => {
    onChange({
      ...quickNote,
      customNoteSelected: value === 'custom'
    });
  };

  const handleTextChange = (quickNote: KLQuickNote, value: string) => {
    onChange({ ...quickNote, customNote: value });
  };

  return (
    <fieldset className="quick-notes-container">
      <RadioButton
        label={note.note}
        checked={!note.customNoteSelected}
        onClick={value => handleSelectChange(note, value)}
        value="default"
        id={`${note.defaultNote}-default`}
        nameGroup={`${note.defaultNote}`}
        className="default-note"
      />
      <div className="custom-input-container">
        <RadioButton
          label=""
          checked={note.customNoteSelected}
          onClick={value => handleSelectChange(note, value)}
          value="custom"
          id={`${note.defaultNote}-custom`}
          nameGroup={`${note.defaultNote}`}
        />
        <textarea
          className="custom-note-input"
          rows={2}
          placeholder="Customize Note"
          value={note.customNote}
          onChange={event => handleTextChange(note, event.target.value)}
        />
      </div>
    </fieldset>
  );
}

type QuickNotesRegionProps = {
  region: string;
  evaluationResponses: KLQuickNote[];
  treatmentResponses: KLQuickNote[];
  postTreatmentResponses: KLQuickNote[];
  onChange: (quickNote: KLQuickNote) => void;
};

function QuickNotesRegion({
  region,
  evaluationResponses,
  treatmentResponses,
  postTreatmentResponses,
  onChange
}: QuickNotesRegionProps): ReactElement {
  const [internalEvaluationResponses, setInternalEvaluationResponses] =
    useState(evaluationResponses);
  const [internalTreatmentResponses, setInternalTreatmentResponses] =
    useState(treatmentResponses);
  const [internalPostTreatmentResponses, setInternalPostTreatmentResponses] =
    useState(postTreatmentResponses);

  useEffect(() => {
    setInternalEvaluationResponses(evaluationResponses);
  }, [evaluationResponses]);

  useEffect(() => {
    setInternalTreatmentResponses(treatmentResponses);
  }, [treatmentResponses]);

  useEffect(() => {
    setInternalPostTreatmentResponses(postTreatmentResponses);
  }, [postTreatmentResponses]);

  const handleEvaluationResponseChange = (changedNote: KLQuickNote) => {
    setInternalEvaluationResponses(
      internalEvaluationResponses.map(currentNote => {
        if (currentNote.quickNoteId === changedNote.quickNoteId) {
          return changedNote;
        } else {
          return currentNote;
        }
      })
    );

    onChange(changedNote);
  };

  const handleTreatmentResponseChange = (changedNote: KLQuickNote) => {
    setInternalTreatmentResponses(
      internalTreatmentResponses.map(currentNote => {
        if (currentNote.quickNoteId === changedNote.quickNoteId) {
          return changedNote;
        } else {
          return currentNote;
        }
      })
    );

    onChange(changedNote);
  };

  const handlePostTreatmentResponseChange = (changedNote: KLQuickNote) => {
    setInternalPostTreatmentResponses(
      internalPostTreatmentResponses.map(currentNote => {
        if (currentNote.quickNoteId === changedNote.quickNoteId) {
          return changedNote;
        } else {
          return currentNote;
        }
      })
    );

    onChange(changedNote);
  };

  return (
    <div className="region-container">
      <span className="region-container__title">{region}</span>
      <Accordion title="Evaluation responses">
        {internalEvaluationResponses.map(element => (
          <QuickNoteEdit
            key={element.defaultNote}
            note={element}
            onChange={handleEvaluationResponseChange}
          />
        ))}
      </Accordion>

      <Accordion title="Treatment responses">
        {internalTreatmentResponses.map(element => (
          <QuickNoteEdit
            key={element.defaultNote}
            note={element}
            onChange={handleTreatmentResponseChange}
          />
        ))}
      </Accordion>

      <Accordion title="Post-treatment responses">
        {internalPostTreatmentResponses.map(element => (
          <QuickNoteEdit
            key={element.defaultNote}
            note={element}
            onChange={handlePostTreatmentResponseChange}
          />
        ))}
      </Accordion>
    </div>
  );
}

export default function CustomNotes(): ReactElement {
  useEffect(() => {
    return () => {
      clearQuickNotes();
    };
  }, []);

  const [user, quickNotes]: [KLUser, KLQuickNote[]] = useSelector(
    (state: KLState) => [state.user, state.quickNotes]
  );
  const [surgeonId, setSurgeonId] = useState<number | null>(null);
  const [evaluationShoulder, setEvaluationShoulder] = useState<KLQuickNote[]>(
    []
  );
  const [treatmentShoulder, setTreatmentShoulder] = useState<KLQuickNote[]>([]);
  const [postTreatmentShoulder, setPostTreatmentShoulder] = useState<
    KLQuickNote[]
  >([]);
  const [evaluationKnee, setEvaluationKnee] = useState<KLQuickNote[]>([]);
  const [treatmentKnee, setTreatmentKnee] = useState<KLQuickNote[]>([]);
  const [postTreatmentKnee, setPostTreatmentKnee] = useState<KLQuickNote[]>([]);
  const [changedQuickNotes, setChangedQuickNotes] = useState<KLQuickNote[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function handleSavePermissions() {
    setLoading(true);
    if (surgeonId && user.accessToken) {
      await updateQuickNotes(user.accessToken, surgeonId, changedQuickNotes);
      await getQuickNotes(user.accessToken, surgeonId);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (user.accessToken && user.uid && user.role === 'Surgeon') {
      getSurgeonByUid(user.accessToken, parseInt(user.uid)).then(surgeonId => {
        if (user.accessToken) {
          setSurgeonId(surgeonId);
          return getQuickNotes(user.accessToken, surgeonId);
        } else {
          return Promise.resolve();
        }
      });
    }
  }, [user.accessToken]);

  useEffect(() => {
    const { knee, shoulder } = sortQuickNotesByRegionAndStage();

    setChangedQuickNotes([]);
    setEvaluationKnee(knee.evaluation);
    setTreatmentKnee(knee.treatment);
    setPostTreatmentKnee(knee.postTreatment);
    setEvaluationShoulder(shoulder.evaluation);
    setTreatmentShoulder(shoulder.treatment);
    setPostTreatmentShoulder(shoulder.postTreatment);
  }, [quickNotes]);

  const handleChangedQuickNote = (changedNote: KLQuickNote) => {
    // Check for unsaved changes to changed note
    const unsavedChangedIdx = changedQuickNotes.findIndex(
      stagedChangedNote =>
        stagedChangedNote.defaultNote === changedNote.defaultNote
    );

    if (unsavedChangedIdx !== -1) {
      updateUnsavedChanges(unsavedChangedIdx, changedNote);
    } else {
      // Changed note does not have unsaved changes and should be added to
      // the array of changed notes
      setChangedQuickNotes([...changedQuickNotes, changedNote]);
    }
  };

  const updateUnsavedChanges = (idx: number, changedNote: KLQuickNote) => {
    for (const remoteQuickNote of quickNotes) {
      if (remoteQuickNote.defaultNote !== changedNote.defaultNote) {
        continue;
      }

      const newChangedQuickNotes = [...changedQuickNotes];
      if (!objDeepEqual(changedNote, remoteQuickNote)) {
        // Unsaved changed note is not equal to the server note and should be updated
        newChangedQuickNotes.splice(idx, 1, changedNote);
        setChangedQuickNotes(newChangedQuickNotes);
      } else {
        // Unsaved changed note is equal to the server note and should be removed
        newChangedQuickNotes.splice(idx, 1);
      }

      setChangedQuickNotes(newChangedQuickNotes);
    }
  };

  function handleCancel() {
    const { knee, shoulder } = sortQuickNotesByRegionAndStage();

    setChangedQuickNotes([]);
    setEvaluationKnee(knee.evaluation);
    setTreatmentKnee(knee.treatment);
    setPostTreatmentKnee(knee.postTreatment);
    setEvaluationShoulder(shoulder.evaluation);
    setTreatmentShoulder(shoulder.treatment);
    setPostTreatmentShoulder(shoulder.postTreatment);
  }

  const sortQuickNotesByRegionAndStage = () => {
    return quickNotes.reduce(
      (acc, curr) => {
        switch (curr.region) {
          case 'knee': {
            sortQuickNoteByStage(
              curr,
              acc.knee.evaluation,
              acc.knee.treatment,
              acc.knee.postTreatment
            );
            break;
          }
          case 'shoulder': {
            sortQuickNoteByStage(
              curr,
              acc.shoulder.evaluation,
              acc.shoulder.treatment,
              acc.shoulder.postTreatment
            );
            break;
          }
        }

        return acc;
      },
      {
        knee: { evaluation: [], treatment: [], postTreatment: [] },
        shoulder: { evaluation: [], treatment: [], postTreatment: [] }
      }
    );
  };

  const sortQuickNoteByStage = (
    quickNote: KLQuickNote,
    evaluation: KLQuickNote[],
    treatment: KLQuickNote[],
    postTreatment: KLQuickNote[]
  ) => {
    switch (quickNote.stage.toLowerCase()) {
      case 'evaluation': {
        evaluation.push(quickNote);
        break;
      }
      case 'treatment': {
        treatment.push(quickNote);
        break;
      }
      case 'post-treatment': {
        postTreatment.push(quickNote);
        break;
      }
    }
  };

  return (
    <KaliberCard title="Customize notes" titleAlignment="left">
      <div className="custom-notes-container">
        <div className="notes-container">
          <QuickNotesRegion
            region="Shoulder"
            evaluationResponses={evaluationShoulder}
            treatmentResponses={treatmentShoulder}
            postTreatmentResponses={postTreatmentShoulder}
            onChange={handleChangedQuickNote}
          />
          <QuickNotesRegion
            region="Knee"
            evaluationResponses={evaluationKnee}
            treatmentResponses={treatmentKnee}
            postTreatmentResponses={postTreatmentKnee}
            onChange={handleChangedQuickNote}
          />
        </div>
        <div className="action-container">
          <KaliberButton
            type="button"
            buttonClass="btn--primary"
            onClick={() => {
              handleCancel();
            }}
            disabled={changedQuickNotes.length === 0}>
            Cancel
          </KaliberButton>
          <KaliberButton
            type="submit"
            buttonClass="btn--primary action-container__save"
            onClick={() => {
              handleSavePermissions();
            }}
            loading={loading}
            disabled={changedQuickNotes.length === 0}>
            Save
          </KaliberButton>
        </div>
      </div>
    </KaliberCard>
  );
}
