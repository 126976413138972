import moment from 'moment';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import kaliberLogo from '../../assets/logos/kaliberAI-logo-white-2022-05.png';
import './KLFooter.scss';

export interface LinkElement {
  title: string;
  link: string;
  external: boolean;
  dataTestId: string;
}

export default function KLFooter(): ReactElement {
  return (
    <div className="footer">
      <div className="footer__links-container">
        <Link to={{ pathname: '/privacy-policy' }} className="footer__link">
          Privacy Policy
        </Link>
        <Link to={{ pathname: '/terms-of-use' }} className="footer__link">
          Terms of Use
        </Link>
        {/* I dont know if we still need this link or not */}
        {/* <Link
          to={{ pathname: '/terms-of-use/surgeon' }}
          className="footer__link">
          Terms of Use - Surgeon
        </Link> */}
        <Link to={{ pathname: '/help' }} className="footer__link">
          Help Center
        </Link>
      </div>
      <div className="footer__logo-container">
        <img src={kaliberLogo} className="footer__logo" />
        <span className="footer__copyright">
          {`© 2016 - ${moment().year()} Kaliber Labs Inc. | all rights reserved`}
        </span>
      </div>
    </div>
  );
}
