import React, { ReactElement, useEffect, useState } from 'react';
import ExternalPatientReport from './ExternalPatientReport';
import { checkReportExpiry } from '../../redux/actions/reportActions';
import { DeletedError } from '../../api';
import { useLocation } from 'react-router-dom';

import KLNavBar from '../../components/navbar/KLNavBar';
import ErrorPage from '../errorPage/ErrorPage';

export default function ExternalReport(): ReactElement {
  const linkExpiredTitle = "The link you're trying to access has expired.";
  const linkExpiredBody =
    'The page you are trying to reach has expired. Please contact the person who shared this link with you and request a new link.';
  const location = useLocation();

  const [isLinkBroken, setIsLinkBroken] = useState(true);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isValidatingLinkExpiry, setIsValidatingLinkExpiry] = useState(true);

  useEffect(() => {
    const fetchReportExpiry = async () => {
      setIsValidatingLinkExpiry(true);
      const key = location.pathname.split('/')[2];

      try {
        await checkReportExpiry(key);
        setIsLinkBroken(false);
      } catch (error) {
        setIsLinkBroken(true);
        if (error instanceof DeletedError) {
          setIsLinkExpired(true);
        }
      } finally {
        setIsValidatingLinkExpiry(false);
      }
    };

    fetchReportExpiry();
  }, [location.pathname]);

  return isValidatingLinkExpiry ? (
    <div />
  ) : (
    <div>
      {isLinkBroken ? (
        <div>
          <KLNavBar />
          {isLinkExpired ? (
            <div>
              <ErrorPage
                isFullPage={false}
                title={linkExpiredTitle}
                body={linkExpiredBody}
              />
            </div>
          ) : (
            <ErrorPage isFullPage={false} />
          )}
        </div>
      ) : (
        <ExternalPatientReport />
      )}
    </div>
  );
}
