import { ReactElement } from 'react';
import moment from 'moment';

import './ReportOverview.scss';

import { CptSurgeryProcedure, SurgeryInfo } from '../../../../redux/types';
import {
  capitalizeEveryWord,
  capitalizeFirstLetter,
  normalizeText,
  replaceLastCommaWithAnd,
  sentenceCase
} from '../../../../utils/textFormat';
import { groupCptSurgeryProceduresByAnatomy } from '../../../../utils/surgeryProcedures';
import { isEmpty, isNotEmpty } from '../../../../utils/validations';

const formatDetail = (key: string, value: string | string[]) =>
  `${capitalizeEveryWord(normalizeText(key))}: ${
    Array.isArray(value) ? replaceLastCommaWithAnd(value.join(', ')) : value
  }, `;

function formatProcedureDetails(cptSurgeryProcedure: CptSurgeryProcedure) {
  if (!cptSurgeryProcedure.procedureDetail) {
    return '';
  }

  let detailString = '';
  let additionalDetailsString = '';
  let medialRepairLengthWithUnit = '';
  let lateralRepairLengthWithUnit = '';

  const details = cptSurgeryProcedure.procedureDetail as {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };

  // Iterate over keys in procedureDetail
  for (const key in details) {
    if (
      ['id', 'type'].includes(key) ||
      !details[key] ||
      isEmpty(details[key])
    ) {
      continue;
    }

    // Check if the key is 'additionalDetails'
    if (key === 'additionalDetails') {
      additionalDetailsString = formatDetail(key, details[key]);
      continue;
    }

    switch (cptSurgeryProcedure.cptCode.code) {
      case '23410':
      case '23412':
      case '29827':
        detailString +=
          key === 'tearRegion'
            ? `Region of Tear: ${replaceLastCommaWithAnd(
                details[key].join(', ')
              )}, `
            : formatDetail(key, details[key]);
        break;
      case '29882':
      case '29883':
        if (key === 'medialRepairLength') {
          medialRepairLengthWithUnit += `${details[key]} `;
        } else if (key === 'lateralRepairLength') {
          lateralRepairLengthWithUnit += `${details[key]} `;
        } else if (key === 'medialRepairLengthUnit') {
          medialRepairLengthWithUnit += `${details[key]}`;
        } else if (key === 'lateralRepairLengthUnit') {
          lateralRepairLengthWithUnit += `${details[key]}`;
        } else {
          detailString += formatDetail(key, details[key]);
        }
        break;
      default:
        detailString += formatDetail(key, details[key]);
    }
  }

  // Append medialRepairLengthWithUnit and lateralRepairLengthWithUnit only if they have a number inside
  detailString += medialRepairLengthWithUnit.match(/\d/)
    ? `Medial Repair Length: ${medialRepairLengthWithUnit}, `
    : '';
  detailString += lateralRepairLengthWithUnit.match(/\d/)
    ? `Lateral Repair Length: ${lateralRepairLengthWithUnit}, `
    : '';

  // Append additionalDetailsString at the end
  detailString += additionalDetailsString;

  // Remove trailing comma and space
  detailString = detailString.slice(0, -2);
  return detailString;
}

type ProcedureGroupProps = {
  title: string;
  content: string[];
};

function ProcedureGroup({ title, content }: ProcedureGroupProps): ReactElement {
  return (
    <div className="report__procedures__container">
      <div className="report__procedures__region">{title.trimStart()}</div>
      {content.map((procedure, idx) => (
        <div key={idx} className="report__procedures__description">
          {procedure}
        </div>
      ))}
    </div>
  );
}

type ReportOverviewProps = {
  surgery: SurgeryInfo;
};

export default function ReportOverview({
  surgery
}: ReportOverviewProps): ReactElement {
  const groupedProcedures = Object.entries(
    groupCptSurgeryProceduresByAnatomy(
      surgery.performedProcedures.cptProcedures
    )
  );

  return (
    <div>
      <div className="overview-container">
        <div className="overview-description">
          <div className="text-container">
            <div>
              {surgery.patientFirstName}{' '}
              {surgery.patientMiddleName ? surgery.patientMiddleName : ''}{' '}
              {surgery.patientLastName}
            </div>
            <div>{moment(surgery.surgeryDate).format('MM/DD/YYYY')}</div>
          </div>
        </div>
        <div className="overview-description">
          <div className="surgeon-bio">
            <div>Surgery performed by {surgery.surgeonName}</div>
            <div>{sentenceCase(surgery.facilityName)}</div>
          </div>
        </div>
      </div>
      <div className="report__procedures">
        {isNotEmpty(groupedProcedures) ? (
          groupedProcedures.map(([anatomy, cptSurgeryProcedures], index) => (
            <ProcedureGroup
              key={`${anatomy}-${index}`}
              title={`${capitalizeFirstLetter(
                cptSurgeryProcedures.at(0)?.side ?? ''
              )} ${capitalizeFirstLetter(anatomy)}`}
              content={cptSurgeryProcedures.map(cptSurgeryProcedure => {
                const formattedProcedureDetails =
                  formatProcedureDetails(cptSurgeryProcedure);
                return `${cptSurgeryProcedure.cptCode.commonName}${
                  formattedProcedureDetails
                    ? ` (${formattedProcedureDetails})`
                    : ''
                }`;
              })}
            />
          ))
        ) : (
          <ProcedureGroup
            title={`${capitalizeFirstLetter(
              surgery.side
            )} ${capitalizeFirstLetter(surgery.region)}`}
            content={
              isNotEmpty(surgery.oldPerformedProcedures)
                ? surgery.oldPerformedProcedures
                : surgery.surgeryType
            }
          />
        )}
        {isNotEmpty(surgery.performedProcedures?.customProcedures) && (
          <ProcedureGroup
            title="Other"
            content={surgery.performedProcedures.customProcedures.map(
              customProcedure => customProcedure.customProcedure.procedure
            )}
          />
        )}
      </div>
    </div>
  );
}
