import * as FullStory from '@fullstory/browser';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import store from './redux/store';
import './utils/i18n';
import './styles/utilities.scss';

FullStory.init({ orgId: '1863FV' });

const STYTCH_PUBLIC_TOKEN =
  'public-token-test-2ffca5ab-c07b-42c2-9c1f-3082d2ad8ee5';
const stytch = new StytchUIClient(STYTCH_PUBLIC_TOKEN);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(
  <StytchProvider stytch={stytch}>
    <Provider store={store}>
      <App />
    </Provider>
  </StytchProvider>
);
