import React, { ReactElement } from 'react';
import Carousel from 'react-material-ui-carousel';
import { v4 as uuidv4 } from 'uuid';
import WomanArmsCrossed from '../../../assets/patientPay/patient-pay-woman-arms-crossed.png';
import Ipad from '../../../assets/patientPay/patient-pay-ipad.png';
import WomanMan from '../../../assets/patientPay/patient-pay-woman-man.png';
import KaliberButton from '../../../components/button/KaliberButton';
import './KLPatientPayCarousel.scss';
import KaliberLogo from '../../../assets/logos/kaliber-rekap-TM-purple.png';

interface KLPatientPayCarouselProps {
  onClick: (val: React.SyntheticEvent) => void;
}
export default function KLPatientPayCarousel({
  onClick
}: KLPatientPayCarouselProps): ReactElement {
  const items = [
    {
      title: 'Screen 1',
      body: (
        <span>
          <span className="item-bold-text">
            A personalized AI-powered surgery report
          </span>{' '}
          that provides you with continued, convenient access to the specifics
          of the surgical treatment you received, including images from the
          procedure and the insights derived from them.
        </span>
      ),
      image: Ipad
    },
    {
      title: 'Screen 2',
      body: (
        <span>
          The detailed information in the Rekap report empowers you to take
          control of your recovery after arthroscopic surgery to help you
          achieve your goals
          <span className="item-bold-text">
            —regaining strength, mobility, and freedom from pain.
          </span>
        </span>
      ),
      image: WomanMan
    },
    {
      title: 'Screen 3',
      body: (
        <span>
          No two injuries or surgeries are alike. In order for your physical
          therapist to be able to develop a plan of care specifically tailored
          to you, they need detailed data about your injury and treatment.{' '}
          <span className="item-bold-text">
            The Rekap report provides that level of knowledge.
          </span>
        </span>
      ),
      image: WomanArmsCrossed
    }
  ];

  return (
    <div>
      <Carousel autoPlay={false}>
        {items.map(item => (
          <KLPatientPayCarouselItem
            key={uuidv4()}
            onClick={onClick}
            bodyText={item.body}
            imageSrc={item.image}
          />
        ))}
      </Carousel>
    </div>
  );
}

interface KLPatientPayCarouselItemProps {
  bodyText: string | JSX.Element;
  imageSrc: string;
  onClick: (val: React.SyntheticEvent) => void;
}

function KLPatientPayCarouselItem({
  onClick,
  bodyText,
  imageSrc
}: KLPatientPayCarouselItemProps) {
  return (
    <div>
      <div className="patient-pay-item-container">
        <img className="patient-pay-item-image" src={imageSrc} />
        <div className="patient-pay-item-text-container">
          <img className="patient-pay-item-logo" src={KaliberLogo} />
          <div className="patient-pay-item-body-text">{bodyText}</div>
          <div className="patient-pay-upgrade-button">
            <KaliberButton
              buttonClass="btn--primary"
              type="button"
              onClick={onClick}>
              Upgrade Now
            </KaliberButton>
          </div>
        </div>
      </div>
    </div>
  );
}
