import { KLSurgery, SurgeryInfo } from '../redux/types';

export function capitalizeFirstLetter(value: string): string {
  if (value) {
    const nonWhitespaceRegex = /[^\s\\]/g;
    const nonWhitespaceIdx = value.search(nonWhitespaceRegex);
    return (
      value?.charAt(nonWhitespaceIdx).toUpperCase() +
      value?.slice(nonWhitespaceIdx + 1)
    );
  } else {
    return '';
  }
}

export function normalizeText(value: string): string {
  return capitalizeFirstLetter(
    value
      .replace(/_/g, ' ')
      .replace(/([A-Z])/g, ' $1')
      .trim()
  );
}

export function sentenceCase(value: string): string {
  return capitalizeFirstLetter(value?.toLowerCase());
}

export function capitalizeEveryWord(value: string): string {
  return value?.replace(/(^\w{1})|(_\w{1})|(\s+\w{1})/g, letter =>
    letter.toUpperCase()
  );
}

export function formatPhoneNumber(phoneNumberString: string): string {
  const cleaned = removeFormatPhoneNumber(phoneNumberString);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
}

export const INVALID_PHONE_MESSAGE =
  'The above phone number is not valid. Please try again';

export function removeFormatPhoneNumber(phoneNumberString: string): string {
  if (phoneNumberString) {
    let number = phoneNumberString;
    number = number.replace(/[^\d+]+/g, '');
    number = number.replace(/^00/, '+');
    if (number.length > 10 && number.match(/^1/)) number = number.substring(1);
    return number;
  }
  return '';
}

export function inlineDescription(
  surgery: KLSurgery | SurgeryInfo,
  withDate = false
): string {
  const surgeryTypes = surgery.surgeryType
    ? surgery.surgeryType.join(', ')
    : 'Surgery not available';

  let text = sideRegionLabel(surgery).concat(` - ${surgeryTypes}`);

  if (withDate) {
    text = text.concat(` - ${surgery.surgeryDate}`);
  }

  return text;
}

export function sideRegionLabel(surgery: KLSurgery | SurgeryInfo): string {
  const side = surgerySideDisplayText(surgery);
  const region = surgery.region ?? 'Joint not available';

  return sentenceCase(`${side} ${region}`);
}

function surgerySideDisplayText(surgery: KLSurgery | SurgeryInfo): string {
  if (surgery.side === 'left' || surgery.side === 'right') {
    return surgery.side;
  } else {
    return '';
  }
}

export function formatStringToArray(value: string): string[] {
  const formattedStringArray = [];
  formattedStringArray.push(value);

  return formattedStringArray;
}

// Escape special regex characters. Needed when searching user input.
// Implementation taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions
export function escapeRegExp(value: string): string {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

// E.g. turns "A, B, C" into "A, B and C"
export function replaceLastCommaWithAnd(value: string): string {
  const lastIndex = value.lastIndexOf(',');
  if (lastIndex !== -1) {
    return (
      value.substring(0, lastIndex) + ' and' + value.substring(lastIndex + 1)
    );
  }
  return value;
}
