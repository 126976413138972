import React, { useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { callStratusApiV2Unauthenticated, RequestMethod } from '../../api';

interface MatchParams {
  shortKey: string;
}

type ShortenedUrlRedirectProps = RouteComponentProps<MatchParams>;

const ShortenedUrlRedirect: React.FC<ShortenedUrlRedirectProps> = ({
  match
}) => {
  const history = useHistory();
  const { shortKey } = match.params;

  useEffect(() => {
    const fetchOriginalUrl = async () => {
      try {
        const res = await callStratusApiV2Unauthenticated<{ url: string }>(
          `shortened_urls/${shortKey}`,
          {
            method: RequestMethod.Get,
            headers: { 'Content-Type': 'application/json' }
          }
        );

        if (res) {
          const originalUrl = res['url'];
          window.location.replace(originalUrl);
        } else {
          history.push('/errorlink');
        }
      } catch (error) {
        history.push('/errorlink');
      }
    };

    fetchOriginalUrl();
  }, [shortKey, history]);

  return null;
};

export default ShortenedUrlRedirect;
