import React, { ReactElement } from 'react';
import { Box, Modal } from '@mui/material';
import './KLFacilityFormConfirmationModal.scss';
import { KLFacility } from '../../redux/types';
import KaliberButton from '../button/KaliberButton';

export interface KLPatientFormConfirmationModalProps {
  visible: boolean;
  newFacility: KLFacility | undefined;
  onConfirmHandler: () => void;
  onCloseHandler: () => void;
}

export function KLFacilityFormConfirmationModal(
  props: KLPatientFormConfirmationModalProps
): ReactElement {
  const handleConfirmModal = () => {
    props.onConfirmHandler();
  };
  const handleCloseModal = () => {
    props.onCloseHandler();
  };

  return (
    <Modal open={props.visible} onClose={handleCloseModal}>
      <Box className="facility-confirmation-modal">
        <div className="details-container">
          <div className="details-section-title">
            <h6>Confirm Facility Information</h6>
          </div>

          <div className="details-section">
            <div className="details-row">
              <div className="field-group">
                <div className="body1 label">Facility name</div>
                <div className="body1 info">
                  {props.newFacility?.facilityName}
                </div>
              </div>
              <div className="field-group">
                <div className="body1 label">Facility type</div>
                <div className="body1 info">
                  {props.newFacility?.facilityType}
                </div>
              </div>
            </div>
            <div className="details-row">
              <div className="field-group">
                <div className="body1 label">Facility address</div>
                <div className="body1 info">
                  {props.newFacility?.facilityAddress}
                </div>
              </div>
              <div className="field-group">
                <div className="body1 label">NPI</div>
                <div className="body1 info">
                  {props.newFacility?.facilityNpi}
                </div>
              </div>
            </div>
            <div className="details-row">
              <div className="field-group">
                <div className="body1 label">Office phone</div>
                <div className="body1 info">
                  {props.newFacility?.facilityPhone}
                </div>
              </div>
              <div className="field-group">
                <div className="body1 label">Fax</div>
                <div className="body1 info">
                  {props.newFacility?.facilityFax}
                </div>
              </div>
              <div className="field-group">
                <div className="body1 label">Email</div>
                <div className="body1 info">
                  {props.newFacility?.facilityEmail}
                </div>
              </div>
            </div>
            <div className="details-row">
              <div className="field-group">
                <div className="body1 label">After hours phone</div>
                <div className="body1 info">
                  {props.newFacility?.facilityAfterHoursPhone}
                </div>
              </div>
              <div className="field-group">
                <div className="body1 label">Website</div>
                <div className="body1 info">
                  {props.newFacility?.facilityWebsite}
                </div>
              </div>
            </div>
          </div>

          <div className="modal-button-container">
            <div className="modal-button">
              <KaliberButton
                buttonClass="btn-secondary"
                type="button"
                onClick={handleCloseModal}>
                Cancel
              </KaliberButton>
            </div>
            <div className="modal-button">
              <KaliberButton
                buttonClass="btn--primary"
                type="submit"
                onClick={() => {
                  handleConfirmModal();
                  handleCloseModal();
                }}>
                Continue
              </KaliberButton>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
