import React, { ReactElement, useState } from 'react';
import TabTitle from './KLCustomTabTitle';
import './KLCustomTabs.scss';

interface KLTabsProps {
  children: ReactElement[];
}

export default function Tabs({ children }: KLTabsProps): ReactElement {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div>
      <ul className="tab-list">
        {children.map((item, index) => (
          <TabTitle
            key={index}
            index={index}
            title={item.props.title}
            setSelectedTab={setSelectedTab}
            selected={index === selectedTab}
          />
        ))}
      </ul>
      {children[selectedTab]}
    </div>
  );
}
