import store from '../store';
import { callStratusApiV2, RequestMethod } from '../../api';
import { KLQuickNote } from '../types';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export async function getQuickNotes(
  accessToken: string,
  surgeonId: number
): Promise<void> {
  const res = await callStratusApiV2<KLQuickNote[]>(
    `surgeons/${surgeonId}/quick_notes`,
    {
      accessToken,
      method: RequestMethod.Get
    }
  );

  store.dispatch({
    type: 'SET_QUICK_NOTES',
    payload: camelcaseKeys(res)
  });
}

export async function updateQuickNotes(
  accessToken: string,
  surgeonId: number,
  quickNotes: KLQuickNote[]
): Promise<void> {
  return await callStratusApiV2<void>(`surgeons/${surgeonId}/quick_notes`, {
    accessToken,
    method: RequestMethod.Put,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ quick_notes: snakecaseKeys(quickNotes) })
  });
}

export function clearQuickNotes(): void {
  store.dispatch({
    type: 'CLEAR_QUICK_NOTES'
  });
}
