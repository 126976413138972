import React, { ReactElement } from 'react';
import { Box, Modal } from '@mui/material';
import './KLModal.scss';
import KaliberButton from '../button/KaliberButton';

export interface KLModalProps {
  message: string;
  body?: string;
  visible: boolean;
  type: 'info' | 'error';
  onCloseHandler: () => void;
}

export default function KLModal(props: KLModalProps): ReactElement {
  let modalTitleClassName;
  switch (props.type) {
    case 'error':
      modalTitleClassName = 'modal-title-error';
      break;
    case 'info':
    default:
      modalTitleClassName = 'modal-title';
      break;
  }

  const handleCloseModal = () => {
    props.onCloseHandler();
  };

  return (
    <Modal open={props.visible} onClose={handleCloseModal}>
      <Box className="custom-modal">
        <h6 className={modalTitleClassName}>{props.message}</h6>
        <div className="body1 modal-body-text">{props.body}</div>
        <div className="custom-modal-button-container">
          <KaliberButton
            type="button"
            buttonClass="btn--primary"
            onClick={handleCloseModal}>
            Ok
          </KaliberButton>
        </div>
      </Box>
    </Modal>
  );
}
