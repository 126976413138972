/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import CheckCircleOutlineWhite from '../../assets/icons/check-circle-outline-white.svg';
import Circle from '../../assets/icons/Circle.svg';

interface toggleButtonProps {
  value: any;
  selected?: boolean;
  children: string;
  //doing this temporary
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  dataTestId?: string;
  className?: string;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  onChange?: (
    event: React.MouseEvent<HTMLElement>,
    value: string | string[]
  ) => void;
}

const ToggleButton = ({
  value,
  children,
  onClick,
  onChange,
  selected = false,
  dataTestId,
  className,
  color = 'primary',
  disabled = false,
  ...options
}: toggleButtonProps): ReactElement => {
  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(event, value);
      if (event.defaultPrevented) {
        return;
      }
    }

    if (onChange) {
      onChange(event, value);
    }
  };

  return (
    <button
      value={value}
      type="button"
      disabled={disabled}
      onClick={handleChange}
      className={`${className !== undefined ? className : ''} toggle-button ${
        selected ? 'selected' : ''
      } ${color}`}
      data-testid={dataTestId ? dataTestId : 'toggle-button'}
      {...options}>
      {selected ? (
        <img
          className="toggle-check-icon"
          src={CheckCircleOutlineWhite}
          alt=""
        />
      ) : (
        <img className="toggle-check-icon" src={Circle} alt="" />
      )}
      <div>{children}</div>
    </button>
  );
};

export default ToggleButton;
