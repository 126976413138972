import {
  SurgeryProcedures,
  CptSurgeryProcedure,
  CustomSurgeryProcedure
} from '../redux/types';

export function createCustomSurgeryProcedure(
  procedure: string,
  procedureType = 'planned'
): CustomSurgeryProcedure {
  return {
    customProcedure: {
      procedure: procedure
    },
    procedureType: procedureType
  };
}

export function updateCustomSurgeryProcedureType(
  customProcedure: CustomSurgeryProcedure,
  procedureType: string
): CustomSurgeryProcedure {
  customProcedure.procedureType = procedureType;
  return customProcedure;
}

export function updateCustomSurgeryProcedureProcedure(
  customProcedure: CustomSurgeryProcedure,
  procedure: string
): CustomSurgeryProcedure {
  customProcedure.customProcedure.procedure = procedure;
  return customProcedure;
}

export type GroupedProcedures = Record<string, CptSurgeryProcedure[]>;

export function groupCptSurgeryProceduresByAnatomy(
  cptSurgeryProcedures: CptSurgeryProcedure[]
): GroupedProcedures {
  const groupedProcedures: GroupedProcedures = {};
  cptSurgeryProcedures.forEach(cptSurgeryProcedure => {
    if (groupedProcedures[cptSurgeryProcedure.region]) {
      groupedProcedures[cptSurgeryProcedure.region].push(cptSurgeryProcedure);
    } else {
      groupedProcedures[cptSurgeryProcedure.region] = [cptSurgeryProcedure];
    }
  });

  return groupedProcedures;
}

export function hasProcedures(surgeryProcedures: SurgeryProcedures): boolean {
  return (
    surgeryProcedures.cptProcedures.length > 0 ||
    surgeryProcedures.customProcedures.length > 0
  );
}

export function transformToPerformed(
  plannedSurgeryProcedures: SurgeryProcedures
): SurgeryProcedures {
  const performedCptProcedures = plannedSurgeryProcedures.cptProcedures.map(
    plannedCptProcedure => {
      delete plannedCptProcedure.id;
      plannedCptProcedure.procedureType = 'performed';
      return plannedCptProcedure;
    }
  );

  const performedCustomProcedures =
    plannedSurgeryProcedures.customProcedures.map(plannedCustomProcedure => {
      delete plannedCustomProcedure.id;
      plannedCustomProcedure.procedureType = 'performed';
      return plannedCustomProcedure;
    });

  return {
    cptProcedures: performedCptProcedures,
    customProcedures: performedCustomProcedures
  };
}

/**
 * Check if CPT procedures are exclusively knee, shoulder, or mixed (other anatomies).
 * Returns 'knee' if at least one CPT procedure is knee and does not include shoulder.
 * Returns 'shoulder' if at least one CPT procedure is shoulder and does not include knee.
 * Returns 'mixed or other' if is a mix of knee and shoulder or knee/shoulder is not present.
 */
export class CPTProcedureCategorizer {
  private hasKnee = false;
  private hasShoulder = false;

  constructor(private procedures: CptSurgeryProcedure[]) {
    this.categorizeProcedures();
  }

  private categorizeProcedures(): void {
    for (const procedure of this.procedures) {
      if (procedure.cptCode.anatomy === 'knee') {
        this.hasKnee = true;
      }
      if (procedure.cptCode.anatomy === 'shoulder') {
        this.hasShoulder = true;
      }
    }
  }

  private isKneeOnly(): boolean {
    return this.hasKnee && !this.hasShoulder;
  }

  private isShoulderOnly(): boolean {
    return this.hasShoulder && !this.hasKnee;
  }

  public getSurgeryRegion(): string {
    if (this.isKneeOnly()) {
      return 'knee';
    } else if (this.isShoulderOnly()) {
      return 'shoulder';
    } else {
      return 'mixed or other';
    }
  }
}
