import ReactGA from 'react-ga4';

/**
 * https://support.google.com/analytics/answer/1033068#zippy=%2Cin-this-article
 * @description trigger a custom GA event that displays on the google analytics console
 * @param action - an action verb for the event e.g. follow_btn_click, this is also the field that displays on the google analytics console
 * @param category - a way to group these event objects i.e. what kind of event is this?
 * @param label - additional information about the event e.g. patientId, surgeryId, etc. (optional but recommended)
 */
export const triggerCustomGAEvent = (
  action: string,
  category: string,
  label?: string
): void => {
  if (label) {
    ReactGA.event({
      action,
      category,
      label
    });
    return;
  }

  ReactGA.event({
    action,
    category
  });
};

/**
 * https://github.com/codler/react-ga4#example
 * @description send pageview with a custom path
 * @param page - custom page path
 * @param title - custom page title
 */
export const triggerGAPageView = (path: string, title?: string): void => {
  if (title) {
    ReactGA.send({
      hitType: 'pageview',
      page: path,
      title
    });
    return;
  }

  ReactGA.send({
    hitType: 'pageview',
    page: path
  });
};
