/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import eyeClosedIcon from '../../assets/icons/eye-closed.svg';
import eyeIcon from '../../assets/icons/eye.svg';
import KaliberIconButton from '../iconButton/KaliberIconButton';
import KaliberTextField from './KaliberTextField';
import { validatePassword } from '../../utils/common';

interface KaliberPasswordFieldProps {
  label: string;
  name: string;
  register?: UseFormRegister<any>;
  watch?: any;
  required?: boolean;
  errors?: any;
  errorMessage?: any;
  dataTestId?: string;
}
export default function KaliberPasswordField({
  label,
  name,
  register,
  watch,
  required,
  errors,
  errorMessage,
  dataTestId
}: KaliberPasswordFieldProps): ReactElement {
  const [icon, setIcon] = useState(eyeClosedIcon);
  const [fieldType, setFieldType] = useState('password');

  const switchIcons = () => {
    if (icon == eyeClosedIcon) {
      setIcon(eyeIcon);
      setFieldType('text');
    } else {
      setIcon(eyeClosedIcon);
      setFieldType('password');
    }
  };

  return (
    <KaliberTextField
      label={label}
      name={name}
      placeholder=""
      register={register}
      errors={errors}
      errorMessage={errorMessage}
      tabIndex={0}
      dataTestId={dataTestId}
      registerObject={{
        required,
        validate: (val: string) => {
          if (watch != val && name == 'confirmPassword') {
            return 'Passwords do not match.';
          }
          // Do validation checks only for the sign up page, not the login page
          if (
            (!validatePassword(val) || val.length < 12) &&
            (name == 'confirmPassword' || name == 'signUpPassword')
          ) {
            return 'Password must be at least 12 characters long and include at least 2 of the following: an uppercase letter, a lowercase letter, a number, or a special character';
          }
        }
      }}
      type={fieldType}>
      <KaliberIconButton onClick={switchIcons} icon={icon} />
    </KaliberTextField>
  );
}
