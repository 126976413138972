import { ReactElement } from 'react';
import { Container } from '@mui/material';

import { StaffTableComponent } from '../../../../components/staffTable';

import { Staff } from '../../../../redux/types';

interface StaffViewComponentProps {
  staffList: Staff[];
}

export default function StaffViewComponent({
  staffList
}: StaffViewComponentProps): ReactElement {
  return (
    <Container maxWidth="xl">
      <div className="page-container">
        <StaffTableComponent staff={staffList} surgeons={[]} />
      </div>
    </Container>
  );
}
