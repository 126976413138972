/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';

import './KaliberTextField.scss';

interface KaliberTextAreaFieldProps {
  label?: Path<any>;
  name: string;
  register: UseFormRegister<any>;
  required?: boolean;
  errors: any;
  disabled?: boolean;
  placeholder?: string;
  errorMessage?: string;
  tabIndex?: number;
  rows?: number;
  maxLength?: number;
  fullwidth?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
export default function KaliberTextAreaField({
  label,
  name,
  register,
  required,
  errors,
  errorMessage,
  tabIndex,
  rows = 4,
  maxLength = 500,
  fullwidth = false,
  value,
  onChange,
  ...options
}: KaliberTextAreaFieldProps): ReactElement {
  const [inputValue, setInputValue] = useState(value);

  return (
    <div className={`ktf-container ${fullwidth ? 'ktf-fullwidth' : ''}`}>
      <div className="ktf-text-container">
        <label className="ktf-label">{label}</label>
      </div>

      <div
        className={`ktf-textarea-container ${
          errors[name] && 'ktf-input-error-border'
        }`}>
        <textarea
          value={inputValue}
          className={`ktf-input-text`}
          tabIndex={tabIndex}
          maxLength={maxLength}
          rows={rows}
          {...register(name, {
            required
          })}
          onChange={e => {
            setInputValue(e.target.value);
            if (onChange) {
              // Check if onChange prop function is provided
              onChange(e);
            }
          }}
          {...options}
        />
      </div>
      {errors[name] && <div className="ktf-input-error">{errorMessage}</div>}
    </div>
  );
}
