/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useState } from 'react';
import KaliberCard from '../../../../components/card/KaliberCard';
import './SurgeryDocuments.scss';
import {
  KLPostOpInstructions,
  KLReview,
  KLState,
  KLUser
} from '../../../../redux/types';
import { useSelector } from 'react-redux';
import { triggerCustomGAEvent } from '../../../../utils/googleAnalytics';

interface DisplayProps {
  review: KLReview;
  rawImagePDF?: string;
  patientReportPDF?: string;
}

interface PostOpInstruction {
  procedure: string;
  url: string;
}

function Display({
  review,
  rawImagePDF,
  patientReportPDF
}: DisplayProps): ReactElement {
  const [user, postOpInstructions]: [KLUser, KLPostOpInstructions] =
    useSelector((state: KLState) => [state.user, state.postOpInstructions]);
  const isKneeOrShoulderRegion =
    review.surgeryInfo.region === 'knee' ||
    review.surgeryInfo.region === 'shoulder';
  const [selectedPostOpInstructions, setSelectedPostOpInstructions] = useState<
    PostOpInstruction[]
  >([]);
  const getPostOpNameAndLink = (): { procedure: string; url: string }[] => {
    const postOpInstructionsIds = review.surgeryInfo.postOpInstructionsId;

    let selectedPostOpArray: { procedure: string; url: string }[] = [];
    if (
      postOpInstructionsIds &&
      postOpInstructionsIds.length > 0 &&
      postOpInstructions.all
    ) {
      selectedPostOpArray = postOpInstructionsIds
        .map(postOpInstructionId => {
          const postOp = postOpInstructions.all.find(
            element => element.postOpInstructionsId === postOpInstructionId
          );

          if (postOp) {
            return {
              procedure: postOp.procedure,
              url: postOp.customPdfSelected
                ? postOp.customUrl
                : postOp.defaultPostOpInstructionsUrl
            };
          }
          return null;
        })
        .filter(postOpInstruction => postOpInstruction !== null) as {
        procedure: string;
        url: string;
      }[];
    } else {
      selectedPostOpArray.push({
        procedure: 'Default Post Op Instructions',
        url: 'https://rekap-post-op-instructions.s3.amazonaws.com/default/Default+Post-op+Instructions+-+Surgeon+Opt-out.pdf'
      });
    }

    return selectedPostOpArray;
  };

  useEffect(() => {
    if (isKneeOrShoulderRegion) {
      setSelectedPostOpInstructions(getPostOpNameAndLink());
    }
  }, [review, postOpInstructions.all]);

  const handleRawImageReportClick = () => {
    if (rawImagePDF) {
      window.open(rawImagePDF, '_blank');
    }

    if (review) {
      const userUid = user.uid;
      const surgeryId = review.surgeryInfo.surgeryId;

      triggerCustomGAEvent(
        'raw_image_pdf_click',
        `role: ${user.role}(uid: ${userUid})`,
        `surgeryId: ${surgeryId}`
      );
    }
  };

  const handlePatientReportPDFClick = () => {
    if (patientReportPDF) {
      window.open(patientReportPDF, '_blank');
    }

    if (review) {
      const userUid = user.uid;
      const surgeryId = review.surgeryInfo.surgeryId;

      triggerCustomGAEvent(
        'patient_report_pdf_click',
        `role: ${user.role}(uid: ${userUid})`,
        `surgeryId: ${surgeryId}`
      );
    }
  };

  // Sort so that default knee/shoulder appears first, followed by remaining procedures in alphabetical order
  const sortPostOpInstructions = (
    a: PostOpInstruction,
    b: PostOpInstruction
  ) => {
    const displayTextA = getDisplayText(a);
    const displayTextB = getDisplayText(b);

    if (
      displayTextA === 'General Shoulder Post Op Instructions' ||
      displayTextA === 'General Knee Post Op Instructions'
    )
      return -1;
    if (
      displayTextB === 'General Shoulder Post Op Instructions' ||
      displayTextB === 'General Knee Post Op Instructions'
    )
      return 1;
    return displayTextA.localeCompare(displayTextB);
  };

  const getDisplayText = (postOpInstruction: PostOpInstruction) => {
    const generalShoulder = 'General Shoulder Post Op Instructions';
    const generalKnee = 'General Knee Post Op Instructions';

    if (postOpInstruction.procedure === 'Default shoulder')
      return generalShoulder;
    if (postOpInstruction.procedure === 'Default knee') return generalKnee;
    return `${postOpInstruction.procedure} Post Op Care`;
  };

  const handlePostOpPDFClick = (postOpUrl: string) => {
    window.open(postOpUrl, '_blank');

    if (review) {
      const userUid = user.uid;
      const surgeryId = review.surgeryInfo.surgeryId;

      triggerCustomGAEvent(
        'post_op_click',
        `role: ${user.role}(uid: ${userUid})`,
        `surgeryId: ${surgeryId}`
      );
    }
  };

  return (
    <div className="surgery-documents__container">
      <KaliberCard title={'Patient Documents'} titleAlignment="left">
        <div className="surgery-documents__card">
          {rawImagePDF && (
            <span
              className="surgery-documents__text"
              onClick={handleRawImageReportClick}>
              {'Raw Image Report'}
            </span>
          )}

          {patientReportPDF &&
            (user.role !== 'Patient' ||
              (user.role === 'Patient' &&
                review.surgeryInfo.surgeryStatus === 'Sent to Patient')) && (
              <span
                className="surgery-documents__text"
                onClick={handlePatientReportPDFClick}>
                {'Rekap Report'}
              </span>
            )}

          {isKneeOrShoulderRegion &&
            [...selectedPostOpInstructions]
              .sort(sortPostOpInstructions)
              .map((postOpInstruction, index) => (
                <span
                  key={index}
                  className="surgery-documents__text"
                  onClick={() => handlePostOpPDFClick(postOpInstruction.url)}>
                  {getDisplayText(postOpInstruction)}
                </span>
              ))}
        </div>
      </KaliberCard>
    </div>
  );
}

export interface SurgeryDocumentProps {
  review: KLReview;
  rawImagePDF?: string;
  patientReportPDF?: string;
  loading?: boolean;
}

export default function SurgeryDocuments({
  review,
  rawImagePDF,
  patientReportPDF,
  loading
}: SurgeryDocumentProps): ReactElement {
  return (
    <>
      {loading ? (
        <div className="quick-switch__container">
          <div className="surgery-page__skeleton surgery-page__skeleton--content" />
        </div>
      ) : (
        <Display
          review={review}
          rawImagePDF={rawImagePDF}
          patientReportPDF={patientReportPDF}
        />
      )}
    </>
  );
}
