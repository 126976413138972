import React, { ReactElement } from 'react';
import './KaliberCard.scss';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilSimple.svg';

interface KaliberCardProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  titleAlignment: 'left' | 'center' | 'right';
  subTitle?: string;
  editButton?: boolean;
  onClickEdit?: () => void;
  //It will be shown instead the edit button
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customOppositeElement?: any;
}

export default function KaliberCard({
  children,
  title,
  titleAlignment,
  subTitle,
  editButton = false,
  onClickEdit,
  customOppositeElement
}: KaliberCardProps): ReactElement {
  function handleEdit() {
    if (onClickEdit) {
      onClickEdit();
    }
  }

  return (
    <div className="kaliber-card">
      <div className="kaliber-card__header">
        <div
          className={`kaliber-card__title kaliber-card__title--${titleAlignment}`}>
          {title}
          {subTitle && (
            <span className="kaliber-card__subtitle">{subTitle}</span>
          )}
        </div>
        {editButton && customOppositeElement === undefined ? (
          <div className="edit-icon" onClick={handleEdit}>
            <EditIcon width={25} height={25} />
          </div>
        ) : (
          customOppositeElement !== undefined && customOppositeElement
        )}
      </div>

      {children}
    </div>
  );
}
