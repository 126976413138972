import { ReactElement } from 'react';
import './KaliberTextButton.scss';

interface KaliberButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  dataTestId?: string;
  disabled?: boolean;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (val: any) => void;
}
export default function KaliberTextButton({
  onClick,
  children,
  disabled = false,
  dataTestId,
  ...options
}: KaliberButtonProps): ReactElement {
  return (
    <button
      onClick={onClick}
      className="text-btn"
      disabled={disabled}
      data-testid={dataTestId}
      type="button"
      {...options}>
      <span className="btn__text">{children}</span>
    </button>
  );
}
