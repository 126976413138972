import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import KaliberCard from '../../components/card/KaliberCard';
import KLDateOfBirthPicker from '../../components/datepicker/KLDateOfBirthPicker';
import KLPhoneField from '../../components/phoneField/KLPhoneField';
import KLSelect from '../../components/select/KLSelect';
import KLTextField from '../../components/textField/KLTextField';
import { RACE_OPTIONS } from '../../constants';
import { getPatientForUser } from '../../redux/actions/patientActions';
import { setLoading } from '../../redux/actions/uiActions';
import { KLPatient, KLState, KLUser } from '../../redux/types';
import './Settings.scss';
import NotificationsSettings from './notificationsSettings/NotificationsSettings';

export default function PatientSettingsPage(): ReactElement {
  const [user, patient]: [KLUser, KLPatient] = useSelector((state: KLState) => [
    state.user,
    state.patient.currentPatient
  ]);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (user.accessToken && user.role === 'Patient') {
      getPatientForUser(user.accessToken).then(() => {
        setDataLoaded(true);
      });
    }
  }, [user.accessToken]);

  useEffect(() => {
    if (dataLoaded) {
      setLoading(false);
    }
  }, [dataLoaded]);

  return (
    <div>
      <Container maxWidth="xl">
        <div className="page-container settings-container">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <NotificationsSettings singleComponent={false} />

            <KaliberCard title="Patient information" titleAlignment="left">
              <div className="info-container">
                <Grid
                  className="info-row"
                  container
                  spacing={2}
                  columns={{ xs: 2, sm: 4, md: 12 }}>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLTextField
                      editable={false}
                      isRequired
                      placeholder="First name"
                      label="First name*"
                      name="FirstName"
                      value={patient.firstName}
                      dataTestId="pp-first-name"
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLTextField
                      editable={false}
                      isRequired
                      placeholder="Last name"
                      label="Last name*"
                      name="LastName"
                      value={patient.lastName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  className="info-row"
                  container
                  spacing={2}
                  columns={{ xs: 2, sm: 4, md: 12 }}>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLDateOfBirthPicker
                      editable={false}
                      label="Date of birth*"
                      value={new Date(patient.dob)}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLSelect
                      editable={false}
                      label="Sex*"
                      value={
                        patient.sex === 'F'
                          ? 'Female'
                          : patient.sex === 'M'
                          ? 'Male'
                          : 'Prefer not to answer'
                      }
                      onChange={() => {
                        //nothing yet
                      }}
                      placeholder="Sex"
                      menuItems={['Male', 'Female', 'Prefer not to answer']}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLSelect
                      editable={false}
                      label="Race*"
                      value={patient.race}
                      onChange={() => {
                        //nothing yet
                      }}
                      placeholder="Race"
                      menuItems={RACE_OPTIONS}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLSelect
                      editable={false}
                      label="Pronouns"
                      value={
                        patient.pronouns
                          ? patient.pronouns
                              ?.replace(/(^\w{1})|(_\w{1})/g, letter =>
                                letter.toUpperCase()
                              )
                              .replace('_', '/')
                          : ''
                      }
                      onChange={() => {
                        //nothing yet
                      }}
                      placeholder="Pronouns (optional)"
                      menuItems={['He/Him', 'She/Her', 'They/Them', 'Name']}
                    />
                  </Grid>
                </Grid>
              </div>
            </KaliberCard>

            <KaliberCard title="Contact Information" titleAlignment="left">
              <div className="info-container">
                <Grid
                  className="info-row"
                  container
                  spacing={2}
                  columns={{ xs: 2, sm: 4, md: 12 }}>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLPhoneField
                      editable={false}
                      id="phoneNumber"
                      label="Mobile phone*"
                      placeholder="(XXX) XXX-XXXX"
                      value={patient.phone ?? ''}
                      isRequired
                      onChange={() => {
                        //nothing yet
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLTextField
                      editable={false}
                      isRequired
                      type="email"
                      placeholder="Email"
                      label="Email*"
                      name="Email"
                      value={patient.email ?? ''}
                    />
                  </Grid>
                </Grid>
                <Grid
                  className="info-row"
                  container
                  spacing={2}
                  columns={{ xs: 2, sm: 4, md: 12 }}>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLTextField
                      editable={false}
                      isRequired
                      placeholder="Name"
                      label="Emergency Contact name"
                      value={patient.emergencyContact?.name ?? ''}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={3}>
                    <KLPhoneField
                      editable={false}
                      label="Emergency contact mobile phone"
                      placeholder="(XXX) XXX-XXXX"
                      value={patient.emergencyContact?.phone ?? ''}
                      onChange={() => {
                        //nothing yet
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </KaliberCard>
          </Box>
        </div>
      </Container>
    </div>
  );
}
