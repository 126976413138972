import { Surgery, SurgeryInfo, KLSurgery } from './../redux/types';

export interface ProcedureTypes {
  shoulder: string[];
  knee: string[];
  [key: string]: string[];
}

export const PROCEDURE_TYPES: ProcedureTypes = {
  shoulder: [
    'Shoulder arthroscopy',
    'Rotator cuff repair',
    'Subacromial decompression',
    'Bursectomy',
    'Distal clavicle resection (Mumford)',
    'Bankart repair',
    'Biceps tenotomy',
    'Biceps tenodesis',
    'Labral repair (SLAP)',
    'Labral Debridement',
    'Chondroplasty',
    'Capsular release',
    'Loose body removal'
  ],
  knee: [
    'Knee arthroscopy',
    'Partial meniscectomy',
    'Meniscus repair',
    'Chondroplasty',
    'ACL reconstruction',
    'Lateral retinacular release',
    'Osteoarticular transfer',
    'PCL reconstruction',
    'Loose body removal',
    'Synovectomy',
    'Biopsy',
    'Micro Fx',
    'MPFL reconstruction',
    'MCL repair'
  ]
};

export interface PostOpInstructions {
  shoulder: string[];
  knee: string[];
  [key: string]: string[];
}

export const POST_OP_INSTRUCTIONS: PostOpInstructions = {
  shoulder: [
    'Rotator Cuff/Shoulder Arthroscopy',
    'Bankart Repair',
    'Biceps Tenodesis'
  ],
  knee: ['Knee arthroscopy', 'ACL reconstruction']
};

export const DEFAULT_POST_OP_INSTRUCTION_URL =
  'https://rekap-post-op-instructions.s3.amazonaws.com/default/Default+Post-op+Instructions+-+Surgeon+Opt-out.pdf';

export function surgeryInfoToKLSurgery(
  surgeryReviewInfo: SurgeryInfo
): KLSurgery {
  return {
    surgeryId: surgeryReviewInfo.surgeryId,
    surgeonId: surgeryReviewInfo.surgeonId,
    facilityId: surgeryReviewInfo.facilityId,
    patientFirstName: surgeryReviewInfo.patientFirstName,
    patientMiddleName: surgeryReviewInfo.patientMiddleName,
    patientLastName: surgeryReviewInfo.patientLastName,
    patientDob: surgeryReviewInfo.patientDob,
    patientPhone: surgeryReviewInfo.patientPhone,
    region: surgeryReviewInfo.region,
    side: surgeryReviewInfo.side,
    surgeryType: surgeryReviewInfo.surgeryType,
    plannedProcedures: surgeryReviewInfo.plannedProcedures,
    performedProcedures: surgeryReviewInfo.performedProcedures,
    surgeryDate: surgeryReviewInfo.surgeryDate,
    surgeonName: surgeryReviewInfo.surgeonName,
    paymentPreference: surgeryReviewInfo.paymentPreference,
    facilityName: surgeryReviewInfo.facilityName,
    surgeryStatus: surgeryReviewInfo.surgeryStatus,
    postOpInstructionsId: surgeryReviewInfo.postOpInstructionsId,
    oldPerformedProcedures: surgeryReviewInfo.oldPerformedProcedures
  };
}

export function surgeryInfoToSurgery(surgeryInfo: SurgeryInfo): Surgery {
  return {
    facility: {
      facilityAddress: surgeryInfo.facilityAddress,
      facilityAfterHoursPhone: surgeryInfo.facilityAfterHoursPhone,
      facilityEmail: surgeryInfo.facilityEmail,
      facilityFax: surgeryInfo.facilityFax,
      facilityId: surgeryInfo.facilityId,
      facilityName: surgeryInfo.facilityName,
      facilityPhone: surgeryInfo.facilityPhone,
      facilityType: surgeryInfo.facilityType,
      facilityWebsite: surgeryInfo.facilityWebsite
    },
    patient: {
      address: surgeryInfo.patientAddress,
      city: surgeryInfo.patientCity,
      dob: surgeryInfo.patientDob,
      email: surgeryInfo.patientEmail,
      emergencyContact: {
        address: surgeryInfo.emergencyContact?.address,
        city: surgeryInfo.emergencyContact?.city,
        email: surgeryInfo.emergencyContact?.email ?? '',
        name: surgeryInfo.emergencyContact?.name ?? '',
        phone: surgeryInfo.emergencyContact?.phone ?? '',
        state: surgeryInfo.emergencyContact?.state,
        zipcode: surgeryInfo.emergencyContactZipcode
      },
      firstName: surgeryInfo.patientFirstName,
      id: surgeryInfo.patientId,
      lastName: surgeryInfo.patientLastName,
      middleName: surgeryInfo.patientMiddleName,
      phone: surgeryInfo.patientPhone,
      preferredCommunication: surgeryInfo.patientPreferredCommunication,
      preferredName: surgeryInfo.patientPreferredName,
      pronouns: surgeryInfo.patientPronouns,
      race: surgeryInfo.patientRace,
      sex: surgeryInfo.patientSex,
      state: surgeryInfo.patientState,
      zipcode: surgeryInfo.patientZipcode
    },
    paymentStatus: surgeryInfo.paymentStatus,
    performedProcedures: surgeryInfo.performedProcedures,
    plannedProcedures: surgeryInfo.plannedProcedures,
    region: surgeryInfo.region,
    side: surgeryInfo.side,
    surgeon: {
      optedIn: surgeryInfo.optedIn,
      paymentPreference: surgeryInfo.paymentPreference,
      surgeonEmail: surgeryInfo.surgeonEmail,
      surgeonId: surgeryInfo.surgeonId,
      surgeonName: surgeryInfo.surgeonName,
      surgeonNpi: surgeryInfo.surgeonNpi ?? 0,
      surgeonPhone: surgeryInfo.surgeonPhone
    },
    surgeryDate: surgeryInfo.surgeryDate,
    surgeryId: surgeryInfo.surgeryId,
    surgeryStatus: surgeryInfo.surgeryStatus,
    oldPerformedProcedures: surgeryInfo.oldPerformedProcedures
  };
}

export function surgeryToKLSurgery(surgery: Surgery): KLSurgery {
  return {
    surgeryId: surgery.surgeryId,
    surgeryDate: surgery.surgeryDate,
    surgeryType: [],
    side: surgery.side,
    region: surgery.region,
    surgeryStatus: surgery.surgeryStatus,
    plannedProcedures: surgery.plannedProcedures,
    performedProcedures: surgery.performedProcedures,
    patientFirstName: surgery.patient.firstName,
    patientMiddleName: surgery.patient.middleName,
    patientLastName: surgery.patient.lastName,
    patientDob: surgery.patient.dob,
    patientPhone: surgery.patient.phone,
    patientEmail: surgery.patient.email,
    patientRace: surgery.patient.race,
    patientSex: surgery.patient.sex,
    preferredCommunication: surgery.patient.preferredCommunication,
    surgeonId: surgery.surgeon.surgeonId,
    surgeonName: surgery.surgeon.surgeonName,
    surgeonEmail: surgery.surgeon.surgeonEmail,
    paymentPreference: surgery.surgeon.paymentPreference,
    facilityId: surgery.facility.facilityId ?? 0,
    facilityName: surgery.facility.facilityName,
    oldPerformedProcedures: surgery.oldPerformedProcedures
  };
}
