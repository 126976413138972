import { ReactElement } from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/cross-large.svg';
import './EditModal.scss';

type EditModalComponentProps = {
  visible: boolean;
  title: string;
  children: ReactElement;
  onClose: () => void;
};

export default function EditModal({
  visible,
  title,
  children,
  onClose
}: EditModalComponentProps): ReactElement {
  if (visible) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }

  return (
    <div className={`edit-modal-container ${visible ? 'show-modal' : ''}`}>
      <div className="edit-modal-content-container">
        <div className="edit-modal-content-container__content">
          <div className="edit-modal-header">
            <span className="edit-modal-header__title">{title}</span>
            <CloseIcon
              className="edit-modal-header__close-icon"
              onClick={onClose}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
