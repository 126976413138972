import { ReactElement } from 'react';
import InfoIcon from '../../assets/icons/Info.svg';
import { ReactComponent as XCircleIcon } from '../../assets/icons/XCircle.svg';
import { capitalizeFirstLetter } from '../../utils/textFormat';
import './KLCptProcedure.scss';

interface KLCptProcedureProps {
  cptCode: string;
  description: string;
  commonName: string;
  ptDetails?: string;
  onRemove?: () => void;
}

export default function KLCptProcedure({
  cptCode,
  description,
  commonName,
  ptDetails,
  onRemove
}: KLCptProcedureProps): ReactElement {
  return (
    <div className="cpt-procedure-container">
      <div className="cpt-code-container">
        {cptCode}
        <div className="cpt-procedure-info-icon">
          <div className="cpt-procedure-description-tooltip">
            {capitalizeFirstLetter(description)}
          </div>
          <img src={InfoIcon} />
        </div>
      </div>
      <div className="cpt-procedure-common-name">
        {capitalizeFirstLetter(commonName)}
      </div>
      {onRemove ? (
        <div className="cpt-procedure-close-icon" onClick={onRemove}>
          <XCircleIcon />
        </div>
      ) : null}
      <div className="cpt-procedure-pt-details">{ptDetails}</div>
    </div>
  );
}
