import { ReactElement } from 'react';
import { isEmpty } from '../../utils/validations';
import KaliberExpandable from '../expandable/KaliberExpandable';
import OptionalValueDisplay from '../optionalValueDisplay/OptionalValueDisplay';
import './PatientHeader.scss';

interface PatientHeaderProps {
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  dob: string;
  sex: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
}

export default function PatientHeader({
  firstName,
  lastName,
  middleName,
  preferredName,
  dob,
  sex,
  address,
  city,
  state,
  zipcode
}: PatientHeaderProps): ReactElement {
  const name = `${isEmpty(preferredName) ? '' : preferredName} ${
    isEmpty(preferredName) ? firstName : '(' + firstName + ')'
  } ${!isEmpty(middleName) ? middleName : ''} ${lastName}`;

  return (
    <KaliberExpandable title={name}>
      <div className="patient-header">
        <div className="patient-header__item">
          <span className="patient-header__label">DOB</span>
          <span className="patient-header__data">{dob}</span>
        </div>
        <div className="patient-header__item">
          <span className="patient-header__label">Sex</span>
          <OptionalValueDisplay value={sex}>
            <span className="patient-header__data">{sex}</span>
          </OptionalValueDisplay>
        </div>
        <div className="patient-header__item">
          <span className="patient-header__label">Address</span>
          <OptionalValueDisplay value={address}>
            <span className="patient-header__data">{address}</span>
            <span className="patient-header__data">
              {city}, {state} {zipcode}
            </span>
          </OptionalValueDisplay>
        </div>
      </div>
    </KaliberExpandable>
  );
}
