import { ReactElement } from 'react';
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
  useWatch
} from 'react-hook-form';

import AclReconstructionRepairComponent, {
  AclReconstructionRepairDetailCardProps
} from './components/aclReconstructionRepiar/AclReconstructionRepairComponent';
import MeniscectomyDetailCard, {
  MeniscectomyDetailCardProps
} from './components/meniscectomy/MeniscectomyComponent';
import RotatorCuffRepairComponent, {
  RotatorCuffRepairDetailCardProps
} from './components/RotatorCuffRepair/RotatorCuffRepairComponent';
import MeniscusRepairDetailCard, {
  MeniscusRepairDetailCardProps
} from './components/meniscusRepair/MeniscusRepairComponent';
import MicroFxDetailCard, {
  MicroFxDetailCardProps
} from './components/microFx/MicroFxComponent';
import KaliberCard from '../../../../../../components/card/KaliberCard';
import { SurgeryDetailsFormData } from '../../SurgeryDetailsComponent';

import './ProcedureDetailsComponent.scss';

import { capitalizeFirstLetter } from '../../../../../../utils/textFormat';
import BankartDetailCard, {
  BankartDetailCardProps
} from './components/bankartRepair/BankartRepairComponent';
import CapsularReleaseComponent, {
  CapsularReleaseDetailCardProps
} from './components/CapsularRelease/CapsularReleaseComponent';
import ChondroplastyDetailCard, {
  ChondroplastyDetailCardProps
} from './components/chondroplasty/ChondroplastyComponent';
import GenericDetailCard, {
  GenericDetailCardProps
} from './components/genericAdditionalDetail/GenericAdditionalDetailComponent';
import OsteoarticularTransferDetailCard, {
  OsteoarticularTransferDetailCardProps
} from './components/osteoarticularTransfer/OsteoarticularTransferComponent';
import { KaliberDivider } from '../../../../../../components';

const DETAIL_COMPONENT_CODES = [
  '29888',
  '29880',
  '29881',
  '29827',
  '23410',
  '23412',
  '29807',
  '29819',
  '29822',
  '29823',
  '29825',
  '29866',
  '29867',
  '29879',
  '29882',
  '29883',
  '29806',
  '29887',
  '29999',
  '27427'
];

const RCR_CODES = ['23410', '23412', '29827'];
const OSTEOARTICULAR_TRANSFER_CODES = ['29866', '29867'];

const isRcrCode = (code: string) => RCR_CODES.includes(code);
const isOsteoarticularTransferCode = (code: string) =>
  OSTEOARTICULAR_TRANSFER_CODES.includes(code);

type DetailComponentProps =
  | AclReconstructionRepairDetailCardProps
  | MeniscectomyDetailCardProps
  | RotatorCuffRepairDetailCardProps
  | MeniscusRepairDetailCardProps
  | BankartDetailCardProps
  | ChondroplastyDetailCardProps
  | GenericDetailCardProps
  | CapsularReleaseDetailCardProps
  | MicroFxDetailCardProps
  | OsteoarticularTransferDetailCardProps;

function detailComponent(
  code: string,
  props: DetailComponentProps
): ReactElement {
  // Note: Use GenericDetailCard if only "Additional Details" are required
  switch (code) {
    case '23410':
      return (
        <RotatorCuffRepairComponent
          {...(props as RotatorCuffRepairDetailCardProps)}
        />
      );
    case '23412':
      return (
        <RotatorCuffRepairComponent
          {...(props as RotatorCuffRepairDetailCardProps)}
        />
      );
    case '29806':
      return <BankartDetailCard {...(props as BankartDetailCardProps)} />;
    case '27427':
      return (
        <GenericDetailCard
          {...(props as GenericDetailCardProps)}
          detailTypeValue="ProcedureDetails::MpflReconstructionDetail"
        />
      );
    case '29807':
      return (
        <GenericDetailCard
          {...(props as GenericDetailCardProps)}
          detailTypeValue="ProcedureDetails::LabralRepairDetail"
        />
      );
    case '29819':
      return (
        <GenericDetailCard
          {...(props as GenericDetailCardProps)}
          detailTypeValue="ProcedureDetails::LooseBodyRemovalDetail"
        />
      );
    case '29822':
      return (
        <GenericDetailCard
          {...(props as GenericDetailCardProps)}
          detailTypeValue="ProcedureDetails::LimitedDebridementDetail"
        />
      );
    case '29823':
      return (
        <GenericDetailCard
          {...(props as GenericDetailCardProps)}
          detailTypeValue="ProcedureDetails::ExtensiveDebridementDetail"
        />
      );
    case '29825':
      return (
        <CapsularReleaseComponent
          {...(props as CapsularReleaseDetailCardProps)}
        />
      );
    case '29827':
      return (
        <RotatorCuffRepairComponent
          {...(props as RotatorCuffRepairDetailCardProps)}
        />
      );
    case '29866':
      return (
        <OsteoarticularTransferDetailCard
          {...(props as OsteoarticularTransferDetailCardProps)}
        />
      );
    case '29867':
      return (
        <OsteoarticularTransferDetailCard
          {...(props as OsteoarticularTransferDetailCardProps)}
        />
      );
    case '29879':
      return <MicroFxDetailCard {...(props as MicroFxDetailCardProps)} />;
    case '29880': //Meniscectomy, Medial AND Lateral
      return (
        <MeniscectomyDetailCard {...(props as MeniscectomyDetailCardProps)} />
      );
    case '29881': //Meniscectomy, Medial OR Lateral
      return (
        <MeniscectomyDetailCard {...(props as MeniscectomyDetailCardProps)} />
      );
    case '29882': //Meniscus Repair, Medial OR Lateral
      return (
        <MeniscusRepairDetailCard
          {...(props as MeniscusRepairDetailCardProps)}
        />
      );
    case '29883': //Meniscus Repair, Medial AND Lateral
      return (
        <MeniscusRepairDetailCard
          {...(props as MeniscusRepairDetailCardProps)}
        />
      );
    case '29887':
      return (
        <ChondroplastyDetailCard {...(props as ChondroplastyDetailCardProps)} />
      );
    case '29888':
      return (
        <AclReconstructionRepairComponent
          {...(props as AclReconstructionRepairDetailCardProps)}
        />
      );
    case '29999':
      return (
        <GenericDetailCard
          {...(props as GenericDetailCardProps)}
          detailTypeValue="ProcedureDetails::MclRepairDetail"
        />
      );
    default:
      return <></>;
  }
}

interface ProcedureDetailsComponentProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  register: UseFormRegister<any>;
  control: Control<any, any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  setValue: UseFormSetValue<SurgeryDetailsFormData>;
}

export default function ProcedureDetailsComponent({
  register,
  control,
  setValue
}: ProcedureDetailsComponentProps): ReactElement {
  const { fields } = useFieldArray({ name: 'cptProcedures', control });
  const cptProceduresValue = useWatch({
    name: 'cptProcedures',
    control
  });
  // Create an array of indices for cptProceduresValue that are included in DETAIL_COMPONENT_CODES
  const detailComponentIndices = cptProceduresValue
    .map((procedure: { cptCode: { code: string } }, index: number) =>
      DETAIL_COMPONENT_CODES.includes(procedure.cptCode.code) ? index : -1
    )
    .filter((index: number) => index !== -1);

  // TODO: Revisit how we can streamline this logic of multiple CPT codes mapping to one procedure
  // Count how many RCR codes are selected.
  const rcrCodeCount = cptProceduresValue.filter(
    (procedure: { cptCode: { code: string } }) =>
      isRcrCode(procedure.cptCode.code)
  ).length;
  const shouldRenderSingleRcrComponent = rcrCodeCount > 1;

  // To keep track of whether we've already rendered the RCR component
  let rcrComponentRendered = false;

  // Create an array of indices for cptProceduresValue that are RCR codes
  const rcrIndices = cptProceduresValue
    .map((procedure: { cptCode: { code: string } }, index: number) =>
      isRcrCode(procedure.cptCode.code) ? index : -1
    )
    .filter((index: number) => index !== -1);

  // Count how many Osteoarticular Transfer codes are selected.
  const osteoarticularTransferCodeCount = cptProceduresValue.filter(
    (procedure: { cptCode: { code: string } }) =>
      isOsteoarticularTransferCode(procedure.cptCode.code)
  ).length;
  const shouldRenderSingleOsteoarticularTransferComponent =
    osteoarticularTransferCodeCount > 1;

  // To keep track of whether we've already rendered the Osteoarticular Transfer component
  let osteoarticularTransferComponentRendered = false;

  // Create an array of indices for cptProceduresValue that are Osteoarticular Transfer codes
  const osteoarticularTransferIndices = cptProceduresValue
    .map((procedure: { cptCode: { code: string } }, index: number) =>
      isOsteoarticularTransferCode(procedure.cptCode.code) ? index : -1
    )
    .filter((index: number) => index !== -1);

  const hasProceduresWithDetails = () => {
    const procedureDetails = cptProceduresValue.filter(
      (cptProcedureValue: { cptCode: { code: string } }) =>
        DETAIL_COMPONENT_CODES.includes(cptProcedureValue.cptCode.code)
    );

    return procedureDetails.length > 0;
  };

  return hasProceduresWithDetails() ? (
    <KaliberCard title="Procedure Details" titleAlignment="left">
      {fields.map((field, index) => {
        const shouldRenderDivider =
          detailComponentIndices.includes(index) &&
          index !== detailComponentIndices[detailComponentIndices.length - 1];

        const isRcr = isRcrCode(cptProceduresValue[index].cptCode.code);

        // Skip all but the first RCR component if more than one is present.
        if (shouldRenderSingleRcrComponent && isRcr && rcrComponentRendered) {
          return null;
        }

        // Mark that we have rendered an RCR component.
        if (isRcr) {
          rcrComponentRendered = true;
        }

        const isOsteoarticularTransfer = isOsteoarticularTransferCode(
          cptProceduresValue[index].cptCode.code
        );

        // Skip all but the first Osteoarticular Transfer component if more than one is present.
        if (
          shouldRenderSingleOsteoarticularTransferComponent &&
          isOsteoarticularTransfer &&
          osteoarticularTransferComponentRendered
        ) {
          return null;
        }

        // Mark that we have rendered an Osteoarticular Transfer component.
        if (isOsteoarticularTransfer) {
          osteoarticularTransferComponentRendered = true;
        }

        return (
          <>
            {cptProceduresValue &&
              DETAIL_COMPONENT_CODES.includes(
                cptProceduresValue[index].cptCode.code
              ) && (
                <>
                  <div key={field.id}>
                    <div className="review-subtitle">
                      <div id="fs-mask">
                        <div className="region-label">
                          {capitalizeFirstLetter(
                            cptProceduresValue?.[index].cptCode.commonName
                          )}
                        </div>
                      </div>
                    </div>
                    {detailComponent(cptProceduresValue[index].cptCode.code, {
                      register: register,
                      control: control,
                      setValue: setValue,
                      cptProcedureIndex: index,
                      cptCode: cptProceduresValue[index].cptCode.code,
                      graftType:
                        cptProceduresValue[index].procedureDetail?.graftType ??
                        '',
                      regionOfTear:
                        cptProceduresValue[index].procedureDetail
                          ?.regionOfTear ?? '',
                      cptProcedures: cptProceduresValue,
                      rcrIndices: rcrIndices,
                      osteoarticularTransferIndices:
                        osteoarticularTransferIndices
                    })}
                  </div>

                  {shouldRenderDivider && <KaliberDivider />}
                </>
              )}
          </>
        );
      })}
    </KaliberCard>
  ) : (
    <></>
  );
}
