import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import KaliberButton from '../../components/button/KaliberButton';
import {
  PATIENT_PAY_FREE_REPORT_OPTIONS,
  PATIENT_PAY_PAID_REPORT_OPTIONS
} from '../../constants';
import { purchaseAdvancedReport } from '../../redux/actions/paymentsActions';
import {
  KLReview,
  KLState,
  KLUser,
  SurgeryInfo,
  PaymentStatus
} from '../../redux/types';
import { isDefinedAndNotNull } from '../../utils/validations';
import PatientReportComplete from './patientReportComplete/PatientReportComplete';
import CheckCircle from '../../assets/icons/check-circle.svg';
import XCircle from '../../assets/icons/x-circle.svg';
import { v4 as uuidv4 } from 'uuid';
import KLPatientPayCarousel from './patientPayCarousel/KLPatientPayCarousel';
import { triggerCustomGAEvent } from '../../utils/googleAnalytics';

type IncompleteReportComponentProps = {
  rawImagePDFUrl: string;
  rawPdfAvailableContent: string;
  onOpenRawImagePDF: () => void;
};

function IncompleteReportComponent({
  rawImagePDFUrl,
  rawPdfAvailableContent,
  onOpenRawImagePDF
}: IncompleteReportComponentProps): ReactElement {
  if (isDefinedAndNotNull(rawImagePDFUrl) && rawImagePDFUrl.length > 0) {
    return (
      <div>
        <div className="report-status">
          <div className="report-status-verbiage">
            <div className="report-status-verbiage-title">
              Your report is now available
            </div>
            <div className="report-status-verbiage-caption">
              {rawPdfAvailableContent}
            </div>
          </div>
          <div className="report-status-raw-pdf-button">
            <KaliberButton
              buttonClass="btn--secondary"
              type="button"
              onClick={onOpenRawImagePDF}>
              Raw Image PDF
            </KaliberButton>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="report-status">
        <div className="report-status-verbiage">
          <div className="report-status-verbiage-title">
            Your report isn&apos;t quite ready yet
          </div>
          <div className="report-status-verbiage-caption">
            Check back here after your surgery to view your raw image report.
            This report will include annotated photos taken by your doctor
            during your procedure.
          </div>
        </div>
        <div className="report-status-raw-pdf-button">
          <KaliberButton
            buttonClass="btn--secondary"
            type="button"
            onClick={onOpenRawImagePDF}
            disabled>
            Raw Image PDF
          </KaliberButton>
        </div>
      </div>
    );
  }
}

type PaymentComponentProps = {
  onPurchaseReport: () => void;
  onOpenRawImagePDF: () => void;
};

function PaymentComponent({
  onPurchaseReport,
  onOpenRawImagePDF
}: PaymentComponentProps): ReactElement {
  return (
    <div>
      <div className="patient-pay-divider"></div>
      <div className="patient-pay-container">
        <div className="patient-pay-carousel-container">
          <div className="patient-pay-carousel-title">
            Upgrade now to access your in-depth Rekap report
          </div>
          <div className="patient-pay-carousel">
            <KLPatientPayCarousel
              onClick={onPurchaseReport}></KLPatientPayCarousel>
          </div>
        </div>
        <div className="patient-pay-plans-container">
          <div className="patient-pay-plans-title">
            Take charge of your health today
          </div>
          <div className="patient-pay-plans-inner-container">
            <div className="patient-pay-plan">
              <div className="patient-pay-plan-title free">
                Raw Image Report
              </div>
              <div className="patient-pay-plan-cta">
                <div className="patient-pay-plan-cta-title"> $0</div>
                <KaliberButton
                  buttonClass="btn--secondary"
                  type="button"
                  onClick={onOpenRawImagePDF}>
                  View Report
                </KaliberButton>
              </div>
              <div className="patient-pay-plan-items">
                {PATIENT_PAY_FREE_REPORT_OPTIONS.map(item => (
                  <div key={uuidv4()} className="patient-pay-plan-item">
                    <div className="patient-pay-plan-icon">
                      <img src={CheckCircle} alt="" />
                    </div>
                    <div>{item}</div>
                  </div>
                ))}
              </div>
              <div className="patient-pay-plan-items bottom-spacer">
                {PATIENT_PAY_PAID_REPORT_OPTIONS.slice(1).map(item => (
                  <div
                    key={uuidv4()}
                    className="patient-pay-plan-item not-included">
                    <div className="patient-pay-plan-icon">
                      <img src={XCircle} alt="" />
                    </div>
                    <div>{item}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="patient-pay-plan">
              <div className="patient-pay-plan-title paid">Rekap Report</div>
              <div className="patient-pay-plan-cta">
                <div className="patient-pay-plan-cta-title"> $30</div>
                <KaliberButton
                  buttonClass="btn--primary"
                  type="button"
                  onClick={onPurchaseReport}>
                  Upgrade Now
                </KaliberButton>
              </div>
              <div className="patient-pay-plan-items bottom-spacer">
                {PATIENT_PAY_PAID_REPORT_OPTIONS.map(item => (
                  <div key={uuidv4()} className="patient-pay-plan-item">
                    <div className="patient-pay-plan-icon">
                      <img src={CheckCircle} alt="" />
                    </div>
                    <div>{item}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type PatientReportPatientParams = {
  review: KLReview;
  loading?: boolean;
};

export default function PatientReportPatient({
  review,
  loading
}: PatientReportPatientParams): ReactElement {
  const user: KLUser = useSelector((state: KLState) => state.user);

  const [hasLoaded, setHasLoaded] = useState(false);
  const [rawImagePDF, setRawImagePDF] = useState('');
  const [patientReportPDF, setPatientReportPDF] = useState('');

  useEffect(() => {
    const userUid = user.uid;
    triggerCustomGAEvent(
      'patient_report_view',
      `role: patient(uid: ${userUid})`,
      `surgeryId: ${review.surgeryInfo.surgeryId}`
    );
  }, []);

  useEffect(() => {
    for (const annotation of review.annotatedData) {
      if (
        annotation.mediaType === 'pdf' &&
        annotation.filename ===
          `${review.surgeryInfo.surgeryDate}_${review.surgeryInfo.patientLastName}_${review.surgeryInfo.patientFirstName}_raw.pdf`
      ) {
        if (annotation.mediaUrl) {
          setRawImagePDF(annotation.mediaUrl);
        }
      }

      //patientReportPDF
      if (
        annotation.mediaType === 'pdf' &&
        annotation.filename ===
          `${review.surgeryInfo.surgeryDate}_${review.surgeryInfo.patientLastName}_${review.surgeryInfo.patientFirstName}_annotated.pdf`
      ) {
        if (annotation.mediaUrl) {
          setPatientReportPDF(annotation.mediaUrl);
        }
      }
    }
  }, [review]);

  const openRawImagePDF = () => {
    window.open(rawImagePDF, '_blank');

    const userUid = user.uid;
    triggerCustomGAEvent(
      'raw_image_pdf_click',
      `role: patient(uid: ${userUid})`,
      `surgeryId: ${review.surgeryInfo.surgeryId}`
    );
  };

  const rawPdfVerbiage = (): string => {
    const baseVerbiage =
      'Your raw image report is ready. This includes photos captured by your doctor during your procedure.';

    return review.surgeryInfo.paymentStatus === PaymentStatus.Unpaid
      ? `${baseVerbiage} Interested in a more in-depth report? Upgrade to Rekap down below.`
      : baseVerbiage;
  };

  const reportContent = (surgeryInfo: SurgeryInfo) => {
    if (
      surgeryInfo.optedIn &&
      surgeryInfo.surgeryStatus === 'Sent to Patient'
    ) {
      if (review.surgeryInfo.paymentStatus === PaymentStatus.Paid) {
        return (
          <PatientReportComplete
            review={review}
            rawImagePDF={rawImagePDF}
            patientReportPDF={patientReportPDF}
            patientReportPatientViewHasLoaded={hasLoaded}
            setPatientReportPatientViewHasLoaded={setHasLoaded}
          />
        );
      } else {
        return (
          <div className="unpaid-action-group">
            <IncompleteReportComponent
              rawImagePDFUrl={rawImagePDF}
              rawPdfAvailableContent={rawPdfVerbiage()}
              onOpenRawImagePDF={openRawImagePDF}
            />
            <PaymentComponent
              onOpenRawImagePDF={openRawImagePDF}
              onPurchaseReport={() => {
                if (user.accessToken) {
                  purchaseAdvancedReport(
                    user.accessToken,
                    review.surgeryInfo.surgeryId
                  );
                }
              }}
            />
          </div>
        );
      }
    } else {
      return (
        <div className="unpaid-action-group">
          <IncompleteReportComponent
            rawImagePDFUrl={rawImagePDF}
            rawPdfAvailableContent={rawPdfVerbiage()}
            onOpenRawImagePDF={openRawImagePDF}
          />
        </div>
      );
    }
  };

  return (
    <>
      {loading ? (
        <div className="quick-switch__container">
          <div className="surgery-page__skeleton surgery-page__skeleton--content" />
        </div>
      ) : (
        <div data-testid={'patient-report-patient'} className="patient-report">
          <div className="patient-report-container">
            {reportContent(review.surgeryInfo)}
          </div>
        </div>
      )}
    </>
  );
}
