import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KaliberLogo from '../../assets/logos/kaliber-rekap-logo-TM-dark.png';
import KaliberButton from '../../components/button/KaliberButton';
import KaliberModal from '../../components/modal/KaliberModal';
import KaliberTextField from '../../components/textField/KaliberTextField';
import { startResetPassword } from '../../redux/actions/userActions';
import { KLState } from '../../redux/types';
import './CredentialForms.scss';

export interface PatientFormData {
  email: string;
}

export default function ForgotPassword(): ReactElement {
  const history = useHistory();
  const user = useSelector((state: KLState) => state.user);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (user.accessToken) {
      history.push('/');
    }
  }, [user.accessToken]);

  const onSubmit = async (data: PatientFormData) => {
    try {
      startResetPassword(data.email);
      setSuccessModal(true);
    } catch (e) {
      if (e instanceof Error) {
        setSuccessModal(true);
      }
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>();

  return (
    <div>
      <div>
        <div className="credential-container">
          <div className="credential-container__title">
            Welcome to
            <img
              className="credential-container__logo"
              src={KaliberLogo}
              alt=""
            />
          </div>
          <div className="credential-container__form">
            <div className="credential-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="credential-form__header">
                  Reset Your Password
                </div>
                <div className="credential-form__sub-header">
                  Enter your email and we will send you the recovery link to
                  reset your password.
                </div>
                <div className="credential-form__textbox">
                  <KaliberTextField
                    label="Email"
                    name="email"
                    placeholder=""
                    register={register}
                    registerObject={{ required: true }}
                    errors={errors}
                    errorMessage="This email is already in use. Please use another email."
                    tabIndex={0}
                    dataTestId="reset-password-email"
                  />
                </div>
                <div className="credential-form__btn">
                  <KaliberButton
                    buttonClass="btn--primary"
                    type="submit"
                    dataTestId="reset-password">
                    Reset Password
                  </KaliberButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <KaliberModal
        title="Email sent!"
        titleAlignment="center"
        visible={successModal}
        closeIcon={true}
        onClose={() => setSuccessModal(false)}>
        <div className="modal-text">
          If an account with that email exists, an email has been sent with
          instructions for you to reset your password.
        </div>
      </KaliberModal>
    </div>
  );
}
